import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import { theme } from '../../../theme';

const { first } = theme;

// Define element styles for the component
export const Div = styled.div`
  background: ${props => props.color};
  height: 100%;
  padding: 0px 15px;
  width: 100%;
`;

export const H3 = styled.h3`
  color: ${props => props.color};
  padding: 10px 15px;
  @media (min-width: 767px) {
    margin-top: 30px;
  }
`;

export const Ul = styled.ul`
  color: ${props => props.color};
`;

export const Li = styled.li`
  color: ${props => props.color};
`;

export const StyledCol = styled(Col)`
  min-height: 100px;

  @media (max-width: 767px) {
    min-height: 65px;
  }
`;

export const StyledRow = styled(Row)`
  margin-right: -15px;
  margin-left: -15px;
  @media (min-width: 767px) {
    min-height: 100vh;
  }
  @media (max-width: 1580px) { 
    margin-bottom: 50px;
  }
`;

export const ToggleHelpButton = styled.img`
  height: 30px;
  width: auto;
  right: 30px;
  margin: auto;
  position: absolute;
  top: 20px;
  cursor: pointer;

  @media (max-width: 767px) {
    height: 30px;
  }
  @media (min-width: 767px) {
    display: none;
  }
`;

export const HomepageButton = styled.div`
  align-items: center;
  background-color: transparent;
  color: #FFF;
  text-decoration: none;
  cursor: pointer;
  border: none;
  justify-content: center;
  height: 150px;
  width: 100%;
  background: ${first};
  margin-top: 100px;
  cursor: pointer;
  align-self: end;
  @media (max-width: 767px) {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
  }
`;

export const HomepageButtonTitle = styled.h5`
  color: #FFF;
  margin: 25px;
  @media (max-width: 767px) {
    margin: 0px;
    font-size: 15px
   }
`;