import React from 'react';
import PropTypes from 'prop-types';
import { useAvatar } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { DivAvatar, Img, Upload, InputFile } from './styled';
import { Form } from 'react-bootstrap';
import { mediaAvatar } from '../../constants';
import imgAvatar from '../../assets/images/profile/avatar.png';
import imgEdit from '../../assets/images/profile/edit.png';

/**
 * Avatar component with structure, image, upload and icon for Profile
 * @component
 * @param {String} margin
 * @param {Boolean} help
 * @returns
 */
export const Avatar = ({ margin, help }) => {
  const { t } = useTranslation();
  const { srcAvatarImage, avatarSize, onChange } = useAvatar();

  return (
    <>
      <DivAvatar style={{ marginTop: margin }}>
        <Img
          data-image="image-avatar"
          src={(srcAvatarImage) ? srcAvatarImage : imgAvatar}
        />
        <Upload>
          <InputFile
            accept={ mediaAvatar }
            onChange={ onChange }
            name="btn_upload_img"
          />
          <img
            alt="Edit Avatar"
            className="img-avatar"
            src={ imgEdit }
          />
        </Upload>
      </DivAvatar>
      {help && (
        <Form.Text muted>
          <strong className="text-danger">&nbsp;*&nbsp;</strong>
          {t ("profile.avatarSize", { size: avatarSize }) }
        </Form.Text>
      )}
    </>
  );
};

Avatar.propTypes = {
  margin: PropTypes.string.isRequired,
  help: PropTypes.bool.isRequired
};

Avatar.defaultProps = {
  margin: '100px',
  help: false
};