import { store } from '../store/store';
import {
  BASE_LOADING,
  BASE_DELAY,
  TIER_SET_CURRENT_TIER,
  USER_NAVIGATE,
  USER_PROFILE,
  USER_SAVE_REQUEST_TOKEN,
  USER_SET_HELP_SECTION_OPEN,
  USER_SET_PASSWORD
} from '../types';

/**
 * Return action and payload
 * @param {Object} tier
 * @returns
 */
export const setTier = nextTier => ({
  type: TIER_SET_CURRENT_TIER,
  payload: {
    nextTier
  }
});

/**
 * Set loader
 * @param {Boolean} value
 * @returns
 */
export const setLoader = value => ({
  type: BASE_LOADING,
  payload: value
});

/**
 * Set delay
 * @param {Boolean} value
 * @returns
 */
 export const setDelay = value => ({
  type: BASE_DELAY,
  payload: value
});

/**
 * Set password to change
 * @param {Boolean} value
 * @returns
 */
export const setPassword = value => ({
  type: USER_SET_PASSWORD,
  payload: value
});

/**
 * Set help section open
 * @param {Boolean} value
 * @returns
 */
export const setHelpSectionOpen = value => ({
  type: USER_SET_HELP_SECTION_OPEN,
  payload: value
});

/**
 * Save request token
 * @param {String} token
 * @returns
 */
export const saveRequestToken = token => {
  store.dispatch({
    type: USER_SAVE_REQUEST_TOKEN,
    payload: token
  });
};

/**
 * Set navigate
 * @param {Boolean} value
 * @param {String} route
 * @returns
 */
export const setNavigate = (value, route='') => ({
  type: USER_NAVIGATE,
  payload: {
    isNavigate: value,
    route
  }
});

/**
 * Set module selection in profile
 * @param {String} value
 * @returns
 */
export const setModuleProfile = value => ({
  type: USER_PROFILE,
  payload: value
});