import { useEffect, useRef, useState } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  baseField,
  envSetting,
  mediaPNG,
  paramsAdd,
  paramsEdit,
  hiddenField,
  fileStatus
} from '../constants';
import {
  actionTextInput,
  assignDate,
  dataURItoBlob,
  fieldAttribute,
  fieldUniqueName,
  isEmpty,
  isUndefinedNull,
  notUndefinedNull,
  sendFormatDate,
  setMask,
  trimLowerCase
} from '../helpers';
import numeral from 'numeral';
import { useParams } from 'react-router-dom';

/**
 * use to SignatureInput
 * @param {Function} setValue
 * @param {String} uniqueName
 * @returns
 */
export const useSignature = (setValue, uniqueName) => {
  const signature = useRef({});
  const [dataURL, setDataURL] = useState('');
  const [dataBase, setDataBase] = useState('');

  useEffect(() => {
    setDataURL(signature?.current?.toDataURL(mediaPNG));
  }, [dataURL, signature]);

  const clear = () => {
    signature.current.clear();
    setValue(uniqueName, '');
  };

  const handleEnd = () => {
    const dataUrl = signature?.current?.toDataURL(mediaPNG);
    setDataBase(dataUrl);
    const file = dataURItoBlob(dataUrl, `digital_sign.png`);
    setValue(uniqueName, file);
  };

  return { clear, dataBase, handleEnd, signature };
};

/**
 * hook to TextInput component
 * @param {Object} field
 * @param {String} uniqueName
 * @returns
 */
export const useTextInput = (field, uniqueName) => {
  const optionType = new Set(['money', 'url']);
  const type = (optionType.has(trimLowerCase(
    field.data_type))) ? 'text' : trimLowerCase(field.data_type);
  const { defaultValuesTier } = useSelector(state => state.tier);
  const { setValue, getValues } = useFormContext();
  const values = getValues();

  useEffect(() => {
    const mask = (notUndefinedNull(field.mask)) ? JSON.parse(field.mask) : '';

    if (mask?.apply === 'start') setMask(fieldUniqueName(
      field.unique_name), paramsAdd, field.mask);
  }, [defaultValuesTier, field]);

  useEffect(() => {
    if (notUndefinedNull(values[uniqueName]) && !isEmpty(values[uniqueName])) {
      setValue(uniqueName, values[uniqueName]);
    } else {
      if (notUndefinedNull(field.default_value)){
        const defaultValue = JSON.parse(field.default_value);
        if (notUndefinedNull(defaultValue?.text)) setValue(uniqueName, defaultValue?.text);
      }
    }
  }, []);

  return { type };
};

/**
 * hook to Input component
 * @param {Object} field
 * @param {String} type
 * @param {Function} setValue
 * @returns
 */
export const useInput = (field, type, setValue) => {
  const [valueChange, setValueChange] = useState('');
  const fieldAction = (field?.action) ? JSON.parse(field.action) : {};

  const onKeyDown = event => {
    if (type === 'number') {
      const invalidChars = [
        '-',
        '+',
        'e',
        '.'
      ];
      if (invalidChars.includes(event.key)) {
        event.preventDefault();
      }
    }
  };

  const onKeyUp = async ({ target }) => {
    const value = target.value;
    if (value.length >= field.min_length && field.action && valueChange !== value) {
      setValueChange(value);
      await actionTextInput(field, target, value, setValue);
    }
  };

  const onClick = ({ target }, name) => {
    const input = fieldAttribute('name', name);
    target.classList.add('d-none');

    if (target.classList.contains('img-eye-open')) {
      const eyeClosed = fieldAttribute('data-password', name, '.img-eye-closed');
      eyeClosed.classList.remove('d-none');
    } else {
      const eyeOpen = fieldAttribute('data-password', name, '.img-eye-open');
      eyeOpen.classList.remove('d-none');
    }

    if (input.type === 'password') {
      input.type = 'text';
    } else {
      input.type = 'password';
    }
  };

  return { fieldAction, onClick, onKeyDown, onKeyUp };
};

/**
 * hook to MoneyInput Component
 * @param {Object} field
 * @param {String} uniqueName
 * @returns
 */
export const useMoneyInput = (field, uniqueName) => {
  const type = (trimLowerCase(
    field.data_type) === 'number') ? 'text' : trimLowerCase(field.data_type);
  const { setValue, getValues } = useFormContext();
  const { requestLanguage, currency } = useSelector(state => state.base);
  const { rates, field: fields } = useSelector(state => state.staticData[
    `data_${trimLowerCase(requestLanguage)}`]);
  const values = getValues();

  const onChange = event => {
    if (event?.type === 'change') {
      let value = event.target.value.replaceAll(',', '').replaceAll('.', '');

      value = (parseInt(value) >= 0) ? value : '0';
      setValue(uniqueName, value);

      value = (isUndefinedNull(value) || isEmpty(value)) ? '0' : value;

      const labelConversion = fieldAttribute(
        'data-unique-name', `${field.unique_name}_conversion`, 'label');

      if (labelConversion && event?.relatedTarget?.type !== 'select-one') {
        if (labelConversion?.dataset?.parent) {
          let nConversion = value;
          let parent = Array.from(fields).filter(
            field => trimLowerCase(field.id) === trimLowerCase(
              labelConversion.dataset.parent));
          parent = parent?.pop();
          parent = fieldAttribute(
            'name', `${parent?.unique_name}__id__${parent?.id}`);
          const codeParent = parent.options[parent.selectedIndex].dataset?.code;
          const name = labelConversion.getAttribute('name');
          if (!isEmpty(nConversion)) {
            if (rates?.[codeParent]) {
              nConversion = (Number.parseFloat(value) / Number.parseFloat(
                rates[codeParent].rate)).toFixed(0);
              nConversion = (trimLowerCase(
                nConversion) === 'nan') ? '0' : nConversion;
            }
            const labelMask = JSON.parse(field.mask);
            const finalValue = numeral(nConversion).format(
              `0${labelMask.group}0${labelMask.decimal}`);
            labelConversion.textContent = `${currency.code} ${finalValue}`;
          } else {
            labelConversion.textContent = '0,00';
          }
          setValue(name, nConversion);
        }
      }
    }
  };

  useEffect(() => {
    if (notUndefinedNull(values[uniqueName]) && !isEmpty(values[uniqueName])) {
      setValue(uniqueName, values[uniqueName]);
    }
  }, [values]);

  return { onChange, type };
};

/**
 * hook to DateInput Component
 * @param {Object} field
 * @param {String} uniqueName
 * @returns
 */
export const useDateInput = (field, uniqueName) => {
  const { setValue, getValues } = useFormContext();
  const { action } = useSelector(state => state.tier);
  const [selectedDay, setSelectedDay] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const values = getValues(uniqueName);
  const { codeTier } = useParams()

  const onChange = date => {
    if (date) {
      setSelectedDay(date);
      date = sendFormatDate(field, 'send', date);
      const fieldMask = (notUndefinedNull(field.mask)) ? JSON.parse(
        field.mask) : null;
      const newDate = (notUndefinedNull(fieldMask) && !fieldMask?.set_hour) ? date :
        (notUndefinedNull(date)) ? new Date(`${date} 00:00:00`) : '';
      setValue(uniqueName, JSON.stringify(newDate));
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if ((action === paramsEdit || action === paramsAdd || codeTier === fileStatus.rejected || codeTier === fileStatus.expired) && values) {
        if (values !== hiddenField) {
          const valueToDate = new Date(JSON.parse(values));
          const date = {
            day: valueToDate.getDate(),
            month: valueToDate.getMonth() + 1,
            year: valueToDate.getFullYear()
          };

          if (notUndefinedNull(date)) {
            setSelectedDay(new Date(
              date.year,
              date.month - 1,
              date.day
            ));
          }
        }
      } else if (action === paramsAdd) {
        if (notUndefinedNull(selectedDay)) onChange(selectedDay);
      }
    }, 20);
  }, [action, field, uniqueName, values, codeTier]);

  useEffect(() => {
    setStartDate(assignDate(field, baseField.startDate));
    setEndDate(assignDate(field, baseField.endDate));
  }, [field]);

  const formatInputValue = () => {
    if (!selectedDay) return '';

    return (sendFormatDate(field, 'format', selectedDay));
  };

  return { endDate, formatInputValue, onChange, selectedDay, startDate };
};

/**
 * hook to get addresses for autocomplete
 * @param {Function} setValue
 * @param {String} uniqueName
 * @returns
 */
export const useAutocompleteGeolocation = (
  setValue,
  uniqueName
) => {
  const { location, actualLanguage } = useSelector(state => state.base);
  const apiKey = envSetting.googleAutocompleteApiKey;
  let isSelectedPlace = false;

  const { ref } = usePlacesWidget({
    apiKey: apiKey,
    onPlaceSelected: (place) => {
      isSelectedPlace = true;
      setValue(uniqueName, place.formatted_address);
    },
    options: {
      types: ["address"],
      fields: ["formatted_address"],
      componentRestrictions: { country: location },
    },
    language: trimLowerCase(actualLanguage)
  });

  const setValueOnNotSelected = event => {
    if (!isSelectedPlace && notUndefinedNull(apiKey)) {
      setValue(uniqueName, event.target.value);
    } else if (isEmpty(event.target.value)) {
      isSelectedPlace = false;
    }
  };

  return { ref, apiKey, setValueOnNotSelected };
};