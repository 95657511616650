import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { avatarImage, envSetting, mediaAvatar } from '../constants';
import { fieldAttribute } from '../helpers';
import { saveUserImage, setMessage } from '../redux/actions';

/**
 * Hook to avatar
 * @returns
 */
export const useAvatar = () => {
  const dispatch = useDispatch();
  const { userIdBifrost, srcAvatarImage } = useSelector(state => state.user);
  const avatarSize = Math.round(parseInt(
    envSetting.avatarSize) / Math.pow(1024, 2), 2);
  const typeFiles = mediaAvatar.split(',');

  const onChange = ({ target: { files: comingFiles } }) => {
    if (comingFiles) {
      const [file] = comingFiles;
      const [currentFile] = Array.prototype.slice.call(comingFiles);

      if (file?.size > envSetting.avatarSize) {
        return dispatch(setMessage(t("files.errors.maxFileSize",
          { size: avatarSize }), 'error'));
      } else if (!typeFiles.includes(file.type)) {
        return dispatch(setMessage(
          t("files.errors.typeFileNotAllowed"), 'error'));
      }
      if (currentFile?.name) {
        const formMedia = new FormData();
        const imageChange = fieldAttribute('data-image', 'image-avatar');
        imageChange.src = URL.createObjectURL(file);
        formMedia.append(avatarImage, currentFile, currentFile.name);
        dispatch(saveUserImage(userIdBifrost, formMedia, true, false));
      }
    }
  };

  return { srcAvatarImage, avatarSize, onChange };
};