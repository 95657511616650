import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  baseField,
  envSetting,
  fileSumSizeLimit,
  paramsFinish,
  paramsNext,
  routes,
  siteMap,
  statusRequest,
  bankReferencesField,
  bankReferencesElement,
  endpoints,
  paramsAdd
} from '../constants';
import {
  defaultChecked,
  getSchemaTier,
  isUndefinedNull,
  moduleCategories,
  tierMigratable,
  trimLowerCase,
  userTier,
  validateCountry,
  validateEmail,
  validateLength,
  validateRequestType,
  validURL,
  removeFromFormObject, 
  isModuleHidden
} from '../helpers';
import {
  getRejectedFiles,
  personData,
  saveUserImage,
  sendCheckUserTier,
  setLoader,
  setMessage,
  setDelay,
  setNavigate,
  setTier,
  updatePersonData,
  migrateBifrost,
  getUserImages,
  validatePersonData
} from '../redux/actions';
import { t } from 'i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TIER_SET_SCHEMA, TIER_STEPS } from '../redux/types';
import { sendPostRequest } from '../services/baseApi';
import { useParams } from 'react-router-dom';

/**
 * hook to init in Form Component
 * @param {Object} tierFieldState
 * @returns
 */
export const useInitFormTier = tierFieldState => {
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.base);
  const { action, schema } = useSelector(state => state.tier);
  const [schemaYup, setSchemaYup] = useState({});
  const { codeTier } = useParams();

  useEffect(() => {

    setTimeout(() => {
      if (tierFieldState.length > 0 && isUndefinedNull(schema)) {

        const { schemaTier, defaultValues } = getSchemaTier(
          action, tierFieldState, codeTier);
        setSchemaYup(schemaTier);
        dispatch({
          type: TIER_SET_SCHEMA,
          payload: {
            schema: { schema: schemaTier, defaultValues },
            defaultValuesTier: defaultValues
          }
        });
        
      }
    }, 0);

  }, [schema, tierFieldState]);

  return { action, loading, schemaTier: schemaYup };
};

/**
 * Hook to form
 * @param {Object} tier
 * @param {Object} tierFieldState
 * @param {Object} categoriesTier
 * @param {Object} schemaTier
 * @returns
 */
export const useFormTier = (tier, tierFieldState, categoriesTier, schemaTier) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { defaultValuesTier, highstStep } = useSelector(state => state.tier);
  const methods = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(yup.object(schemaTier))
  });
  const { requestLanguage } = useSelector(state => state.base);
  const { tier: allTiers } = useSelector(state => state.staticData[
    `data_${trimLowerCase(requestLanguage)}`]);
  const [hideCategories, setHideCategories] = useState([]);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    setHideCategories(moduleCategories(categoriesTier, tierFieldState));
    defaultChecked(userTier());
  }, [categoriesTier, tierFieldState]);

  // Effect runs when user state is updated
  useEffect(() => {
    // Reset form
    methods.reset(defaultValuesTier);
  }, [defaultValuesTier]);

  useEffect(() => {
    if (allTiers && tier && allTiers.filter(item =>
      trimLowerCase(item.unique_name) === trimLowerCase(
        tier.unique_name) && item.disabled).length > 0) {
      navigate(siteMap.homepage.path);
    }
  }, [allTiers, tier]);

  useEffect(() => {
    if (highstStep === 0 && hideCategories.length > 0) {
      const newSteps = (hideCategories?.filter(item => trimLowerCase(
        item.unique_name) !== baseField.modalValidateSms).map(
          item => item.step).filter((c, index) => {
            return hideCategories?.filter(item => trimLowerCase(
              item.unique_name) !== baseField.modalValidateSms).map(
                item => item.step).indexOf(c) === index;
          }));
      setSteps(newSteps);
      dispatch({
        type: TIER_STEPS,
        payload: (newSteps.length !== 0) ? Math.max(...newSteps) : 1
      });
    }
  }, [highstStep, hideCategories]);

  const onRouteProfile = () => navigate(routes.profile('profile'));

  return { hideCategories, methods, onRouteProfile, steps };
};

/**
 * Hook to action in form
 * @param {Object} hideCategories
 * @param {Object} methods
 * @param {Object} tier
 * @param {Object} tierFieldState
 * @returns
 */
export const useFormSend = (hideCategories, methods, tier, tierFieldState) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { nextTier, highstStep, lastTier, action } = useSelector(state => state.tier);
  const { allowedCountries, location } = useSelector(state => state.base);
  const { data: { id: userId } } = useSelector(state => state.personData);
  const {
    isValidatedPhone,
    userIdBifrost,
    lastProductRequestId,
    dataProductRequest
  } = useSelector(state => state.user);
  const [openModal, setOpenModal] = useState(1);
  const [activeGroup, setActiveGroup] = useState(1);
  const [sendTier, setSendTier] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [finishChanges, setFinishChanges] = useState(false);
  const { formState: { errors } } = methods;

  const getUniqueNameByValueKey = key => key.split('__id__').shift();

  const onChangeGroup = () => setActiveGroup(activeGroup - 1);

  const reUpdatePersonData = async () => {
    await dispatch(updatePersonData(userIdBifrost, lastProductRequestId)) 
    await dispatch(getUserImages(userIdBifrost))
  }

  const onSubmit = async values => {
    try {
      const formObject = validateRequestType(values, tierFieldState, userId);
      const allowed = validateCountry(formObject.elements, allowedCountries, tier.unique_name);

      removeFromFormObject(bankReferencesField, 1, methods, formObject, bankReferencesElement);

      if (allowed) {
        // Leave this comparison, since it can get an array
        if (!isValidatedPhone && hideCategories.filter(
          category => trimLowerCase(
            category.unique_name) === baseField.modalValidateSms).length > 0) {
          setOpenModal(openModal + 1);
          setActiveGroup(1);
          setModalIsOpen(false);
        } else {
          let saveFormMedia = true;
          const migratable = tierMigratable(
            formObject.migratable, tier, finishChanges);
          if (formObject.isElement || formObject.isMedia) {
            dispatch(setLoader(true));
            formObject.elements['country_code'] = location;
            const response = await dispatch(personData(
              formObject, userIdBifrost, lastProductRequestId));
            if (response && !response.error && !response.client_error) {
              if (nextTier?.verification_level) {
                if (tier.verification_level > nextTier.verification_level) dispatch(
                  setTier(tier));
              } else {
                dispatch(setTier(tier));
              }
              if (formObject.isMedia) {
                dispatch(setDelay(true));
                saveFormMedia = await dispatch(saveUserImage(
                  userIdBifrost, formObject.formMedia, false, false));
              }
              if (saveFormMedia) {
                const result = await dispatch(migrateBifrost(
                  userIdBifrost, migratable, userId, lastProductRequestId));
                if (result && !result.error) {
                  if (migratable.migrate){
                    /* This call currently reupdates all of the tiers (intermediate, deeper and advanced) */
                    setTimeout(() => reUpdatePersonData(), 20000)
                  } 
                  if (await dispatch(updatePersonData(userIdBifrost, lastProductRequestId))) {
                    await dispatch(sendCheckUserTier());
                    await dispatch(getUserImages(userIdBifrost))
                    dispatch(getRejectedFiles());
                    if (finishChanges) {
                      navigate(routes.completed(paramsFinish));
                    } else {
                      navigate(routes.completed(paramsNext));
                    }
                    dispatch(setLoader(false));
                    dispatch(setDelay(false));
                  }
                } else {
                  dispatch(setNavigate(false));
                  dispatch(setLoader(false));
                  dispatch(setDelay(false));
                }
              } else {
                dispatch(setNavigate(false));
                dispatch(setLoader(false));
                dispatch(setDelay(false));
              }
            } else {
              if (!nextTier?.verification_level) dispatch(setTier([]));
              dispatch(setLoader(false));
              dispatch(setDelay(false));
            }
          } else {
            if (finishChanges) {
              dispatch(setLoader(true));
              const migratable = {
                'migrate': true,
                'edit': true,
                'tier': lastTier
              };
              const result = await dispatch(migrateBifrost(
                userIdBifrost, migratable, userId, lastProductRequestId));
              if (result && !result.error) {
                if (migratable.migrate){
                  /* This call currently reupdates all of the tiers (intermediate, deeper and advanced) */
                  setTimeout(() => reUpdatePersonData(), 20000)
                } 
                dispatch(setLoader(false));
                navigate(routes.completed(paramsFinish));
              } else {
                dispatch(setLoader(false));
              }

            } else {
              navigate(routes.completed(paramsNext));
            }
          }
        }
      } else {
        dispatch(setMessage(t("common.countryNotAllowed"), 'error'));
      }
    } catch (error) {
      console.error('onSubmit in useFormTier: ', error);
      dispatch(setMessage(t("message.errorFormTier"), 'error'));
    }
  };

  const onContinueStep = async () => {
    const values = methods.getValues();
    const tierFieldStateByStep = tierFieldState.filter(
      item => (item.step === activeGroup) && (
        trimLowerCase(item.unique_name) !== baseField.modalValidateSms));
    const tierStepFile = tierFieldState.filter(
      item => (item.step === activeGroup) && (
        trimLowerCase(item.group) === baseField.validateGroupFile));
    const isSendTier = tierFieldState.filter(item => {
      if (activeGroup > 1) {
        return (item.step === activeGroup) && (trimLowerCase(
          item.group) === baseField.sendStepTier);
      } else {
        return true;
      }
    });
    setSendTier(isSendTier);

    const valuesArray = values ? Object.keys(values)
      .map(function (key) {
        const [selectedField] = tierFieldStateByStep?.filter(
          item => trimLowerCase(item.unique_name) === trimLowerCase(
            getUniqueNameByValueKey(key)));
        return selectedField?.id ? { value: values[key], ...selectedField } : {};
      }).filter(item => item)
      : null;

    let listMediaFields;
    let totalFilesSize = 0;
    if (tierStepFile.length > 0) {
      listMediaFields = tierFieldStateByStep?.filter(item =>
        item.data_type === 'media_upload' &&
        item.group === 'file' &&
        item.hasOwnProperty('input_file')
      );

      totalFilesSize = listMediaFields.reduce(
        (previousValue, currentValue) =>
          previousValue + currentValue.file_size, totalFilesSize
      );

      if (totalFilesSize > envSetting.maxTotalFilesSize) {
        dispatch(setMessage(t("files.errors.totalSumMaxFilesSize",
          { size: fileSumSizeLimit }), 'error'));
      }
    }

    const validateArray = valuesArray.filter(
      item => item.step === activeGroup && ((
        item.required && item.value !== false && !item.value && !isModuleHidden(item)) || (
          item.required && (Array.isArray(item) && item?.length === 0)) || (
            item.required && trimLowerCase(item.data_type) === 'url' && !validURL(item.value)) || (
              item.required && trimLowerCase(item.data_type) === 'email' && !validateEmail(item.value)) || (
                validateLength(item.unique_name, item.id))));
    
    if (validateArray.length > 0) {
      dispatch(setMessage(t("common.missingFields"), 'error'));
      methods.clearErrors();
    
      let fieldNames = [];
      tierFieldStateByStep?.forEach(field => {
        const uniqueName = `${field.unique_name}__id__${field.id}`;
        methods.trigger(uniqueName);
        fieldNames.push(uniqueName);
      });
  
      if (fieldNames?.length > 0 && errors) {
        fieldNames.forEach(uniqueName => {
          const errorsListNames = Array.from(Object.keys(errors));
          if (errorsListNames?.includes(uniqueName)) {
            methods.setError(uniqueName, { message: errors[uniqueName].message });
          }
        });
      }
    } else {
      if (highstStep > 1) {
        setActiveGroup(activeGroup + 1)
        if(action === paramsAdd){
          const formObject = validateRequestType(values, tierFieldStateByStep, userId);
          removeFromFormObject(bankReferencesField, 1, methods, formObject, bankReferencesElement);
          const validatedElements = validatePersonData(formObject.elements)
          validatedElements['country_code'] = location;
          await sendPostRequest(validatedElements, endpoints.personData(userIdBifrost, lastProductRequestId))
        }
      }; 
    }

  };

  const onChangeStep = () => {
    if (activeGroup > 1) {
      if (highstStep === activeGroup) {
        setSendTier(true);
      }
    }
    const values = methods.getValues();
    onSubmit(values);
  };

  const getTrigger = async () => {
    methods.clearErrors();
    const trigger = await methods.trigger();
    if (!trigger) {
      dispatch(setMessage(t("common.missingFields"), 'error'));
      return trigger;
    }
    return trigger;
  };

  const onSave = async () => {
    if (await getTrigger()) {
      const [productRequest] = Array.from(dataProductRequest).filter(
        item => item.id === lastProductRequestId);
      if (statusRequest.processing !== productRequest.status.unique_description) {
        const values = methods.getValues();
        onSubmit(values);
      } else {
        setModalMessage(t("message.modalConfirmation"));
        setModalIsOpen(true);
      }
    }
  };

  const onFinishRequest = async () => {
    if (await getTrigger()) {
      setModalMessage(t("message.modalFinishRequest"));
      setModalIsOpen(true);
      setFinishChanges(true);
    }
  };

  return {
    activeGroup,
    highstStep,
    lastTier,
    modalIsOpen,
    modalMessage,
    onChangeGroup,
    onChangeStep,
    onContinueStep,
    onFinishRequest,
    onSave,
    onSubmit,
    openModal,
    sendTier,
    setModalIsOpen
  };
};
