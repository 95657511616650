import CryptoJS from 'crypto-js';
import { envSetting } from '../constants';

/**** Export functions  ****/
/**
 * Encrypt text with AES
 * @param {String} text
 * @returns
 */
export const encryptAES = text => {
  const encryptedText = CryptoJS.AES.encrypt(
    text, envSetting.cryptoAesPassword).toString();
  return encryptedText;
};

/**
 * Decrypt text with AES
 * @param {String} text
 * @returns
 */
export const decryptAES = text => {
  const bytes = CryptoJS.AES.decrypt(text, envSetting.cryptoAesPassword);
  const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
  return decryptedText;
};