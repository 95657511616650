import i18n from '../../translations/i18n';
import {
  BASE_ACTIVE_LOCATION,
  BASE_ALLOWED_COUNTRIES,
  BASE_GEOLOCATION,
  BASE_LOCATION,
  BASE_QRROUTE,
  BASE_REQUEST,
  BASE_SET_ELEMENTS,
  TIER_LAST_TIER
} from '../types';
import { endpoints, envSetting, endpointNominatim } from '../../constants';
import { sendGetEndpoint, sendPostRequest } from '../../services/baseApi';
import { setMessage, setLoader } from '.';
import {
  getGeolocation,
  handleRequest,
  searchLocation,
  trimLowerCase,
  verifyGeolocationPermissions
} from '../../helpers';
import { store } from '../store/store';
import { publicIpv4 } from 'public-ip';

/**
 * Call to endpoint to get base
 */
export const getBase = appRequest => async dispatch => {
  const { actualLanguage } = store.getState().base;
  const { appOrigin } = store.getState().user;
  const { [`data_${trimLowerCase(actualLanguage)}`]: data } = store.getState().staticData;
  const codeCountry = trimLowerCase(envSetting.baseCountry);
  const codeCurrency = trimLowerCase(envSetting.baseCurrency);
  const [baseCountry] = Array.from(data?.country).filter(
    item => trimLowerCase(item.code) === codeCountry);
  const [baseCurrency] = Array.from(data?.currency).filter(
    item => trimLowerCase(item.code) === codeCurrency);
  const [application] = Array.from(data?.application).filter(
    item => trimLowerCase(item.unique_name) === trimLowerCase(appRequest || appOrigin));
  const [lastTier] = Array.from(data?.tier).filter(
    item => trimLowerCase(item.id) === trimLowerCase(application?.tier));

  if (baseCountry?.country_id) {
    dispatch({ type: BASE_SET_ELEMENTS, payload: { country: baseCountry }});
  }

  if (baseCurrency?.currency_id) {
    dispatch({ type: BASE_SET_ELEMENTS, payload: { currency: baseCurrency }});
  }

  if (lastTier?.id) {
    dispatch({ type: TIER_LAST_TIER, payload: lastTier });
  }
};

/**
 * Get endpoint
 * @param {Object} getValues
 * @returns
 */
export const sendGetEndpointData = async getValues => {
  try {
    const endpoint = (getValues?.params === 'value') ? `/${getValues?.name}/${
      getValues?.compare}` : `/${getValues?.name}`;
    const params = (getValues?.params === 'key') ? getValues.compare : '';
    const response = await sendGetEndpoint(endpoint, params);
    return response;
  } catch (error) {
    console.error('sendGetEndpointData: ', error);
    return;
  }
};

/**
 * Get allowed countries
 * @returns
 */
export const getAllowedCountries = () => async dispatch => {
  try {
    const response = await sendGetEndpoint(endpoints.allowedCountries);
    dispatch(handleRequest(response));
    if (response && !response.error) {
      dispatch({ type: BASE_ALLOWED_COUNTRIES, payload: response.success });
    }
  } catch (error) {
    console.error('getAllowedCountries: ', error);
    dispatch(setMessage(i18n.t("message.errorAllowedCountries"), 'error'));
    dispatch(setLoader(false));
    return;
  }
};

/**
 * Get allowed user geolocation
 * @returns
 */
export const getUserGeolocation = () => async dispatch => {
  try {
    const result = await dispatch(getPermissionState());
    const response = await getGeolocation();
    dispatch(handleRequest(response));
    if (response && !response.error && result?.state !== 'denied') {
      dispatch({ type: BASE_GEOLOCATION, payload: response.data });
      dispatch({ type: BASE_LOCATION, payload: response.data.country });
    }
    return response;
  } catch (error) {
    console.error('getUserGeolocation: ', error);
    dispatch(setLoader(false));
    dispatch(setMessage(i18n.t("common.locationPermissionDenied"), 'error'));
    dispatch(setMessage(i18n.t("message.geolocation"), 'error'));
    return;
  }
};

/* Get an approximation of user geolocation */
export const getAproxUserGeolocation = () => async dispatch => {
  try {
    const ip =  await publicIpv4()
    const params = {
      ip_address: ip
    };
    
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    };

    const geolocationApi = await fetch(`${envSetting.wisenrollApi}${endpoints.ipAddressInfo}`, options)
      .then(data => {
          if (!data.ok) {
            dispatch(setLoader(false));
            dispatch(setMessage(i18n.t("message.geolocation"), 'error'));
            return;
          }
          return data.json();
        });
    const geolocationRes = geolocationApi.success;
    const latitude = geolocationRes.latitude
    const longitude = geolocationRes.longitude

    const geolocationObject =  {
      country: geolocationRes.country.toUpperCase(),
      state: geolocationRes.state,
      city: geolocationRes.city,
      latitude,
      longitude
    }

    const url = `${endpointNominatim.nominatim}reverse?format=geojson&lat=${
      latitude}&lon=${longitude}`;
    const response = await fetch(url);
    const result = await response.json();
    const [{ properties }] = result.features;
    if(properties?.address){
      dispatch(handleRequest(geolocationObject))
    }

    if(geolocationApi && geolocationObject){
      dispatch({ type: BASE_GEOLOCATION, payload: geolocationObject });
      dispatch({ type: BASE_LOCATION, payload: geolocationObject.country });
      return geolocationObject
    }
  } catch (error) {
    dispatch(setLoader(false));
    dispatch(setMessage(i18n.t("message.geolocation"), 'error'));
    console.error('getAproxUserGeolocation: ', error);
    return;
  }
}


/**
 * get permission
 * @returns
 */
export const getPermissionState = () => async dispatch => {
  try {
    const response = await verifyGeolocationPermissions();
    dispatch(handleRequest(response));
    if (response && !response.error) {
      dispatch({ type: BASE_ACTIVE_LOCATION, payload: response.permission });
    }
    return response.permission;
  } catch (error) {
    console.error('getPermissionState: ', error);
    dispatch(setLoader(false));
    dispatch(setMessage(i18n.t("message.errorPermissionsState"), 'error'));
    return;
  }
};

/**
 * Get Available assistants
 *  @param {Object} data
 * @returns
 */
 export const getAvailableAssistants = data => async dispatch => {
  try {
    const response = await sendPostRequest(data, endpoints.availableAssistants);
    if (response && !response.error) {
      return response.success;
    }
    return;
  } catch (error) {
    console.error('getAvailableAssistants', error);
    dispatch(setMessage(i18n.t("assistants.errorGetAssistants"), 'error'));
    dispatch(setLoader(false));
    return;
  }
};

/**
 * Send the request to the assistant
 * @param {Object} data
 * @returns
 */
 export const notifyAssistant = data => async dispatch  => {
  try {
    const response = await sendPostRequest(data, endpoints.notifyAssistant);
    dispatch(handleRequest(response));
    if (response && !response.error) {
      return response;
    }
    return;
  } catch (error) {
    console.error('notifyAssistant', error);
    dispatch(setMessage(i18n.t("assistants.errorNotifyAssistant"), 'error'));
    dispatch(setLoader(false));
    return;
  }
};

/**
 * Verify the request token of the assistant
 * @param {Object} data
 * @returns
 */
 export const verifyAssisantCode = data => async dispatch  => {
  try {
    const response = await sendPostRequest(data, endpoints.verifyAssistant);
    dispatch(handleRequest(response));
    if (response && !response.error) {
      return response;
    }
    return;
  } catch (error) {
    console.error('verifyAssistantCode', error);
    dispatch(setMessage(i18n.t("assistants.errorVerifyAssistant"), 'error'));
    dispatch(setLoader(false));
    return;
  }
};

/**
 * Get location details by parameters
 * @param {Object} data
 * @returns
 */
 export const getLocationByParameter = data => async dispatch  => {
  try {
    const response = await searchLocation(data);
    dispatch(handleRequest(response));
    if (response && !response.error) {
      return response.data;
    }
    return;
  } catch (error) {
    console.error('getLocationByParameter', error);
    dispatch(setMessage(i18n.t("message.errorLocationByParameter"), 'error'));
    dispatch(setLoader(false));
    return;
  }
};

export const setRequest = boolean => ({
  type: BASE_REQUEST,
  payload: boolean
});

/**
 * Set loader
 * @param {Object} value
 * @returns
 */
export const setQRRoute = value => ({
  type: BASE_QRROUTE,
  payload: value
});