import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHelp } from '../../../hooks';
import { useTranslation } from 'react-i18next';
import { Col } from 'react-bootstrap';
import {
  Div,
  H3,
  Ul,
  Li,
  StyledCol,
  ToggleHelpButton,
  HomepageButton,
  HomepageButtonTitle,
  StyledRow
} from './styled';
import { LogoHelp } from '../simple';
import { appsOrigin, logoBgAppOrigin } from '../../../constants';
import { setHelpSectionOpen } from '../../../redux/actions';
import { theme } from '../../../theme';
import helpButton from '../../../assets/images/shared/help-button.svg';

/**
 * Component for messages help
 * @component
 * @param {Boolean} backButton
 * @param {Boolean} isProduct
 * @param {Object} selectedProduct
 * @returns
 */
export const Help = ({ backButton, isProduct, selectedProduct }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isHelpOpen, tier, nextTier, listHelp, goBack } = useHelp();
  const { infoProductRequest } = useSelector(state => state.user);
  const { first, sixth } = theme;
  const parseHTML = html => ({ __html: html });
  const { applications } = useSelector(state => state.user);
  let selectedApp;
  if (selectedProduct) {
    selectedApp = applications?.find(app => app.description === appsOrigin[
      selectedProduct.unique_name]);
  }

  return (
    <Div color={tier?.main_color || nextTier?.main_color
      ? `#${tier?.main_color || nextTier?.main_color}` : `${first}`}>
      <StyledRow style={!backButton ? { minHeight: 0 } : {}}>
        <StyledCol xs={12} className="d-flex py-4">
          <LogoHelp
            src={
              selectedProduct ?
              (selectedApp?.image_main || logoBgAppOrigin.default) :
              (isProduct ? logoBgAppOrigin.default : infoProductRequest?.image_main || logoBgAppOrigin.default)
            }
          />
          {listHelp?.length > 0 && (
            <ToggleHelpButton
              onClick={() => dispatch(setHelpSectionOpen(!isHelpOpen))}
              src={ helpButton }
            />
          )}
        </StyledCol>
        {(listHelp?.length > 0 && isHelpOpen) && (
          <>
            <Col xs={12}>
              <H3 color={ sixth }>
                { t("common.help") }
              </H3>
            </Col>
            <Col xs={12}>
              <Ul color={ sixth }>
                {listHelp.map(help => {
                  return (
                    <Li
                      className="p-2"
                      name={`li_help_${help.id}`}
                      key={`li_${help.id}`}
                      color={ sixth }
                    >
                      { help?.text }
                      {help?.html && (
                        <div dangerouslySetInnerHTML={parseHTML(help.html)}></div>
                      )}
                    </Li>
                  );
                })}
              </Ul>
            </Col>
          </>
        )}
        {(backButton && isHelpOpen) && (
          <HomepageButton onClick={() => goBack()}>
            <HomepageButtonTitle name="link_back_home">
              { t("common.backHomepage") }
            </HomepageButtonTitle>
          </HomepageButton>
        )}
      </StyledRow>
    </Div>
  );
};

Help.propTypes = {
  backButton: PropTypes.bool
};

Help.defaultProps = {
  backButton: true
};