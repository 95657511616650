import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, trimLowerCase } from '../../../helpers';

export const OptionItem = ({
  options,
  text,
  id,
  type,
  defaultValue,
  languageText
}) => {
  return (
    <>
      {options?.map(option => {
        if (type === 'simple') {
          if (!isEmpty(option?.[text].trim())) {
            return (
              <option
                id={ option[id] }
                key={ option[id] }
                value={ option[id] }
              >
                { option[text] }
              </option>
            );
          }
          return null;
        }
        if (type === 'tier') {
          if (option?.active && !option?.exclude) {
            if (trimLowerCase(option?.idValue?.toString()) === trimLowerCase(
              defaultValue?.toString()) || trimLowerCase(
                option?.codeValue?.toString()) === trimLowerCase(defaultValue?.toString())) {
              return (
                <option
                  data-code-parent={ option?.codeParent }
                  data-code={ option?.codeValue }
                  data-id={ option?.codeId }
                  id={ option.idValue }
                  key={ option.idValue }
                  value={ option.idValue }
                  selected
                >
                  { (option?.[`textValue${languageText}`])
                    ? (option[`textValue${languageText}`])
                    : option.textValue }
                </option>
              );
            } else {
              return (
                <option
                  data-code-parent={ option?.codeParent }
                  data-code={ option?.codeValue }
                  data-id={ option?.codeId }
                  id={ option.idValue }
                  key={ option.idValue }
                  value={ option.idValue }
                >
                  { (option?.[`textValue${languageText}`])
                    ? (option[`textValue${languageText}`])
                    : option.textValue }
                </option>
              );
            }
          }
          return null;
        }
        return null;
      })}
    </>
  );
};

OptionItem.propTypes = {
  options: PropTypes.array.isRequired,
  text: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string.isRequired,
  defaultValue: PropTypes.any,
  languageText: PropTypes.string
};