import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { trimLowerCase } from './../../../../helpers';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { blockEditPath, editionStatus } from '../../../../constants';

/**
 * Component button
 * @component
 * @param {Object} field
 * @param {Boolean} isBlockDisabled
 * @param {Boolean} loading
 * @param {String} action
 * @param {Funciton} handleCheck
 * @param {Object} entry
 * @param {Object} selectedProduct
 * @returns
 */
export const Validate = (
  {
    field,
    isBlockDisabled = false,
    loading = false,
    action,
    handleCheck,
    entry,
    selectedProduct
  }
) => {
  const { t } = useTranslation();
  const uniqueName = field ? `${field.unique_name}__id__${field.id}` : 'btn-check';
  let location = useLocation();
  /* The validation button on the old tier Advanced edition path must be disabled */
  let isPathDisabled = location.pathname === blockEditPath;
  let isPending = selectedProduct?.status === editionStatus.pending;

  const handleClick = (e) => {
    e.target.blur();
    handleCheck();
  };

  return (
    <Col
      className="col-md-6 col-xl-4 d-flex align-items-center"
      data-module={ field ? `module-${field?.category?.field_category}` : ''}
      data-name={ field ? uniqueName : 'validate-btn'}
      id={ field ? `group_field_${field?.id}` : '' }
      xs={12}
    >
      <button
        onClick={handleClick}
        type='button'
        className={`w-100 btn btn-outline-secondary mt-1 rounded-3 ${loading ? 'disabled' : 'shadow-sm'}`}
        id={ field ? trimLowerCase(field?.id) : '' }
        disabled={isBlockDisabled || isPathDisabled || isPending }
        data-unique-name={field ? field?.unique_name : ''}
      >
        <span>
          { t("block.blockCheck") }
        </span>
      </button>
    </Col>
  );
};

Validate.propTypes = {
  field: PropTypes.object,
  isBlockDisabled: PropTypes.bool,
  loading: PropTypes.bool,
  action: PropTypes.string,
  handleCheck: PropTypes.func.isRequired,
  entry: PropTypes.object,
  selectedProduct: PropTypes.object
};