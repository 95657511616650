import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { Category } from './Category';
import { CloseImage } from '../user/styled';
import { customStyles } from '../../constants';
import { useTokenTier } from '../../hooks';
import closeIcon from '../../assets/images/token-modal/close.png';
import Modal from 'react-modal';

/**
 * Token component
 * @component
 * @param {Object} category
 * @param {Number} openModal
 * @param {Object} tier
 * @param {Object} tierFieldState
 * @returns
 */
export const Token = ({ category, openModal, tier, tierFieldState}) => {
  const { setValue } = useFormContext();
  const {
    loading,
    modalIsOpen,
    sendToken,
    setModalIsOpen,
    showTimer,
    submitModal,
    tokenSms
  } = useTokenTier(openModal, tier, tierFieldState);

  return (
    <Modal
      ariaHideApp={ false }
      key={ `modal_${category.unique_name}_${category.id}` }
      isOpen={ modalIsOpen }
      onAfterOpen={() => {
        setValue(`${tokenSms.unique_name}__id__${tokenSms.id}`, '');
        sendToken();
      }}
      onRequestClose={() => setModalIsOpen(false)}
      onAfterClose={() => {
        setValue(`${tokenSms.unique_name}__id__${tokenSms.id}`, '');
      }}
      style={ customStyles }
    >
      <CloseImage
        alt=""
        key={ `close_${category.unique_name}_${category.id}` }
        onClick={() => setModalIsOpen(false)}
        src={ closeIcon }
      />
      <Category
        category={ category }
        loading={ loading }
        key={ `category_${category.unique_name}_${category.id}` }
        onClickToken={() => {
          setValue(`${tokenSms.unique_name}__id__${tokenSms.id}`, '');
          sendToken();
        }}
        showTimer={ showTimer }
        submitModal={ submitModal }
      />
    </Modal>
  );
};

Token.propTypes = {
  category: PropTypes.object.isRequired,
  openModal: PropTypes.number.isRequired,
  tier: PropTypes.object.isRequired,
  tierFieldState: PropTypes.array.isRequired
};