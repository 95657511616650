import { STATIC_DATA_CLEAR, STATIC_DATA_SET_DATA } from '../types';

const initState = {};

/**
 * Reducer to static data
 * @param {Object} state
 * @param {Object} action
 * @returns
 */
export const staticDataReducer = (state=initState, action) => {
  switch (action.type) {
    case STATIC_DATA_CLEAR:
      return initState;

    case STATIC_DATA_SET_DATA:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};