import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAppData, setLogout } from '../redux/actions';
import { appsOrigin, routes, siteMap } from '../constants';
import { validateAppOrigin } from '../helpers';
import { BASE_ERROR_REQUEST, USER_APP_ORIGIN, PERSON_DATA_CLEAR_DATA_SENT } from '../redux/types';

/**
 * Hook to validate appOrigin with params
 */
export const useAppOriginParam = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { appOrigin, isAuthenticated, applications } = useSelector(state => state.user);
  const { location } = useSelector(state => state.base)
  const [valid, setValid] = useState(false);

  useEffect(() => {
    const getUniqueNames = () => {
      let uniqueNames = [];
      uniqueNames = applications?.map(app => {
        if (app?.application?.create_account || app?.application?.require_validate_invitation) {
          return app.application.unique_name;
        };
        return null;
      });
      return uniqueNames;
    };

    const appsUniqueNames = Object.values(appsOrigin).concat(getUniqueNames());
    if (!appsUniqueNames?.includes(params.appOrigin)) {
      navigate(siteMap.login.path2);
    }
  }, [params.appOrigin]);

  useEffect(() => {
    if (!location?.pathname?.includes('logout')) setValid(validateAppOrigin(appOrigin, params?.appOrigin, isAuthenticated));
  }, [appOrigin, params]);

  useEffect(() => {
    if (valid) navigate(siteMap.login.path2);
  }, [valid, navigate]);
};

/**
 * Hook to validate appOrigin
 */
export const useAppOrigin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { appOrigin } = useSelector(state => state.user);
  const { data } = useSelector(state => state.personData);
  const { appOriginData } = getAppData(data, null);
  const ebankingCreationPending = useSelector(
    state => state?.personData?.data?.product_request?.user?.ebanking_user_creation_pending);
  const uniqueName = useSelector(
    state => state?.personData?.data?.product_request?.application_origin?.unique_name);
  const { QRRoute } = useSelector(state => state.base);


  useEffect(() => {
    if (appOrigin === appsOrigin.default || !appOrigin) {
      if (appOriginData) {
        dispatch({ type: USER_APP_ORIGIN, payload: appOriginData });
      } else {
        dispatch(setLogout());
        navigate(siteMap.login.path2);
      }
    }
  }, [appOrigin, appOriginData, dispatch, navigate]);

  useEffect(() => {
    if (ebankingCreationPending) {
      navigate(routes.createEbankingUsername(uniqueName));
    }
  }, [ebankingCreationPending]);

  /* Redirecting to the view for the autovideo recording after scaning QR  */
  useEffect(() => {
    if (QRRoute?.active) {
      setTimeout(() => {
        navigate(routes.QRRoute(QRRoute.peerId), { replace: true } );
      }, 50);
    }
  }, [QRRoute?.active]);

  /* Clearing redux.data_sent, when getting into the homepage. */
  useEffect(() => {
    dispatch({
      type: PERSON_DATA_CLEAR_DATA_SENT
    })
  }, [])
};

/**
 * Validate if error to navigate
 */
export const useError = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { code, error } = useSelector(state => state.base);

  useEffect(() => {
    if (error && code) {
      if ((code).toString() !== '400') navigate(routes.errors(code));
      dispatch({
        type: BASE_ERROR_REQUEST,
        payload: {
          error: false,
          code: 200
        }
      });
    }
  }, [error, code, dispatch, navigate]);
};