

export const endpointNominatim = {
  nominatim: 'https://nominatim.openstreetmap.org/'
};

export const querysNominatim = {
  listQuery: ['street', 'city', 'county', 'country', 'state', 'postalcode'],
  street: 'street',
  city: 'city',
  county: 'county',
  state: 'state',
  country: 'country',
  postalCode: 'postalcode'
};

export const uniqueAddress = {
  postalCode: 'postal_code',
  address: 'address',
  country: 'country'
};

export const uniqueNameProduct = 'assistant';