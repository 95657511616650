import React from 'react';
import styled from 'styled-components';
import { Image } from 'react-bootstrap';

// Define element styles for the component
const StyledLogo = styled(Image)`
  height: 100px;
  left: 30px;
  margin: auto;
  top: 20px;
  width: 80%;
  object-fit: contain;

  @media (max-width: 767px) {
    top: 0px;
    height: 65px;
    left: 20px;
    width: 200px;
  }
`;

/**
 * Logo component
 * @component
 * @returns
 */
export const LogoHelp = ({ ...props }) => {
  return <StyledLogo { ...props } />;
};
