import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useCheckboxRadio, useDisabled, useEdit, useTextLanguage } from '../../../hooks';
import { Col } from 'react-bootstrap';
import { StyledGroup } from '../../shared/Input/styled';
import { Error, Label, TooltipIcon } from '../../shared/simple';
import { StyledCheck } from '../styled';

/**
 * Component checkbox select
 * @component
 * @param {Object} field
 * @returns
 */
export const CheckboxSelect = ({ field }) => {
  const uniqueName = `${field.unique_name}__id__${field.id}`;
  const { description, languageText } = useTextLanguage();
  const { formState: { errors }, getValues } = useFormContext();
  const { onClick, options } = useCheckboxRadio(field, true);
  const { colSize } = useEdit(field);
  const { isDisabled } = useDisabled(field);
  const values = getValues(uniqueName)

  return (
    <>
      {field && (
        <>
          {(options && colSize?.includes('col-before')) && (
            <Col xs={12} key={`col_before_${field.id}`}></Col>
          )}
          {options && (
            <Col
              className={ colSize }
              data-module={ `module-${field?.category?.field_category}` }
              data-name={ uniqueName }
              data-type="checkbox"
              id={ `group_field_${field.id}` }
              key={ field.id }
              xs={12}
            >
              <StyledGroup
                aria-labelledby="checkbox-group"
                className="mb-3"
                role="group"
                data-action={ field.action }
                data-category={ `category-${field.category?.field_category}` }
                data-condition={ field.parent_condition }
                data-parent={ field.parent }
                id={ field.id }
              >
                <Label
                  className="d-block"
                  htmlFor={ uniqueName }
                  xs={12}
                >
                  { field[description] }{ (field.required) && (
                    <strong className="text-danger">&nbsp;*</strong>) }
                  { (field?.[`tooltip${languageText}`]) && (
                    <TooltipIcon tooltipText={ field?.[`tooltip${languageText}`] } />) }
                </Label>
                {options?.map(option => {
                  if (option?.active && !option?.exclude) {
                    return (
                      <StyledGroup
                        className="mb-3"
                        key={ `group_check_${option.idValue}` }
                      >
                        <StyledCheck
                          data-category={
                            `category-${field?.category?.field_category}` }
                          data-code-parent={ option?.codeParent }
                          data-code={ option?.codeValue }
                          data-id={ option?.codeId }
                          data-object={ JSON.stringify(option) }
                          data-unique-name={ field.unique_name }
                          data-pep={ option?.pep }
                          data-field-id={ field.id }
                          id={ option.idValue }
                          key={ option.idValue }
                          label={ (option?.[`textValue${languageText}`])
                            ? (option[`textValue${languageText}`])
                            : option.textValue }
                          name={ uniqueName }
                          onClick={ onClick }
                          type="checkbox"
                          value={ option.idValue }
                          disabled={ isDisabled }
                          defaultChecked={(values && Array.isArray(values)) ? values?.some(value => String(value) === String(option.idValue) ): false}
                        />
                      </StyledGroup>
                    );
                  }
                  return null;
                })}
                {errors?.[uniqueName]?.message && (
                  <Error message={ errors[uniqueName].message } />
                )}
              </StyledGroup>
            </Col>
          )}
        </>
      )}
    </>
  );
};

CheckboxSelect.propTypes = {
  field: PropTypes.object.isRequired
};