import React from 'react';
import { Col } from 'react-bootstrap';
import { Help } from '../components/shared/Help';
import { Completed } from '../components/tier/Completed';

/**
 * Page tier
 * @returns {JSX}
 */
const CompletedPage = () => {
  return (
    <>
      <Col className="p-0" xl={3} md={4} xs={12}>
        <Help isButton={true} />
      </Col>
      <Col className="p-4" xl={9} md={8} xs={12}>
        <Completed />
      </Col>
    </>
  );
};

export default CompletedPage;