import React from 'react';
import { useAllowedCountries, useInitUser, useRecoverPassword } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { Button, StyledForm, Label, RouterLink, LoaderSpinner } from '../shared/simple';
import { Container } from 'react-bootstrap';
import { Div, StyledImage, StyledLogo, StyledRow } from './styled';
import { characters, routes } from '../../constants';
import { Input } from '../shared/Input';
import { LinkLogo } from './LinkLogo';
import { useSelector } from 'react-redux';
import SlideCaptchaModal from '../shared/slide-captcha-modal/SlideCaptchaModal';
import ImageAds from '../../assets/images/login/wisenroll.png';

/**
 * Change Password Component
 * @component
 * @returns
 */
export const ChangePassword = () => {
  const { t } = useTranslation();
  const { allowed } = useAllowedCountries();
  const {
    appOrigin,
    isSlideModalOpen,
    loading,
    onSubmit,
    setIsSlideModalOpen,
    values
  } = useInitUser(allowed);
  const { errors, handleSubmit, onRecover, register } = useRecoverPassword();
  const { selectedProduct } = useSelector(state => state.user);

  return (
    <Div>
      <LinkLogo />
      <Container>
        <StyledImage
          className="d-xs-block d-md-none"
          src={ ImageAds }
        />
        <StyledLogo
          className="d-xs-block d-md-none"
          src={ selectedProduct?.image_ads }
        />
        <StyledRow className="justify-content-md-center">
          <StyledForm
            className="col-md-10 col-xs-12 col-lg-6 col-xl-5"
            onSubmit={ handleSubmit(onSubmit) }
          >
            <h1 className="pb-4">
              { t("password.changeTitle") }
            </h1>
            <Input
              autoComplete="off"
              disabled={ loading }
              isRequiredInput={ true }
              label={ t("password.new") }
              message={ errors.new_password?.message }
              name={ register("new_password").name }
              register={ register("new_password") }
              tooltipText={ t("getStarted.passwordRequired", { min: 8, characters }) }
              type="password"
            />
            <Input
              autoComplete="off"
              disabled={ loading }
              isRequiredInput={ true }
              label={ t("password.repeat") }
              message={ errors.confirm_password?.message }
              name={ register("confirm_password").name }
              register={ register("confirm_password") }
              tooltipText={ t("getStarted.passwordRequired", { min: 8, characters }) }
              type="password"
            />
            {loading
              ? (
                <LoaderSpinner />
              ) : (
                <Button
                  className="btn mb-0"
                  disabled={ loading }
                  color="first"
                  type="submit"
                  name="btn_continue_password"
                >
                  { t("common.continue") }
                </Button>
              )
            }
            <Label className="wantText">
              { t("password.wantToLogin") }&nbsp;
              {!loading && (
                <RouterLink
                  className="p-0 col-12"
                  to={ routes.login(appOrigin) }
                  color="third"
                  disabled={ loading }
                >
                  { t("login.buttonLogIn") }
                </RouterLink>
              )}
            </Label>
          </StyledForm>
        </StyledRow>
      </Container>
      <SlideCaptchaModal
        isOpen={ isSlideModalOpen }
        onRequestClose={ () => setIsSlideModalOpen(false) }
        onSuccess={ () => onRecover(values) }
      />
    </Div>
  );
};