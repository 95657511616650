import React from 'react';
import { useAllowedCountries, useGetStartedEmail, useInitUser } from '../../hooks';
import { useTranslation } from 'react-i18next';
import {
  appsOrigin,
  envSetting,
  routes
} from '../../constants';
import { Button, StyledForm, Label, LoaderSpinner, RouterLink, Link } from '../shared/simple';
import {
  Div,
  StyledImage,
  StyledLogo,
  StyledRow,
  ProductItem,
  ProductsContainer,
  SingleProductContainer
} from './styled';
import { Input } from '../shared/Input';
import { LinkLogo } from './LinkLogo';
import { Container } from 'react-bootstrap';
import { checkMinutesPassed, trimLowerCase } from '../../helpers';
import { useSelector } from 'react-redux';
import SlideCaptchaModal from '../shared/slide-captcha-modal/SlideCaptchaModal';
import ImageAds from '../../assets/images/login/wisenroll.png';

/**
 * Get Started Component only mail
 * @component
 * @returns
 */
export const GetStartedEmail = () => {
  const { t } = useTranslation();
  const { allowed } = useAllowedCountries();
  const { selectedProduct, timeRefresh } = useSelector(state => state.user);
  const {
    actualLanguage,
    appOrigin,
    isSlideModalOpen,
    loading,
    onSubmit,
    setIsSlideModalOpen,
    values
  } = useInitUser(allowed);
  const {
    applications,
    errors,
    handleSubmit,
    onProduct,
    onSignUp,
    register
  } = useGetStartedEmail();

  return (
    <Div>
      <LinkLogo />
      <Container>
        <StyledImage
          className="d-xs-block d-md-none"
          rel="preload"
          src={ ImageAds }
        />
        <StyledLogo
          className="d-xs-block d-md-none"
          rel="preload"
          src={ selectedProduct?.image_ads }
        />
        <StyledRow className="justify-content-md-center">
          <StyledForm
            className="col-md-10 col-xs-12 col-lg-6 col-xl-5 w-100"
            onSubmit={ handleSubmit(onSubmit) }
          >
            {(appOrigin === appsOrigin.default || !appOrigin) && (
              <>
                <h1 className="pb-4 text-center" style={{ margin: 0 }}>
                  { t("getStarted.productServices") }
                </h1>
                <h4 className="pb-4 text-center" style={{ margin: 0 }}>
                  { t("getStarted.productServices2") }
                </h4>
                {applications && !checkMinutesPassed(timeRefresh, envSetting.minutesRefreshApplication)
                  ? (
                    <ProductsContainer>
                      {applications.map((product, i) => {
                        if (product.application.active && product.application.create_account) {
                          const title = product.application[`description_${trimLowerCase(actualLanguage)}`]
                          return (
                            <SingleProductContainer
                              key={ i }
                              alt=""
                              onClick={ () => onProduct(product.application.unique_name) }
                            >
                              <ProductItem
                                rel="preload"
                                src={ product.image_main }
                                name={`image_${product?.application?.unique_name}` }
                              />
                              <p>{ title }</p>
                            </SingleProductContainer>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </ProductsContainer>
                  ) : (
                    <LoaderSpinner />
                  )
                }
                <Label className="wantText">
                  { t("password.wantToLogin") }&nbsp;
                  {!loading && (
                    <RouterLink
                      className="p-0 col-12"
                      to={ routes.login(appOrigin) }
                      color="third"
                      disabled={ loading }
                    >
                      { t("login.buttonLogIn") }
                    </RouterLink>
                  )}
                </Label>
              </>
            )}
            {appOrigin !== appsOrigin.default && (
              <div style={{display: "flex", justifyContent: "center"}}>
                <div style={{width: "400px"}}>
                  <h1 className="pb-4">{ t("getStarted.getStarted") }</h1>
                  <Input
                    autoComplete="off"
                    disabled={ loading }
                    label={ t("common.email") }
                    message={ errors.email?.message }
                    name={ register("email").name }
                    placeholder={ t("common.emailExample") }
                    register={ register("email") }
                    type="email"
                  />
                  {loading
                    ? (
                      <LoaderSpinner />
                    ) : (
                      <Button
                        className="btn mb-0"
                        disabled={ loading }
                        color="first"
                        type="submit"
                        name="btn_email"
                      >
                        { t("common.continue") }
                      </Button>
                    )
                  }
                  <Label className="wantText">
                    {!loading && <>
                      { t("getStarted.signUp") }&nbsp;
                      <RouterLink
                        className="p-0 col-12"
                        to={ routes.login(appOrigin) }
                        color="third"
                        disabled={ loading }
                      >
                        { t("login.buttonLogIn") }
                      </RouterLink>
                    </>}
                  </Label>
                  <Label className="wantText">
                    { t("common.createAccount") }&nbsp;
                    <Link
                      className="p-0 col-12"
                      href={ envSetting?.routeCreateAccount }
                      color="first"
                      target="_blank"
                    >
                      { t("common.createAccountURL") }
                    </Link>
                  </Label>
                </div>
              </div>
            )}
          </StyledForm>
        </StyledRow>
      </Container>
      <SlideCaptchaModal
        isOpen={ isSlideModalOpen }
        onRequestClose={ () => setIsSlideModalOpen(false) }
        onSuccess={ () => onSignUp(values) }
      />
    </Div>
  );
};