import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { documentType, hiddenField, paramsEdit, paramsAdd, sOption } from '../constants';
import {
  checkPEPRPEP,
  getValue,
  isUndefinedNull,
  moduleCategory,
  notUndefinedNull,
  parentCondition,
  saveFieldValue,
  searchDependentValue,
  sendData,
  fieldAttribute,
  isEmpty
} from '../helpers';
import { USER_FILES } from '../redux/types';
import { theme } from '../theme';

/**
 * hook to Select Component
 * @param {Object} field
 * @param {String} uniqueName
 * @returns
 */
export const useSelect = (field, uniqueName, entryProps='') => {
  const defaultValue = getValue(field);
  const { requestLanguage } = useSelector(state => state.base);
  const { action } = useSelector(state => state.tier);
  const [options, setOptions] = useState([]);
  const [idValue, setIdValue] = useState();
  const [dependencyField, setDependencyField] = useState(null);
  const { getValues, setValue, watch } = useFormContext();
  const values = getValues();
  const areValuesUndefined = Object.values(values)?.every(value => (isUndefinedNull(value)))
  const watchDependsField = dependencyField ? watch(dependencyField) : null;

  useEffect(() => {
    const getDataType = async () => {
      const getValues = (isUndefinedNull(field.function_get_values)) ? [] : JSON.parse(
        field.function_get_values);
      const fieldAction = (isUndefinedNull(field.action)) ? [] : JSON.parse(
        field.action)
      setIdValue(getValues?.id);
      if ((getValues?.apply === 'start' && getValues?.type !== 'endpoint') || (
        action === paramsEdit && getValues.depends)) {
        const response = sendData(getValues?.type, getValues, true, setValue);
        setOptions(response);

        if ((action === paramsEdit || action === paramsAdd) && getValues.depends) {
          const { result, dependsField } = await searchDependentValue(
            getValues, values, response, fieldAction);
          if (result?.length > 0) setOptions(result);
          if (notUndefinedNull(dependsField)) setDependencyField(dependsField);
        }
      }
    };

    getDataType();
  }, [field, requestLanguage, areValuesUndefined]);

  const onChange = ({ target: option }) => {
    setValue(uniqueName, option.value);
    parentCondition(option, field, setValue);
    if (notUndefinedNull(field?.id)) moduleCategory(field.id);

    if (entryProps) {
    const { setEntries, setWisenrollUser, setIsBlockDisabled, setCanContinue, entry } = entryProps;
      /* If the select changes, that entry is no longer valid */
      if (setEntries) {
        setEntries(prevEntries => {
          if (prevEntries.length > 1) {
            return prevEntries.map(prevEntry => {
              if (prevEntry.id === entry.id) {
                return {...prevEntry, isValid: false };
              }
              return prevEntry;
            });
          }
          if (prevEntries.length === 1) {
            if( option.value === sOption) {
              setCanContinue(true);
            } else {
              setCanContinue(false);
            }
            return prevEntries.map(prevEntry => {
              return { ...prevEntry, isValid:false };
            });
          }
          return prevEntries;
        });
      }
      /* User info to be shown */
      if (setWisenrollUser) setWisenrollUser(null);
      /* Enabling or disabling validate button */
      if (setIsBlockDisabled && setCanContinue) {
        if (option.value !== sOption){
          setIsBlockDisabled(false);
          setCanContinue(false);
        }
        if (option.value === sOption){
          setIsBlockDisabled(true);
          setCanContinue(true);
        }
      }
    }
  };

  useEffect(() => {
    if (values[uniqueName] && !isEmpty(values[uniqueName]) && values[
      uniqueName] !== hiddenField) {
      setValue(uniqueName, values[uniqueName]);
    }
  }, [values]);

  useEffect(() => {
    if (notUndefinedNull(watchDependsField)) {
      if (action === paramsEdit && isEmpty(watchDependsField) && (JSON.parse(
        field.function_get_values))?.depends) {
        const select = fieldAttribute('name', uniqueName);
        let idOptions = [];
        options.forEach(item => {
          idOptions.push(item.id);
        });
        Array.from(select.options).forEach(option => {
          if (idOptions.includes(option.id)) option.hidde = true;
        });
        select.options[0].selected = true;
        setValue(uniqueName, '');
        setOptions([]);
      }
    }
  }, [watchDependsField]);

  const handleChange = (e) => {
    onChange(e, field);
  };

  return { defaultValue, idValue, onChange, options, handleChange, setValue };
};

/**
 * hook to CheckboxRadio Component
 * @param {Object} field
 * @param {Boolean} isMultiple
 * @returns
 */
export const useCheckboxRadio = (field, isMultiple = false) => {
  const uniqueName = `${field.unique_name}__id__${field.id}`;
  const dispatch = useDispatch();
  const { setValue, getValues } = useFormContext();
  const { requestLanguage } = useSelector(state => state.base);
  const { files } = useSelector(state => state.user);
  const [options, setOptions] = useState();

  useEffect(() => {
    const getDataType = () => {
      let getValuesField = field?.function_get_values;
      getValuesField = (getValuesField) ? JSON.parse(getValuesField) : [];
      if (getValuesField?.apply === 'start' && getValuesField?.type !== 'endpoint') {
        const response = sendData(
          getValuesField?.type, getValuesField, true, setValue);
        setOptions(response);
      }
    };

    getDataType();
  }, [field, requestLanguage, setValue]);

  const onClick = ({ target: option }) => {
    (isMultiple) ? checkPEPRPEP(option, field, setValue) : parentCondition(
      option, field, setValue);
    if (notUndefinedNull(field?.id)) moduleCategory(field.id);

    if (uniqueName.includes(documentType)) {
      if (option.checked) {
        const newValues = [...files, option.id];
        setValue(uniqueName, newValues);
        dispatch({ type: USER_FILES, payload: { files: newValues } });
      } else {
        const newValues = files.filter(item => item !== option.id);
        setValue(uniqueName, newValues);
        dispatch({ type: USER_FILES, payload: { files: newValues } });
      }
    } else {
      const prevValues = getValues(uniqueName)
      if(!prevValues.includes(option.id)){
        setValue(uniqueName, [...prevValues, option.id])
      }else{
        setValue(uniqueName, prevValues.filter(value => value !== option.id))
      }
    }
  };

  return { onClick, options };
};

/**
 * hook to ImageSelect component
 * @param {Object} field
 * @returns
 */
export const useImageSelect = field => {
  const { setValue, getValues } = useFormContext();
  const { first } = theme;
  const { requestLanguage } = useSelector(state => state.base);
  const [images, setImages] = useState();
  const [imagesSelect, setImagesSelect] = useState([]);

  useEffect(() => {
    const getDataType = () => {
      let getValues = field?.function_get_values;
      getValues = (getValues) ? JSON.parse(getValues) : [];
      if (getValues?.apply === 'start' && getValues?.type !== 'endpoint') {
        const response = sendData(getValues?.type, getValues, true, setValue);
        setImages(response);
      }
    };

    getDataType();
  }, [field, requestLanguage]);

  useEffect(() => {
    if (images) {
      const nameImages = field.unique_name.split('__').pop();
      const values = getValues(`${field.unique_name}__id__${field.id}`);
      if (values) {
        Array.from(images).forEach(item => {
          const index = Array.from(values).findIndex(
            elem => elem[nameImages] === item.id);
          if (index < 0) {
            item['classStatus'] = 'inactive';
            item['style'] = { border: '1px solid #eee', boxShadow: '1px 1px 1px #eee' };
          } else {
            item['classStatus'] = 'active';
            item['style'] = { border: `1px solid ${first}`, boxShadow: `1px 1px 1px ${first}` };
          }
        });
      
        setImagesSelect(values);
      }
    }
  }, [images]);

  const onClick = ({ target: image }) => {
    const { allField, newObject, index } = saveFieldValue(image, imagesSelect);

    if (image.className.includes('inactive') || (!image.className.includes('active')  && !image.className.includes('inactive'))) {
      image.classList.remove('inactive');
      image.classList.add('active');
      image.style.border = `1px solid ${first}`;
      image.style.boxShadow = `1px 1px 1px ${first}`;
      (index < 0) && allField.push(newObject);
    } else {
      image.classList.remove('active');
      image.classList.add('inactive');
      image.style.border = '1px solid #eee';
      image.style.boxShadow = '1px 1px 1px #eee';
      (index >= 0) && allField.splice(index, 1);
    }

    setValue(image.name, allField);
    setImagesSelect(allField);
  };

  return { images, onClick };
};