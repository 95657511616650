import * as React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import { DialogContent, DialogActions, DialogTitle } from '@mui/material';
import { Row, Col } from 'react-bootstrap';
import { Button } from '../../../../shared/simple';
import { Label } from '../../../../shared/simple';
import { StyledControl, StyledGroup } from '../../../../shared/Input/styled';
import { useTokenDialog } from '../../../../../hooks/useDialog';

/**
 * Token component outside of the Form's Provider
 * @component
 * @returns
 */
export const TokenDialog = ({open, handleClose, handleSubmit, loading = false}) => {
  const { token, setToken, onClose, t } = useTokenDialog({ handleClose });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
    >
      <DialogTitle id="alert-dialog-title">
        { t("block.tokenDialogTitle") }
      </DialogTitle>
      <DialogContent>
        <Row>
          <Col xs={12} className="d-flex">
            <StyledGroup className="mb-3 w-100">
              <Label>
                Token
              </Label>
              <strong className="text-danger">&nbsp;*</strong>
              <StyledControl
                className="w-100"
                value={token}
                maxlength="6"
                onChange={(e) => setToken(e.target.value)}
                disabled={ loading }
              />
            </StyledGroup>
          </Col>
        </Row>
      </DialogContent>
      <DialogActions className="pb-0 px-4">
        <Button
          className="btn my-0"
          color="first"
          type="button"
          onClick={ () => handleSubmit(token) }
          disabled={ (token.length < 6) || loading }
        >
          { t("common.buttonToken") }
        </Button>
      </DialogActions>
      <DialogActions className="px-4">
        <Button
            className="btn my-0"
            onClick={ onClose }
            color="fourth"
            type="button"
            disabled={ loading }
          >
            { t("common.cancel") }
          </Button>
      </DialogActions>
    </Dialog>
  );
};

TokenDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool
};