/**
 * environment variables
 */
export const envSetting = {
  apiPassword: process.env.REACT_APP_API_AUTH_PASSWORD,
  apiUser: process.env.REACT_APP_API_AUTH_USER,
  assistantsAppUniqueCode: process.env.REACT_APP_ASSISTANTS_APP_UNIQUE_CODE,
  avatarSize: process.env.REACT_APP_MAX_AVATAR_SIZE,
  baseCountry: process.env.REACT_APP_BASE_COUNTRY,
  baseCurrency: process.env.REACT_APP_BASE_CURRENCY,
  bgLogoCompany: process.env.REACT_APP_BG_MAIN_LOGO_COMPANY,
  bgLogoWisenroll: process.env.REACT_APP_BG_MAIN_LOGO_WISENROLL,
  companyName: process.env.REACT_APP_COMPANY_NAME,
  companyPage: process.env.REACT_APP_COMPANY_PAGE,
  cryptoAesPassword: process.env.REACT_APP_CRYPTO_AES_PASSWORD,
  eighth: process.env.REACT_APP_COLOR_EIGHTH,
  expirationTime: process.env.REACT_APP_EXPIRATION_TIME,
  fifth: process.env.REACT_APP_COLOR_FIFTH,
  fifthHover: process.env.REACT_APP_COLOR_FIFTH_HOVER,
  fileSize: process.env.REACT_APP_MAX_FILE_SIZE,
  first: process.env.REACT_APP_COLOR_FIRST,
  firstHover: process.env.REACT_APP_COLOR_FIRST_HOVER,
  fourth: process.env.REACT_APP_COLOR_FOURTH,
  fourthHover: process.env.REACT_APP_COLOR_FOURTH_HOVER,
  general: process.env.REACT_APP_COLOR,
  generalHover: process.env.REACT_APP_COLOR_HOVER,
  googleAutocompleteApiKey: process.env.REACT_GOOGLE_AUTOCOMPLETE_API_KEY,
  logoAdsWisenroll: process.env.REACT_APP_ADS_LOGO_WISENROLL,
  maxMinutesToken: process.env.REACT_APP_MAX_LENGTH_TOKEN,
  maxTotalFilesSize: process.env.REACT_APP_MAX_TOTAL_FILES_SIZE,
  minutesRefreshApplication: process.env.REACT_APP_MINUTES_REFRESH_APPLICATIONS,
  minutesToken: process.env.REACT_APP_MINUTES_TOKEN,
  routeBnfaffiliates: process.env.REACT_APP_ROUTE_BNFAFFILIATES,
  routeCreateAccount: process.env.REACT_APP_CREATE_ACCOUNT,
  routeCrediroll: process.env.REACT_APP_ROUTE_CREDIROLL,
  routeGenieWizz: process.env.REACT_APP_ROUTE_GENIE_WIZZ,
  routeOnlineBankAccount: process.env.REACT_APP_ROUTE_ONLINE_BANK_ACCOUNT,
  routePalMerchant: process.env.REACT_APP_ROUTE_PALMERCHANT,
  routeTurboAhorro: process.env.REACT_APP_ROUTE_TURBO_AHORRO,
  routeWisenroll: process.env.REACT_APP_ROUTE_WISENROLL,
  second: process.env.REACT_APP_COLOR_SECOND,
  secondHover: process.env.REACT_APP_COLOR_SECOND_HOVER,
  seventh: process.env.REACT_APP_COLOR_SEVENTH,
  seventhHover: process.env.REACT_APP_COLOR_SEVENTH_HOVER,
  sixth: process.env.REACT_APP_COLOR_SIXTH,
  sixthHover: process.env.REACT_APP_COLOR_SIXTH_HOVER,
  termsConditionsEn: process.env.REACT_APP_TERMS_CONDITIONS_EN,
  termsConditionsEs: process.env.REACT_APP_TERMS_CONDITIONS_ES,
  third: process.env.REACT_APP_COLOR_THIRD,
  thirdHover: process.env.REACT_APP_COLOR_THIRD_HOVER,
  videoSize: process.env.REACT_APP_MAX_VIDEO_SIZE,
  wisenrollApi: process.env.REACT_APP_WISENROLL_API_BASE_URL,
  showUploadFromFiles: process.env.REACT_APP_ENABLE_BUTTON_UPLOAD_FILE,
  defaultLanguage: process.env.REACT_APP_DEFAULT_LANGUAGE,
  isDebugErrorBoundary: process.env.REACT_APP_DEBUG_ERROR_BOUNDARY
};