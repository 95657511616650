import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonGroup, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Img, StyledToggle } from './styled';
import { useLanguage } from '../../../hooks';
import imgLanguage from '../../../assets/images/shared/language.png';

/**
 * Language component for change languages in the footer
 * @component
 * @returns
 */
export const Language = () => {
  const { t } = useTranslation();
  const languages = {
    en: { label: t("footer.english"), name: 'lang_en' },
    es: { label: t("footer.spanish"), name: 'lang_es' }
  };
  const { onClick } = useLanguage();

  return (
    <>
    <OverlayTrigger
      overlay={<Tooltip>{t("footer.language")}</Tooltip>}
      placement="left"
      name="buttone-group-language"
    >
      <Dropdown as={ButtonGroup} name="button-group-language">
        <StyledToggle className="btn-link">
          <Img src={imgLanguage} />
        </StyledToggle>
        <Dropdown.Menu>
          {Object.keys(languages).map(item => (
            <Dropdown.Item
              key={item}
              name={languages[item].name}
              onClick={() => onClick(item)}
            >
              {languages[item].label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </OverlayTrigger>
    </>
  );
};