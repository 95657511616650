import React from 'react';
import { createPortal } from 'react-dom';
import './modal.css';

/**
 * Component to Alert Modal
 * @component
 * @returns
 */
const AlertModal = (props) => {
  if (!props.isOpen) {
    return null;
  }
  return createPortal(
    <div className="Modal">
      <div className={`overlay ${props.overlayClassName}`}>
        <div className={`Modal__container ${props.modalClassName}`}>
          {props.children}
        </div>
      </div>
    </div>,
    document.getElementById('modal')
  );
};

export default AlertModal;