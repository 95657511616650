import i18n from '../translations/i18n';

export const baseField = {
  modalValidateSms: 'modal_validate_sms',
  sendStepTier: 'identity_protection_data',
  validateGroupFile: 'document',
  spanFieldError: (name, error) => `<span name=${name}
    style="color: #dc3545; font-size: .875em; width: 100%;">${error}</span>`,
  spanFieldLength: (name, length) => `<span name=${name}
    style="color: #dc3545; font-size: .875em; width: 100%;">
    ${i18n.t("common.lengthFields", { length: length })}</span>`,
  startDate: 'start',
  endDate: 'end',
};

export const statusRequest = {
  processing: 'processing',
  sent: 'request_sent',
  canceled: 'request_canceled',
  success: 'request_success',
  rejected: 'request_rejected'
};

export const statusRequestOrder = [
  statusRequest.sent,
  statusRequest.processing,
  statusRequest.success,
  statusRequest.rejected,
  statusRequest.canceled
];

export const buttonClose = 'close btn-close';

export const languagueEs = 'ES';