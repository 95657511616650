import { endpoints } from '../constants';
import { postRequest } from '../helpers';
import { setDelay } from '../redux/actions';
import { store } from '../redux/store/store';

/**
 * Update bifrost data AND perform migrate (depending on the tier)
 * @param {Object} tier
 * @param {String} userIdBifrost
 * @param {Boolean} migrate
 * @param {String} productRequestId
 * @returns
 */
export const migrateSubmit = async (tier, userIdBifrost, migrate, productRequestId) => {
  try {
    let payload = {
      'bifrost_user_id': userIdBifrost.toString(),
      'tier': tier.unique_name,
      'product_request_id': productRequestId.toString()
    };
    let response;

    if (localStorage.getItem('idRequest')){
      payload.product_request_id = localStorage.getItem('idRequest');
    }

    payload.new_verification_level = tier.verification_level;
    response = await postRequest(endpoints.updateBifrost, payload);
    if (migrate) {
      store.dispatch(setDelay(true));
      response = await postRequest(endpoints.migrate, payload);
    }
    return response;
  } catch (error) {
    console.error('migrateSubmit: ', error);
    store.dispatch(setDelay(false));
  }
};