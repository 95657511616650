import { useState } from 'react'

/**
 * Description placeholder
 * @date 13/12/2023 - 22:44:44
 * @returns {Object} An object containing the following functions and state:
 * - {Boolean}  - isOpenQR      - Determines if the QR Modal is open
 * - {Function} - handleOpenQR  - Handle opening the modal  
 * - {Funtcion} - handleCloseQR - Handle closing the modal
 * 
 **/
export const useQRModal = () => {
	const [isOpenQR, setIsOpenQR] = useState(false)

	const handleOpenQR = () => {
		setIsOpenQR(true)
	}

	const handleCloseQR = () => {
		setIsOpenQR(false)
	}

  return {
		isOpenQR,
		handleOpenQR,
		handleCloseQR
  }
}

