import i18n from '../../translations/i18n';
import { BASE_EXPIRED_FILES, BASE_REJECTED_FILES, USER_FILES, PERSON_DATA_SET_DATA } from '../types';
import { saveUserFile } from '../../services/userApi';
import { avatarImage, endpoints, fileStatus, envSetting } from '../../constants';
import { handleRequest, notUndefinedNull, trimLowerCase } from '../../helpers';
import { sendGetEndpoint, sendPostRequest } from '../../services/baseApi';
import { store } from '../store/store';
import { setLoader, setMessage } from '.';

/**
 * Call to endpoint to save user picture
 * @param {String} userId
 * @param {Object} formMedia
 * @param {Boolean} isGetImages
 * @param {Boolean} isImagesFull
 * @returns
 */
export const saveUserImage = (userId, formMedia, isGetImages, isImagesFull) => async dispatch => {
  try {
    const response = await saveUserFile(userId, formMedia, isImagesFull);
    dispatch(handleRequest(response));
    if (response && !response.error) {
      if (isGetImages) dispatch(getUserImages());
      dispatch({ type: BASE_REJECTED_FILES, payload: { rejectedFiles: false } });
      dispatch({ type: BASE_EXPIRED_FILES, payload: { expiredFiles: false } });
      return true;
    }
    return false;
  } catch (error) {
    console.error('saveUserImage: ', error);
    dispatch(setLoader(false));
    dispatch(setMessage(i18n.t("message.errorSaveUserImage"), 'error'));
    return false;
  }
};

/**
 * Call to get user files
 * @returns
 */
export const getUserImages = () => async dispatch => {
  try {
    const { userIdBifrost } = store.getState().user;
    const { data } = store.getState().personData
    const response = await sendGetEndpoint(endpoints.getUserFiles(userIdBifrost));
    dispatch(handleRequest(response));
    if (response && !response.error) {
      dispatch(getRejectedFiles());
      const avatarFile = Array.from(response).filter(
        file => trimLowerCase(file.file_name) === avatarImage).shift();

      if (notUndefinedNull(avatarFile)) {
        const getAvatarFile = `${envSetting.wisenrollApi}${avatarFile.file_download_link}`;
        dispatch({
          type: USER_FILES,
          payload: { srcAvatarImage: getAvatarFile }
        });
      }
      data.user_files = Array.from(response)
      dispatch({
        type: PERSON_DATA_SET_DATA,
        payload: {
          data
        }
      })
    }
  } catch (error) {
    console.error('getUserImages: ', error);
    dispatch(setLoader(false));
    dispatch(setMessage(i18n.t("message.errorUserFiles"), 'error'));
  }
};

/**
 * Call to post to check expired document of user
 * @returns
 */
export const sendCheckUserTier = () => async dispatch => {
  try {
    const { code, appOrigin } = store.getState().user;
    const response = await sendPostRequest(
      { code, app_name: appOrigin }, endpoints.checkUserTier);
    dispatch(handleRequest(response));
    if (response && !response.error) {
      dispatch({
        type: BASE_EXPIRED_FILES,
        payload: { expiredFiles: response?.success?.expired_document }
      });
      return true;
    }
    return false;
  } catch (error) {
    console.error('sendCheckUserTier: ', error);
    dispatch(setLoader(false));
    dispatch(setMessage(i18n.t("message.errorCheckUserTier"), 'error'));
    return;
  }
};

/**
 * Get if user has rejected files
 * @returns
 */
export const getRejectedFiles = () => async dispatch => {
  const userFiles = store.getState().personData?.data?.user_files;

  if (userFiles) {
    const rejectedFiles = userFiles?.some(item => ((item.user_file_status) && (
      trimLowerCase(item.user_file_status.unique_description) === fileStatus.rejected)));
    if (notUndefinedNull(rejectedFiles)) {
      dispatch({
        type: BASE_REJECTED_FILES,
        payload: {
          rejectedFiles: rejectedFiles,
        }
      });
    }
  }
};