import PropTypes from 'prop-types';
import { useAutoLogout } from '../../hooks';

/**
 * Logout component
 * @component
 * @param {Element} children
 * @returns
 */
export const Logout = ({ children }) => {
  useAutoLogout();

  return children;
};

Logout.propTypes = {
  children: PropTypes.any
};