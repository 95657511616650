import React from 'react';
import { useParams } from 'react-router-dom';
import { Img } from './styled';
import { LinkLogo } from '../user/LinkLogo';
import { trimUpperCase } from '../../helpers';
import { useAllowedCountries, useInitUser } from '../../hooks';
import { languagueEs } from '../../constants';
import EN400 from '../../assets/images/errors/400EN.png';
import ES400 from '../../assets/images/errors/400ES.png';
import EN401 from '../../assets/images/errors/401EN.png';
import ES401 from '../../assets/images/errors/401ES.png';
import EN403 from '../../assets/images/errors/403EN.png';
import ES403 from '../../assets/images/errors/403ES.png';
import EN404 from '../../assets/images/errors/404EN.png';
import ES404 from '../../assets/images/errors/404ES.png';
import EN500 from '../../assets/images/errors/500EN.png';
import ES500 from '../../assets/images/errors/500ES.png';
import EN502 from '../../assets/images/errors/502EN.png';
import ES502 from '../../assets/images/errors/502ES.png';

/**
 * Component with error image
 * @component
 * @returns
 */
export const Errors = () => {
  const { allowed } = useAllowedCountries();
  const { actualLanguage } = useInitUser(allowed);
  const { code } = useParams();
  const isEspanish = (trimUpperCase(actualLanguage) === languagueEs);

  return (
    <>
      <LinkLogo />
      {(actualLanguage && code) ? (
        <>
          {code.toString() === '404'
            ? <Img src={(isEspanish) ? ES404 : EN404} />
            : code.toString() === '401'
              ? <Img src={(isEspanish) ? ES401 : EN401} />
              : code.toString() === '400'
                ? <Img src={(isEspanish) ? ES400 : EN400} />
                : code.toString() === '403'
                  ? <Img src={(isEspanish) ? ES403 : EN403} />
                  : code.toString() === '500'
                    ? <Img src={(isEspanish) ? ES500 : EN500} />
                    : code.toString() === '502'
                      ? <Img src={(isEspanish) ? ES502 : EN502} />
                      : null
          }
        </>
      ) : null}
    </>
  );
};