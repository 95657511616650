import { MESSAGE_CLEAR, MESSAGE_SET } from '../types';

const initState = {
  isOpen: false,
  description: null,
  variant: null
};

/**
 * Reducer to messages
 * @param {Object} state
 * @param {Object} action
 * @returns
 */
export const messageReducer = (state=initState, action) => {
  switch (action.type) {
    case MESSAGE_CLEAR:
      return initState;

    case MESSAGE_SET:
      return {
        ...state,
        ...action.payload,
        isOpen: true
      };

    default:
      return state;
  }
};