import i18next from 'i18next';
import { useState } from 'react';
import {
  BASE_ACTUAL_LANGUAGE,
  BASE_REQUEST_LANGUAGE,
  BASE_SELECTED_LANGUAGE
} from '../redux/types';
import { getProductRequest, getStaticData, setLoader } from '../redux/actions';
import { notUndefinedNull, trimUpperCase } from '../helpers';
import { useDispatch, useSelector } from 'react-redux';

/**
 * Hook to change the language
 * @returns
 */
export const useLanguage = () => {
  const dispatch = useDispatch();
  const { selectedLanguages, actualLanguage, requestLanguage } = useSelector(
    state => state.base);
  const { isAuthenticated } = useSelector(state => state.user);
  const { data } = useSelector(state => state.personData);
  const [result, setResult] = useState(true);

  const updateLanguage = language => {
    if (notUndefinedNull(language) && notUndefinedNull(requestLanguage)) {
      if (!selectedLanguages.includes(trimUpperCase(language))) {
        selectedLanguages.push(trimUpperCase(language));
        dispatch({
          type: BASE_SELECTED_LANGUAGE,
          payload: [...selectedLanguages]
        });
      }
      if (trimUpperCase(language) !== trimUpperCase(requestLanguage)) {
        dispatch({
          type: BASE_REQUEST_LANGUAGE,
          payload: trimUpperCase(language)
        });
      }
    }
  };

  const onClick = async item => {
    i18next.changeLanguage(item);
    if (notUndefinedNull(actualLanguage) && notUndefinedNull(requestLanguage)) {
      if (trimUpperCase(item) !== trimUpperCase(requestLanguage)) {
        dispatch({
          type: BASE_REQUEST_LANGUAGE,
          payload: trimUpperCase(actualLanguage)
        });
      }
      if (trimUpperCase(actualLanguage) !== trimUpperCase(item)) {
        dispatch({
          type: BASE_ACTUAL_LANGUAGE,
          payload: trimUpperCase(item)
        });
      }
      if (!selectedLanguages.includes(trimUpperCase(item)) && isAuthenticated) {
        dispatch(setLoader(true));
        setResult(await dispatch(getStaticData()));
        await dispatch(getProductRequest(data.id));
        dispatch(setLoader(false));
      } else if (isAuthenticated) await dispatch(getProductRequest(data.id));
      if (result) updateLanguage(item);
    } else {
      if (!isAuthenticated) {
        if (notUndefinedNull(requestLanguage)) {
          dispatch({
            type: BASE_REQUEST_LANGUAGE,
            payload: trimUpperCase(actualLanguage)
          });
        }
        if (trimUpperCase(actualLanguage) !== trimUpperCase(item)) {
          dispatch({
            type: BASE_ACTUAL_LANGUAGE,
            payload: trimUpperCase(item)
          });
        }
      }
    }
  };

  return { onClick };
};