import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import {
  appsOrigin,
  defaultValuesClientEntity,
  defaultValuesEmail,
  defaultValuesGetStarted,
  defaultValuesInternetBanking,
  defaultValuesLogin,
  defaultValuesRecoverPassword,
  envSetting,
  fileStatus,
  logoAdsAppOrigin,
  logoBgAppOrigin,
  paramsAdd,
  profile,
  routes,
  schemaClientEntity,
  schemaEmail,
  schemaGetStarted,
  schemaInternetBankingUser,
  schemaLogin,
  schemaRecoverPassword,
  siteMap,
  browserNames,
  operativeSystemNames
} from '../constants';
import {
  changePassword,
  getBase,
  getDataSummary,
  getPermissionState,
  getPersonData,
  getRejectedFiles,
  getStaticData,
  getUserApplications,
  getTimeRefreshProducts,
  getUserImages,
  loginUser,
  recoverPasswordEmail,
  registerClientEntity,
  sendCheckUserTier,
  setLoader,
  setLogout,
  setMessage,
  setPassword,
  signUpUser,
  verifyUser,
  getProductRequest,
  createInternetBankingUser,
  getAproxUserGeolocation
} from '../redux/actions';
import {
  USER_APP_ORIGIN,
  USER_INFO_PRODUCT_REQUEST,
  USER_LOGIN,
  USER_SELECTED_PRODUCT
} from '../redux/types';
import {
  checkMinutesPassed,
  fieldUniqueName,
  isEmpty,
  isUndefinedNull,
  notUndefinedNull,
  setMask,
  timeMinutes,
  trimLowerCase
} from '../helpers';
import { useDetect } from './useDetect';

/**
 * Hook to values init
 * @param {Boolean} allowed
 * @returns
 */
export const useInitUser = allowed => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const { appOrigin, isAuthenticated, applications } = useSelector(state => state.user);

  const { actualLanguage, loading, locationStatus } = useSelector(
    state => state.base);
  const [isSlideModalOpen, setIsSlideModalOpen] = useState(false);
  const [values, setValues] = useState([]);

  useEffect(() => {
    if (location.pathname.includes('logout')) {
      dispatch(setLogout());
      // Send the user back to /login
      setTimeout(() => { 
        navigate(siteMap.login.path)
      }, 500);
    } else {
      if (isAuthenticated) navigate(siteMap.homepage.path);
    }
  }, [isAuthenticated, navigate, location]);

  // Automatically send us to /logout path to reset redux buggy state
  useEffect(() => {
    if (!location.pathname.includes('logout') && loading){
      navigate(siteMap.logout.path)
    }
  }, [])

  useEffect(() => {
    if (!applications) {
      dispatch(getUserApplications());
    } else {
      let product = applications.filter(
        product => product?.application?.unique_name === appOrigin);
      if (product.length === 0 ) product = [{
        image_ads: logoAdsAppOrigin.default, image_main: logoBgAppOrigin.default}];
      const [selectProduct] = product;
      dispatch({ type: USER_SELECTED_PRODUCT, payload: selectProduct });
    };
  }, [applications, appOrigin]);

  const { browserInfo, OS } = useDetect()
  // Should detect the Chrome for iOS (CriOS) buggy combination
  const isBrowserAllowed = !( (trimLowerCase(browserInfo?.name) === trimLowerCase(browserNames.crios) || trimLowerCase(browserInfo?.name) === trimLowerCase(browserNames.unknown)) && (trimLowerCase(OS) === trimLowerCase(operativeSystemNames.iOS)) )
  const onSubmit = values => {
    if(isBrowserAllowed){
      if (allowed) {
        dispatch(setLoader(false));
        setIsSlideModalOpen(true);
        setValues(values);
      } else {
        dispatch(setMessage(t("common.countryNotAllowed"), 'error'));
      }
    } else{
      dispatch(setLoader(true))
      setTimeout(() => {
        dispatch(setMessage(t('common.browserNotAllowed'), 'error'))
        dispatch(setLoader(false))
      }, 1000);
    }
  };

  return {
    actualLanguage,
    appOrigin,
    isSlideModalOpen,
    loading,
    onSubmit,
    setIsSlideModalOpen,
    values,
    locationStatus: locationStatus?.active
  };
};

/**
 * Hook to get the values to Login
 * @returns
 */
export const useLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { allowed, geolocation, deviceInfo, loading } = useSelector(
    state => state.base);
    const { applications } = useSelector(state => state.user);
  const { register, formState: { errors }, handleSubmit, reset } = useForm({
    resolver: yupResolver(schemaLogin),
    defaultValues: defaultValuesLogin
  });

  const onLogin = async (values) => {
    try {
      dispatch(setLoader(true));
      reset();

      if (notUndefinedNull(deviceInfo)) {
        const data = {
          ...values,
          ip_info: {
            ...deviceInfo,
            ...geolocation
          }
        };
        const response = await dispatch(loginUser(data));
        if (!response.error && response?.success) {
          const {
            token,
            info,
            local_user_info: localUserInfo,
            last_product_request_id: lastProductRequestId
          } = response.success;
          const appOriginData = await dispatch(getPersonData(info.user_id, lastProductRequestId));
          if (appOriginData) {
            if (await dispatch(getStaticData())) {
              dispatch({
                type: USER_LOGIN,
                payload: {
                  userIdBifrost: info.user_id,
                  username: info.username,
                  token: token,
                  lastProductRequestId: lastProductRequestId
                }
              });
              await dispatch(getUserImages(info.user_id));
              dispatch(getBase());
              if (await dispatch(sendCheckUserTier())) {
                dispatch(getRejectedFiles());
                dispatch({ type: USER_APP_ORIGIN, payload: appOriginData });
                dispatch(getProductRequest(localUserInfo.id));
                dispatch(setLoader(false));
              } else {
                dispatch(setLogout());
                dispatch(setLoader(false));
                dispatch(setMessage(t("message.onLogin"), 'error'));
              }
              let productsAndServices = [];
              if (!applications) {
                productsAndServices = await dispatch(getUserApplications());
              } else {
                productsAndServices = applications;
              }
              let product = productsAndServices.filter(
                product => product?.application?.unique_name === appOriginData);
              if (product.length === 0) product = [{image_main: logoBgAppOrigin.default }];
              const [productRequest] = product;
              dispatch({ type: USER_INFO_PRODUCT_REQUEST, payload: productRequest });
            }
          }
        } else {
          dispatch(setLoader(false));
        }
      } else {
        dispatch(setLoader(false));
        dispatch(setMessage(t("message.onLogin"), 'error'));
      }
    } catch (error) {
      console.error('onLogin in useLogin : ', error);
      if (!loading) dispatch(setLoader(false));
      dispatch(setMessage(t("message.onLogin"), 'error'));
    }
  };

  const onGetStarted = () => {
    if (allowed) {
      if (notUndefinedNull(geolocation)) {
        navigate(routes.getStartedEmail(appsOrigin.default));
      } else {
        dispatch(setMessage(t("common.requestActivateGeolocation"), 'error'));
      }
    } else {
      dispatch(setMessage(t("common.countryNotAllowed"), 'error'));
    }
  };

  const activateLocation = async () => {
    try {
      const response = await dispatch(getAproxUserGeolocation())
      if (response) dispatch(setMessage(t("message.geolocationSuccess"), 'success'));
    } catch (error) {
      dispatch(setMessage(t("message.geolocationError"), 'error'));
      console.error(error)
    }
  };
  
  useEffect(() => {
    if (isUndefinedNull(geolocation)) {
      activateLocation()
    }
  }, [])

  return {
    errors, handleSubmit, onGetStarted, onLogin, register, activateLocation, reset
  };
};

/**
 * Hook to get the values to GetStartedEmail
 * @returns
 */
export const useGetStartedEmail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { loading } = useSelector(state => state.base);
  const { appOrigin, applications, timeRefresh } = useSelector(state => state.user);
  const { register, formState: { errors }, handleSubmit, reset } = useForm({
    resolver: yupResolver(schemaEmail),
    defaultValues: defaultValuesEmail
  });

  useEffect(() => {
    if (appOrigin !== appsOrigin.default) dispatch({ type: USER_APP_ORIGIN, payload: appsOrigin.default });

    if (notUndefinedNull(timeRefresh)) {
      if (checkMinutesPassed(timeRefresh, envSetting.minutesRefreshApplication)) {
        dispatch(getTimeRefreshProducts());
        dispatch(getUserApplications());
      }
    } else {
      dispatch(getTimeRefreshProducts());
      dispatch(getUserApplications());
    }
  }, [applications, timeRefresh]);

  const onSignUp = async values => {
    try {
      dispatch(setLoader(true));
      reset();
      values['application_origin'] = appOrigin;
      await dispatch(signUpUser(values));
      dispatch(setLoader(false));
    } catch (error) {
      console.error('onSignUp in useGetStartedEmail: ', error);
      if (!loading) dispatch(setLoader(false));
      dispatch(setMessage(t("message.signUpUser"), 'error'));
    }
  };

  const onProduct = product => navigate(routes.getStartedEmail(product));

  return { applications, errors, handleSubmit, onProduct, onSignUp, register };
};

/**
 * Hook to get the values to useGetStarted
 * @returns
 */
export const useGetStarted = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { loading } = useSelector(state => state.base);
  const { appOrigin } = useSelector(state => state.user);
  const appOriginParam = params.hash.split('&').pop().replace('app_origin=', '');
  const { register, formState: { errors }, handleSubmit, reset } = useForm({
    resolver: yupResolver(schemaGetStarted),
    defaultValues: defaultValuesGetStarted
  });

  useEffect(() => {
    if (appOrigin !== appOriginParam) dispatch(
      { type: USER_APP_ORIGIN, payload: appOriginParam });
  }, [appOriginParam]);

  const onSignUp = async values => {
    try {
      dispatch(setLoader(true));
      reset();
      values['hash'] = params?.hash.split('&').shift();
      delete values.termservice;
      const response = await dispatch(verifyUser(values));
      dispatch(setLoader(false));
      if (!response) navigate(routes.login(appOrigin));
    } catch (error) {
      console.error('onSignUp in useGetStarted: ', error);
      if (!loading) dispatch(setLoader(false));
      dispatch(setMessage(t("message.verifyUser"), 'error'));
    }
  };

  return { errors, handleSubmit, onSignUp, register };
};

/**
 * Hook to get the values to useGetStarted
 * @returns
 */
export const useInternetBanking = (allowed) => {
  const { register, formState: { errors }, handleSubmit, reset } = useForm({
    resolver: yupResolver(schemaInternetBankingUser),
    defaultValues: defaultValuesInternetBanking
  });
  const [isSlideModalOpen, setIsSlideModalOpen] = useState(false);
  const [values, setValues] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { loading } = useSelector(state => state.base);
  const { userIdBifrost, lastProductRequestId, selectedProduct } = useSelector(state => state?.user);
  const ebankingCreationPending = useSelector(
    state => state?.personData?.data?.product_request?.user?.ebanking_user_creation_pending);
  const userId = useSelector(state => state?.personData?.data?.client_person_id);

  useEffect(() => {
    if (!ebankingCreationPending) {
      navigate(siteMap.homepage.path);
    }
  }, [ebankingCreationPending]);

  const onSubmit = values => {
    if (allowed) {
      dispatch(setLoader(false));
      setIsSlideModalOpen(true);
      setValues(values);
    } else {
      dispatch(setMessage(t("common.countryNotAllowed"), 'error'));
    }
  };

  const onLogout = () => dispatch(setLogout());

  const onSignUp = async values => {
    try {
      dispatch(setLoader(true));
      reset();
      const response = await dispatch(createInternetBankingUser(values));
      if(response) dispatch(setLoader(false));
      if (!response) {
        // Dispatch getPersonData. This will change the value of 'ebanking_user_creation_pending' to 'false' and redirect accordingly.
        dispatch(getPersonData(userIdBifrost, lastProductRequestId)).then(
          (res) => dispatch(setLoader(false)));
      }
    } catch (error) {
      console.error('onSignUp in useInternetBanking: ', error);
      if (!loading) dispatch(setLoader(false));
      dispatch(setMessage(t("eBanking.error"), 'error'));
    }
  };

  return {
    errors,
    register,
    handleSubmit,
    onSubmit,
    isSlideModalOpen,
    setIsSlideModalOpen,
    values,
    loading,
    selectedProduct,
    userId,
    onLogout,
    onSignUp
  };
};

/**
 * Hook to get the values to RecoverPasswordEmail
 * @returns
 */
export const useRecoverPasswordEmail = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { loading } = useSelector(state => state.base);
  const { appOrigin, applications } = useSelector(state => state.user);
  const { register, formState: { errors }, handleSubmit, reset } = useForm({
    resolver: yupResolver(schemaEmail),
    defaultValues: defaultValuesEmail
  });

  useEffect(() => {
    if (!applications) dispatch(getUserApplications());
  }, [applications, dispatch]);

  const onRecover = async values => {
    try {
      dispatch(setLoader(true));
      reset();
      values['application_origin'] = appOrigin;
      await dispatch(recoverPasswordEmail(values));
      dispatch(setLoader(false));
    } catch (error) {
      console.error('onRecover in useRecoverPasswordEmail: ', error);
      if (!loading) dispatch(setLoader(false));
      dispatch(setMessage(t("message.changePassword"), 'error'));
    }
  };

  return { errors, handleSubmit, onRecover, register };
};

/**
 * Hook to get the values to Change Password in recover password
 * @returns
 */
export const useRecoverPassword = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const appOriginParam = params?.hash.split('&').pop().replace('app_origin=', '');
  const { t } = useTranslation();
  const { loading } = useSelector(state => state.base);
  const { appOrigin, isPassword } = useSelector(state => state.user);
  const { register, formState: { errors }, handleSubmit, reset } = useForm({
    resolver: yupResolver(schemaRecoverPassword),
    defaultValues: defaultValuesRecoverPassword
  });

  useEffect(() => {
    if (appOrigin !== appOriginParam) dispatch(
      { type: USER_APP_ORIGIN, payload: appOriginParam });
  }, [appOriginParam, appOrigin]);

  useEffect(() => {
    if (isPassword) {
      navigate(siteMap.login.path2);
      dispatch(setPassword(false));
    }
  }, [isPassword]);

  const onRecover = async values => {
    try {
      dispatch(setLoader(true));
      reset();
      values['hash'] = params?.hash.split('&').shift();
      await dispatch(changePassword(values));
      dispatch(setLoader(false));
    } catch (error) {
      if (!loading) dispatch(setLoader(false));
      console.error('onRecover in useRecoverPassword: ', error);
      dispatch(setMessage(t("message.changePassword"), 'error'));
    }
  };

  return { errors, handleSubmit, onRecover, register };
};

/**
 * Hook to get the values to Homepage
 * @returns
 */
export const useHomepage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { appOrigin, code, level, username, applications } = useSelector(
    state => state.user);
  const { expiredFiles, rejectedFiles } = useSelector(state => state.base);

  const routeProducts = () =>
    navigate(routes.productsServices('product_and_services'));

  const routeRequests = () =>
    navigate(routes.productRequests('product_requests'));

  const routeCreatedProducts = () =>
    navigate(routes.createdProducts('edition_requests') );

  const routeProfile = () => navigate(routes.profile('profile'));

  const routeExpired = () => navigate(routes.files(fileStatus.expired));

  const routeRejected = () => navigate(routes.files(fileStatus.rejected));

  const onLogout = () => dispatch(setLogout());

  useEffect(() => {
    const getApplications = async() => {
      let productsAndServices = [];
      if (!applications) {
        productsAndServices = await dispatch(getUserApplications());
      } else {
        productsAndServices = applications;
      }
      let product = productsAndServices.filter(
        product => product?.application?.unique_name === appOrigin);
      if (product.length === 0 ) product = [{image_main: logoBgAppOrigin.default }];
      const [productRequest] = product;
      dispatch({ type: USER_INFO_PRODUCT_REQUEST, payload: productRequest });
    };

    getApplications();
  }, [dispatch, appOrigin]);

  return {
    code,
    expiredFiles,
    level,
    onLogout,
    rejectedFiles,
    routeExpired,
    routeProducts,
    routeProfile,
    routeRejected,
    username,
    routeRequests,
    routeCreatedProducts
  };
};

/**
 * Hook to get the values to Profile
 * @returns
 */
export const useInformation = () => {
  const { t } = useTranslation();
  const { data: personData } = useSelector(state => state.personData);
  const email = [];
  const general = [];
  const phone = [];

  // Email
  if (personData?.personal_email) {
    email.push({
      text: personData.personal_email,
      name: t("common.email"),
      key: profile.personalEmail,
      lg: 6
    });
  }
  if (personData?.business_email) {
    email.push({
      text: personData.business_email,
      name: t("profile.businessEmail"),
      key: profile.businessEmail,
      lg: 6
    });
  }

  // General
  if (personData?.first_name && personData?.last_name) {
    general.push({
      text: `${personData?.first_name} ${personData?.second_name ?? ''} ${
        personData?.last_name} ${personData?.second_last_name ?? ''}`,
      name: t("profile.fullName"),
      key: profile.fullName
    });
  }

  // Phone
  if (personData.validate_phone) {
    phone.push({
      text: personData.celular_phone,
      name: t("profile.cellPhone"),
      key: profile.cellPhone
    });
  }

  return { email, general, phone };
};

/**
 * Hook to get the values to ClientEntity
 * @returns
 */
export const useClientEntity = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [productsList, setProductsList] = useState([]);
  const [oldMask, setOldMask] = useState(null);
  const [country, setCountry] = useState(null);
  const { loading } = useSelector(state => state.base);
  const { applications, dataSummary } = useSelector(state => state.user);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    setError
  } = useForm({
    resolver: yupResolver(schemaClientEntity),
    defaultValues: defaultValuesClientEntity
  });

  useEffect(() => {
    if (!applications) {
      dispatch(getUserApplications());
    } else {
      const products = applications.filter(
        product => product?.application?.active && product?.application?.create_account).map(
          (item) => {
            return item.application;
      });
      setProductsList(products);
    };
  }, [applications, dispatch]);

  useEffect(() => {
    if (!dataSummary) {
      dispatch(getDataSummary());
    } else {
      const documentType = dataSummary.document_type.filter(item => !item.company).sort(
        (a,b) => a.description.localeCompare(b.description));
      const country = dataSummary.country.sort((a,b) => a.name.localeCompare(b.name));
      dataSummary.document_type = documentType;
      dataSummary.country = country;
      setCountry(dataSummary);
    };
  }, [dataSummary, dispatch]);

  const setValuesCountry = value => {
    setValue("document_country_id", value);
    const message = (!isEmpty(value))
      ? ''
      : t("common.fieldRequired");
    setError('document_country_id', { message: message });
  };

  const setValuesDocument = value => {
    const [document_additional_info] = country.document_additional_info.filter(
      mask => mask.document_type === parseInt(value));
    const documentField = fieldUniqueName('document_number');
    if (document_additional_info) {
      setMask(documentField, paramsAdd, JSON.stringify(document_additional_info?.mask));
      setOldMask(document_additional_info?.mask);
    } else {
      setMask(documentField, 'remove', JSON.stringify(oldMask));
    }
    const message = (!isEmpty(value))
      ? ''
      : t("common.fieldRequired");
    setError('document_type_id', { message: message });
  };

  const setValuePhoneCountry = value => {
    setValue("cell_phone_country_id", value);
    const message = (!isEmpty(value))
      ? ''
      : t("common.fieldRequired");
    setError('cell_phone_country_id', { message: message });
  };

  const onRegister = async values => {
    try {
      dispatch(setLoader(true));
      values['cell_phone'] = (values['cell_phone'].substring(values['cell_phone'].indexOf(' ') + 1))
        .split(' ').join('');
      values['document_number'] = values['document_number'].split(' ').join('');
      await dispatch(registerClientEntity(values));
      reset();
      dispatch(setLoader(false));
    } catch (error) {
      console.error('onRegister: error in client entity', error);
      if (!loading) dispatch(setLoader(false));
      dispatch(setMessage(t("message.signUpUser"), 'error'));
    }
  };

  return {
    country,
    errors,
    handleSubmit,
    onRegister,
    register,
    setValuesCountry,
    setValuesDocument,
    setValuePhoneCountry
  };
};