import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { Label, TooltipIcon } from '../../shared/simple';
import { StyledGroup } from '../../shared/Input/styled';
import { useTextLanguage } from '../../../hooks';

/**
 * Component to label
 * @component
 * @param {Object} field
 * @returns
 */
export const LabelConversion = ({ field }) => {
  const uniqueName = `${field.unique_name}__id__${field.id}`;
  const { description, languageText } = useTextLanguage();

  return (
    <>
      {field && (
        <>
          <Col
            className={ field.col_size }
            data-module={ `module-${field.category?.field_category}` }
            data-name={ uniqueName }
            id={ `group_field_${field.id}` }
            key={ field.id }
            xs={12}
          >
            <StyledGroup className="mb-3">
              <Label
                className="w-100"
              >
                { field[description] }&nbsp;{ field?.[`tooltip${
                  languageText}`] && (<TooltipIcon tooltipText={ field[
                  `tooltip${languageText}`] } />)}
              </Label>
              <Label
                className="w-100 fw-bold"
                data-action={ field.action }
                data-category={ `category-${field.category?.field_category}` }
                data-condition={ field.parent_condition }
                data-parent={ field.parent }
                data-unique-name={ field.unique_name }
                id={ field.id }
                key={ field.id }
                name={ uniqueName }
              >
                0,00
              </Label>
            </StyledGroup>
          </Col>
        </>
      )}
    </>
  );
};

LabelConversion.propTypes = {
  field: PropTypes.object.isRequired,
};