import React from 'react';
import { Col } from 'react-bootstrap';
import { Help } from '../components/shared/Help';
import { Files } from '../components/files';

/**
 * Page tier
 * @returns {JSX}
 */
const FilesPage = () => {
  return (
    <>
      <Col className="p-0" xl={3} md={4} xs={12}>
        <Help backButton={false} />
      </Col>
      <Col className="p-4" xl={9} md={8} xs={12}>
        <Files />
      </Col>
    </>
  );
};

export default FilesPage;