import React from 'react';
import { useAppOrigin } from '../hooks';
import { Col } from 'react-bootstrap';
import { Homepage } from '../components/user/Homepage';
import { Menu } from '../components/shared/Menu';

/**
 * User home page and level menu page
 * @returns {JSX}
 */
const HomepagePage = () => {
  useAppOrigin();

  return (
    <>
      <Col className="p-0" xs={12} md={6} xl={8}>
        <Homepage />
      </Col>
      <Col className="p-0" xs={12} md={6} xl={4}>
        <Menu />
      </Col>
    </>
  );
};

export default HomepagePage;