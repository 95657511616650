import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, Outlet } from 'react-router-dom';
import { siteMap } from '../constants';
import { useRouteParams } from '../hooks';

/**
 * Public route
 * @param {Boolean} isAuthenticated
 * @returns
 */
const PublicRoute = ({ isAuthenticated }) => {
  useRouteParams();

  return isAuthenticated ? <Navigate to={siteMap.homepage.path} /> : <Outlet />;
};

PublicRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired
};

export default PublicRoute;