import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { uniqueAddress, querysNominatim } from '../constants';
import { isUndefinedNull, notUndefinedNull, trimUpperCase } from '../helpers';
import { getLocationByParameter, setLoader, setMessage } from '../redux/actions';

/**
 * use to Map
 * @param {Function} getValues
 * @param {Function} setValue
 * @param {Function} uniqueName
 * @returns
 */
export const useMap = (getValues, setValue, watch) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { geolocation: { latitude, longitude }, allowedCountries } = useSelector(
    state => state.base);
  const [latLong, setLatLong] = useState([latitude, longitude]);
  const values = getValues();
  const namePostalCode = Array.from(Object.keys(values)).filter(
    elem => elem.includes(uniqueAddress.postalCode)).pop();
  const nameCountry = Array.from(Object.keys(values)).filter(
    elem => elem.includes(uniqueAddress.country)).pop();
  const watchPostalCode = watch(namePostalCode, '');

  const setInputValues = async data => {
    dispatch(setLoader(true));
    const address = await dispatch(getLocationByParameter(data));
    const country = allowedCountries?.find(country =>
      trimUpperCase(country.code) === trimUpperCase(address?.country));
    if (country) {
      const nameAddress = Array.from(Object.keys(values)).filter(
        elem => elem.includes(uniqueAddress.address)).pop();
      if (nameAddress) setValue(nameAddress, address?.address);
      setValue(nameCountry, country.country_id);
      setValue(namePostalCode, address?.postalCode);
      if (data.type === 'search') setLatLong(
        [address?.latitude, address?.longitude]);
    } else if (isUndefinedNull(country) || isUndefinedNull(address)) {
      dispatch(setMessage(t("common.postalCodeError"), 'info'));
    }
    dispatch(setLoader(false));
  };

  const getLatLong = async latLong => {
    const data = {
      type: 'reverse',
      value: {
        lat: latLong[0],
        lon: latLong[1]
      }
    };
    setLatLong(latLong);
    await setInputValues(data);
  };

  useEffect(() => {
    const getAddressByPostalCode = async () => {
      const countrySelected = allowedCountries.find(country =>
        country.country_id === parseInt(values[nameCountry]));
      if (values[namePostalCode]?.length > 4 && notUndefinedNull(countrySelected)) {
        const data = {
          type: 'search',
          searchName: [
            querysNominatim.postalCode,
            querysNominatim.country
          ],
          value: [
            values[namePostalCode],
            countrySelected.code
          ]
        };
        await setInputValues(data);
      }
    };

    getAddressByPostalCode();
  }, [watchPostalCode]);

  return { latLong, getLatLong };
};