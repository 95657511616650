import React from 'react'
import { useNavigate } from 'react-router-dom';
import { AlertContainer, ContentWrapper, LinkButton, InnerContainer, ErrorImg } from '../components/errors/styled';
import { LinkLogo } from '../components/user/LinkLogo';
import { Footer } from '../components/shared/Footer';
import { Row, Container } from 'react-bootstrap';
import ErrorIcon from '../assets/images/errors/ErrorIcon9.1.png'
import { Button } from '../components/shared/simple';
import { useTranslation } from 'react-i18next';
import { envSetting } from '../constants';
import { trimLowerCase } from '../helpers';

const useErrorBoundary = () => {
  const navigate = useNavigate()
  const { t } = useTranslation();
  const { isDebugErrorBoundary } = envSetting
  let isDebugMode = (trimLowerCase(isDebugErrorBoundary) === 'true') ? true : false
  const [errors, setError] = React.useState('')

  const logError = (error, info) => {
    // Do something with the error, e.g. log to an external API
    console.log(error)
    console.log(error.message)
    setError(error.message)
  };

  const ErrorComponent = ({ error, resetErrorBoundary }) => {

    const navigateLogin = () => {
      navigate('/login')
      setTimeout(() => {
        resetErrorBoundary()
      }, 100);
    }

    const navigateLogout = () => {
      navigate('/logout')
      setTimeout(() => {
        resetErrorBoundary()
      }, 100);
    }

    return (
      <Container fluid className='h-100'>
        <Row>
          <AlertContainer role="alert">
            <LinkLogo />
            <ContentWrapper>
              <InnerContainer>
                <ErrorImg src={ErrorIcon} />
                <p
                  className='mb-0'
                >
                  {t('ERRORS.boundaryTitle')}
                </p>
                {
                  isDebugMode ?
                  (
                    <div className="alert alert-danger mb-0" role="alert">
                      {errors}
                    </div>
                  ) :
                  (
                    <div className="alert alert-primary mb-0" role="alert">
                      {t('ERRORS.boundaryMessage1')} <br />
                      {t('ERRORS.boundaryMessage2')}
                    </div>
                  )
                }
                <div>
                  <Button 
                    className='py-1'
                    onClick={navigateLogin}
                    color='third' 
                  >
                    {t('ERRORS.resetBoundary')}
                  </Button>
                  <span className='font-weight-light text-muted'>
                    {t('ERRORS.hardResetBoundary1')} &nbsp;
                    <LinkButton 
                      onClick={navigateLogout}
                      className='text-muted'
                    >
                      {t('ERRORS.hardResetBoundary2')}.
                    </LinkButton>
                  </span>
                </div>
              </InnerContainer>
            </ContentWrapper>
          </AlertContainer>
          <Footer />
        </Row>
      </Container>
    );  
  }

  return {
    logError,
    ErrorComponent
  }
}

export default useErrorBoundary