import {
  TIER_CATEGORIES,
  TIER_CATEGORY,
  TIER_CLEAR_CATEGORY,
  TIER_CLEAR,
  TIER_LAST_TIER,
  TIER_PARAMS,
  TIER_SET_CURRENT_TIER,
  TIER_SET_SCHEMA,
  TIER_SET_TIERS,
  TIER_STEPS,
  TIER_VALUES_EDIT,
  TIER_PARENTS
} from '../types';

const initState = {
  action: null,
  category: null,
  code: null,
  completed: null,
  defaultValuesTier: null,
  highstStep: 0,
  lastTier: null,
  nextTier: null,
  numberCategories: 0,
  parents: [],
  schema: null,
  tier: null,
  tierCategories: null,
  tierFieldsEdit: null,
  tierFieldState: null,
  tiers: null
};

/**
 * Reducer to tier
 * @param {Object} state
 * @param {Object} action
 * @returns
 */
export const tierReducer = (state=initState, action) => {
  switch (action.type) {
    case TIER_CLEAR:
      return initState;

    case TIER_SET_CURRENT_TIER:
      return {
        ...state,
        ...action.payload,
      };

    case TIER_SET_TIERS:
      return {
        ...state,
        tiers: action.payload,
      };

    case TIER_SET_SCHEMA:
      return {
        ...state,
        ...action.payload,
      };

    case TIER_STEPS:
      return {
        ...state,
        highstStep: action.payload,
      };

    case TIER_CATEGORIES:
      return {
        ...state,
        ...action.payload,
      };

    case TIER_CATEGORY:
      return {
        ...state,
        category: action.payload,
      };

    case TIER_LAST_TIER:
      return {
        ...state,
        lastTier: action.payload,
      };

    case TIER_VALUES_EDIT:
      return {
        ...state,
        tierFieldsEdit: action.payload,
      };

    case TIER_CLEAR_CATEGORY:
      return {
        ...state,
        category: null,
        code: null,
        defaultValuesTier: null,
        highstStep: 0,
        numberCategories: 0,
        parents: [],
        schema: null,
        tier: null,
        tierCategories: null,
        tierFieldsEdit: null,
        tierFieldState: null,
      };

    case TIER_PARAMS:
      return {
        ...state,
        ...action.payload,
      };

    case TIER_PARENTS:
      return {
        ...state,
        parents: action.payload,
      };

    default:
      return state;
  }
};