import {
  BASE_ACTIVE_LOCATION,
  BASE_ACTUAL_LANGUAGE,
  BASE_ALL_ASSISTANTS,
  BASE_ALLOWED_COUNTRIES,
  BASE_ALLOWED,
  BASE_CLEAR,
  BASE_DELAY,
  BASE_DEVICE_INFO,
  BASE_ERROR_REQUEST,
  BASE_EXPIRED_FILES,
  BASE_GEOLOCATION,
  BASE_LOADING,
  BASE_LOCATION,
  BASE_MESSAGE_TIER,
  BASE_REJECTED_FILES,
  BASE_REQUEST_LANGUAGE,
  BASE_REQUEST,
  BASE_SELECTED_ASSISTANT,
  BASE_SELECTED_LANGUAGE,
  BASE_SET_ELEMENTS,
  BASE_PARAMS,
  BASE_QRROUTE
} from '../types';

const initState = {
  actualLanguage: null,
  allAssistants: null,
  allowed: true,
  allowedCountries: null,
  code: 200,
  country: {},
  currency: {},
  delay: false,
  deviceInfo: null,
  error: false,
  expiredFiles: false,
  geolocation: null,
  loading: false,
  location: null,
  locationStatus: { state: null, active: true },
  messageTier: false,
  QRRoute: {
    active: false,
    peerId: ''
  },
  rejectedFiles: false,
  request: false,
  requestLanguage: null,
  route: {},
  selectedAssistant: null,
  selectedLanguages: null
};

/**
 * Reducer to base
 * @param {Object} state
 * @param {Object} action
 * @returns
 */
export const baseReducer = (state=initState, action) => {
  switch (action.type) {
    case BASE_CLEAR:
      return initState;

    case BASE_QRROUTE:
      return {
        ...state,
        QRRoute: action.payload
      }
      ;
    case BASE_SET_ELEMENTS:
      return {
        ...state,
        ...action.payload,
      };

    case BASE_ERROR_REQUEST:
      return {
        ...state,
        ...action.payload,
      };

    case BASE_REJECTED_FILES:
      return {
        ...state,
        ...action.payload,
      };

    case BASE_EXPIRED_FILES:
      return {
        ...state,
        ...action.payload,
      };

    case BASE_LOCATION:
      return {
        ...state,
        location: action.payload
      };

    case BASE_ALLOWED_COUNTRIES:
      return {
        ...state,
        allowedCountries: action.payload
      };

    case BASE_REQUEST:
      return {
        ...state,
        request: action.payload
      };

    case BASE_ALLOWED:
      return {
        ...state,
        allowed: action.payload
      };

    case BASE_SELECTED_LANGUAGE:
      return {
        ...state,
        selectedLanguages: action.payload
      };

    case BASE_ACTUAL_LANGUAGE:
      return {
        ...state,
        actualLanguage: action.payload
      };

    case BASE_LOADING:
      return {
        ...state,
        loading: action.payload
      };

    case BASE_REQUEST_LANGUAGE:
      return {
        ...state,
        requestLanguage: action.payload
      };

    case BASE_GEOLOCATION:
      return {
        ...state,
        geolocation: action.payload
      };

    case BASE_DEVICE_INFO:
      return {
        ...state,
        deviceInfo: action.payload
      };

    case BASE_ACTIVE_LOCATION:
      return {
        ...state,
        locationStatus: action.payload
      };

    case BASE_DELAY:
      return {
        ...state,
        delay: action.payload
      };

    case BASE_SELECTED_ASSISTANT:
      return {
        ...state,
        selectedAssistant: action.payload
      };

    case BASE_ALL_ASSISTANTS:
      return {
        ...state,
        allAssistants: action.payload
      };

    case BASE_MESSAGE_TIER:
      return {
        ...state,
        messageTier: action.payload
      };

    case BASE_PARAMS:
      return {
        ...state,
        route: action.payload
      };

    default:
      return state;
  }
};