import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../shared/simple';
import { Alert } from 'react-bootstrap';
import { useDialog } from '../../../../hooks/useDialog';
import { ConditionsDialog } from './dialog/Dialog';
import { useEditBlock } from '../../../../hooks/useBlock';
import { TokenDialog } from './dialog/TokenDialog';
import { editionStatus } from '../../../../constants';
import Skeleton from '@mui/material/Skeleton';
import Entry from './Entry';

/**
 * Component for editing beneficiaries
 * @component
 * @param {Object} selectedProduct
 * @returns {JSX.Element}
 */
export const Edit = ({ selectedProduct }) => {
  const {
    handleAdd,
    entries,
    canSubmit,
    t,
    setEntries,
    resetBeneficiary,
    loadingRelatedAccounts,
    wasPopulated
  } = useEditBlock({ selectedProduct });
  const {
    open,
    handleClose,
    setOpen,
    handleSubmitTokenRequest,
    openToken,
    handleCloseToken,
    handleTokenValidation,
    loading
  } = useDialog({ entries, setEntries, selectedProduct });
  const [show, setShow] = useState(true);

  return (
    <>
      {loadingRelatedAccounts
      ? (
        <div className="mt-4">
          <Skeleton variant="rounded" width={"100%"} height={126} />
        </div>
      )
      : (
        <Row className="mt-4 mb-4">
          <Col className="pt-0 pb-0" xs={12}>
            {entries?.filter(entry => entry.active).length ? entries.filter(entry => entry.active).map( entry => {
              return(
                <Entry
                  entry={entry}
                  entries={entries}
                  setEntries={setEntries}
                  key={entry.order}
                  handleAdd={handleAdd}
                  canSubmit={canSubmit}
                  selectedProduct={selectedProduct}
                />
              );
            })
            : <>
              <NoEntries handleAdd={handleAdd} setOpen={setOpen} selectedProduct={selectedProduct} wasPopulated={wasPopulated} />
            </>
            }
            { (show && selectedProduct.status === editionStatus.pending) &&
              <Alert variant='info' onClose={() => setShow(false)}  dismissible>
                {t("block.pendingChanges")}
              </Alert>
            }
          </Col>

          <Col xs={12} md={4} xl={3} className="pt-0">
            {((entries?.filter(entry => entry.active).length > 0) && (
              selectedProduct.status === editionStatus.editable))
            ? (
              <button
                style={{width: "100%"}}
                className="btn btn-danger mb-0 ml-3"
                type="button"
                onClick={resetBeneficiary}
                name="btn_reset_all"
              >
                {t("block.deleteAll")}
              </button>
            )
            : ''
            }
          </Col>

          <Col xs={12} md={{ span:4, offset:4 }} xl={{ span:3, offset:6 }} className="pt-0">
            { ((selectedProduct.status === editionStatus.editable) &&
              (entries?.filter(entry => entry.active).length > 0)) &&
              <Button
              className="btn mb-0"
              type="button"
              color="fourth"
              onClick={ () => setOpen(true) }
              name="btn_dialog"
              disabled={!canSubmit}
            >
              {t("block.saveEdit")}
            </Button>
            }
          </Col>
          <ConditionsDialog
            open={open}
            handleClose={handleClose}
            handleSubmit={handleSubmitTokenRequest}
            loading={loading}
          />
          <TokenDialog
            open={openToken}
            handleClose={handleCloseToken}
            handleSubmit={handleTokenValidation}
            loading={loading}
          />
        </Row>
      )}
    </>
  );
};

/**
* Component to render when there are no entries
* @component
* @param {Function} handleAdd
* @param {Function} setOpen
* @param {Object} selectedProduct
* @returns {JSX.Element}
*/
const NoEntries = ({ handleAdd, setOpen, selectedProduct, wasPopulated }) => {
  const { t } = useTranslation();
  return(
    <>
      <Alert variant={'info'}>
        {`${t("block.noEntries")}`}
        {selectedProduct.status === editionStatus.editable && (
          <>
            &nbsp;
            {
              !wasPopulated ?
              <>
                <Alert.Link onClick={handleAdd} href="#">
                  {`${t("block.noEntriesLink")} `}
                </Alert.Link>
                {`${t("block.noEntriesPostLink")}.`}
              </> :
              <>
                <Alert.Link onClick={handleAdd} href="#">
                  {`${t("block.noEntriesLink")} `}
                </Alert.Link>
                {`${t("block.noEntriesPostLink")} `}
                {`${t("block.noEntriesPreSave")} `}
                <Alert.Link onClick={() => setOpen(true)} href="#">
                  {`${t("block.noEntriesSave")}`}
                </Alert.Link>
                {`${t("block.noEntriesPostSave")} `}
              </>
            }
          </>
        )}
      </Alert>
    </>
  );
};