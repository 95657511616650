import { useEffect } from 'react';

export const useDigicert = () => {
  useEffect(() => {
    let __dcid = [];
    __dcid.push({ "cid": "DigiCertClickID_LZjokdpX", "tag": "LZjokdpX" });

    const script = document.createElement('script');
    script.src = "https://seal.digicert.com/seals/cascade/seal.min.js";
    script.async = true;

    document.body.appendChild(script);
    var s = document.getElementsByTagName("script");
    var ls = s[(s.length - 1)];
    ls.parentNode.insertBefore(script, ls.nextSibling);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
};