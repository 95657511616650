import React from 'react';
import { Alert } from 'react-bootstrap';

/**
 * Message component for static message
 * @component
 * @returns
 */
export const StaticMessage = ({ variant, message }) => {
  return (
    <Alert key={ variant } variant={ variant } className="mb-1">
      { message }
    </Alert>
  );
};