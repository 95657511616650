import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationsES from './es/translations.json';
import translationsEN from './en/translations.json';

const defaultLang = process.env.REACT_APP_DEFAULT_LANGUAGE
let navigatorLang = navigator.language || navigator.userLanguage;
navigatorLang = navigatorLang.split('-')[0];
const availableLanguages = ['en', 'es'];
export const fallbackLng = availableLanguages.includes(navigatorLang) ? navigatorLang : defaultLang;
const resources = {
  es: {
    translation: translationsES
  },
  en: {
    translation: translationsEN
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,
    detection: {
      checkWhiteList: true
    },
    debug: false,
    whiteList: availableLanguages,
    interpolation: {
      escapeValue: false
    }
  });

i18n.changeLanguage(fallbackLng);
export default i18n;