import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { getShowField, trimLowerCase } from './../../../helpers';
import { CheckSelect, Select } from './../select';
import { TextInput } from './../input';
import { useEntry } from './../../../hooks/useEntry';
import { blockAdd, blockRemove } from './../../../constants';
import { Add, Remove, Validate } from './buttons';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useRemoveEntry } from '../../../hooks/useRemoveEntry';
import { useCheck } from '../../../hooks/useCheck';

const StyledEntry = styled.div`
  border: 1px solid gray;
  border-radius: 6px;
  position: relative;
`;

/**
 * Component Entry
 * @component
 * @param {Array<Object>} fields - An array of fields.
 * @param {Function} setEntries - React setter function. to set the entries.
 * @param {Object} entry - Current entry.
 * @param {Number} maxLength - Max number of entries allowed.
 * @param {Array<Objetc>} entries - An array of entries.
 * @property {Boolean} canContinue - Variable that controls if the user can advance on the steps or create a new Entry
 * @property {Function} setCanContinue - canContinue setter function.
 * @returns {JSX.Element}
 */
export const Entry = ({
  fields,
  setEntries,
  entry,
  maxLength,
  entries,
  canContinue,
  setCanContinue, handleAdd
}) => {
  const { t } = useTranslation();
  const [wisenrollUser, setWisenrollUser] = useState({
    error: false,
    user: null
  });
  const { entryNumber, isBlockDisabled, setIsBlockDisabled, action } = useEntry(entry.id);
  const { handleRemove } = useRemoveEntry(setEntries, entryNumber);
  const entryFields = fields.filter(field => trimLowerCase(field.unique_name).includes(`block${entryNumber}__`));
  const { handleCheck } = useCheck({entryFields, setWisenrollUser, setEntries, entryNumber});

  return (
    <StyledEntry className="row mb-4 py-2 mx-2">
      {fields.map(field => {
        if (field.active && getShowField(field)) {
          if (trimLowerCase(field.unique_name).includes(`block${entryNumber}__`)) {
            if (trimLowerCase(field.data_type) === 'selection') {
              return (
                <Select
                  field={field}
                  key={field.id}
                  entryProps={{
                    setWisenrollUser,
                    setIsBlockDisabled,
                    setCanContinue,
                    setEntries,
                    entry
                  }}
                />
              );
            }
            if (trimLowerCase(field.data_type) === 'text') {
              return(
                <TextInput
                  field={field}
                  key={field.id}
                />
              );
            }
            if (trimLowerCase(field.data_type) === 'checkbox') {
              return(
                <CheckSelect
                  field={field}
                  key={field.id}
                />
              );
            }
            if (trimLowerCase(field.data_type) === 'button_check') {
              return(
                <Validate
                  field={field}
                  key={field.id}
                  isBlockDisabled={isBlockDisabled}
                  action={action}
                  handleCheck={handleCheck}
                  entry={entry}
                />
              );
            }
          }
          if (entry.id === 1 && entries.length < maxLength && action !== 'edit' && trimLowerCase(
            field.data_type) === blockAdd && (entries[0].isValid) && (canContinue === true)) {
            return(
              <Add
                key={field.id}
                handleAdd={handleAdd}
              />
            );
          }
          if (trimLowerCase(field.data_type) === blockRemove && entry.id !== 1  && action !== 'edit') {
            return(
              <Remove
                key={field.id}
                entry={entry}
                handleRemove={handleRemove}
              />
            );
          }
        }
        return null;
      })}
      {(wisenrollUser?.user && !wisenrollUser?.error) && (
        <>
          <hr className="mt-1"></hr>
          <div className="alert alert-success d-flex flex-column" role="alert">
            <label className="text-muted">
              {t("block.name")}: <b>{wisenrollUser?.user?.firstName}</b>
            </label>
            <label className="text-muted">
              {t("block.lastName")}: <b>{wisenrollUser?.user?.lastName}</b>
            </label>
            <label className="text-muted">
              {t("block.docType")}: <b>{wisenrollUser?.user?.docType}</b>
            </label>
            <label className="text-muted">
              {t("block.docNumber")}: <b>{wisenrollUser?.user?.docNumber}</b>
            </label>
          </div>
        </>
      )}
      {(wisenrollUser?.error === true) && (
        <>
          <hr className="mt-1"></hr>
          <div className="alert alert-danger" role="alert">
            {t("block.error")}
          </div>
        </>
      )}
    </StyledEntry>
  );
};

Entry.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  setEntries: PropTypes.func.isRequired,
  entry: PropTypes.object.isRequired,
  maxLength: PropTypes.number.isRequired,
  entries: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleAdd: PropTypes.func.isRequired
};