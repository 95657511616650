import React from 'react';
import PropTypes from 'prop-types';
import {
  mediaUploadAccept,
  mediaUploadLabel,
  mediaVideoAccept,
  mediaVideoLabel,
  uniqueFileNames
} from '../../constants';
import { getShowField, trimLowerCase } from '../../helpers';
import {
  AuxInput,
  DateInput,
  FileInputButton,
  SignatureInput,
  TextInput
} from '../tier/input';
import { CheckboxSelect, ImageSelect, Select, AutocompleteTypeahead } from '../tier/select';
import { VideoMessage } from '../tier/VideoMessage';
import { useParams } from 'react-router-dom';

/**
 * Component with fields for tier
 * @component
 * @param {Object} fields
 * @returns
 */
export const Fields = ({ fields, files }) => {
  const { codeTier } = useParams() 

  return (
    <>
      {fields.map(item => {
        if (item.data) {
          if (item.data.active && getShowField(item.data)) {
            let file = files.filter(file => file.field_request.unique_name === item.data.unique_name)[0]
            let isVisible = false
            if (file) {
              isVisible = file.user_file_status.unique_description === codeTier
            }
            if (trimLowerCase(item.data.data_type) === 'text' ||
              trimLowerCase(item.data.data_type) === 'number' ||
              trimLowerCase(item.data.data_type) === 'textarea') {
              return (
                <TextInput
                  field={item.data}
                  key={item.data.id}
                />
              );
            }
            if (trimLowerCase(item.data.data_type) === 'image_selection') {
              return (
                <ImageSelect
                  field={item.data}
                  key={item.data.id}
                />
              );
            }
            if (trimLowerCase(item.data.data_type) === 'checkbox_selection' ||
              trimLowerCase(item.data.data_type) === 'media_selection') {
              return (
                <CheckboxSelect
                  field={item.data}
                  key={item.data.id}
                />
              );
            }
            if (trimLowerCase(item.data.data_type) === 'date') {
              return (
                <DateInput
                  field={item.data}
                  key={item.data.id}
                />
              );
            }
            if (trimLowerCase(item.data.data_type) === 'selection' && !JSON.parse(item.data?.action)?.isBlock) {
              if (item.data?.action && JSON.parse(item.data?.action).isTypeahead) {
                return (
                  <AutocompleteTypeahead
                    field={ item.data }
                    key={ item.data.id }
                  />
                );
              }
              return (
                <Select
                  field={ item.data }
                  key={ item.data.id }
                />
              );
            }
            if (trimLowerCase(item.data.data_type) === 'message') {
              return (
                <VideoMessage
                  field={item.data}
                  key={item.data.id}
                />
              );
            }
            if (trimLowerCase(item.data.data_type) === 'media_video') {
              return (
                <FileInputButton
                  accept={mediaVideoAccept}
                  formatAccept={mediaVideoLabel}
                  field={item.data}
                  key={item.data.id}
                  type="video"
                />
              );
            }
            if (trimLowerCase(item.data.data_type) === 'media_upload') {
              return (
                <FileInputButton
                  accept={mediaUploadAccept}
                  formatAccept={mediaUploadLabel}
                  field={item.data}
                  key={item.data.id}
                  type={(item.data.unique_name === uniqueFileNames[item.data.file_name])
                    ? "document"
                    : "image"}
                  isVisible={isVisible}
                />
              );
            }
            if (trimLowerCase(item.data.data_type) === 'media_signature') {
              return (
                <SignatureInput
                  field={item.data}
                  key={item.data.id}
                />
              );
            }
            if (trimLowerCase(item.data.data_type) === 'aux') {
              return (
                <AuxInput
                  field={item.data}
                  key={item.data.id}
                />
              );
            }
          }
        }
        return null;
      })}
    </>
  );
};

Fields.propTypes = {
  fields: PropTypes.array.isRequired,
};