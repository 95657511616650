import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRequestList, useTextLanguage } from '../../hooks';
import { statusRequest } from '../../constants';
import { DropdownActions } from './DropdownActions';
import Table from 'react-bootstrap/Table';
import { formatDate } from '../../helpers/base';

/**
 * Page application listing table
 * @returns {JSX}
 */
export const TableRequest = () => {
  const { t } = useTranslation();
  const { requests, getColorStatus, actualLanguage } = useRequestList(true);
  const { description } = useTextLanguage();

  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr className="text-center">
          <th>{ t("productRequest.date") }</th>
          <th>{ t("productRequest.product") }</th>
          <th>{ t("productRequest.status") }</th>
          <th>{ t("productRequest.actions") }</th>
        </tr>
      </thead>
      <tbody>
        {requests.length > 0 && requests.map((item, index) => {
          return (
            <tr key={index} className="text-center">
              <td>{formatDate(actualLanguage, item.create_date)}</td>
              <td>{item.application_origin[description]}</td>
              <td style={{color: getColorStatus(item.status.unique_description)}}>
                {item.status.description}
              </td>
              <td>
                {(item.status.unique_description !== statusRequest.sent
                  && item.status.unique_description !== statusRequest.success
                  && item.status.unique_description !== statusRequest.rejected) ? (
                    <DropdownActions data={item}/>
                ): "-"}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};