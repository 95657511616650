import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useCheckboxRadio, useTextLanguage } from '../../../hooks';
import { Col, Form } from 'react-bootstrap';
import { StyledGroup } from '../../shared/Input/styled';
import { Error, Label, TooltipIcon } from '../../shared/simple';
import { useFormContext } from 'react-hook-form';
import { theme } from '../../../theme';

const { first } = theme;

// Define element styles for the component
const StyledCheck = styled(Form.Check.Input)`
  border-radius: .5rem;
  outline: none;
  &:focus {
    border-color: ${first};
    box-shadow: none;
  }
`;

/**
 * Component radio select
 * @component
 * @param {Object} field
 * @returns
 */
export const RadioSelect = ({ field }) => {
  const uniqueName = `${field.unique_name}__id__${field.id}`;
  const { description, languageText } = useTextLanguage();
  const { register, formState: { errors } } = useFormContext();
  const { onClick, options } = useCheckboxRadio(field);

  return (
    <>
      {field && (
        <>
          {(options && field.col_size?.includes('col-before')) && (
            <Col xs={12} key={`col_before_${field.id}`}></Col>
          )}
          {options && (
            <Col
              className={ field.col_size }
              data-module={ `module-${field?.category?.field_category}` }
              data-name={ uniqueName }
              id={ `group_field_${field.id}` }
              key={ field.id }
              xs={12}
            >
              <StyledGroup
                aria-labelledby="checkbox-group"
                className="mb-3"
                role="group"
              >
                <Label
                  className="d-block"
                  htmlFor={ uniqueName }
                  xs={12}
                >
                  { field[description] }{ (field.required) && (
                    <strong className="text-danger">&nbsp;*</strong>)}
                  { (field?.[`tooltip${languageText}`]) && (
                    <TooltipIcon tooltipText={ field[`tooltip${languageText}`] } />)}
                </Label>
                {options?.map(option => {
                  if (option.active && !option?.exclude) {
                    return (
                      <StyledGroup
                        className="mb-1 form-check"
                        key={ `group_check_${ option.idValue }` }
                      >
                        <Form.Check>
                          <StyledCheck
                            { ...register(uniqueName) }
                            data-category={
                              `category-${field?.category?.field_category}` }
                            data-code-parent={ option?.codeParent }
                            data-code={ option?.codeValue }
                            data-id={ option?.codeId }
                            data-object={ JSON.stringify(option) }
                            id={ option.idValue }
                            key={ option.idValue }
                            name={ uniqueName }
                            onClick={ onClick }
                            type="radio"
                            value={ option.idValue }
                          />
                          <Form.Check.Label>
                            { option.textValue }
                          </Form.Check.Label>
                        </Form.Check>
                      </StyledGroup>
                    );
                  }
                  return null;
                })}
                {errors?.[uniqueName]?.message && (
                  <Error message={ errors[uniqueName].message } />
                )}
              </StyledGroup>
            </Col>
          )}
        </>
      )}
    </>
  );
};

RadioSelect.propTypes = {
  field: PropTypes.object.isRequired
};