import React from 'react';
import PropTypes from 'prop-types';
import { Map, Marker, ZoomControl } from 'pigeon-maps';
import { Col } from 'react-bootstrap';
import { Label, TooltipIcon } from '../../shared/simple';
import { useTextLanguage, useMap } from '../../../hooks';
import { useFormContext } from 'react-hook-form';

/**
 * Component Maps
 * @component
 * @param {Object} field
 * @returns
 */
export const Maps = ({ field }) => {
  const uniqueName = `${field.unique_name}__id__${field.id}`;
  const { getValues, setValue, watch } = useFormContext();
  const { description, languageText } = useTextLanguage();
  const { latLong, getLatLong } = useMap(getValues, setValue, watch);

  return (
    <>
     {field && (
      <>
        {(field.col_size?.includes('col-before')) && (
          <Col xs={12} key={ `col_before_${field.id}` }></Col>
        )}
        <Col
          className={ field.col_size }
          data-module={ `module-${field.category?.field_category}` }
          data-name={ uniqueName }
          id={ `group_field_${field.id}` }
          key={ field.id }
          xs={12}
        >
          <Label htmlFor={ uniqueName }>
            { field[description] }{ (field.required) && (
              <strong className="text-danger">&nbsp;*</strong>)}
            {(field[`tooltip${languageText}`]) && (
              <TooltipIcon tooltipText={ field[`tooltip${languageText}`] } />)}
          </Label>
          <Map
            height={ 500 }
            defaultCenter={ latLong }
            center={ latLong }
            defaultZoom={ 13 }
            mouseEvents={ true }
            onClick={ ({latLng}) => getLatLong(latLng) }
          >
            <Marker
              width={ 50 }
              anchor={ latLong } />
            <ZoomControl />
          </Map>
        </Col>
      </>
     )}
    </>
  );
};

Maps.propTypes = {
  field: PropTypes.object.isRequired
};