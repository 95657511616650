import { store } from '../redux/store/store';
import {
  fieldAttribute,
  fieldsAttribute,
  isEmpty,
  notUndefinedNull,
  sortElementInt,
  trimLowerCase,
} from './shared';
import { paramsAdd, paramsEdit } from '../constants';
import { getShowField } from '.';

/**** Export functions  ****/
/**
 * Get the categories of the selected tier
 * @param {Object} tier
 * @param {Object} categories
 * @param {Object} tierFields
 * @returns
 */
export const setCategoriesTierFields = (categories, tier, tierFields) => {
  let tierCategoriesField = tierFields?.filter(elem =>
    trimLowerCase(elem.tier) === trimLowerCase(tier?.id));
  tierCategoriesField = sortElementInt(tierCategoriesField, 'order');
  tierCategoriesField = sortElementInt(tierCategoriesField, 'step');

  let tierCategories = tierCategoriesField?.filter((elem, index, self) =>
    index === self.findIndex(t => (
      trimLowerCase(t.field_category) === trimLowerCase(elem.field_category)
    ))
  );
  tierCategories = sortElementInt(tierCategories, 'order');
  tierCategories = sortElementInt(tierCategories, 'step');

  categories?.forEach((category, index) => {
    const isIndex = tierCategories?.findIndex(
      elem => elem.field_category === category.id);
    categories[index].delete = !(isIndex >= 0);
    if (isIndex >= 0) {
      categories[index].step = tierCategories[isIndex].step;
      categories[index].order = tierCategories[isIndex].order;
    }
  });

  let result = categories?.filter(elem => !elem.delete);
  result = sortElementInt(result, 'order');
  result = sortElementInt(result, 'step');

  return result;
};

/**
 * Get the fields based on tier and category. And order the object
 * @param {Object} category
 * @param {Object} fields
 * @param {Object} tier
 * @param {Object} tierFields
 * @returns
 */
export const setFieldsCategories = (categories, tierFields, tier) => {
  let result = {};

  if (categories.length > 0) {
    Array.from(categories).forEach(category => {
      let categoriesField = tierFields?.filter(item => (
        trimLowerCase(item.category.tier) === trimLowerCase(
          tier.id) && trimLowerCase(item.category.field_category) === trimLowerCase(
          category.id)));
      categoriesField = sortElementInt(categoriesField, 'step');

      categoriesField.map(field => field['order'] = field.category.order);

      result[category.unique_name] = sortElementInt(categoriesField, 'order');
    });
  }

  return result;
};

/**
 * Get the fields based on tier
 * @param {Object} fields
 * @param {Object} tier
 * @param {Object} tierFields
 * @returns
 */
export const setTierFields = (fields, tier, tierFields) => {
  const result = [];

  if (tierFields.length > 0 && fields.length > 0) {
    const categoriesField = tierFields?.filter(elem => (trimLowerCase(
      elem.tier) === trimLowerCase(tier?.id)));

    fields?.forEach(field => {
      const indexCategory = categoriesField?.findIndex(
        elem => (elem.field === field.id && field.active));
      const category = categoriesField?.filter(
        elem => (trimLowerCase(elem.field) === trimLowerCase(
          field.id) && field.active));

      if (indexCategory >= 0) {
        const [categoryStep] = (category && category.length > 0) ? category : [];
        result.push(field);
        result[result.length - 1].category = categoriesField[indexCategory];
        result[result.length - 1].step = (
          categoryStep?.step) ? categoryStep.step : null;
        result[result.length - 1].group = field.unique_name?.split('__').shift();
      }
    });
  }

  return result;
};

/**
 * Show or not the module of the category at the beginning
 * @param {Object} categoriesTier
 * @param {Object} tierFieldState
 * @returns
 */
export const moduleCategories = (categoriesTier, tierFieldState) => {
  if (categoriesTier.length > 0) {
    const fieldsApps = [];

    tierFieldState.forEach(item => {
      const condition = (item.parent_condition) ? JSON.parse(
        item.parent_condition) : {};
      if (notUndefinedNull(condition?.app)) {
        if (getShowField(item)) fieldsApps.push(item);
      } else {
        fieldsApps.push(item);
      }
    });

    categoriesTier?.forEach((category, index, array) => {
      const indexElem = (fieldsApps?.findIndex(
        elem => (category?.id === elem?.category?.field_category) &&
          !(elem?.col_size?.includes('d-none'))));
      array[index].action = (indexElem >= 0) ? '' : 'd-none';
    });
  }

  return categoriesTier;
};

/**
 * Show or not the title of the category at the conditions
 * @param {String} id
 * @param {Boolean} allHide
 */
export const moduleCategory = (id, allHide=false) => {
  const childrenCategory = [];
  const children = fieldsAttribute('data-parent', id);
  const allCategories = [...new Set(Array.from(children).map(
    elem => elem?.dataset?.category))];

  Array.from(allCategories).forEach(elem => {
    if (notUndefinedNull(elem) && !isEmpty(elem)) {
      const id = elem.replace('category-', '');
      childrenCategory[elem] = fieldsAttribute('data-module', `module-${id}`);
    }
  });

  Array.from(allCategories).forEach(category => {
    const id = category.replace('category-', '');
    if (notUndefinedNull(id) && notUndefinedNull(trimLowerCase(id))) {
      if (document.getElementById(`module_${id}`)) {
        if (allHide) {
          document.getElementById(`module_${id}`).classList.add('d-none');
        } else {
          const childrenHide = Array.from(childrenCategory[category]).filter(
            elem => fieldAttribute(
              'data-module', `module-${id}`, `#${elem?.id}`)?.className.includes(
              'd-none'));
          if (childrenHide.length === childrenCategory[category].length) {
            document.getElementById(`module_${id}`).classList.add('d-none');
          } else {
            document.getElementById(`module_${id}`).classList.remove('d-none');
          }
        }
      }
    }
  });
};

/**
 * Get tier is migratable
 * @param {Boolean} migratable
 * @param {Object} actualTier
 * @param {Boolean} finishChanges
 * @returns
 */
export const tierMigratable = (migratable, actualTier, finishChanges) => {
  const { action, lastTier } = store.getState().tier;
  const { level } = store.getState().user;
  let tier = actualTier;
  let migrate = false;
  let edit = true;

  // When action is 'add'
  if (action === paramsAdd) {
    migrate = (parseInt(tier.verification_level) >= parseInt(
      lastTier.verification_level) && migratable);
  }

  // When action is 'edit'
  if (action === paramsEdit) {
    migrate = (finishChanges && migratable && parseInt(level) >= parseInt(
      lastTier.verification_level));
    edit = (migratable && parseInt(level) >= parseInt(
      lastTier.verification_level));
    tier = (migratable && parseInt(level) >= parseInt(
      lastTier.verification_level)) ? lastTier : actualTier;
  }

  return { migrate, edit, tier };
};