
class IdleTimer {
  constructor({ timeout, onTimeout, onExpired, onExpiredSession }) {
    this.timeout = timeout;
    this.onTimeout = onTimeout;
    this.onExpiredSession = onExpiredSession;
    this.count = 0;

    const expiredTime = parseInt(localStorage.getItem('_expiredTime') || 0, 10);
    if (expiredTime > 0 && expiredTime < Date.now()) {
      onExpired();
      return;
    }
    this.eventHandler = this.updateExpiredTime.bind(this);
    this.tracker();
    this.startInterval();
  };

  startInterval() {
    this.updateExpiredTime();
    this.interval = setInterval(() => {
      const expiredTime = parseInt(
        localStorage.getItem('_expiredTime') || 0,
        10
      );
      const oneMinute = expiredTime - 60 * 1000;
      if (oneMinute < Date.now()) {
        this.count += 1;
        if (this.count === 1 ){
          return this.onExpiredSession();
        }
      }
      if (expiredTime < Date.now()) {
        if (this.onTimeout) {
          this.onTimeout();
          this.cleanUp();
        }
      }
    }, 1000);
  };

  updateExpiredTime() {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }
    this.timeoutTracker = setTimeout(() => {
      this.count = 0;
      localStorage.setItem("_expiredTime", Date.now() + this.timeout * 1000);
    }, 300);
  };

  tracker() {
    window.addEventListener('mousemove', this.eventHandler);
    window.addEventListener('scroll', this.eventHandler);
    window.addEventListener('keydown', this.eventHandler);
    window.addEventListener('click', this.eventHandler);
  };

  cleanUp() {
    localStorage.removeItem('_expiredTime');
    clearInterval(this.interval);
    window.removeEventListener('mousemove', this.eventHandler);
    window.removeEventListener('scroll', this.eventHandler);
    window.removeEventListener('keydown', this.eventHandler);
  };
};

export default IdleTimer;