import * as yup from 'yup';
import { envSetting } from '.';

export const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 500
  },
};

export const profile = {
  businessEmail: 'business_email',
  cardEmail: 'cardEmail',
  cardGeneral: 'cardGeneral',
  cardPhone: 'cardPhone',
  cellPhone: 'cell_phone',
  editModuleEmail: 'edit_email',
  editModulePhone: 'edit_phone',
  email: 'email',
  fullName: 'full_name',
  moduleFormEmail: 'module_email',
  moduleFormPhone: 'module_phone',
  personalEmail: 'personal_email',
  phoneNumber: 'phone_number',
  phoneNumberCountry: 'phone_number_country',
  requestEdit: 'requestEdit',
  requestTokenForce: 'requestTokenForce',
  resetTimer: 'reset_timer'
};

export const sOption = 's_option';

export const hiddenField = 'hidden_field';

export const hiddenCode = 'hidden';

export const hiddenNumber = 'hidden_rn';

export const validateSMS = 'validate_sms';

export const contactSMS = 'contact_information';

export const paramsEdit = 'edit';

export const paramsAdd = 'add';

export const paramsNext = 'next';

export const paramsFinish = 'finish';

export const characters = '@$!%*#?&';

export const beneficiaryCategory = 'co_owner_beneficiary';

export const blockAdd = 'button_add_block';

export const blockRemove = 'button_remove_block';

export const bankReferencesField = 'bank__bank_references';
export const bankReferencesElement = 'bank_references'
export const assistantsApp = 'assistants'

export const userAmericanCitizenField = 'user__american_citizen'
export const documentOperatorField = 'document__operator'
export const documentNationalityField = 'document__nationality'
export const documentLastNationalityField = 'document__last_nationality'
export const userCitizenshipCountryField = 'user__citizenship_country';
export const dominicanRepublic = 'do'
export const unitedStates = 'us'

export const blockEditPath = '/tier/edit/advanced';

export const editionStatus = {
  editable: 'editable',
  pending: 'pending'
};

export const fieldsAllowedCountries = {
  intermediate: [
    'client_address_country_id'
  ]
};

export const schemaLogin = yup.object({
  username: yup
    .string()
    .required('fieldRequired'),
  password: yup
    .string()
    .required('fieldRequired'),
});

export const schemaEmail = yup.object({
  email: yup
    .string()
    .trim()
    .min(6, 'fieldMin6')
    .email('emailInvalid')
    .required('fieldRequired')
});

export const schemaRecoverPassword = yup.object({
  new_password: yup
    .string()
    .trim()
    .min(8, 'fieldMin8')
    .matches(/[a-z]+/, 'passwordLowerCase')
    .matches(/[A-Z]+/, 'passwordUpperCase')
    .matches(/[@$!%*#?&]+/, 'passwordSpecial')
    .matches(/\d+/, 'passwordNumber')
    .required('fieldRequired')
    .oneOf([yup.ref('confirm_password')], 'passwordNotTheSame'),
  confirm_password: yup
    .string()
    .trim()
    .min(8, 'fieldMin8')
    .matches(/[a-z]+/, 'passwordLowerCase')
    .matches(/[A-Z]+/, 'passwordUpperCase')
    .matches(/[@$!%*#?&]+/, 'passwordSpecial')
    .matches(/\d+/, 'passwordNumber')
    .required('fieldRequired')
    .oneOf([yup.ref('new_password')], 'passwordNotTheSame'),
});

export const schemaGetStarted = yup.object({
  username: yup
    .string()
    .trim()
    .min(6, 'fieldMin6')
    .matches(/^([a-zA-Z0-9_-]+)$/, 'usernameRequired')
    .required('fieldRequired'),
  password: yup
    .string()
    .trim()
    .min(8, 'fieldMin8')
    .matches(/[a-z]+/, 'passwordLowerCase')
    .matches(/[A-Z]+/, 'passwordUpperCase')
    .matches(/[@$!%*#?&]+/, 'passwordSpecial')
    .matches(/\d+/, 'passwordNumber')
    .required('fieldRequired'),
  termservice: yup
    .boolean()
    .required('fieldRequired')
    .oneOf([true], 'fieldRequired')
});

export const schemaInternetBankingUser = yup.object({
  username: yup.string().trim().min(6, 'fieldMin6').required('fieldRequired')
});

export const schemaToken = yup.object({
  token: yup
    .string()
    .required('fieldRequired')
    .max(envSetting.maxMinutesToken, 'tokenFieldMax')
});

export const schemaProfilePassword = yup.object({
  current_password: yup
    .string()
    .trim()
    .min(8, 'fieldMin8')
    .matches(/[a-z]+/, 'passwordLowerCase')
    .matches(/[A-Z]+/, 'passwordUpperCase')
    .matches(/[@$!%*#?&]+/, 'passwordSpecial')
    .matches(/\d+/, 'passwordNumber')
    .required('fieldRequired'),
  new_password: yup
    .string()
    .trim()
    .min(8, 'fieldMin8')
    .matches(/[a-z]+/, 'passwordLowerCase')
    .matches(/[A-Z]+/, 'passwordUpperCase')
    .matches(/[@$!%*#?&]+/, 'passwordSpecial')
    .matches(/\d+/, 'passwordNumber')
    .required('fieldRequired')
    .oneOf([yup.ref('confirm_password')], 'passwordNotTheSame'),
  confirm_password: yup
    .string()
    .trim()
    .min(8, 'fieldMin8')
    .matches(/[a-z]+/, 'passwordLowerCase')
    .matches(/[A-Z]+/, 'passwordUpperCase')
    .matches(/[@$!%*#?&]+/, 'passwordSpecial')
    .matches(/\d+/, 'passwordNumber')
    .required('fieldRequired')
    .oneOf([yup.ref('new_password')], 'passwordNotTheSame'),
});

export const schemaPhone = yup.object({
  phone_number: yup
    .string()
    .required('fieldRequired')
    .max(25, 'phoneFieldMax'),
  phone_number_country: yup
    .string()
    .required('fieldRequired'),
});

export const schemaAssistans = yup.object({
  select_assistant: yup
    .string()
    .required('fieldRequired'),
  assistant_code: yup
    .string()
    .required('fieldRequired'),
});

export const schemaClientEntity = yup.object({
  wisenroll_code: yup
    .string()
    .trim()
    .max(6, 'fieldMax6')
    .required('fieldRequired'),
  document_country_id: yup
    .string()
    .required('fieldRequired'),
  document_type_id: yup
    .string()
    .required('fieldRequired'),
  document_number: yup
    .string()
    .matches(/^[0-9-]+$/, 'integer')
    .required('fieldRequired')
    .max(20, 'documentNumberFieldMax'),
  email: yup
    .string()
    .trim()
    .min(6, 'fieldMin6')
    .email('emailInvalid')
    .required('fieldRequired'),
  cell_phone: yup
    .string()
    .matches(/^[\d\s\+]+$/, 'integer')
    .required('fieldRequired')
    .max(25, 'phoneFieldMax'),
  cell_phone_country_id: yup
    .string()
    .required('fieldRequired')
});

export const schemaNewProductRequest = yup.object({
  termservice: yup
    .boolean()
    .required('fieldRequired')
    .oneOf([true], 'fieldRequired')
});

export const defaultValuesLogin = {
  username: "",
  password: ""
};

export const defaultValuesEmail = {
  email: ""
};

export const defaultValuesRecoverPassword = {
  new_password: "",
  confirm_password: ""
};

export const defaultValuesGetStarted = {
  username: "",
  password: "",
  termservice: false
};

export const defaultValuesInternetBanking = {
  username: ""
};

export const defaultValuesToken = {
  token: ""
};

export const defaultValuesProfilePassword = {
  current_password: "",
  new_password: "",
  confirm_password: ""
};

export const defaultPhone = {
  phone_number: "",
  phone_number_country: ""
};

export const defaultValuesAssistants = {
  select_assistant: "",
  assistant_code: ""
};

export const optionEdit = '<option id="##id##" value="##value##">##text##</option>';

export const defaultValuesClientEntity = {
  wisenroll_code: "",
  document_country_id: "",
  document_type_id: "",
  document_number: "",
  email: "",
  cell_phone: "",
  cell_phone_country_id: ""
};

export const defaultValuesNewProductRequest = {
  termservice: false
};

export const recorderStatus = {
  stopped: 'stopped',
  displaying: 'displaying',
  inactive: 'inactive',
  paused: 'paused',
  recording: 'recording',
  receivedFromQR: 'received',
  sent: 'sent'
}

export const QRPath = 'AutovideoQRPage'

export const browserNames = {
  firefox: 'firefox',
  chrome: 'chrome',
  crios: 'chrios',
  safari: 'safari',
  edg: 'edg',
  edge: 'edge',
  opera: 'opera',
  samsung: 'samsung',
  unknown: 'unknown'
}

export const operativeSystemNames = {
  macOS: 'Mac OS',
  iOS: 'iOS',
  windows: 'Windows',
  android: 'Android',
  linux: 'Linux'
}
