import { envSetting } from '../constants';

/**
 * theme is an object with component styles that can be configured
 * It is separated by specific components and component folders.
 */
export const theme = {
  general: (envSetting.general) ? envSetting.general : '#767676',
  generalHover: (envSetting.generalHover) ? envSetting.generalHover : '#5E5D5D',
  first: (envSetting.first) ? envSetting.first : '#00B3C0',
  firstHover: (envSetting.firstHover) ? envSetting.firstHover : '#0098A3',
  second: (envSetting.second) ? envSetting.second : '#5E5D5D',
  secondHover: (envSetting.secondHover) ? envSetting.secondHover : '#525151',
  third: (envSetting.third) ? envSetting.third : '#5422CC',
  thirdHover: (envSetting.thirdHover) ? envSetting.thirdHover : '#765CE2',
  fourth: (envSetting.fourth) ? envSetting.fourth : '#013E5A',
  fourthHover: (envSetting.fourthHover) ? envSetting.fourthHover : '#012536',
  fifth: (envSetting.fifth) ? envSetting.fifth : '#E0E0E0',
  fifthHover: (envSetting.fifthHover) ? envSetting.fifthHover : '#CCCCCC',
  sixth: (envSetting.sixth) ? envSetting.sixth : '#FFF',
  sixthHover: (envSetting.sixthHover) ? envSetting.sixthHover : '#F5F5F5',
  seventh: (envSetting.seventh) ? envSetting.seventh : '#B92D3B',
  seventhHover: (envSetting.seventhHover) ? envSetting.seventhHover : '#A42834',
  eighth: (envSetting.eighth) ? envSetting.eighth : '#00b347',
};