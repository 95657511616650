import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { optionColor, optionColorHover } from '../../../helpers';

// Define element styles for the component
const StyledButton = styled.button`
  color: white;
  background-color: ${props => optionColor(props.color)};
  border-color: ${props => optionColor(props.color)};
  border-radius: 50% !important;
  margin: 0;
  width: 40px;
  height: 40px;
  border-width: 1px !important;
  &:hover,
  &:active,
  &:focus,
  &:focus-visible {
    color: white;
    background-color: ${props => optionColorHover(props.color)};
    border-color: ${props => optionColorHover(props.color)};
  }
`;

/**
 * ButtonCircle component with distinct colors
 * @component
 * @param {Element} children
 * @returns
 */
export const ButtonCircle = ({ children, ...props }) => {
  return (
    <StyledButton { ...props }>{ children }</StyledButton>
  );
};

ButtonCircle.propTypes = {
  children: PropTypes.any
};