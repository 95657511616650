import React from 'react';
import { useAllowedCountries } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { Button, StyledForm, LoaderSpinner } from '../shared/simple';
import { Container } from 'react-bootstrap';
import { Div, StyledImage, StyledLogo, StyledRow } from './styled';
import { siteMap } from '../../constants';
import { Input } from '../shared/Input';
import { LinkLogo } from './LinkLogo';
import { LogOutButton } from './styled';
import { useInternetBanking } from '../../hooks';
import { useNavigate } from 'react-router-dom';
import SlideCaptchaModal from '../shared/slide-captcha-modal/SlideCaptchaModal';
import ImageAds from '../../assets/images/login/wisenroll.png';

/**
 * Internet Banking Component
 * @component
 * @returns
 */
export const InternetBanking = () => {
  const { t } = useTranslation();
  const { allowed } = useAllowedCountries();
  const navigate = useNavigate();

  const {
    errors,
    register,
    handleSubmit,
    onSubmit,
    onLogout,
    onSignUp,
    setIsSlideModalOpen,
    isSlideModalOpen,
    values,
    selectedProduct,
    loading,
    userId
  } = useInternetBanking(allowed);

  const handleClick = () => {
    onLogout();
    navigate(siteMap.login.path2);
  };

  return (
    <Div>
      <LinkLogo />
      <LogOutButton onClick={ handleClick } name="link_logout">
        <h5>{ t("homepage.logout") }</h5>
      </LogOutButton>
      <Container>
        <StyledImage
          className="d-xs-block d-md-none"
          rel="preload"
          src={ ImageAds }
        />
        <StyledLogo
          className="d-xs-block d-md-none"
          rel="preload"
          src={ selectedProduct?.image_ads }
        />
        <StyledRow className="justify-content-md-center">
          <StyledForm
            className="col-md-10 col-xs-12 col-lg-6 col-xl-5"
            onSubmit={ handleSubmit(onSubmit) }
          >
            <h3 className="pb-4">{ t("eBanking.creationHeader") }</h3>
            <Input
              autoComplete="new-password"
              disabled={ loading }
              label={ t("common.username") }
              message={ errors?.username?.message }
              name={ register("username").name }
              register={ register("username") }
              type="text"
              placeholder={ t("common.username") }
            />
            <input {...register('person_id')} type="hidden" value={userId} />
            {loading
              ? (
                <LoaderSpinner />
              ) : (
                <Button
                  className="btn mb-0"
                  disabled={ loading }
                  color="first"
                  type="submit"
                  name="btn_get_started"
                >
                  { t("common.continue") }
                </Button>
              )
            }
          </StyledForm>
        </StyledRow>
      </Container>
      <SlideCaptchaModal
        isOpen={ isSlideModalOpen }
        onRequestClose={ () => setIsSlideModalOpen(false) }
        onSuccess={() => onSignUp(values) }
      />
    </Div>
  );
};