import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { customStyles } from '../../../constants';
import { CloseImage } from '../../user/styled';
import Modal from 'react-modal';
import closeIcon from '../../../assets/images/token-modal/close.png';
import { Col, Row } from 'react-bootstrap';
import { Button } from './Button';

/**
 * Component modal of confirmation to tier
 * @returns
 */
export const ModalQuestion = ({
  modalIsOpen,
  setModal,
  onChangeStep,
  message,
  product,
  children
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      ariaHideApp={ false }
      isOpen={ modalIsOpen }
      onAfterClose={ () => setModal(false) }
      style={ customStyles }
    >
      <CloseImage
        alt=""
        onClick={ () => setModal(false) }
        src={ closeIcon }
        name="image_close"
      />
      <p className="mt-4">{ message }</p>
      {product && children }
      <Row className="justify-content-center m-0">
        <Col className="p-2 mt-2 mb-2" xs={12} md={6}>
          <Button
            className="btn mb-0"
            type="submit"
            color="first"
            onClick={ onChangeStep }
            name="btn_modal"
          >
            {product ? t("common.requestButton") : t("common.submit")}
          </Button>
        </Col>
        <Col className="p-2 mt-2 mb-2" xs={12} md={6}>
          <Button
            className="btn mb-0"
            type="button"
            color="second"
            onClick={() => setModal(false)}
            name="btn_modal_cancel"
          >
            {t("common.cancel")}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

ModalQuestion.prototype = {
  modalIsOpen: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
  onChangeStep: PropTypes.func.isRequired
};