import React from 'react';
import { useAllowedCountries, useGetStarted, useInitUser } from '../../hooks';
import { useTranslation } from 'react-i18next';
import {
  Button,
  CheckLink,
  Label,
  LoaderSpinner,
  RouterLink,
  StyledForm
} from '../shared/simple';
import { Container } from 'react-bootstrap';
import { Div, StyledImage, StyledLogo, StyledRow } from './styled';
import { characters, envSetting, languagueEs, routes } from '../../constants';
import { Input } from '../shared/Input';
import { LinkLogo } from './LinkLogo';
import { useSelector } from 'react-redux';
import { trimUpperCase } from '../../helpers';
import SlideCaptchaModal from '../shared/slide-captcha-modal/SlideCaptchaModal';
import ImageAds from '../../assets/images/login/wisenroll.png';

/**
 * Get Started Component
 * @component
 * @returns
 */
export const GetStarted = () => {
  const { t } = useTranslation();
  const { allowed } = useAllowedCountries();
  const { selectedProduct } = useSelector(state => state.user);
  const { actualLanguage } = useSelector(state => state.base);
  const {
    appOrigin,
    isSlideModalOpen,
    loading,
    onSubmit,
    setIsSlideModalOpen,
    values
  } = useInitUser(allowed);
  const { errors, handleSubmit, onSignUp, register } = useGetStarted();

  return (
    <Div>
      <LinkLogo />
      <Container>
        <StyledImage
          className="d-xs-block d-md-none"
          rel="preload"
          src={ ImageAds }
        />
        <StyledLogo
          className="d-xs-block d-md-none"
          rel="preload"
          src={ selectedProduct?.image_ads }
        />
        <StyledRow className="justify-content-md-center">
          <StyledForm
            className="col-md-10 col-xs-12 col-lg-6 col-xl-5"
            onSubmit={ handleSubmit(onSubmit) }
          >
            <h1 className="pb-4">{ t("getStarted.getStarted") }</h1>
            <Input
              autoComplete="off"
              disabled={ loading }
              isRequiredInput={ true }
              label={ t("common.username") }
              message={ errors.username?.message }
              name={ register("username").name }
              register={ register("username") }
              tooltipText={ t("getStarted.usernameRequired", { min: 6 }) }
              type="text"
            />
            <Input
              autoComplete="off"
              disabled={ loading }
              isRequiredInput={ true }
              label={ t("common.password") }
              message={ errors.password?.message }
              name={ register("password").name }
              register={ register("password") }
              tooltipText={ t("getStarted.passwordRequired", { min: 8, characters }) }
              type="password"
            />
            <CheckLink
              color="fourth"
              href={ (trimUpperCase(actualLanguage) === languagueEs) ? envSetting.termsConditionsEs : envSetting.termsConditionsEn }
              message={ errors.termservice?.message }
              params={{ company: envSetting.companyName }}
              register={ register("termservice") }
              translation="getStarted.termService"
              type="checkbox"
            />
            {loading
              ? (
                <LoaderSpinner />
              ) : (
                <Button
                  className="btn mb-0"
                  disabled={ loading }
                  color="first"
                  type="submit"
                  name="btn_get_started"
                >
                  { t("common.continue") }
                </Button>
              )
            }
            <Label className="wantText">
              { t("getStarted.signUp") }&nbsp;
              {!loading && (
                <RouterLink
                  className="p-0 col-12"
                  to={ routes.login(appOrigin) }
                  color="third"
                  disabled={ loading }
                >
                  { t("login.buttonLogIn") }
                </RouterLink>
              )}
            </Label>
          </StyledForm>
        </StyledRow>
      </Container>
      <SlideCaptchaModal
        isOpen={ isSlideModalOpen }
        onRequestClose={ () => setIsSlideModalOpen(false) }
        onSuccess={ () => onSignUp(values) }
      />
    </Div>
  );
};