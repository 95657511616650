import styled from 'styled-components';
import PropTypes from 'prop-types';
import { StyledGroup } from '../Input/styled';
import { Form } from 'react-bootstrap';
import { theme } from '../../../theme';
import { useFormContext } from 'react-hook-form';
import { useDisabled, useTextLanguage } from '../../../hooks';
import { Error } from '.';

const { first } = theme;

// Define element styles for the component
const StyledCheck = styled(Form.Check)`
  border-radius: .5rem;
  outline: none;
  &:focus {
    border-color: ${first};
    box-shadow: none;
  }
`;

/**
 * Check component with input type checkbox
 * @component
 * @param {Element} children
 * @param {Object} field
 * @param {String} name
 * @param {Function} onClick
 * @returns
 */
export const Check = ({ children, field, name, onClick }) => {
  const { languageText } = useTextLanguage();
  const { register, formState: { errors } } = useFormContext();
  const { isDisabled } = useDisabled(field);

  return (
    <StyledGroup className="mb-3">
      <StyledCheck
        { ...register(name) }
        data-action={ field.action }
        data-category={ `category-${field?.category?.field_category}` }
        data-condition={ field.parent_condition }
        data-parent={ field.parent }
        data-unique-name={ field.unique_name }
        id={ field.id }
        key={ field.id }
        label={ field[`placeholder${languageText}`] }
        name={ name }
        onClick={ onClick }
        type="checkbox"
        disabled={isDisabled}
      >
        { children }
      </StyledCheck>
      {errors?.[name]?.message && (
        <Error message={ errors[name].message } />
      )}
    </StyledGroup>
  );
};

Check.propTypes = {
  children: PropTypes.any,
  field: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};