import { useCallback, useEffect, useState } from 'react';
import { useAppOrigin } from '.';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { paramsAdd, paramsEdit, routes, siteMap } from '../constants';
import {
  defaultChecked,
  setContentFields,
  isUndefinedNull,
  userTier,
  trimLowerCase
} from '../helpers';
import { setMessage } from '../redux/actions';
import { useTranslation } from 'react-i18next';

/**
 * Hook to get the helps
 * @returns
 */
export const useHelp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { codeTier } = useParams();
  const { appOrigin, isHelpOpen = true } = useSelector(state => state.user);
  const { requestLanguage } = useSelector(state => state.base);
  const {
    field_helper: fieldHelper,
    tier_field: tiersFields,
    tier: tiers
  } = useSelector(state => state.staticData[`data_${trimLowerCase(requestLanguage)}`]);
  const { nextTier } = useSelector(state => state.tier);
  const [tier, setTier] = useState('');
  const [listHelp, setListHelp] = useState('');

  useEffect(() => {
    Array.from(tiers).forEach(item => {
      if (trimLowerCase(item?.['unique_name']) === trimLowerCase(codeTier)) {
        if (item.active) setTier(item);
      }
    });
  }, [tiers, codeTier]);

  useEffect(() => {
    if (isUndefinedNull(tier) && !location.pathname.includes(
      'completed')) navigate(siteMap.homepage.path);
  }, [tier, navigate, location]);

  const handleUpdateHelp = useCallback(() => {
    setListHelp(setContentFields(fieldHelper, tier, tiersFields));
  }, [fieldHelper, tier, tiersFields]);

  useEffect(() => {
    handleUpdateHelp();
  }, [handleUpdateHelp]);

  const goBack = async() => {
    localStorage.removeItem('idRequest');
    navigate(siteMap.homepage.path);
  };

  return { appOrigin, isHelpOpen, tier, nextTier, listHelp, goBack };
};

/**
 * Hook to get the values to Menu
 * @returns
 */
export const useMenu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [desktop, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );
  const [tiers, setTiers] = useState([]);
  const [heightTier, setHeightTier] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(desktop ? true : false);
  const { rejectedFiles, expiredFiles, loading } = useSelector(
    state => state.base);
  const { t } = useTranslation();
  useAppOrigin();

  const handleUpdateTiers = useCallback(() => {
    setTiers(defaultChecked(userTier()));
  }, []);

  useEffect(() => {
    window
      .matchMedia("(max-width: 768px)")
      .addEventListener('change', e => {
        if (e.matches) {
          setMatches(false);
          setIsMenuOpen(false);
        }
      });
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener('change', e => {
        if (e.matches) {
          setMatches(true);
          setIsMenuOpen(true);
        }
      });
    handleUpdateTiers();
  }, [handleUpdateTiers]);

  useEffect(() => {
    if (tiers?.length !== 0) {
      const showTiers = tiers.filter(item => item.show);
      setHeightTier(`${90 / (showTiers?.length)}%`);
    }
  }, [tiers]);

  const onClick = tier => {
    if (!rejectedFiles && !expiredFiles) {
      if (tier.edit) {
        navigate(routes.tier(tier.unique_name, paramsEdit));
      } else {
        navigate(routes.tier(tier.unique_name, paramsAdd));
      }
    } else {
      if (expiredFiles) dispatch(setMessage(t("files.updateExpired"), 'warning'));
      if (rejectedFiles) dispatch(setMessage(t("files.updateRejected"), 'warning'));
    }
  };

  return { isMenuOpen, onClick, tiers, loading, heightTier, desktop, setIsMenuOpen };
};