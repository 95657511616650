import React from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar } from '../Avatar';
import { Col, ListGroup, Row, Tab } from 'react-bootstrap';
import { faUser, faKey, faAddressCard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Information } from './Information';
import { ListItem } from '../styled';
import { Password } from './Password';

/**
 * Component with profile
 * @component
 * @returns
 */
export const Profile = () => {
  const { t } = useTranslation();

  return (
    <Tab.Container defaultActiveKey="#option_avatar">
      <Row className="mt-4">
        <Col xs={12} lg={6} xl={4} className="mt-4">
          <ListGroup>
            <ListItem className="p-3" action href="#option_avatar" name="option_avatar">
              <FontAwesomeIcon icon={ faUser } />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <strong>{ t("profile.avatar") }</strong>
            </ListItem>
            <ListItem className="p-3" action href="#option_information" name="option_info">
              <FontAwesomeIcon icon={ faAddressCard } />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <strong>{ t("profile.title") }</strong>
            </ListItem>
            <ListItem className="p-3" action href="#option_password" name="option_password">
              <FontAwesomeIcon icon={ faKey } />
              &nbsp;&nbsp;&nbsp;&nbsp;
              <strong>{ t("profile.password") }</strong>
            </ListItem>
          </ListGroup>
        </Col>
        <Col xs={12} lg={6} xl={8} className="mt-4 p-5 pt-0">
          <Tab.Content>
            <Tab.Pane eventKey="#option_avatar">
              <Col>
                <h4 className="mb-4 mt-2">
                  { t("profile.avatar") }
                </h4>
                <Row className="justify-content-center">
                  <p>{ t("profile.avatarChange") }</p>
                  <Avatar margin='50px' help={ true } />
                </Row>
              </Col>
            </Tab.Pane>
            <Tab.Pane eventKey="#option_information">
              <Col>
                <h4 className="mb-4 mt-2">
                  { t("profile.title") }
                </h4>
                <Information />
              </Col>
            </Tab.Pane>
            <Tab.Pane eventKey="#option_password">
              <Col>
                <h4 className="mb-4 mt-2">
                  { t("profile.password") }
                </h4>
                <Password />
              </Col>
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};