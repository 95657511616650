import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useTextLanguage } from '../../../../hooks';
import { LoaderSpinner } from '../../../shared/simple';
import { formatDate, hideAccountNumber, isUndefinedNull } from '../../../../helpers';
import { appsOrigin, logoBgAppOrigin, editionStatus } from '../../../../constants';
import { FiEye, FiEdit } from 'react-icons/fi';
import { FaEyeLowVision } from 'react-icons/fa6';

const TableProducts = ({
  setSelectedProduct,
  selectedProduct,
  clientProducts,
  actualLanguage,
  loading
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Col className="p-4" xs={12}></Col>
      <Table striped bordered hover responsive>
        <thead>
          <tr className="text-center">
            <th>{t("block.product")}</th>
            <th>{t("block.accountNumber")}</th>
            <th>{t("block.date")}</th>
            <th>{t("block.status")}</th>
            <th>{t("block.action")}</th>
          </tr>
        </thead>
        {loading && (
          <tbody>
            <tr className="p-2">
              <td colSpan={5}><LoaderSpinner /></td>
            </tr>
          </tbody>
        )}
        {(!loading && clientProducts?.length > 0) && (
          <tbody>
            {clientProducts?.map((item) => {
              return (
                <TableRow
                  item={item}
                  actualLanguage={actualLanguage}
                  selectedProduct={selectedProduct}
                  setSelectedProduct={setSelectedProduct}
                  key={item.application_origin.unique_name}
                />
              );
            })}
          </tbody>
        )}
      </Table>
    </>
  );
};

const TableRow = ({ item, setSelectedProduct, selectedProduct, actualLanguage }) => {
  const { description } = useTextLanguage();
  const { t } = useTranslation();
  const [isHidden, setIsHidden] = useState(true);
  const { applications } = useSelector(state => state.user);
  const currentApp = applications?.find(app => app.description === appsOrigin[
    item.application_origin.unique_name]);
  let isActive = selectedProduct.product_id === item.client_product_id;

  const onRowClick = (item) => {
    setSelectedProduct((prevSelectedProducts) => {
      if (isUndefinedNull(prevSelectedProducts.product_id) &&  isUndefinedNull(
        prevSelectedProducts.unique_name)) {
        return {
          'unique_name': item.application_origin.unique_name,
          'product_id': item.client_product_id,
          'status': item.related_account_request_pending ? editionStatus.pending : editionStatus.editable
        };
      } else {
        return {
          'unique_name': undefined,
          'product_id': undefined,
          'status': undefined
        };
      }
    });
  };

  const handleHideAccount = (e) => {
    setIsHidden(!isHidden);
    e.stopPropagation();
  };

  return (
    <tr className={`text-center active ${isActive ? 'bg-secondary' : ''}`}
      style={{
        cursor:'pointer',
        transition:'.5s ease-out all'
      }}
      onClick={() => onRowClick(item)}
    >
      <td className={`${isActive ? 'text-white' : ''}`}>
        { currentApp?.image_main ?
        (<>
          <img
            alt={item.application_origin.unique_name}
            src={(currentApp?.image_main || logoBgAppOrigin.default)}
            width={40}
          />
          </>) :
        ''
      }
        &nbsp;&nbsp;
        {item.application_origin[description]}
      </td>
      <td
        className={`${isActive ? 'text-white' : ''}`}
        onMouseLeave={() => setIsHidden(true)}
      >
        { isHidden ? hideAccountNumber(item?.account_number) : item?.account_number}
        &nbsp;&nbsp;
        <FaEyeLowVision onClick={handleHideAccount} className="fs-5" />
      </td>
      <td className={`${isActive ? 'text-white' : ''}`}>
        {formatDate(actualLanguage ,item.opening_date)}
      </td>
      <td className={`${isActive ? 'text-white' : ''}`}>
        {item.related_account_request_pending
        ? <>{ t("block.pending") }</>
        : <>{ t("block.editable") }</>
        }
      </td>
      {item.related_account_request_pending
      ? (<td title={t("block.see")} className={`${isActive ? 'text-white' : ''}`}>
          <FiEye className="fs-5"/>
        </td>)
      : (<td title={t("block.edit")} className={`${isActive ? 'text-white' : ''}`}>
          <FiEdit className="fs-5"/>
        </td>)
      }
    </tr>
  );
};

export default TableProducts;