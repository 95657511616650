import React, { useState } from 'react';
import { useInformation } from '../../../hooks';
import { useTranslation } from 'react-i18next';
import { CardModal } from './CardModal';
import { Col, Row } from 'react-bootstrap';
import { Section } from './Section';
import { theme } from '../../../theme';
import { profile } from '../../../constants';

/**
 * Component with information to profile
 * @component
 * @returns
 */
export const Information = () => {
  const { t } = useTranslation();
  const { email, general, phone } = useInformation();
  const { first, third, fourth } = theme;
  const [requestEdit, setRequestEdit] = useState(false);
  const [requestTokenForce, setRequestTokenForce] = useState(false);

  return (
    <Row className="mb-5">
      {(general.length > 0) && (
        <Col xs={12}>
          <Section
            codeCard={ profile.cardGeneral }
            color={ first }
            data={ general }
            title={ t("profile.cardGeneral") }
          />
        </Col>
      )}
      {(email.length > 0) && (
        <Col xs={12}>
          <Section
            codeCard={ profile.cardEmail }
            color={ fourth }
            data={ email }
            setRequestEdit={ setRequestEdit }
            setRequestTokenForce={ setRequestTokenForce }
            title={ t("profile.cardEmail") }
          />
        </Col>
      )}
      {(phone.length > 0) && (
        <Col xs={12}>
          <Section
            codeCard={ profile.cardPhone }
            color={ third }
            data={ phone }
            setRequestEdit={ setRequestEdit }
            setRequestTokenForce={ setRequestTokenForce }
            title={ t("profile.cardPhone") }
          />
        </Col>
      )}
      {(email.length > 0 || phone.length > 0) && (
        <>
          <CardModal
            codeModal="requestEdit"
            modalOpen={ requestEdit }
            setModalOpen={ setRequestEdit }
          />
          <CardModal
            codeModal="requestTokenForce"
            modalOpen={ requestTokenForce }
            setModalOpen={ setRequestTokenForce }
          />
        </>
      )}
    </Row>
  );
};