import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col } from 'react-bootstrap';
import { StyledGroup } from '../../shared/Input/styled';
import { Error, Label, OptionItem, TooltipIcon } from '.';
import { StyledSelect } from '../../tier/styled';
import { useSelectSimple } from '../../../hooks';

/**
 * Component select
 * @component
 * @param {*} dataName
 * @param {String} id
 * @param {Boolean} isRequired
 * @param {Boolean} isSetValue
 * @param {Boolean} isStaticData
 * @param {String} label
 * @param {String} message
 * @param {String} name
 * @param {Object} register
 * @param {Function} setValue
 * @param {String} text
 * @param {String} tooltip
 * @returns
 */
export const Select = ({
  dataName,
  id,
  isRequired,
  isSetValue,
  isStaticData,
  label,
  message,
  name,
  register,
  setValue,
  text,
  tooltip
}) => {
  const { t } = useTranslation();
  const { options, handleChange } = useSelectSimple(
    dataName, setValue, text, isStaticData, isSetValue);

  return (
    <Col xs={12}>
      <StyledGroup className="mb-3">
        <Label htmlFor={ name }>
          { label }{isRequired && (
            <strong className="text-danger">&nbsp;*</strong>)}
          { (tooltip) && <TooltipIcon tooltipText={ tooltip } />}
        </Label>
        <StyledSelect
          { ...register }
          id={ name }
          name={ name }
          onChange={ handleChange }
        >
          <option value="">{ t("common.optionDefault") }</option>
          <OptionItem
            id={ id }
            options={ options || [] }
            text={ text }
            type="simple"
          />
        </StyledSelect>
        {message && (
          <Error message={ message } />
        )}
      </StyledGroup>
    </Col>
  );
};

Select.propTypes = {
  dataName: PropTypes.oneOfType([
    PropTypes.array.isRequired,
    PropTypes.string.isRequired
  ]),
  id: PropTypes.string.isRequired,
  isRequired: PropTypes.bool.isRequired,
  isSetValue: PropTypes.bool,
  isStaticData: PropTypes.bool,
  label: PropTypes.string.isRequired,
  message: PropTypes.string,
  name: PropTypes.string.isRequired,
  register: PropTypes.object,
  setValue: PropTypes.func,
  text: PropTypes.string.isRequired,
  tooltip: PropTypes.string
};

Select.defaultProps = {
  isStaticData: true,
  isSetValue: true
};