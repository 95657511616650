import React from 'react';
import { Col } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import {
  moduleCategory,
  notUndefinedNull,
  parentCondition
} from '../../../helpers';
import { useEdit } from '../../../hooks';
import { Check } from '../../shared/simple';

export const CheckSelect = ({ field }) => {
  const { setValue } = useFormContext();
  const { colSize } = useEdit(field);

  const handleClick = ({ target }) => {
    if (notUndefinedNull(field?.id)) {
      parentCondition(target, field, setValue);
      moduleCategory(field.id);
    }
  };

  return (
    <div key={`div_${field.id}`}>
      {(colSize?.includes('col-before')) && (
        <Col xs={12} key={ `col_before_${field.id}` }></Col>
      )}
      <Col
        className={ `${colSize} pt-4` }
        data-module={ `module-${field?.category?.field_category}` }
        data-name={ `${field.unique_name}__id__${field.id}` }
        id={ `group_field_${field.id}` }
        key={ `col_${field.id}` }
        xs={12}
      >
        <Check
          field={ field }
          name={ `${field.unique_name}__id__${field.id}` }
          onClick={ e => handleClick(e, field) }
        />
      </Col>
    </div>
  );
};
