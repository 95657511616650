import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Error, Label, TooltipIcon } from '.';
import { theme } from '../../../theme';
import { notUndefinedNull } from '../../../helpers';
import { StyledGroup } from '../Input/styled';
import CurrencyInput from 'react-currency-input-field';

const { first } = theme;

export const CustomMoneyInput = styled(CurrencyInput)`
  width: 100%;
  padding: .375rem .75rem;
  border-radius: .5rem;
  border: 1px solid #C4C4C4;
  outline: none;
  &:focus {
    border-color: ${first};
    box-shadow: none;
  }
`;

/**
 * Input Currency component
 * @component
 * @param {Object} field
 * @param {Boolean} isRequiredInput
 * @param {String} label
 * @param {String} message
 * @param {String} name
 * @param {Function} onChange
 * @param {String} placeholder
 * @param {Function} register
 * @param {String} tooltipText
 * @returns
 */
export const CurrencyMoneyInput = ({
  field,
  isRequiredInput,
  label,
  message,
  name,
  onChange,
  placeholder,
  register,
  tooltipText,
  disabled
}) => {
  return (
    <StyledGroup className="mb-3">
      <Label htmlFor={ name }>
        { label }{ isRequiredInput && (
          <strong className="text-danger">&nbsp;*</strong>)}{ tooltipText && (
          <TooltipIcon tooltipText={ tooltipText } />)}
      </Label>
      <CustomMoneyInput
        { ...register(name) }
        allowDecimals={false}
        autoComplete="off"
        data-action={ field?.action }
        data-category={ `category-${field?.category?.field_category}` }
        data-condition={ field?.parent_condition }
        data-parent={ field?.parent }
        data-unique-name={ name }
        decimalSeparator={(field && field?.mask)
          ? JSON.parse(field?.mask)?.decimal : "."}
        decimalsLimit={0}
        defaultValue={ field?.defaultValue }
        groupSeparator={(field && field?.mask)
          ? JSON.parse(field?.mask)?.group : ","}
        id={ field?.id }
        key={ field?.id }
        maxLength={(notUndefinedNull(
          field?.max_length)) ? field.max_length.toString() : ''}
        name={ name }
        onChange={ onChange }
        placeholder={ placeholder }
        intlConfig={{ locale: 'en-US' }}
        disabled={disabled}
      />
      {message && (
        <Error message={ message } />
      )}
    </StyledGroup>
  );
};

CurrencyInput.propTypes = {
  field: PropTypes.object,
  isRequiredInput: PropTypes.bool,
  label: PropTypes.string,
  message: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  register: PropTypes.func,
  tooltipText: PropTypes.string,
  disabled: PropTypes.bool
};