import React from 'react';
import { useAppOrigin } from '../hooks';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Back } from '../components/shared/simple';
import { Help } from '../components/shared/Help';
import { Profile } from '../components/user/profile/Profile';
import { Row, Col } from 'react-bootstrap';

/**
 * User profile page and level menu page
 * @returns {JSX}
 */
export const ProfilePage = () => {
  const { t } = useTranslation();
  const { username } = useSelector(state => state.user);
  useAppOrigin();

  return (
    <>
      <Col className="p-0" xl={3} md={4} xs={12}>
        <Help backButton={false} />
      </Col>
      <Col className="p-4" xl={9} md={8} xs={12}>
        <Row>
          <Col className="p-0 text-end" xs={12}>
            <Back />
          </Col>
          <Col className="p-4" xs={12}>
            <Row>
              <Col xs={12}>
                <h2 className="mb-2">
                  { t("profile.welcome", { username }) }
                </h2>
              </Col>
              <Col xs={12}>
                <Profile />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default ProfilePage;