import { envSetting } from './envSetting';

export const appsOrigin = {
  credinomina: 'credinomina',
  default: 'wisenroll',
  genie_wizz: 'genie_wizz',
  merchant: 'merchant',
  turboahorro: 'turboahorro',
  wisenroll: 'wisenroll',
  bnfaffiliates: 'bnfaffiliates',
  online_bank_account: 'online_bank_account',
  assistants: envSetting.assistantsAppUniqueCode,
  data_update: 'data_update'
};

export const logoBgAppOrigin = {
  default: envSetting.bgLogoWisenroll,
};

export const logoAdsAppOrigin = {
  default: envSetting.logoAdsWisenroll,
};