import React from 'react';
import { useDescription, useTextLanguage } from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Back, Button, CheckLink, Logo, ModalQuestion, Title } from '../shared/simple';
import { Container, Row, Col } from 'react-bootstrap';
import { Img } from './styled';
import { envSetting, languagueEs, logoBgAppOrigin } from '../../constants';
import { trimUpperCase } from '../../helpers';

/**
 * Component with description of the selected product
 * @component
 * @returns
 */
export const ProductDescription = () => {
  const navigate = useNavigate();
  const { description, languageText } = useTextLanguage();
  const { t } = useTranslation();
  const {
    product,
    onRequest,
    modalMessage,
    modalIsOpen,
    setModalIsOpen,
    onModalRequest,
    flagRequest,
    errors,
    register,
    handleSubmit
  } = useDescription();

  return (
    <>
      <Logo src={logoBgAppOrigin.default} />
      <Container fluid className="p-4">
        <Row>
          <Col className="p-0 text-end" xs={12}>
            <Back />
          </Col>
        </Row>
      </Container>
      <Container className="mt-5">
        <Col className="p-3" xs={12}>
          <Img
            src={product?.image_main}
            alt={product && product?.application[description]}
          />
          <Title className="mt-5">
            {product && product?.application[description]}
          </Title>
        </Col>
        <Col className="mt-3 p-3" xs={12}>
          <p className="text-break text-justify">
            {product && product?.application[`details${languageText}`]}
          </p>
        </Col>
        <Row className="justify-content-end m-0" style={{ marginBottom: 100 }}>
          {(!flagRequest) && (
            <Col className="p-3" xs={12} md={6} lg={3}>
              <Button
                className="btn"
                color="first"
                onClick={onModalRequest}
                type="button"
                name="btn_request"
              >
                {t("common.requestButton")}
              </Button>
            </Col>
          )}
          <Col className="p-3" xs={12} md={6} lg={3}>
            <Button
              className="btn"
              color="fourth"
              onClick={() => navigate(-1)}
              type="button"
              name="btn_back"
            >
              {t("common.back")}
            </Button>
          </Col>
        </Row>
        <ModalQuestion
          modalIsOpen={ modalIsOpen }
          setModal={ setModalIsOpen }
          onChangeStep={handleSubmit(onRequest) }
          message={ modalMessage }
          product
        >
          <CheckLink
            color="fourth"
            href={ (trimUpperCase(languageText) === languagueEs) ? envSetting.termsConditionsEs : envSetting.termsConditionsEn }
            message={ errors.termservice?.message }
            params={{ company: envSetting.companyName }}
            register={ register("termservice") }
            translation="getStarted.termService"
            type="checkbox"
          />
        </ModalQuestion>
      </Container>
    </>
  );
};