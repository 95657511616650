/**
 * Routes for redirect
 */
export const routes = {
  completed: code => `/completed/${code}`,
  errors: code => `/error/${code}`,
  files: codeFile => `/files/${codeFile}`,
  getStarted: appOrigin => `/get-started/${appOrigin}`,
  getStartedEmail: appOrigin => `/get-started-email/${appOrigin}`,
  login: appOrigin => `/login/${appOrigin}`,
  productDescription: code => `/product-description/${code}`,
  productsServices: code => `/products/${code}`,
  profile: code => `/user/${code}`,
  tier: (codeTier, actionTier) => `/tier/${actionTier}/${codeTier}`,
  createEbankingUsername: appOrigin => `/internet-banking/${appOrigin}`,
  productRequests: code => `/requests/${code}`,
  createdProducts: code => `/beneficiaries/${code}`,
  QRRoute: peerId => `/autovideoQRPage/${peerId}`
};

export const siteMap = {
  changePassword: {
    description: 'Change Password',
    path: '/change-password/:hash'
  },
  getStartedEmail: {
    description: 'Get Started with in App registration',
    path: '/get-started-email/:appOrigin',
    path2: '/get-started-email'
  },
  login: {
    description: 'Login in App',
    path: '/login/:appOrigin',
    path2: '/login'
  },
  recoverPasswordEmail: {
    description: 'Recover Password with Email',
    path: '/recover-password'
  },
  verifyUser: {
    description: 'Verify User with hash',
    path: '/verify-user/:hash'
  },
  // loginByGivenToken: {
  //   description: 'Login with given token without enter username and password',
  //   path: '/login-bifrost'
  // },
  completed: {
    description: 'Completed',
    path: '/completed/:code'
  },
  homepage: {
    description: 'Homepage',
    path: '/homepage'
  },
  productDescription: {
    description: 'Description of the selected product',
    path: '/product-description/:code'
  },
  productsServices: {
    description: 'List of all products and services by levels',
    path: '/products/:codeTier'
  },
  profile: {
    description: 'User profile and level menu',
    path: '/user/:codeTier'
  },
  tier: {
    description: 'Tier',
    path: '/tier/:actionTier/:codeTier'
  },
  files: {
    description: 'Expired/Rejected files',
    path: '/files/:codeTier',
  },
  errors: {
    description: 'Errors',
    path: '/error/:code'
  },
  logout: {
    description: 'Logout',
    path: '/logout'
  },
  clientEntity: {
    description: 'Customer registration through the code',
    path: '/client-entity'
  },
  productRequests: {
    description: 'List of all product requests',
    path: '/requests/:codeTier'
  },
  createdProducts: {
    description: 'list of all created products',
    path: '/beneficiaries/:codeTier'
  },
  createEbankingUsername: {
    description: 'Internet banking user creation',
    path: '/internet-banking/:appOrigin'
  },
  AutovideoQRPage:{
    description: 'Route for remotely recording the auto video',
    path: '/autovideoQRPage/:userPeerId'
  }
};