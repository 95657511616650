import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col } from 'react-bootstrap';
import { StyledGroup, StyledControl } from '../../../../shared/Input/styled';
import { Label } from '../../../../shared/simple';
import { editionStatus } from '../../../../../constants';

/**
 * Component text input
 * @component
 * @param {Object} field
 * @returns
 */
export const TextInput = ({ entry, setEntries, setWisenrollUser, loading, selectedProduct }) => {
  const { t } = useTranslation();

  const handleChange = (e) => {
    setWisenrollUser({});
    setEntries(prevEntries => {
      return prevEntries.map(prevEntry => {
        if (prevEntry.order === entry.order) {
          return {
            ...prevEntry,
            related_user_wisenroll_code: e.target.value,
            isValid:false
          };
        } else {
          return prevEntry;
        }
      });
    });
  };

  return (
    <>
      <Col
        className="col-md-6 col-xl-4"
        xs={12}
      >
        <StyledGroup className="mb-3">
          <Label>
            { t("block.blockCode") }
            <strong className="text-danger">&nbsp;*</strong>
          </Label>
          <StyledControl
            type='text'
            value={entry.related_user_wisenroll_code}
            onChange={(e) => handleChange(e)}
            disabled={loading || selectedProduct.status === editionStatus.pending }
          />
        </StyledGroup>
      </Col>
    </>
  );
};

TextInput.propTypes = {
  field: PropTypes.object.isRequired,
  setEntries: PropTypes.func.isRequired,
  setWisenrollUser: PropTypes.func.isRequired,
  loading: PropTypes.bool
};