/**
 * Types to base
 */
export const BASE_ACTIVE_LOCATION = '[BASE] ACTIVE LOCATION';
export const BASE_ACTUAL_LANGUAGE = '[BASE] ACTUAL LANGUAGE';
export const BASE_ALL_ASSISTANTS = '[BASE] ALL ASSISTANTS';
export const BASE_ALLOWED = '[BASE] ALLOWED';
export const BASE_ALLOWED_COUNTRIES = '[BASE] ALLOWED COUNTRIES';
export const BASE_CLEAR = '[BASE] CLEAR';
export const BASE_DELAY = '[BASE] DELAY';
export const BASE_DEVICE_INFO = '[BASE] DEVICE INFO';
export const BASE_ERROR_REQUEST = '[BASE] ERROR REQUEST';
export const BASE_EXPIRED_FILES = '[BASE] EXPIRED FILES';
export const BASE_GEOLOCATION = '[BASE] GEOLOCATION';
export const BASE_LOADING = '[BASE] LOADING';
export const BASE_LOCATION = '[BASE] LOCATION';
export const BASE_MESSAGE_TIER = '[BASE] MESSAGE TIER';
export const BASE_PARAMS = '[BASE] PARAMS';
export const BASE_REJECTED_FILES = '[BASE] REJECTED FILES';
export const BASE_REQUEST = '[BASE] REQUEST';
export const BASE_REQUEST_LANGUAGE = '[BASE] REQUEST LANGUAGE';
export const BASE_SELECTED_ASSISTANT = '[BASE] SELECTED ASSISTANT';
export const BASE_SELECTED_LANGUAGE = '[BASE] SELECTED LANGUAGE';
export const BASE_SET_ELEMENTS = '[BASE] SET ELEMENTS';
export const BASE_QRROUTE = '[BASE] SET QR ROUTE'