import styled from 'styled-components';
import { Col, Dropdown } from 'react-bootstrap';
import { theme } from '../../../theme';
import { optionColor, optionColorHover } from '../../../helpers';

const { fifth } = theme;

// Define element styles for the component
export const Div = styled.div`
  background: ${fifth};
  bottom: 0;
  height: 45px;
  list-style: none;
  position: fixed;
  z-index: 1001;

  @media (max-width: 767px) {
    height: 65px;
  }
`;

export const Img = styled.img`
  height: 35px;
  margin-right: 5px;
  width: 35px;
`;

export const StyledCol = styled(Col)`
  align-items: center;
  display: flex;
  padding: 5px;

  @media (max-width: 767px) {
    display: ${props => props.displaycol};
    padding: 0.8em;
  }
`;

export const StyledToggle = styled(Dropdown.Toggle)`
  background-color: transparent;
  border-color: transparent;
  color: #767676;
  cursor: pointer;
  padding: 0 10px;
  width: 50px;
  &:hover,
  &:active,
  &:focus,
  &:focus-visible {
    background-color: transparent !important;
    border-color: transparent !important;
    box-shadow: unset !important;
    color: #767676 !important;
    outline: unset !important;
  }
`;

export const Link = styled.a`
  color: ${props => optionColor(props.color)};
  font-size: 15px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  text-align: center;
  text-decoration: none;
  @media (max-width: 767px) {
    font-size: 13px;
  }
  &:hover {
    color: ${props => optionColorHover(props.color)};
    transition: 200ms ease-in;
  }
`;