import { Col, Form } from 'react-bootstrap'
import { TooltipIcon, Label, Error } from '../../shared/simple'
import { useFormContext } from 'react-hook-form'
import videoIconSource from '../../../assets/images/advance-tier/video-camera.svg'
import { t } from 'i18next';
import QRModal from './QRModal'
import { IoClose } from 'react-icons/io5'
import { DialogContent, DialogTitle, DialogActions, Dialog } from '@mui/material'
import { recorderStatus } from '../../../constants'
import IncomingVideoModal from './IncomingVideoModal'
import { fieldAttribute } from '../../../helpers'
import { 
  SectionContainer, SectionFront, InstructionSection,
  StyledDialogTitle, StyledMobileOption, FileIcon ,
  FileButton, InputRtc, FileVideo
} from '../../user/styled'
import { 
  useEdit, useEditFiles, useTextLanguage, 
  useFile, useRecord, useVideoModal ,
  useQRModal, useIncomingVideoModal, useQRPeer,
  useDetect, useVideos
} from '../../../hooks'

/**
 * @component
 * @param {Object} field - JSON Field from admin.
 * @param {String} type - String representing de file type; 'video' | 'audio'
 * @returns 
 */

const VideoInputRTC = ({ field, type }) => {
  const { description, languageText } = useTextLanguage();
  const { colSize } = useEdit(field)
  const { uniqueName, fileSizeLimit } = useFile(field, type);
  const { register, setValue, formState: {errors} } = useFormContext()
  useEditFiles(field)
  const { videoUrl, title } = useVideos()

  const {userPermission, videoMessage} = useRecord()
  const { browserInfo, OS } = useDetect()
  /* Incoming video modal */
  const {
    openIncomingVideoModal,
    handleOpenIncomingVideoModal,
    handleCloseIncomingVideoModal,
    payload, setPayload
  } = useIncomingVideoModal(browserInfo)

  /* QRModals */
  const { isOpenQR, handleCloseQR, handleOpenQR } = useQRModal()

  /* Peer connection and url */
  const { id } = useQRPeer(isOpenQR, handleOpenIncomingVideoModal)

  const origin = window.location.origin
  const urlValue = `${origin}/AutovideoQRPage/${id}`

  /* Video Modal */
  const { 
    handleOpen,
    isOpen,
    status, setStatus,
    buttons,
    cancelRecording,
    handleClose,
    currentVideoSize
  } = useVideoModal(field, setValue, payload, unsetPeerRecording, browserInfo, OS)

  const handleOpenQRModal = () => {
    handleClose()
    handleOpenQR()
  }

  function unsetPeerRecording(){
    const videoPreview = fieldAttribute('data-video', `video-${field.unique_name}-preview`)
		const placeholderImage = fieldAttribute('data-image', `image-${uniqueName}`)
    const modalPreviewVideo = fieldAttribute('data-video', `video-${uniqueName}-modal-preview`)
    const videoIntructions = fieldAttribute('data-video', `video-instructions`)
    if(window.confirm(t('videoRecorder.confirmCancel'))){
      setPayload(null)
			videoPreview.src=''
			videoPreview.classList.add('d-none')
      modalPreviewVideo.classList.add('d-none')
			placeholderImage.classList.remove('d-none')
      videoIntructions.classList.remove('d-none')
			setValue(uniqueName, '')
			setStatus(recorderStatus.inactive)
		}

  }

  return (
    <>
      {field && (
        <>
          {colSize?.includes('col-before') && (
              <Col xs='12' key={`col_before_${field.id}`}></Col>
          )}
          <Col
            className={ `mt-4 ${colSize}` }
            data-module={ `module-${field?.category?.field_category}` }
            data-name={uniqueName}
            id={ `group_field_${field.id}` }
            key={ field.id }
            xs={12}
          >
            <div className='mb-3'>
              <Label htmlFor={ field.name || field.id }>
                { field[description] }{ field.required && (
                  <strong className="text-danger">&nbsp;*</strong>) }
                { field?.[`tooltip${languageText}`] && (
                  <TooltipIcon tooltipText={ field[`tooltip${languageText}`] } />) }
              </Label>

              <FileButton onClick={handleOpen}>
                <InputRtc 
                  type='file'
                  { ...register(uniqueName) }
                  data-action={ field?.action }
                  data-category={ `category-${field?.category?.field_category}` }
                  data-condition={ field?.parent_condition }
                  data-parent={ field?.parent }
                  data-type-field={ field?.data_type }
                  data-unique-name={ field.unique_name }
                  id={ field.id }
                  key={ field.id }
                  name={ uniqueName }
                />
                <FileIcon 
                  src={videoIconSource}
                  data-image={`image-${uniqueName}`}
                />
                <FileVideo
                  data-video={ `video-${field.unique_name}-preview` }
                  className="d-none"
                />
              </FileButton>
              <Form.Text muted>
                <strong className="text-danger">&nbsp;*&nbsp;</strong>
                { t("files.fileSizeLimit", { size: fileSizeLimit }) }
              </Form.Text><br />
              {errors?.[uniqueName]?.message && (
                <Error message={ errors[uniqueName].message } />
              )}
            </div>
          </Col>
          {/* Modal where the video is gonna be recorded */}

          <Dialog onClose={handleClose} open={isOpen}> 
            {/* Cancel recording at any time (except inactive or received from QR) */}
            {(status !== recorderStatus.inactive && status !== recorderStatus.receivedFromQR) ? (
              <IoClose 
                onClick={cancelRecording}
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  top: '1px',
                  right: '2px',
                  fontSize: '20px',
                  zIndex: 20
                }}
              />
            ) : null}
            {/* Cancel recording received from QR */}
            {(status === recorderStatus.receivedFromQR) ? (
              <IoClose 
                onClick={unsetPeerRecording}
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  top: '1px',
                  right: '2px',
                  fontSize: '20px',
                  zIndex: 20
                }}
              />
            ) : null}

            {(userPermission !== 'denied') ? 
              (<>
                <DialogTitle sx={{ position: 'relative' }}>
                  <StyledDialogTitle className='text-muted'>
                    <span>
                      {t('videoRecorder.callToAction')}
                    </span>              
                  </StyledDialogTitle>
                </DialogTitle>
                <DialogContent dividers sx={{ padding: '0px'}}>

                  <SectionContainer>
                    <SectionFront>
                      <div
                        style={{
                          position: 'relative'
                        }}
                      >
                        {/* Video tag for the recording */}
                        <video className='d-none' data-video={ `video-${uniqueName}` } autoPlay muted playsInline
                          style={{
                            width: '100%',
                          }}
                        /> 
                        {(status !== recorderStatus.inactive && status !== recorderStatus.stopped && status !== recorderStatus.receivedFromQR)
                          ? (
                              <div className='badge rounded-pill bg-dark position-absolute user-select-none'
                                style={{
                                  bottom: '8px',
                                  left: '2px'
                                }}
                              >
                                {(parseInt(currentVideoSize)/ Math.pow(1024, 2)).toFixed(1)}/{fileSizeLimit}MB
                              </div>
                            )
                          : null}
                        {/* Video tag for the preview */}
                        <video className='d-none' data-video={ `video-${uniqueName}-modal-preview` } playsInline
                          style={{
                            width: '100%',
                          }}
                        /> 
                        <iframe data-video='video-instructions' height={'250px'} src={videoUrl} title={title} rameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                      </div>
                        
                      {status !== recorderStatus.receivedFromQR ?
                      (
                      <InstructionSection>
                        {t("videoRecorder.instructions")}
                        <ol>
                          <li>
                          {t("videoRecorder.first")}
                          </li>
                          <li>
                          {t("videoRecorder.second")}
                          </li>
                          <li>
                            {t("videoRecorder.third")}<br></br>
                          </li>
                        </ol>
                        <div 
                          style={{
                            padding: '0.3rem 0.6rem',
                            marginTop: '6px'
                          }}
                          className='alert alert-info'
                          role='alert'
                        >
                          {videoMessage.message}
                        </div>
                      </InstructionSection>
                      ): null}
                      <DialogActions sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingInline: '40px', paddingBlock: '0px' }}>
                        {buttons}
                        {/* Don't show mobile option if the video is recording, paused, already recorded (stopped) or received from QR */}
                        {(status !== recorderStatus.stopped && status !== recorderStatus.recording && status !== recorderStatus.paused && status !== recorderStatus.receivedFromQR) ? (
                        <StyledMobileOption onClick={handleOpenQRModal}>
                            {t('videoRecorder.mobileOption')}
                        </StyledMobileOption>
                        ) : null}
                      </DialogActions>
                    </SectionFront>
                  </SectionContainer>
      
                </DialogContent>
              </>) : null
            }
      
            {/* Camera permission denied previously? ↓ */}
            {(userPermission === 'denied') ?
              (<>
                <DialogTitle sx={{ paddingInline: '40px' }}>
                  Oops
                </DialogTitle>
                <DialogContent dividers sx={{ padding: '0px'}}>
                  <div className='alert alert-warning m-1' role='alert' dangerouslySetInnerHTML={{__html: t('videoRecorder.permissionsDeniedPreviously')}}>
                    
                  </div>
                </DialogContent>
              </>) : null
            }
          </Dialog>
          {/* Modal for the QR the be shown */}
          <QRModal
            open={isOpenQR} urlValue={urlValue} id={id}
            handleCloseQR={handleCloseQR} handleOpen={handleOpen}

          />
          {/* Modal for the video to be received and accepted */}
          <IncomingVideoModal 
            open={openIncomingVideoModal}
            onCloseIncomingVideoModal={handleCloseIncomingVideoModal}
            onCloseQR={handleCloseQR}
            payload={payload}
            field={field} setValue={setValue}
            setStatus={setStatus} id={id}
          />
        </>
      )}
    </>
  )
}

export default VideoInputRTC