import { useState } from "react"
import { browserNames, operativeSystemNames } from "../constants"

/**
 * Description placeholder
 * @date 31/1/2024 - 21:25:03
 * @returns {Object} An object containing the following functions and state:
 * - {String} browserInfo     - Object containing the 'name' and the supported mimeType of the current browser.
 */
export const useDetect = () => {
	/* In order to get the browser name */
	const [browserInfo, setBrowserInfo] = useState({
		name:'',
	})
	function getBrowserInfo(userAgent = window.navigator.userAgent) {
		// The order matters here, and this may report false positives for unlisted browsers.
		// Code for browser detection comes from: https://developer.mozilla.org/en-US/docs/Web/API/Window/navigator
		if (userAgent.includes("Firefox")) {
			// "Mozilla/5.0 (X11; Linux i686; rv:104.0) Gecko/20100101 Firefox/104.0"
			setBrowserInfo({
				name: browserNames.firefox
			})
			return
		} else if (userAgent.includes("SamsungBrowser")) {
			// "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36"
			setBrowserInfo({
				name: browserNames.samsung
			})
			return
		} else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
			// "Mozilla/5.0 (Macintosh; Intel Mac OS X 12_5_1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36 OPR/90.0.4480.54"
			setBrowserInfo({
				name: browserNames.opera
			})
			return
		} else if (userAgent.includes("Edge")) {
			// "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
			setBrowserInfo({
				name: browserNames.edge
			})
			return
		} else if (userAgent.includes("Edg")) {
			// "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36 Edg/104.0.1293.70"
			setBrowserInfo({
				name: browserNames.edg
			})
			return
		} else if (userAgent.includes("Chrome")) {
			// "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36"
			setBrowserInfo({
				name: browserNames.chrome
			})
			return
		} else if (userAgent.includes("CriOS")) {
			// "Mozilla/5.0 (iPhone; CPU iPhone OS 10_3 like Mac OS X) AppleWebKit/602.1.50 (KHTML, like Gecko) CriOS/56.0.2924.75 Mobile/14E5239e Safari/602.16"
			setBrowserInfo({
				name: browserNames.crios
			})
			return
		}
		 else if (userAgent.includes("Safari")) {
			// "Mozilla/5.0 (iPhone; CPU iPhone OS 15_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.6 Mobile/15E148 Safari/604.1"
			setBrowserInfo({
				name: browserNames.safari
			})
			return
		} else {
			setBrowserInfo({
				name: browserNames.unknown
			})
			return
		}
	}
	if (!browserInfo?.name) getBrowserInfo()

	const [OS, setOS] = useState('')
	function detectOS() {
		let userAgent = window.navigator.userAgent,
			platform = window.navigator.platform,
			macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
			windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
			iosPlatforms = ['iPhone', 'iPad', 'iPod']
			
		if (macosPlatforms.indexOf(platform) !== -1) {
			setOS(operativeSystemNames.macOS)
		} else if (iosPlatforms.indexOf(platform) !== -1) {
			setOS(operativeSystemNames.iOS)
		} else if (windowsPlatforms.indexOf(platform) !== -1) {
			setOS(operativeSystemNames.windows)
		} else if (/Android/.test(userAgent)) {
			setOS(operativeSystemNames.android)
		} else if (!OS && /Linux/.test(platform)) {
			setOS(operativeSystemNames.linux)
		}
	}
	if(!OS) detectOS() 

	return {
		browserInfo,
		OS
	}
}