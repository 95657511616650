import React from 'react';
import Loader from 'react-loader-spinner';
import styled from 'styled-components';
import { theme } from '../../../theme';

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

/**
 * Loader component
 * @component
 * @returns
 */
export const LoaderSpinner = () => {
  const { first } = theme;

  return (
    <LoaderContainer>
      <Loader
        type="TailSpin"
        color={ first }
        height={30}
        width={30}
        style={{ margin: "auto" }}
      />
    </LoaderContainer>
  );
};