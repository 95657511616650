import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import { Label, TooltipIcon } from '../shared/simple';
import { sendData } from '../../helpers';
import { StyledGroup } from '../shared/Input/styled';
import { theme } from '../../theme';
import { useTextLanguage } from '../../hooks';

const { first } = theme;

const StyledLabel = styled(Label)`
  background: ${first};
  border-radius: 10px;
  padding: 10px;
  color: white !important;
`;

/**
 * Component video messages
 * @component
 * @param {Object} field
 * @returns
 */
export const VideoMessage = ({ field }) => {
  const uniqueName = `${field.unique_name}__id__${field.id}`;
  const { languageText } = useTextLanguage();
  const [ videoMessage, setVideoMessage ] = useState({});

  useEffect(() => {
    const getDataType = () => {
      if (!videoMessage?.message) {
        let getValues = field?.function_get_values;
        getValues = (getValues) ? JSON.parse(getValues) : [];
        if (getValues?.apply === 'start' && getValues?.type !== 'endpoint') {
          const response = sendData(getValues?.type, getValues, true);
          setVideoMessage(response?.voice_message);
        }
      }
    };

    getDataType();
  }, [field, videoMessage]);

  return (
    <>
      {(videoMessage?.message && field.col_size?.includes(
        'col-before')) && (
        <Col xs={12} key={`col_before_${field.id}`}></Col>
      )}
      {(videoMessage?.message) && (
        <Col
          className={ field.col_size }
          data-module={ `module-${field?.category?.field_category}` }
          data-name={ uniqueName }
          id={ `group_field_${field.id}` }
          key={ field.id }
          xs={12}
        >
          <StyledGroup className="mb-3">
            <StyledLabel
              className="d-block"
              htmlFor={ uniqueName }
              xs={12}
            >
              { videoMessage?.message }
              { (field[`tooltip${languageText}`]) && (
                <TooltipIcon
                  color='fifth'
                  tooltipText={ field[`tooltip${languageText}`] }
                />
              )}
            </StyledLabel>
          </StyledGroup>
        </Col>
      )}
    </>
  );
};

VideoMessage.propTypes = {
  field: PropTypes.object.isRequired
};