import { useState } from 'react';
import { StyledGroup } from '../../../../shared/Input/styled';
import { Form } from 'react-bootstrap';
import { theme } from '../../../../../theme';
import { useTranslation } from 'react-i18next';
import { editionStatus } from '../../../../../constants';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const { first } = theme;

// Define element styles for the component
const StyledCheck = styled(Form.Check)`
  border-radius: .5rem;
  outline: none;
  &:focus {
    border-color: ${first};
    box-shadow: none;
  }
`;

/**
 * Check component with input type checkbox
 * @component
 * @param {Element} children
 * @param {Object} field
 * @param {String} name
 * @param {Function} onClick
 * @returns
 */
export const CheckSelect = ({ entry, setEntries, selectedProduct }) => {
  const [authorized, setAuthorized] = useState(entry.authorized_signature || false);
  const { t } = useTranslation();

  const handleCheck = (e) => {
    setAuthorized(e.target.checked);
    setEntries(prevEntries => {
      return prevEntries.map(prevEntry => {
        if (prevEntry.order === entry.order) {
          return {
            ...prevEntry,
            authorized_signature: e.target.checked
          };
        } else {
          return prevEntry;
        }
      });
    });
  };

  return (
    <StyledGroup className="mb-3">
      <StyledCheck
        label={ t("block.blockSignatory") }
        onClick={ (e)=> handleCheck(e) }
        type="checkbox"
        disabled={ selectedProduct.status === editionStatus.pending }
        checked={authorized}
      >
      </StyledCheck>
    </StyledGroup>
  );
};

CheckSelect.propTypes = {
  entry: PropTypes.object.isRequired,
  setEntries: PropTypes.func.isRequired
};