import React from 'react';
import styled from 'styled-components';
import { Select } from './select/Select';
import { TextInput } from './input/TextInput';
import { Validate } from '../buttons/Validate';
import { CheckSelect } from './select/CheckSelect';
import { useTranslation } from 'react-i18next';
import { LoaderSpinner } from '../../../shared/simple';
import { Remove } from '../buttons/Remove';
import { Add } from '../buttons/Add';
import { useEditEntry } from '../../../../hooks/useBlock';
import { editionStatus } from '../../../../constants';

const StyledEntry = styled.div`
  border: 1px solid gray;
  border-radius: 6px;
  position: relative;
`;

const Entry = ({ entry, entries, setEntries, handleAdd, canSubmit, selectedProduct }) => {
  const { t } = useTranslation();
  const {
    handleCheck,
    loading,
    wisenrollUser,
    setWisenrollUser,
    handleRemove
  } = useEditEntry({ entry, entries, setEntries });

  return (
    <StyledEntry className="row mb-4 py-2 mx-1">
      <section className="row px-4">
        <Select
          entry={entry}
          entries={entries}
          setEntries={setEntries}
          setWisenrollUser={setWisenrollUser}
          selectedProduct={selectedProduct}
        />
        { !(entry.client_account_link_type_id === 0)
          ? (
              <TextInput
                entry={entry}
                entries={entries}
                setEntries={setEntries}
                setWisenrollUser={setWisenrollUser}
                loading={loading}
                selectedProduct={selectedProduct}
              />
            )
          : '' }
        <Validate
          entry={entry}
          setEntries={setEntries}
          handleCheck={handleCheck}
          loading={loading}
          selectedProduct={selectedProduct}
        />
        { (entry.client_account_link_type_id === 3)
        ?
          (<div className="mt-3">
            <CheckSelect
              entry={entry}
              entries={entries}
              setEntries={setEntries}
              selectedProduct={selectedProduct}
            />
          </div>)
        : '' }
      </section>
      { ((entry.order !== 1) && (selectedProduct.status === editionStatus.editable) )
      ? <Remove
          handleRemove={handleRemove}
        />
      : ''}

      { (entry.order === 1 && entries.filter(
        entry => entry.active).length < 3 && canSubmit) && (
          selectedProduct.status === editionStatus.editable)
      ? <Add
          handleAdd={handleAdd}
        />
      : ''}

      <div className="px-3">
        {loading
        ?
          <div className="mb-1">
            <LoaderSpinner />
          </div>
        : (
          <>
            {(wisenrollUser?.user && !wisenrollUser?.error) && (
              <>
                <hr className="mt-1"></hr>
                <div className="alert alert-success d-flex flex-column" role="alert">
                  <label className="text-muted">
                    {t("block.name")}: <b>{wisenrollUser?.user?.firstName}</b>
                  </label>
                  <label className="text-muted">
                    {t("block.lastName")}: <b>{wisenrollUser?.user?.lastName}</b>
                  </label>
                  <label className="text-muted">
                    {t("block.docType")}: <b>{wisenrollUser?.user?.docType}</b>
                  </label>
                  <label className="text-muted">
                    {t("block.docNumber")}: <b>{wisenrollUser?.user?.docNumber}</b>
                  </label>
                </div>
              </>
            )}
            {(wisenrollUser?.error === true) && (
              <>
                <hr className="mt-1"></hr>
                <div className="alert alert-danger" role="alert">
                  {t("block.error")}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </StyledEntry>
  );
};

export default Entry;