import React from 'react';
import { useTranslation } from 'react-i18next';
import { Back, Title } from '../components/shared/simple';
import { Help } from '../components/shared/Help';
import { ProductsList } from '../components/products';
import { Row, Col } from 'react-bootstrap';
import { theme } from '../theme';
import { useNavigate } from 'react-router-dom';
import { routes } from '../constants';

/**
 * Page listing products and services by levels
 * @returns {JSX}
 */
const ProductServicePage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { first } = theme;

  return (
    <>
      <Col className="p-0" xl={3} md={4} xs={12}>
        <Help backButton={false} isProduct/>
      </Col>
      <Col className="p-4" xl={9} md={8} xs={12}>
        <Row>
          <Col className="p-0 text-end" xs={12}>
            <Back />
          </Col>
          <Col className="p-4" xs={12}>
            <Title >
              { t("common.productsServices") }
            </Title>
            <p
              style={{marginBottom: "50px", fontSize: "16px", color: first, fontWeight: 600, cursor: "pointer"}}
              onClick={() => navigate(routes.productRequests('product_requests') )}
            >
              {t("common.productRequest")}
            </p>
            <ProductsList />
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default ProductServicePage;