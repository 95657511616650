import React from 'react';
import { useTranslation } from 'react-i18next';
import { SubTitle, Title } from '../shared/simple';
import { Row, Col } from 'react-bootstrap';
import { StyledLogo } from './styled';
import { useCompleted, useInfoTiers } from '../../hooks';
import { Loading } from '../shared/Loading';
import { envSetting } from '../../constants';

/**
 * Component completed
 * @component
 * @returns
 */
export const Completed = () => {
  const { t } = useTranslation();
  const { nextTier } = useInfoTiers();
  const { loading } = useCompleted(nextTier);

  return (
    <Row>
      {loading
        ? (
          <Loading />
        ) : (
          <Col className="p-5 pt-0 pb-3" xs={12}>
            <StyledLogo src={ envSetting.bgLogoCompany} />
            <Title className="mt-2">
              { t("common.congratulations") }
            </Title>
            <SubTitle className="mt-2">
              { t("common.waitEmail") }
            </SubTitle>
          </Col>
        )
      }
    </Row>
  );
};