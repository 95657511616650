import { getRequest, postRequest } from '../helpers';

/**
 * Return helpers
 * @param {String} endpoint
 * @param {Object} params
 * @returns
 */
export const sendGetEndpoint = async (endpoint, params='') => {
  try {
    const response = await getRequest(endpoint, params);
    return response;
  } catch (error) {
    console.error(`sendGetEndpoint - ${endpoint}`, error);
  }
};

/**
 * Call request
 * @param {Object} data
 * @param {Object} String
 * @returns
 */
export const sendPostRequest = async (data, endpoint) => {
  try {
    const response = await postRequest(endpoint, data);
    return response;
  } catch (error) {
    console.error(`sendPostRequest - ${endpoint}`, error);
  }
};