import React from 'react';
import { Div, Block, Title, ImgList, DivImage, LabelList } from './styled';
import { Row, Col } from 'react-bootstrap';
import { useProductList } from '../../hooks';
import { theme } from '../../theme';
import Stepper from 'react-stepper-js';
import 'react-stepper-js/dist/index.css';
import { trimLowerCase } from '../../helpers';

/**
 * Component with products and services. With their images, titles and tier
 * @component
 * @returns
 */
export const ProductsList = () => {
  const { openProductDetails, tiers, currentStep, actualLanguage} = useProductList();
  const { third } = theme;

  return (
    <Div>
      <Row>
        <Col
          style={{marginTop: "0px"}}
          xs={1}
          md={2}
          lg={1}
        >
          <Stepper
            color={third}
            fontSize="20px"
            fontColor={third}
            steps={tiers}
            currentStep={currentStep}
          />
        </Col>
        <Col
          xs={11}
          md={10}
          lg={11}
        >
          <Row>
            {Object.keys(tiers).map(tier => (
              <Col
                style={{ marginBottom: 10, minWidth: 250 }}
                xs={12}
                md={12}
                key={`col_${tier}`}
              >
                <Block xs={12} md={3} key={`block_${tier}`}>
                  <Title key={`title_${tier}`} className="mb-2">
                    {tiers[tier].title}
                  </Title>
                  <div style={{
                    width: "100%",
                    overflowX: "auto",
                  }}>
                    <div style={{
                      display: "flex",
                      height: '150px',
                      gap: '10px'
                    }}>
                      {tiers[tier].products.map(product => {
                        if (product.application?.active) {
                          return (
                            <DivImage key={product.key} onClick={() => openProductDetails(product)}>
                              <ImgList
                                alt=""
                                style={{minWidth: '160px'}}
                                src={product.image_main}
                              />
                              <LabelList
                                title={product?.application[`description_${trimLowerCase(actualLanguage)}`]}
                              >
                                {product?.application[`description_${trimLowerCase(actualLanguage)}`]}
                              </LabelList>
                            </DivImage>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </div>
                  </div>
                </Block>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Div>
  );
};