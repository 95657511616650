import React, { useEffect } from 'react';
import { appsOrigin, routes, siteMap } from '../constants';
import { Navigate, Routes, Route } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import { ToastContainer } from 'react-toastify';
import { useError, useLanguage } from '../hooks';
import { useSelector } from 'react-redux';
import ChangePasswordPage from '../pages/ChangePassword';
import CompletedPage from '../pages/Completed';
import GetStartedEmailPage from '../pages/GetStartedEmail';
import GetStartedPage from '../pages/GetStarted';
import HomepagePage from '../pages/Homepage';
import LoginPage from '../pages/Login';
import ProductDescriptionPage from '../pages/ProductDescription';
import ProductServicePage from '../pages/ProductService';
import ProfilePage from '../pages/Profile';
import PublicRoute from './PublicRoute';
import RecoverPasswordPage from '../pages/RecoverPassword';
import TierPage from '../pages/Tier';
import FilesPage from '../pages/Files';
import ErrorsPage from '../pages/Errors';
import ClientEntityPage from '../pages/ClientEntity';
import ProductRequestsPage from '../pages/ProductRequests';
import 'react-toastify/dist/ReactToastify.css';
import InternetBankingPage from '../pages/InternetBanking';
import CreatedProductRequests from '../pages/CreatedProductRequests';
import AutovideoQRPage from '../components/tier/autovideoRTC/AutovideoQRPage';
import { fallbackLng } from '../translations/i18n';

/**
 * Routes component containing routes for the whole application
 * @returns {JSX}
 */
export const PrincipalRoute = () => {
  const { isAuthenticated } = useSelector(state => state.user);
  const { onClick } = useLanguage();
  useError();
  
  useEffect(() => {
    onClick(fallbackLng);
  }, [])
  

  return (
    <>
      <Routes>
        <Route
          element={<Navigate to={ routes.login(appsOrigin.default) } replace />}
          path="/"
        />
        <Route
          element={<PublicRoute isAuthenticated={ isAuthenticated } />}
        >
          <Route
            element={<LoginPage />}
            path={ siteMap.login.path }
          />
          <Route
            element={<Navigate to={routes.login(appsOrigin.default)} replace />}
            path={ siteMap.login.path2 }
          />
          {/* <Route
            element={<LoginBifrostPage />}
            path={ siteMap.loginByGivenToken.path }
          /> */}
          <Route
            element={<GetStartedPage />}
            path={ siteMap.verifyUser.path }
          />

          <Route
            element={<LoginPage />}
            path={ siteMap.getStartedEmail.path2 }
          />
          <Route
            element={<GetStartedEmailPage />}
            path={ siteMap.getStartedEmail.path }
          />
          <Route
            element={<RecoverPasswordPage />}
            path={ siteMap.recoverPasswordEmail.path }
          />
          <Route
            element={<ChangePasswordPage />}
            path={ siteMap.changePassword.path }
          />
        </Route>
        <Route
          element={<PrivateRoute isAuthenticated={ isAuthenticated } />}
        >
          <Route
            element={<InternetBankingPage />}
            path={ siteMap.createEbankingUsername.path }
          />
          <Route
            element={<ProfilePage />}
            path={ siteMap.profile.path }
          />
          <Route
            element={<ProductServicePage />}
            path={ siteMap.productsServices.path }
          />
          <Route
            element={<ProductDescriptionPage />}
            path={ siteMap.productDescription.path }
          />
          <Route
            element={<TierPage />}
            path={ siteMap.tier.path }
          />
          <Route
            element={<CompletedPage />}
            path={ siteMap.completed.path }
          />
          <Route
            element={<HomepagePage />}
            path={ siteMap.homepage.path }
          />
          <Route
            element={<FilesPage />}
            path={ siteMap.files.path }
          />
          <Route
            element={<AutovideoQRPage />}
            path={ siteMap.AutovideoQRPage.path }
          />
        </Route>
        <Route element={<LoginPage />} path={ siteMap.logout.path } />
        <Route element={<ClientEntityPage />} path={ siteMap.clientEntity.path } />
        <Route
          element={<ProductRequestsPage />}
          path={ siteMap.productRequests.path }
        />
        <Route
          element={<CreatedProductRequests />}
          path={ siteMap.createdProducts.path }
        />
        <Route element={<ErrorsPage />} path={ siteMap.errors.path } />
        <Route
          element={<Navigate to={ routes.login(appsOrigin.default) } replace />}
          path="*"
        />
      </Routes>
      <ToastContainer className="m-0 p-0 w-0 h-0"/>
    </>
  );
};