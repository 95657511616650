import styled from 'styled-components';

// Define element styles for the component
const Form = styled.form`
  background: transparent;
  width: 400px;
  min-width: 270px;
  margin: auto;
`;

/**
 * Form component
 * @component
 * @returns
 */
export const StyledForm = ({ children, className, onSubmit, id }) => {
  return (
    <Form
      className={ className }
      id={ id }
      onSubmit={ onSubmit }>
      { children }
    </Form>
  );
};