import i18n from '../../translations/i18n';
import { endpoints } from '../../constants';
import { setLogout, setMessage } from '.';
import { handleRequest, trimLowerCase, trimUpperCase } from '../../helpers';
import { sendGetEndpoint } from '../../services/baseApi';
import { BASE_REQUEST_LANGUAGE, STATIC_DATA_SET_DATA } from '../types';
import { store } from '../store/store';

/**** Export functions  ****/
/**
 * Call to endpoint to get static-data
 * @returns
 */
export const getStaticData = () => async dispatch => {
  try {
    const response = await sendGetEndpoint(endpoints.staticData);
    dispatch(handleRequest(response));
    if (!response.error) {
      delete response.error;
      delete response.status;
      if (response?.success) {
        const { actualLanguage } = store.getState().base;
        let data = {};
        data[`data_${trimLowerCase(actualLanguage)}`] = response.success;
        dispatch({ type: STATIC_DATA_SET_DATA, payload: { ...data } });
        dispatch({ type: BASE_REQUEST_LANGUAGE, payload: trimUpperCase(
          actualLanguage) });
        return true;
      } else {
        dispatch(setLogout());
        dispatch(setMessage(i18n.t("common.errorStatic"), 'error'));
        return false;
      }
    } else {
      dispatch(setLogout());
      dispatch(setMessage(i18n.t("common.errorStatic"), 'error'));
      return false;
    }
  } catch (error) {
    console.error('getStaticData: ', error);
    dispatch(setLogout());
    dispatch(setMessage(i18n.t("common.errorStatic"), 'error'));
    return false;
  }
};