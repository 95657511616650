import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { optionColor, optionColorHover } from '../../../helpers';
import { Link } from 'react-router-dom';

// Define element styles for the component
const LinkText = styled(Link)`
  color: ${props => optionColor(props.color)};
  font-size: 15px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  text-decoration: none;
  &:hover {
    color: ${props => optionColorHover(props.color)};
    transition: 200ms ease-in;
  }
`;

/**
 * RouterLink component
 * @component
 * @param {Element} children
 * @returns
 */
export const RouterLink = ({ children, ...props }) => {
  return <LinkText { ...props }>{ children }</LinkText>;
};

RouterLink.propTypes = {
  children: PropTypes.any
};