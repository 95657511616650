import React from 'react';
import AlertModal from '../../shared/modal/Modal';
import CloseIcon from '../../../assets/images/slide-captcha-modal/close-btn.svg';
import { Translation } from 'react-i18next';
import SlideCaptcha from 'react-slide-captcha';
import { getPuzzle, validate } from './mock';
import resetIcon from "../../../assets/images/slide-captcha-modal/reset.svg";
import './slide-captcha-modal.css';
import 'react-slide-captcha/dist/styles.css';

class SlideCaptchaModal extends React.Component {
  state = {
    puzzleUrl: '',
    bgUrl: '',
    id: '',
    isLoading: false,
    showResetButton: false
  };

  componentDidMount() {
    this.initPuzzle();
  };

  initPuzzle = () => {
    this.getPuzzleInfo().then((res) => {
      this.setState({
        id: res.id,
        puzzleUrl: res.puzzleUrl,
        bgUrl: res.bgUrl,
      });
    });
  };

  getPuzzleInfo = async () => {
    return await getPuzzle().then((res) => {
      const data = JSON.parse(res);
      return data;
    });
  };

  handleGetPuzzleInfo = () => {
    this.setState({
      isLoading: true,
    });
    this.getPuzzleInfo().then((res) => {
      this.setState({
        id: res.id,
        puzzleUrl: res.puzzleUrl,
        bgUrl: res.bgUrl,
        isLoading: false,
      });
    });
  };

  resultCallback = (
    validateValue,
    validatedSuccess,
    validatedFail,
    resetCaptcha,
  ) => {
    const {
      onRequestClose,
      onSuccess,
    } = this.props;
    validate({ id: this.state.id, distance: validateValue }).then(res => {
      const data = JSON.parse(res);
      const { code } = data;
      if (code === 100) {
        validatedSuccess(() => {
          onSuccess();
          onRequestClose();
          resetCaptcha();
        });
      } else {
        validatedFail(() => {
        });
      }
    }).catch((error) => {
      resetCaptcha();
      console.error('resultCallback in SlideCaptchaModal: ', error);
    });
  };

  componentReload = () => {
    this.vnode.resetCaptcha();
  };

  render() {
    const {
      isOpen,
      onRequestClose,
      title
    } = this.props;
    const positionStringMap = {
      top: 'top',
      bottom: 'bottom',
    };

    return (
      <Translation>
        {(t) => (
          <AlertModal
            overlayClassName="SlideModal__Overlay"
            modalClassName="SlideModal__Container"
            isOpen={isOpen}
          >
            <div className="Slide__Modal__Container">
              {title && <div className="SlideModal__Header">
                <h1>{title ?? t("SLIDE_CAPTCHA_MODAL.title")}</h1>
              </div>}
              <SlideCaptcha
                ref={ref => this.vnode = ref}
                displayType="static"
                puzzleUrl={this.state.puzzleUrl ? this.state.puzzleUrl.default : this.state.puzzleUrl}
                bgUrl={this.state.bgUrl ? this.state.bgUrl.default : this.state.bgUrl}
                onRequest={this.resultCallback}
                onReset={this.handleGetPuzzleInfo}
                containerClassName="Slide__Container"
                tipsStyle={{ fontSize: "12px" }}
                tipsText={ t("SLIDE_CAPTCHA_MODAL.tips") }
                reset="manual"
                isLoading={this.state.isLoading}
                resetButton="inline"
                imagePosition={positionStringMap.top}
                robotValidate={{
                  offsetY: 5,
                  handler: () => {
                    // alert("robotValidate / handler");
                  },
                }}
              />
              <div style={{ width: "100%", display: "flex", justifyContent: "space-between", marginTop: 10 }}>
                <button className="reset-button" onClick={this.componentReload} name="btn_reset">
                  <img alt="Reset button" src={resetIcon} style={{ width: 30, height: 30 }} />
                </button>
                <img
                  src={CloseIcon}
                  alt=""
                  className="SlideModal__Close-btn"
                  onClick={() => {
                    onRequestClose();
                  }}
                />
              </div>

            </div>
          </AlertModal>
        )}
      </Translation>
    );
  }
};

export default SlideCaptchaModal;
