import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import { Back, Title } from '../components/shared/simple';
import { Edit} from '../components/tier/block/edit/Edit';
import { useExistantProducts } from '../hooks/useBlock';
import { Help } from '../components/shared/Help';
import TableProducts from '../components/tier/block/edit/TableProducts';

const CreatedProductRequests = () => {
  const { t } = useTranslation();
  const { loading, clientProducts, actualLanguage } = useExistantProducts();
  const [selectedProduct, setSelectedProduct] = useState({
    "unique_name": undefined,
    "product_id": undefined,
    "status": undefined
  });

  return (
    <>
      <Col className="p-0" xl={3} md={4} xs={12}>
        <Help backButton={false} />
      </Col>
      <Col className="p-4" xl={9} md={8} xs={12}>
        <Row>
          <Col className="p-0 text-end" xs={12}>
            <Back />
          </Col>
          <Col className="p-4" xs={12}>
            <Title style={{marginBottom: "30px"}}>
              { t("block.myProducts") }
            </Title>
            <TableProducts
              setSelectedProduct={setSelectedProduct}
              selectedProduct={selectedProduct}
              clientProducts={clientProducts}
              actualLanguage={actualLanguage}
              loading={loading}
            />
            {(!loading && selectedProduct?.product_id) &&
              <Edit
                selectedProduct={selectedProduct}
              />
            }
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default CreatedProductRequests;