import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { optionColor, optionColorHover } from '../../helpers';

// Define element styles for the component
const StyledFloatButton = styled.button`
  color: white;
  right: 0;
  top: 80%;
  position: fixed;
  z-index: 100;
  background-color: ${props => optionColor(props.color)};
  border-color: ${props => optionColor(props.color)};
  margin: 25px;
  padding: 0 3px;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  &:hover,
  &:active,
  &:focus,
  &:focus-visible {
    color: white;
    background-color: ${props => optionColorHover(props.color)};
    border-color: ${props => optionColorHover(props.color)};
  }
`;

/**
 * Float button component for assistants request
 * @component
 * @param {Element} children
 * @returns
 */
export const FloatButton = ({ children, ...props }) => {
  const { t } = useTranslation();

  return (
    <OverlayTrigger
      overlay={<Tooltip>{t("assistants.requestAssistant")}</Tooltip>}
      placement="left"
    >
      <StyledFloatButton {...props}>{children}</StyledFloatButton>
    </OverlayTrigger>
  );
};

FloatButton.propTypes = {
  children: PropTypes.any
};