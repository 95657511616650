import React from 'react';
import { useValidateTier } from '../hooks';
import { Col } from 'react-bootstrap';
import { Help } from '../components/shared/Help';
import { Module } from '../components/tier/Module';

/**
 * Page tier
 * @returns {JSX}
 */
const TierPage = () => {
  useValidateTier();

  return (
    <>
      <Col className="p-0" xl={3} md={4} xs={12}>
        <Help backButton={false} />
      </Col>
      <Col className="p-4" xl={9} md={8} xs={12}>
        <Module />
      </Col>
    </>
  );
};

export default TierPage;