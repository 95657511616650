import { store } from '../redux/store/store';
import { trimLowerCase } from './shared';

/**** Export functions  ****/
/**
 * Get the help of the fields depending on the tier selected
 * @param {Object} helps
 * @param {Object} tier
 * @param {Object} tierFields
 * @returns
 */
export const setContentFields = (helps, tier, tierFields) => {
  let result = [];

  if (tier?.id) {
    const { actualLanguage } = store.getState().base;
    const categoriesField = tierFields?.filter(elem => (trimLowerCase(
      elem.tier) === trimLowerCase(tier.id)));

    helps.sort((a, b) => a.field - b.field);
    helps?.forEach((elem, index) => {
      const content = JSON.parse(elem[`content_${trimLowerCase(actualLanguage)}`]);

      const isIndex = categoriesField?.findIndex(item => (
        elem.field === item.field && item.active));

      helps[index].delete = !(isIndex >= 0);
      helps[index].text = content?.text;
      helps[index].html = content?.html;
    });

    result = helps?.filter(elem => !elem.delete);
  }

  return result;
};