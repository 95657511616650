import React from 'react';
import { useAllowedCountries, useClientEntity, useInitUser } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { Div, StyledImage, StyledLogo, StyledRow } from './styled';
import { LinkLogo } from './LinkLogo';
import { envSetting, routes } from '../../constants';
import {
  Button,
  Label,
  Link,
  LoaderSpinner,
  RouterLink,
  Select,
  StyledForm
} from '../shared/simple';
import { Input } from '../shared/Input';
import { PhoneNumberInput } from '../shared/Input/PhoneNumberInput';
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import SlideCaptchaModal from '../shared/slide-captcha-modal/SlideCaptchaModal';
import ImageAds from '../../assets/images/login/wisenroll.png';

/**
 * ClientEntity Component
 * @component
 * @returns
 */
export const ClientEntity = () => {
  const { t } = useTranslation();
  const { allowed } = useAllowedCountries();
  const { selectedProduct } = useSelector(state => state.user);
  const {
    appOrigin,
    isSlideModalOpen,
    loading,
    onSubmit,
    setIsSlideModalOpen,
    values
  } = useInitUser(allowed);
  const {
    country,
    errors,
    handleSubmit,
    onRegister,
    register,
    setValuesCountry,
    setValuesDocument,
    setValuePhoneCountry
  } = useClientEntity();

  return (
    <Div>
      <LinkLogo />
      <Container>
        <StyledImage
          className="d-xs-block d-md-none"
          rel="preload"
          src={ ImageAds }
        />
        <StyledLogo
          className="d-xs-block d-md-none"
          rel="preload"
          src={ selectedProduct?.image_ads }
        />
        <StyledRow className="justify-content-md-center">
          <StyledForm
            className="col-md-10 col-xs-12 col-lg-6 col-xl-5"
            onSubmit={ handleSubmit(onSubmit) }
          >
            <h1 className="pb-4">{ t("clientEntity.title") }</h1>
            {country ? (
              <div>
                <Input
                  autoComplete="off"
                  disabled={ loading }
                  label={ t("clientEntity.code") }
                  message={ errors.wisenroll_code?.message }
                  name={ register("wisenroll_code").name }
                  register={ register("wisenroll_code") }
                  type="text"
                />
                <Select
                  dataName={country?.country}
                  id="country_id"
                  isRequired={false}
                  isSetValue={false}
                  isStaticData={false}
                  label={t("clientEntity.country")}
                  message={errors.document_country_id?.message}
                  name={register("document_country_id").name}
                  register={register("document_country_id")}
                  setValue={setValuesCountry}
                  text="name"
                />
                <Select
                  dataName={country?.document_type}
                  id="document_type_id"
                  isRequired={false}
                  isSetValue={false}
                  isStaticData={false}
                  label={t("clientEntity.typeDocument")}
                  message={errors.document_type_id?.message}
                  name={register("document_type_id").name}
                  register={register("document_type_id")}
                  setValue={setValuesDocument}
                  text="description"
                />
                <Input
                  autoComplete="off"
                  disabled={ loading }
                  label={ t("clientEntity.documentNumber") }
                  message={ errors.document_number?.message }
                  name={ register("document_number").name }
                  register={ register("document_number") }
                  field={{unique_name: 'document_number'}}
                  type="text"
                />
                <Input
                  autoComplete="off"
                  disabled={ loading }
                  label={ t("clientEntity.email") }
                  message={ errors.email?.message }
                  name={ register("email").name }
                  register={ register("email") }
                  type="text"
                />
                <PhoneNumberInput
                  autoComplete="off"
                  disabled={ loading }
                  label={ t("clientEntity.phoneNumber") }
                  message={ errors.cell_phone?.message }
                  name={ register("cell_phone").name }
                  register={ register("cell_phone") }
                  type="text"
                  setCountryValue={setValuePhoneCountry}
                />
                <input 
                  className='d-none'
                  { ...register("cell_phone_country_id") }
                  name={ register("cell_phone_country_id").name }
                  disabled
                  readOnly
                />
                {loading
                  ? (<LoaderSpinner />)
                  : (
                    <Button
                      className="btn"
                      color="first"
                      disabled={ loading }
                      type="submit"
                      name="btn_client"
                    >
                      { t("login.buttonSignUp") }
                    </Button>
                )}
              </div>
            ) : (<LoaderSpinner />)}
            {!loading && (
              <Label className="wantText">
                { t("password.wantToLogin") }&nbsp;
                <RouterLink
                  className="p-0 col-12"
                  to={ routes.login(appOrigin) }
                  color="third"
                  disabled={ loading }
                >
                  { t("login.buttonLogIn") }
                </RouterLink>
              </Label>
            )}
            <Label className="wantText">
              { t("common.createAccount") }&nbsp;
              <Link
                className="p-0 col-12"
                href={ envSetting.routeCreateAccount }
                color="first"
                target="_blank"
              >
                { t("common.createAccountURL") }
              </Link>
            </Label>
          </StyledForm>
        </StyledRow>
      </Container>
      <SlideCaptchaModal
        isOpen={ isSlideModalOpen }
        onRequestClose={ () => setIsSlideModalOpen(false) }
        onSuccess={ () => onRegister(values) }
      />
    </Div>
  );
};