import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { Col } from 'react-bootstrap';
import { StyledGroup } from '../../shared/Input/styled';
import { Error, Label, TooltipIcon } from '../../shared/simple';
import DatePicker, {registerLocale} from 'react-datepicker';
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';
import { useDateInput, useDisabled, useEdit, useTextLanguage } from '../../../hooks';
import i18next from 'i18next';

registerLocale("es", es);
registerLocale("en", en);

/**
 * Component date input
 * @component
 * @param {Object} field
 * @returns
 */
export const DateInput = ({ field }) => {
  const uniqueName = `${field.unique_name}__id__${field.id}`;
  const { description, languageText } = useTextLanguage();
  const { register, formState: { errors } } = useFormContext();
  const {
    endDate,
    formatInputValue,
    onChange,
    selectedDay,
    startDate
  } = useDateInput(field, uniqueName);
  const { colSize } = useEdit(field);
  const { isDisabled } = useDisabled(field);
  const localeLanguage = (i18next.language === 'en' || i18next.language === 'en-US') ? 'en' : 'es'

  return (
    <>
      {field && (
        <>
          {(colSize?.includes('col-before')) && (
            <Col xs={12} key={`col_before_${field.id}`}></Col>
          )}
          <Col
            className={ colSize }
            data-module={ `module-${field?.category?.field_category}` }
            data-name={ uniqueName }
            id={ `group_field_${field.id}` }
            key={ field.id }
            xs={12}
          >
            <StyledGroup
              className="mb-3"
              data-action={ field.action }
              data-category={ `category-${field?.category?.field_category}` }
              data-condition={ field.parent_condition }
              data-name={ uniqueName }
              data-parent={ field.parent }
              data-unique-name={ field.unique_name }
              id={ field.id }
            >
              <Label htmlFor={ uniqueName }>
                { field[description] }{ (field.required) && (
                  <strong className="text-danger">&nbsp;*</strong>)}
                { (field[`tooltip${languageText}`]) && (
                  <TooltipIcon tooltipText={ field[`tooltip${languageText}`] } />)}
              </Label>
              <DatePicker
                { ...register(uniqueName) }
                autoComplete="off"
                className="form-control"
                dropdownMode="select"
                formatInputText={ formatInputValue }
                inputPlaceholder={ field.placeholder }
                maxDate={ endDate }
                maximumDate={ endDate }
                minDate={ startDate }
                name={ uniqueName }
                onChange={ onChange }
                peekNextMonth
                placeholderText={ field.placeholder }
                popperClassName="some-custom-class"
                popperPlacement="top-end"
                selected={ selectedDay }
                showMonthDropdown
                showYearDropdown
                locale={localeLanguage}
                disabled={isDisabled}
              />
              {errors?.[uniqueName]?.message && (
                <Error message={ errors[uniqueName].message } />
              )}
            </StyledGroup>
          </Col>
        </>
      )}
    </>
  );
};

DateInput.propTypes = {
  field: PropTypes.object.isRequired
};