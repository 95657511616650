import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { customStyles } from '../../constants';
import { useListAssistants } from '../../hooks';
import { Select, Button, StyledForm } from './../shared/simple';
import { Input } from '../shared/Input';
import { useTranslation } from 'react-i18next';
import { Col } from 'react-bootstrap';

/**
 * List Modal component for assistants request
 * @component
 * @param {Boolean} modalIsOpen
 * @param {Function} setModalIsOpen
 * @returns
 */
export const ListModal = ({ modalIsOpen, setModalIsOpen }) => {
  const { t } = useTranslation();
  const {
    register,
    errors,
    assistantsSelect,
    stepModal,
    changeStep,
    selectedAssistant,
    loading,
    setValues,
    handleSubmit,
    onSubmit,
    afterCloseModal,
    seeAll,
    seeAllAssistants
  } = useListAssistants(setModalIsOpen);

  return (
    <Modal
      ariaHideApp={false}
      key="modalAssistants"
      isOpen={modalIsOpen}
      onAfterOpen={() => { }}
      onRequestClose={() => setModalIsOpen(false)}
      onAfterClose={afterCloseModal}
      style={customStyles}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      <Col xs={12}>
        <StyledForm
          className="col-xs-12 p-0"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Col xs={12} className="mb-4">
            {(stepModal === 1) &&
              <>
                <Select
                  dataName={assistantsSelect}
                  id="id"
                  isRequired={true}
                  isSetValue={false}
                  isStaticData={false}
                  label={t("assistants.selectAssistant")}
                  message={errors.select_assistant?.message}
                  name={register("select_assistant").name}
                  register={register("select_assistant")}
                  setValue={setValues}
                  text="value"
                  tooltip={t("assistants.assistantTooltip")}
                />
                <Button
                  className={seeAll ? "btn mb-0 d-none" : "btn mb-0"}
                  size="sm"
                  type="button"
                  color="third"
                  onClick={seeAllAssistants}
                  name="btn_see_all"
                >
                  {t("common.seeAll")}
                </Button>
              </>
            }
            {(stepModal === 2) &&
              <p>
                {t("assistants.assistantConfirmation")}&nbsp;
                <strong>
                  {`${selectedAssistant.first_name} ${selectedAssistant.last_name}`}
                </strong>?
              </p>
            }
            {
              (stepModal === 3) &&
              <>
                <p className="mb-3">
                  {t("assistants.assistantContact")}{" "}
                  <span name="item_assistant_email">{selectedAssistant.email}</span>{" "}
                  {t("assistants.assistantContactPhone",
                    {
                      phone: `${selectedAssistant.phone_number}`
                    })}
                </p>
                <p>
                  {t("assistants.assistantContactConfirm")}
                </p>
              </>
            }
            {(stepModal === 4) &&
              <Input
                autoComplete="off"
                disabled={loading}
                isRequiredInput={true}
                label={t("assistants.assistantCode")}
                message={errors.assistant_code?.message}
                name={register("assistant_code").name}
                register={register("assistant_code")}
                type="text"
              />
            }
          </Col>
          {(stepModal === 4)
            ? (
              <Button
                className="btn mb-0"
                type="submit"
                color="first"
                name="btn_accept"
              >
                {t("common.accept")}
              </Button>
            ) : (
              <Button
                className="btn mb-0"
                type="button"
                color="first"
                onClick={changeStep}
                name="btn_continue_assistants"
              >
                {t("common.continue")}
              </Button>
            )
          }
          <Button
            className="btn mb-0"
            type="button"
            color="fourth"
            name="btn_cancel"
            onClick={() => setModalIsOpen(false)}
          >
            {t("common.cancel")}
          </Button>
        </StyledForm>
      </Col>
    </Modal>
  );
};

ListModal.propTypes = {
  modalIsOpen: PropTypes.bool.isRequired,
  setModalIsOpen: PropTypes.func.isRequired
};