import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { Col } from 'react-bootstrap';
import { trimLowerCase } from '../../../helpers';
import { Input } from '../../shared/Input';
import {
  useDisabled,
  useEdit,
  useTextInput,
  useTextLanguage
} from '../../../hooks';

/**
 * Component text input
 * @component
 * @param {Object} field
 * @returns
 */
export const TextInput = ({ field }) => {
  const uniqueName = `${field.unique_name}__id__${field.id}`;
  const { description, languageText } = useTextLanguage();
  const { register, formState: { errors }, setValue } = useFormContext();
  const { type } = useTextInput(field, uniqueName);
  const { isDisabled } = useDisabled(field);
  const { colSize } = useEdit(field);

  return (
    <>
      {field && (
        <>
          {(colSize?.includes('col-before')) && (
            <Col xs={12} key={ `col_before_${field.id}` }></Col>
          )}
          <Col
            className={ colSize }
            data-module={ `module-${field.category?.field_category}` }
            data-name={ uniqueName }
            id={ `group_field_${field.id}` }
            key={ field.id }
            xs={12}
          >
            <Input
              autoComplete="off"
              className="w-100"
              disabled={ isDisabled }
              field={ field }
              id={ trimLowerCase(field.id) }
              isRequiredInput={ field.required }
              label={ field[description] }
              message={ errors?.[uniqueName]?.message }
              name={ register(uniqueName).name }
              placeholder={ field[`placeholder${languageText}`] }
              register={ register(uniqueName) }
              setValue={ setValue }
              tooltipText={ field[`tooltip${languageText}`] }
              type={ type }
            />
          </Col>
        </>
      )}
    </>
  );
};

TextInput.propTypes = {
  field: PropTypes.object.isRequired
};
