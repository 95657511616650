import styled from 'styled-components';
import { Image } from 'react-bootstrap';
import { theme } from '../../../theme';

const { first } = theme;

// Define element styles for the component
export const Div = styled.div`
  background: ${first};
  height: 100%;
  left: 0;
  position: relative;
  top: 0;
`;

export const StyledImage = styled(Image)`
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const StyledLogo = styled(Image)`
  height: 250px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  text-align: center;
  width: 100%;
  padding: 50px;
`;