import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useEmail, useSection } from '../../../hooks';
import { Button, LoaderSpinner, StyledForm } from '../../shared/simple';
import { ButtonCircle } from '../../shared/simple/ButtonCircle';
import { Col, Row } from 'react-bootstrap';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from '../../shared/Input';
import { profile } from '../../../constants';

/**
 * Email component
 * @component
 * @param {Function} setRequestEdit
 * @param {Function} setRequestTokenForce
 * @returns
 */
export const Email = ({ setRequestEdit, setRequestTokenForce }) => {
  const { t } = useTranslation();
  const { errors, handleSubmit, loading, onSubmit, register, reset } = useEmail(
    setRequestTokenForce);
  const { cancelEditRequest, editRequest } = useSection(
    profile.cardEmail,
    'editModuleEmail',
    reset,
    'moduleFormEmail',
    setRequestEdit,
    loading
  );

  return (
    <>
      <Col xs={12}>
        <StyledForm
          className="d-none p-0 m-0 w-100"
          onSubmit={ handleSubmit(onSubmit) }
          id={ profile.moduleFormEmail }
        >
          <Row>
            <Col className="pt-1 col-xl-6 col-xs-12">
              <Input
                autoComplete="off"
                disabled={ loading }
                isRequiredInput={ true }
                label={ t("common.email") }
                message={ errors.email?.message }
                name={ register("email").name }
                placeholder={ t("common.emailExample") }
                register={ register("email") }
                type="email"
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            {loading
              ? (
                <Col className="pt-1" xs={12}>
                  <LoaderSpinner />
                </Col>
              ) : (
                <>
                  <Col className="pt-1 col-xs-12 col-xl-6">
                    <Button
                      className="btn mb-1"
                      disabled={ loading }
                      color="first"
                      type="submit"
                      name="btn_save_email"
                    >
                      { t("common.submit") }
                    </Button>
                  </Col>
                  <Col className="pt-1 col-xs-12 col-xl-6">
                    <Button
                      className="btn mb-1"
                      disabled={ loading }
                      onClick={ cancelEditRequest }
                      color="fourth"
                      type="button"
                      name="btn_cancel_email"
                    >
                      { t("common.cancel") }
                    </Button>
                  </Col>
                </>
              )
            }
          </Row>
        </StyledForm>
      </Col>
      <Col xs={12} className="float-end" id={ profile.editModuleEmail }>
        <ButtonCircle
          className="float-end"
          color="first"
          onClick={ editRequest }
          title={ t("common.edit") }
          name="btn_edit_email"
        >
          <FontAwesomeIcon icon={ faPen } />
        </ButtonCircle>
      </Col>
    </>
  );
};

Email.propTypes = {
  setRequestEdit: PropTypes.func,
  setRequestTokenForce: PropTypes.func
};