import React from 'react';
import { useAllowedCountries, useInitUser, useLogin } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { Div, StyledImage, StyledLogo, StyledRow } from './styled';
import { LinkLogo } from './LinkLogo';
import { siteMap } from '../../constants';
import { Button, LoaderSpinner, Link, StyledForm, RouterLink, Label } from '../shared/simple';
import { Input } from '../shared/Input';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarked } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import SlideCaptchaModal from '../shared/slide-captcha-modal/SlideCaptchaModal';
import ImageAds from '../../assets/images/login/wisenroll.png';
import OtpInputModal from '../shared/otp-input-modal/OtpInputModal';
import { useOtpModal } from '../../hooks/useOtpModal';
import { isUndefinedNull, notUndefinedNull } from '../../helpers';

/**
 * Login Component
 * @component
 * @returns
 */
export const Login = () => {
  const { t } = useTranslation();
  const { allowed } = useAllowedCountries();
  const { selectedProduct } = useSelector(state => state.user);
  const { geolocation, deviceInfo } = useSelector(state => state.base)
  const {
    isSlideModalOpen,
    loading,
    onSubmit,
    setIsSlideModalOpen,
    values,
    locationStatus
  } = useInitUser(allowed);
  const {
    errors,
    handleSubmit,
    onGetStarted,
    onLogin,
    register,
    activateLocation,
    reset
  } = useLogin();
  const { isOpen, handleClose, handleOpen, otpValues } = useOtpModal({ reset })

  return (
    <Div>
      <LinkLogo />
      <Container>
        <StyledImage
          className="d-xs-block d-md-none"
          rel="preload"
          src={ ImageAds }
        />
        <StyledLogo
          className="d-xs-block d-md-none"
          rel="preload"
          src={ selectedProduct?.image_ads }
        />
        <StyledRow className="justify-content-md-center">
          <StyledForm
            className="col-md-10 col-xs-12 col-lg-6 col-xl-5"
            onSubmit={ handleSubmit(onSubmit) }
          >
            <h1 className="pb-4">{ t("login.login") }</h1>
            <Input
              autoComplete="off"
              disabled={ loading }
              label={ t("common.username") }
              message={ errors.username?.message }
              name={ register("username").name }
              register={ register("username") }
              type="text"
            />
            <Input
              autoComplete="off"
              disabled={ loading }
              label={ t("common.password") }
              message={ errors.password?.message }
              name={ register("password").name }
              register={ register("password") }
              type="password"
            />
            {!loading && (
              <RouterLink
                className="p-0 col-12"
                color="third"
                disabled={ loading }
                to={ siteMap.recoverPasswordEmail.path }
                name="link_recover_password"
              >
                { t("login.recoverPassword") }
              </RouterLink>
            )}
            {!loading && (
              <div className="col-12">
                <RouterLink
                  className="p-0 col-12"
                  color="first"
                  disabled={ loading }
                  to={ siteMap.clientEntity.path }
                >
                  { t("clientEntity.title") }
                </RouterLink>
              </div>
            )}
            {loading
              ? (<LoaderSpinner />)
              : (
                <Button
                  className="btn"
                  color="first"
                  disabled={ loading }
                  type="submit"
                  name="btn_login"
                >
                  { t("login.buttonLogIn") }
                </Button>
              )
            }
            {!loading && (
              <Button
                className="btn"
                color="third"
                disabled={ loading }
                onClick={ onGetStarted }
                type="button"
                name="btn_sign_up"
              >
                { t("login.buttonSignUp") }
              </Button>
            )}
            {!loading && (
              <Label
                disabled={ loading }
              >
                { isUndefinedNull(geolocation) ? t("login.needGrantGeolocation") : t("login.allowedGeolocation") }
              </Label>
            )}
            {!loading && isUndefinedNull(geolocation) && (
              <Button
                className="btn"
                color="third"
                disabled={ loading }
                onClick={ activateLocation }
                type="button"
              >
                <Link
                  className="btn"
                  color="fourth"
                  disabled={ loading }
                  title={ t("login.disabledLocation") }
                  type="button"
                >
                  <FontAwesomeIcon color='#FFFFFF' icon={ faMapMarked } />
                </Link>
                <span
                  className='ms-2'
                  disabled={ loading }
                >
                  { t("login.activateGeolocation") }
                </span>
              </Button>
            )}
          </StyledForm>
        </StyledRow>
      </Container>
      <SlideCaptchaModal
        isOpen={ isSlideModalOpen }
        onRequestClose={ () => setIsSlideModalOpen(false) }
        onSuccess={() => handleOpen(values)}
      />
      <OtpInputModal 
        isOpen={isOpen}
        handleClose={handleClose}
        title={true}
        values={otpValues}
      />
    </Div>
  );
};