import i18n from '../../translations/i18n';
import {
  BASE_CLEAR,
  MESSAGE_CLEAR,
  PERSON_DATA_CLEAR,
  STATIC_DATA_CLEAR,
  TIER_CLEAR,
  USER_APPLICATIONS,
  USER_CLEAR,
  USER_DATA_SUMMARY,
  USER_LOGOUT,
  USER_TIME_REFRESH,
  USER_PRODUCT_REQUEST,
} from '../types';
import {
  getPersonData,
  getStaticData,
  setLoader,
  setMessage,
  setPassword
} from '.';
import { decryptAES, handleRequest } from '../../helpers';
import { endpoints } from '../../constants';
import { sendGetEndpoint, sendPostRequest } from '../../services/baseApi';
import jwt_decode from 'jwt-decode';

/**** Export functions  ****/
/**
 * Call to endpoint to login
 * @param {Object} data
 * @returns
 */
export const loginUser = data => async dispatch => {
  try {
    const response = await sendPostRequest(data, endpoints.login);
    dispatch(handleRequest(response));
    return response;
  } catch (error) {
    console.error('loginUser: ', error);
    dispatch(setLoader(false));
    dispatch(setMessage(i18n.t("message.onLogin"), 'error'));
    return;
  }
};

/**
 * Get static and person data by given token
 * and redirect to home page
 * @param {String} token
 * @param {Object} data
 * @returns
 */
export const loginByGivenToken = (token, callback) => async dispatch => {
  try {
    const decryptedToken = decryptAES(token);
    const userInfo = jwt_decode(decryptedToken);
    if (userInfo) {
      // Missing product_request_id
      dispatch(getPersonData(userInfo.user_id));
      dispatch(getStaticData(token, userInfo));
    } else {
      dispatch(setMessage(i18n.t("message.errorGetUserInfo"), 'error'));
      callback();
    }
  } catch (error) {
    console.error('loginByGivenToken: ', error);
    dispatch(setMessage(i18n.t("message.errorGetUserInfo"), 'error'));
    dispatch(setLoader(false));
    callback();
  }
};

/**
 * Call to endpoint to register
 * @param {Object} data
 * @returns
 */
export const signUpUser = data => async dispatch => {
  try {
    const response = await sendPostRequest(data, endpoints.signUp);
    dispatch(handleRequest(response));
    if (response && !response.error) {
      dispatch(setMessage(i18n.t("getStarted.emailSent"), 'success'));
    }
  } catch (error) {
    console.error('signUpUser: ', error);
    dispatch(setLoader(false));
    dispatch(setMessage(i18n.t("message.signUpUser"), 'error'));
  }
};

/**
 * Call to endpoint to revocer password
 * @param {Object} data
 * @returns
 */
export const recoverPasswordEmail = data => async dispatch => {
  try {
    const response = await sendPostRequest(data, endpoints.recoverPassword);
    dispatch(handleRequest(response));
  } catch (error) {
    console.error('recoverPasswordEmail: ', error);
    dispatch(setLoader(false));
    dispatch(setMessage(i18n.t("message.changePassword"), 'error'));
  }
};

/**
 * Call to endpoint to verify user
 * @param {Object} data
 * @returns
 */
export const verifyUser = data => async dispatch => {
  try {
    const response = await sendPostRequest(data, endpoints.verifyUser);
    dispatch(handleRequest(response));
    if (response && !response.error) {
      dispatch(setMessage(i18n.t("message.loginSuccess"), 'success'));
    }
    return response.error;
  } catch (error) {
    console.error('verifyUser: ', error);
    dispatch(setLoader(false));
    dispatch(setMessage(i18n.t("message.verifyUser"), 'error'));
    return;
  }
};

/**
 * Call to endpoint to create user for internet Banking
 * @param {Object} data
 * @returns
 */
export const createInternetBankingUser = data => async dispatch => {
  try {
    const response = await sendPostRequest(data, endpoints.createInternetBankingUser);
    dispatch(handleRequest(response));
    if (response && !response.error) {
      dispatch(setMessage(i18n.t("eBanking.mailInstructions"), 'info'));
    }
    return response.error;
  } catch (error) {
    console.error('createInternetBankingUser: ', error);
    dispatch(setLoader(false));
    dispatch(setMessage(i18n.t("eBanking.error"), 'error'));
    return;
  }
};

/**
 * Call to endpoint to change password with hash
 * @param {Object} data
 * @returns
 */
export const changePassword = data => async dispatch => {
  try {
    const response = await sendPostRequest(data, endpoints.changePasswordService);
    dispatch(handleRequest(response));
    if (response && !response.error) dispatch(setPassword(true));
  } catch (error) {
    console.error('changePassword: ', error);
    dispatch(setLoader(false));
    dispatch(setMessage(i18n.t("message.changePassword"), 'error'));
  }
};

/**
 * Call to get applications
 * @returns
 */
export const getUserApplications = () => async dispatch => {
  try {
    const response = await sendGetEndpoint(endpoints.getApplications);
    dispatch(handleRequest(response));
    if (response && !response.error) {
      dispatch({ type: USER_APPLICATIONS, payload: response });
      return response;
    }
    return [];
  } catch (error) {
    console.error('getUserApplications: ', error);
    dispatch(setLoader(false));
    dispatch(setMessage(i18n.t("message.getUserApplications"), 'error'));
  }
};

/**
 * Call to refresh time that was updated the applications
 * @returns
 */
export const getTimeRefreshProducts = () => async dispatch => {
  try {
    const newDateTime = new Date();
    dispatch({ type: USER_TIME_REFRESH, payload: newDateTime });
  } catch (error) {
    console.error('getTimeRefreshProducts: ', error);
    dispatch(setLoader(false));
    dispatch(setMessage(i18n.t("message.getUserApplications"), 'error'));
  }
};

/**
 * Call to endpoind to change password without hash
 * @param {Object} data
 * @returns
 */
export const changePasswordNoHash = data => async dispatch => {
  try {
    const response = await sendPostRequest(data, endpoints.changePasswordNoHash);
    if (response && !response.error) {
      dispatch(setPassword(true));
    }
    if (response.errors) setMessage(response.errors, 'error')
    return response;
  } catch (error) {
    console.error('changePasswordNoHash: ', error);
    dispatch(setLoader(false));
    dispatch(setMessage(i18n.t("message.changePasswordNoHash"), 'error'));
    return;
  }
};

/**
 * Return action
 * @returns
 */
export const setLogout = () => async dispatch => {
  localStorage.clear();
  dispatch({ type: USER_LOGOUT });
  dispatch({ type: USER_CLEAR });
  dispatch({ type: BASE_CLEAR });
  dispatch({ type: MESSAGE_CLEAR });
  dispatch({ type: PERSON_DATA_CLEAR });
  dispatch({ type: STATIC_DATA_CLEAR });
  dispatch({ type: TIER_CLEAR });
};

/**
 * Call to get data summary
 * @returns
 */
export const getDataSummary = () => async dispatch => {
  try {
    const response = await sendGetEndpoint(endpoints.getDataSummary);
    dispatch(handleRequest(response));
    if (response && !response.error) {
      dispatch({ type: USER_DATA_SUMMARY, payload: response.success });
    }
  } catch (error) {
    console.error('getDataSummary: ', error);
    dispatch(setLoader(false));
    dispatch(setMessage(i18n.t("message.getDataSummary"), 'error'));
  }
};

/**
 * Call to endpoint to register client entity
 * @param {Object} data
 * @returns
 */
export const registerClientEntity = data => async dispatch => {
  try {
    const response = await sendPostRequest(
      data, endpoints.registerClientEntity);
    dispatch(handleRequest(response));
    if (response && !response.error) {
      dispatch(setMessage(i18n.t("getStarted.emailSent"), 'success'));
    }
  } catch (error) {
    console.error('registerClientEntity: ', error);
    dispatch(setLoader(false));
    dispatch(setMessage(i18n.t("message.signUpUser"), 'error'));
  }
};

/**
 * Call to product request
 * @returns
 */
export const getProductRequest = userId => async dispatch => {
  try {
    const response = await sendGetEndpoint(endpoints.getProductRequest(userId));
    dispatch(handleRequest(response));
    if (response && !response.error) {
      let [productRequest] = response.filter(item => item.id === Math.min(...response.map(
        item => item.id)));
      let [newProductRequest] = response.filter(item => item.id === Math.max(...response.map(
        item => item.id)));
      dispatch({
        type: USER_PRODUCT_REQUEST,
        payload: {
          dataProductRequest: response,
          firstProductRequestId: productRequest?.id,
          lastProductRequestId: newProductRequest?.id
        }
      });
    }
  } catch (error) {
    console.error('getDataSummary: ', error);
    dispatch(setLoader(false));
    dispatch(setMessage(i18n.t("message.getDataSummary"), 'error'));
  }
};

/**
 * Call to new product request
 * @returns
 */
export const newProductRequest = data => async dispatch => {
  try {
    const response = await sendPostRequest(data, endpoints.newProductRequest);
    dispatch(handleRequest(response));
    if (response && !response.error) {
      dispatch(getProductRequest(data.user_id));
      localStorage.setItem("idRequest", response.success?.id);
      dispatch(setMessage(i18n.t("message.requestSuccess"), 'success'));
    }
  } catch (error) {
    console.error('newProductRequest: ', error);
    dispatch(setLoader(false));
    dispatch(setMessage(i18n.t("message.errorRequestSuccess"), 'error'));
  }
};

/**
 * Call to update request
 * @returns
 */
export const updateRequest = (data, userIdBifrost) => async dispatch => {
  try {
    const response = await sendPostRequest(data, endpoints.updateRequest);
    dispatch(handleRequest(response));
    if (response && !response.error) {
      dispatch(getProductRequest(userIdBifrost));
      dispatch(setMessage(i18n.t("message.requestSuccess"), 'success'));
    }
  } catch (error) {
    console.error('newProductRequest: ', error);
    dispatch(setLoader(false));
    dispatch(setMessage(i18n.t("message.errorRequestSuccess"), 'error'));
  }
};