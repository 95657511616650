import React from 'react';
import { Ads } from '../components/shared/Ads';
import { Col } from 'react-bootstrap';
import { GetStarted } from '../components/user/GetStarted';

/**
 * Get started page
 * @returns {JSX}
 */
const GetStartedPage = () => {
  return (
    <>
      <Col className="p-0" xs={12} md={6} xl={7}>
        <GetStarted />
      </Col>
      <Col className="p-0 d-none d-md-block" md={6} xl={5}>
        <Ads />
      </Col>
    </>
  );
};

export default GetStartedPage;