import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { theme } from '../../../theme';

const { general } = theme;

// Define element styles for the component
const StyledLabel = styled(Form.Label)`
  color: ${general};
  margin-bottom: 0;
`;

/**
 * Label component
 * @component
 * @param {Element} children
 * @returns
 */
export const Label = ({ children, ...props }) => {
  return <StyledLabel { ...props }>{ children }</StyledLabel>;
};

Label.propTypes = {
  children: PropTypes.any
};