import { getAproxUserGeolocation, saveRequestToken, setMessage } from '../redux/actions';
import { endpoints, envSetting } from '../constants';
import {
  getDevice,
  getRequest,
  handleRequest,
  isUndefinedNull,
  postFileRequest,
  trimLowerCase
} from '../helpers';
import { store } from '../redux/store/store';
import { BASE_DEVICE_INFO } from '../redux/types';
import i18n from '../translations/i18n';

/**
 * Call request for save user file
 * @param {String} userIdBifrost
 * @param {Object} payload
 * @param {Boolean} isImagesFull
 * @returns
 */
export const saveUserFile = async (userIdBifrost, payload, isImagesFull) => {
  try {
    const endpoint = endpoints.saveUserFileFull(userIdBifrost)
    const response = await postFileRequest(endpoint, payload, true);
    return response;
  } catch (error) {
    console.error('saveUserFile: ', error);
  }
};

/**
 * Call request for save user file
 * @param {Object} payload
 * @returns
 */
export const downloadFile = async payload => {
  try {
    const response = await getRequest(endpoints.downloadFile(payload), '', true);
    return response;
  } catch (error) {
    console.error('downloadFile: ', error);
  }
};

/**
 * Call request for get request token
 * @returns
 */
export const getRequestToken = async () => {
  try {
    let { actualLanguage, geolocation, deviceInfo } = store.getState().base;

    // Commented call to 'getUserGeolocation'
    if (isUndefinedNull(geolocation)) {
      const responseGeolocation = await store.dispatch(getAproxUserGeolocation())
    }

    if (isUndefinedNull(deviceInfo)) {
      const responseDevice = await getDevice();
      store.dispatch(handleRequest(responseDevice));
      if (responseDevice && !responseDevice.error) {
        deviceInfo = responseDevice;
        store.dispatch({ type: BASE_DEVICE_INFO, payload: deviceInfo });
      }
    }

    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Accept-Language': trimLowerCase(actualLanguage)
    };
    const url = `${envSetting.wisenrollApi}${endpoints.loginToken}`;
    const requestInit = {
      method: 'POST',
      headers,
      body: JSON.stringify({
        'username': envSetting.apiUser,
        'password': envSetting.apiPassword,
      })
    };
    const response = await fetch(url, requestInit);
    const result = await response.json();
    if (result && !result.error && result.success) {
      saveRequestToken(result.success.token);
      return result.success.token;
    }
    return;
  } catch (error) {
    console.error('getRequestToken: ', error);
    store.dispatch(setMessage(i18n.t("message.errorRequestToken"), 'error'));
    return;
  }
};