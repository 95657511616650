import { useState, useEffect } from "react"
import { Button } from "../components/shared/simple"
import { fieldAttribute, notUndefinedNull } from "../helpers"
import { RecordRTCPromisesHandler, invokeSaveAsDialog, getSeekableBlob } from 'recordrtc'
import { setMessage } from "../redux/actions"
import { IoFilm, IoSave, IoPause, IoPlay, IoDownload, IoCheckmark, IoClose } from 'react-icons/io5'
import { BtnContainer, InputFileExport, LabelButtonUploadFile } from "../components/user/styled"
import { useSelector } from "react-redux"
import { useTextLanguage } from "./useShared"
import { sendData } from "../helpers"
import { t } from 'i18next'
import { envSetting, mediaVideoAccept, recorderStatus, browserNames } from "../constants"
import { useFormContext } from "react-hook-form"
import { stopBothVideoAndAudio } from "../helpers"
import { useFile } from "./useFile"

/**
 * Hook to control the Dialog Modal for the VideoInputRTC component (web)
 * @returns
 */
export const useVideoModal = (field, setValue, payload, unsetPeerRecording, browserInfo, OS) => {
	const [isOpen, setIsOpen] = useState(false)
	// Handlig recorder logic, after lifting the state up
	const uniqueName = `${field.unique_name || field.file_name}__id__${field.id}`
	const [status, setStatus] = useState('inactive') // 'inactive' |'recording' | 'paused' | 'stopped' | 'displaying' (This last one was created, and is not included in the recordRTC API)
	let buttons
	const [recorder, setRecorder] = useState(null)
	const [savedBlob, setSavedBlob] = useState(null)
	const { onChange } = useFile(field, 'video');
	// Handling browser's permissions for camera
	const [stream, setStream] = useState(null)
	// Handling video size in real time
	const [currentVideoSize, setCurrentVideoSize] = useState(0)
	const { getValues } = useFormContext()
	const [mimetype, setMimetype] = useState('video/webm;codecs=vp9')

  useEffect(() => {
    if(browserInfo?.name === browserNames.safari) setMimetype('video/webm;codecs=vp8')
  }, [browserInfo?.name])

	const handleOpen = () => {
		setIsOpen(true)
	}

	const handleClose = async () => {
    if(status !== recorderStatus.stopped && status !== recorderStatus.receivedFromQR) setStatus(recorderStatus.inactive) // Reset status, if not 'stopped'
    if (recorder && (status !== recorderStatus.stopped)){ // Avoid destroying recorder if status === 'stopped'
      try {
        await recorder?.destroy() 
      } catch (error) {
        console.error(error)
      }
    }      
    stopBothVideoAndAudio(stream)
    setCurrentVideoSize(0)
    // Close videomodal
    setIsOpen(false)
  }

	// Sets video preview when openning the modal (in case there's a previously recorded video)
	useEffect(() => {
		setTimeout(() => {
			if((status === recorderStatus.stopped)){
				const modalPreviewVideo = fieldAttribute('data-video', `video-${uniqueName}-modal-preview`)
				const videoIntructions = fieldAttribute('data-video', `video-instructions`)
				try {
					const file = new File([savedBlob], 'auto_video_preview', { type: 'video/mp4' })
					if(modalPreviewVideo){
						modalPreviewVideo.classList.remove('d-none')
						videoIntructions.classList.add('d-none')
						modalPreviewVideo.src = URL.createObjectURL(file)
						modalPreviewVideo.pause()
						modalPreviewVideo.controls = true
					}
				} catch (error) {
					console.error(error)
				}
			}
			if (payload?.data && getValues(uniqueName) ){
				const modalPreviewVideo = fieldAttribute('data-video', `video-${uniqueName}-modal-preview`)
				const videoIntructions = fieldAttribute('data-video', `video-instructions`)
				try {
					const file = new File([payload.data], 'auto_video_preview', { type: 'video/mp4' })
					if(modalPreviewVideo){
						modalPreviewVideo.classList.remove('d-none')
						videoIntructions.classList.add('d-none')
						modalPreviewVideo.src = URL.createObjectURL(file)
						modalPreviewVideo.pause()
						modalPreviewVideo.controls = true
					}
				} catch (error) {
					console.error(error)
				}
			}
		}, 333);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen])

	/* Functions */
	const uploadFromCamera = async () => {
		const video = fieldAttribute('data-unique-name', `video-from-files`)
		const placeholderImage = fieldAttribute('data-image', `image-${uniqueName}`)

		try {
			const input = fieldAttribute('data-unique-name', `${field.unique_name || field.file_name}`)
			const transferer = new DataTransfer()
			const fileVideo = video.files[0]
			setCurrentVideoSize(fileVideo.size)
			const file = new File([fileVideo], 'autovideo', { type: 'video/mp4' })
			transferer.items.add(file)
			setValue(uniqueName, file)
			input.files = transferer.files
			placeholderImage.classList.add('d-none')
			handleClose();
			onChange({ target: { files: video.files } })
		} catch (error) {
			setMessage(error, 'error')
		}
	}

	const showCamera = async () => {
		setCurrentVideoSize(0)

		const video = fieldAttribute('data-video', `video-${uniqueName}`)
		const videoIntructions = fieldAttribute('data-video', `video-instructions`)
		/* Reseting video intructions video */
		const videoIntructionsSrc = videoIntructions.src;
		videoIntructions.src = videoIntructionsSrc;

		try {
			const stream = await navigator.mediaDevices.getUserMedia({
				video: {
					width: 640
				},
				audio: true
			})
			setStream(stream)

			const recorder = new RecordRTCPromisesHandler(stream, { 
				type: 'video',
				timeSlice: 1000,
				mimeType: mimetype
			})
			setRecorder(recorder)
			video.classList.remove('d-none')
			videoIntructions.classList.add('d-none')
			video.srcObject = stream
			setStatus(recorderStatus.displaying)

		} catch (error) {
			console.error('Error encountered: ' + error)
			if(error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError'){
				setMessage(t('videoRecorder.errorMessagePermissionDenied'), 'error')
			}
			if(error.name === 'NotFoundError' || error.name === 'DevicesNotFoundError'){
				setMessage(t('videoRecorder.errorMessageNotConnected'), 'error')
			}
			if(error.name === 'NotReadableError' || error.name === 'TrackStartError'){
				setMessage(t('videoRecorder.errorMessageNotReadableError'), 'error')
			}
		}		
	}

	const startRecording = async () => {
		/* IIFE in order to track file size in real time */
		(async function looper (){
			if(!recorder || await recorder.getState() === recorderStatus.stopped){
				return
			}
			const internal = await recorder.getInternalRecorder()
			if(internal && internal.getArrayOfBlobs){
				const blob = new Blob(internal.getArrayOfBlobs(), {
					type: 'video/mp4'
				})
				setCurrentVideoSize(blob.size)

				if(blob.size >(envSetting.videoSize)){
					stopRecording(recorder)
					return
				}

			}
			setTimeout(looper, 500)
		})()

		recorder.startRecording()
		setStatus(await recorder.getState())
	}

	const pauseRecording = async () => {
		const video = fieldAttribute('data-video', `video-${uniqueName}`)
		video.pause()
		await recorder.pauseRecording()
		setStatus(await recorder.getState())
	}
	
	const resumeRecording = async () => {
		const video = fieldAttribute('data-video', `video-${uniqueName}`)
		video.play()
		await recorder.resumeRecording()
		setStatus(await recorder.getState())
	}

	const cancelRecording = async () => {
		const video = fieldAttribute('data-video', `video-${uniqueName}`)
		const videoIntructions = fieldAttribute('data-video', `video-instructions`)
		const videoPreview = fieldAttribute('data-video', `video-${field.unique_name}-preview`)
		const placeholderImage = fieldAttribute('data-image', `image-${uniqueName}`)
		const modalPreviewVideo = fieldAttribute('data-video', `video-${uniqueName}-modal-preview`)

		video.pause()
		modalPreviewVideo.pause()
		await recorder.pauseRecording()
		if(window.confirm(t('videoRecorder.confirmCancel'))){
			setCurrentVideoSize(0)
			videoPreview.src=''
			videoPreview.classList.add('d-none')
			modalPreviewVideo.classList.add('d-none')
			videoIntructions.classList.remove('d-none')
			video.classList.add('d-none')
			placeholderImage.classList.remove('d-none')
			await recorder.destroy()
			setRecorder(null)
			setValue(uniqueName, '')
			stopBothVideoAndAudio(stream)
			setStatus(recorderStatus.inactive)
		}else{
			if(await recorder.getState() === 'inactive'){
				setStatus(recorderStatus.displaying)
				video.play()
			}else{
				setStatus(await recorder.getState())
			}
		}

	}

	const stopRecording = async () => {
		const video = fieldAttribute('data-video', `video-${uniqueName}`)
		const placeholderImage = fieldAttribute('data-image', `image-${uniqueName}`)
		const videoPreview = fieldAttribute('data-video', `video-${field.unique_name}-preview`)
		const modalPreviewVideo = fieldAttribute('data-video', `video-${uniqueName}-modal-preview`)

		try {
			const input = fieldAttribute('data-unique-name', `${field.unique_name || field.file_name}`)
			video.pause()
			const transferer = new DataTransfer()
			await recorder.stopRecording()
			setStatus(await recorder.getState())
			video.classList.add('d-none')
			const blob = await recorder.getBlob()
			setCurrentVideoSize(blob.size)
			const file = new File([blob], 'autovideo', { type: 'video/mp4' })

			modalPreviewVideo.classList.remove('d-none')
			modalPreviewVideo.pause()
			modalPreviewVideo.controls = true

			// Let's not use 'getSeekableBlob' in Safari, since there seems to be an ongoing issue with it.
			// https://github.com/muaz-khan/RecordRTC/issues/719
			if(browserInfo?.name === browserNames.safari){
				modalPreviewVideo.src = URL.createObjectURL(file)
				setSavedBlob(blob)
			}else{
				getSeekableBlob(blob, (seekableBlob) => {
					const seekableFile = new File([seekableBlob], 'autovideo', { type: 'video/mp4' })
					modalPreviewVideo.src = URL.createObjectURL(seekableFile)
					setSavedBlob(seekableBlob)
				})
			}

			transferer.items.add(file)
			setValue(uniqueName, file)
			input.files = transferer.files
			videoPreview.classList.remove('d-none')
			videoPreview.src = URL.createObjectURL(file)
			placeholderImage.classList.add('d-none')
			stopBothVideoAndAudio(stream)
			setMessage(t('videoRecorder.uploadSuccess'), 'success')
		
		} catch (error) {
			setMessage(error, 'error')
		}
	}

	const downloadRecording = () => {
		invokeSaveAsDialog(savedBlob, 'autovideo_preview')
	}

	/* Styling */
	const btnExtraStyles = {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '8px',
		color: 'white',
		fontWeight: '500'
	}

	/* Here we create the buttons according to the current 'status' */
	switch (status) {
		case 'inactive':
			buttons = (
				<BtnContainer>
					<Button key='0' color='first' type='button' className='btn' onClick={showCamera}>
						{t('videoRecorder.record')}
					</Button>
					{envSetting.showUploadFromFiles === '1' && 
						<LabelButtonUploadFile color={"first"} className="btn">
							<InputFileExport
								accept={ mediaVideoAccept }
								data-action={ field?.action }
								data-category={ `category-${field?.category?.field_category}` }
								data-condition={ field?.parent_condition }
								data-parent={ field?.parent }
								data-type-field={ field?.data_type }
								data-unique-name={ 'video-from-files' }
								id={ field.id }
								key={ field.id }
								name={ 'video-from-files' }
								onChange={ uploadFromCamera }
							/>
							{t('videoRecorder.uploadFile')}
						</LabelButtonUploadFile>
					}
				</BtnContainer>
			)
			break;
		case 'displaying':
				buttons = (
					<BtnContainer>
						<Button key='1' color='first' type='button' className='btn' onClick={startRecording} style={btnExtraStyles}>
							{t('videoRecorder.start')}
							<IoFilm />
						</Button>
					</BtnContainer>
				)
				break;
		case 'recording':
			buttons = (
				<BtnContainer>
					<Button key='2' color='first' type='button' className='btn' onClick={stopRecording} style={btnExtraStyles}>
						{t('videoRecorder.save')} 
						<IoSave />
					</Button>
					<Button key='3' color='third' type='button' className='btn' onClick={pauseRecording} style={btnExtraStyles}>
						<IoPause />
					</Button>
					<Button key='7' color='second' type='button' className='btn' onClick={cancelRecording}  style={btnExtraStyles}>
						<IoClose />
					</Button>
				</BtnContainer>
			)
			break;
		case 'paused':
			buttons = (
				<BtnContainer>
					<Button key='4' color='first' type='button' className='btn' onClick={stopRecording} style={btnExtraStyles}>
						{t('videoRecorder.save')} 
						<IoSave />
					</Button>
					<Button key='5' color='third' type='button' className='btn' onClick={resumeRecording} style={btnExtraStyles}>
						<IoPlay />
					</Button>
					<Button key='6' color='second' type='button' className='btn' onClick={cancelRecording}  style={btnExtraStyles}>
						<IoClose />
					</Button>
				</BtnContainer>
			)
			break;
		case 'stopped':
			buttons = (
				<BtnContainer>
					<Button key='8' color='first' type='button' className='btn' onClick={handleClose}  style={btnExtraStyles}>
						{t('videoRecorder.accept')} 
						<IoCheckmark />
					</Button>
					<Button key='9' color='third' type='button' className='btn' onClick={downloadRecording} style={btnExtraStyles}>
						<IoDownload />
					</Button>
					<Button key='10' color='second' type='button' className='btn' onClick={cancelRecording}  style={btnExtraStyles}>
						<IoClose />
					</Button>
				</BtnContainer>
			)
			break;
		case recorderStatus.receivedFromQR:
			buttons = (
				<BtnContainer>
					<Button key='7' color='first' type='button' className='btn' onClick={handleClose}  style={btnExtraStyles}>
						{t('videoRecorder.accept')} 
						<IoCheckmark />
					</Button>
					<Button key='8' color='second' type='button' className='btn' onClick={unsetPeerRecording}  style={btnExtraStyles}>
						{t('videoRecorder.cancel')} 
						<IoClose />
					</Button>
				</BtnContainer>
			)
			break;

		default:
			break;
	}

	return { 
		isOpen,
		handleOpen,
		handleClose,
		status,
		setStatus,
		buttons, 
		cancelRecording,
		currentVideoSize,
		setCurrentVideoSize,
		recorder
	};
};

/**
 * 
 * Hook to control the recording, using the recordrtc library (web)
 * @returns {Object} An object containing the following functions and state:
 * - {String} userPermission  - Represents the current userPermission status ('granted' | 'denied' | 'prompt')
 * - {String} videoMessage    - String for the user to read and record.
 */
export const useRecord = () => {
	// Handling browser's permissions for camera
	const [userPermission, setUserPermission] = useState(null) // 'granted' | 'denied' | 'prompt'
	// Handling video message
	const { languageText } = useTextLanguage()
	const staticData = useSelector(state => state.staticData[`data${languageText}`])
	let fields
	if (notUndefinedNull(staticData)) fields = staticData.field

	const videoMessageField = fields?.filter(field => field.unique_name === 'file__video_message')?.[0]
	const [videoMessage, setVideoMessage] = useState({})

	/* Effects */
	useEffect(() => {
    const getDataType = () => {
      if (!videoMessage?.message) {
        let getValues = videoMessageField?.function_get_values;
        getValues = (getValues) ? JSON.parse(getValues) : [];
        if (getValues?.apply === 'start' && getValues?.type !== 'endpoint') {
          const response = sendData(getValues?.type, getValues, true)
          setVideoMessage(response?.voice_message)
        }
      }
    };

    getDataType()
  }, [videoMessage, videoMessageField])

	useEffect(() => {
		navigator?.permissions?.query({ name: 'camera' })
			.then((permissionStatus) => { 
				setUserPermission(permissionStatus.state)
			})
	}, [])

	return {
		userPermission,
		videoMessage
	}
}