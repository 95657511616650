import React from 'react';
import PropTypes from 'prop-types';
import { useErrors } from '../../../hooks/useShared';

/**
 * Component to Error
 * @component
 * @param {String} message
 * @returns
 */
export const Error = ({ message }) => {
  const messageError = useErrors(message);

  return (
    <span style={{ color: '#dc3545', fontSize: '.875em', width: '100%' }}>
      { messageError }
    </span>
  );
};

Error.propTypes = {
  message: PropTypes.string.isRequired
};