import styled from 'styled-components';
import { TbFaceIdError } from 'react-icons/tb';

export const Img = styled.img`
  height: 300px;
  width: 600px;
  position: absolute;
  left: 0;
  right: 0;
  top: 30%;
  margin-left: auto;
  margin-right: auto;
`;

//* Styled components for the Error Boundary implementation
export const ErrorImg = styled.img`
  display:block;
  margin: auto;
`

export const AlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  min-height: 100vh;
  justify-content: center;
  caret-color: transparent;
  max-width: 64rem;
  margin: 0 auto;
`;

export const ContentWrapper = styled.div`
  background-color: #f3f4f6; /* bg-gray-100 */
  padding: .5rem 5rem;
  width: 100vw;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  max-width: 32rem;
  margin: 0 auto;
  position:relative;
  // top:-80px;
  padding:0px 10px 10px 10px;
  & > p {
    text-align: center;
  }
  & > div > span {
    font-size: 13px
  }
  & > * {
    width:100%;
    @media screen and (min-width: 768px) {
      width:80%;
    }
  }
  & > img {
    width:80%;
    @media screen and (min-width: 768px) {
      width:60%;
    }
  }
`;

export const LinkButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
`

