import React from 'react';
import PropTypes from 'prop-types';
import { IoIosRemove } from 'react-icons/io';
import styled from 'styled-components';

const Btn = styled.button`
  width:30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding:0;
  border-radius: 100%;
  position: absolute;
  right:20px;
  top:-15px;
`;

/**
 * Component button
 * @component
 * @param {Object} entry
 * @param {Function} handleRemove
 * @returns {JSX.Element}
 */
export const Remove = ({ entry='', handleRemove }) => {
  const handleClick = () => {
    if(entry){
      handleRemove(entry.id);
    } else{
      handleRemove();
    }
  };

  return (
    <Btn className="btn btn-danger" type="button" onClick={handleClick}>
      <IoIosRemove className="fs-2" />
    </Btn>
  );
};

Remove.propTypes = {
  entry: PropTypes.object,
  handleRemove: PropTypes.func.isRequired,
};