import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { Footer } from './components/shared/Footer';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './redux/store/store';
import { PrincipalRoute } from './routers/PrincipalRoute';
import { Provider } from 'react-redux';
import { useDigicert } from './hooks';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ErrorBoundary } from 'react-error-boundary';
import useErrorBoundary from './hooks/useErrorBoundary';

/**
 * Base Template component holding the basic web application
 * @returns {JSX.Element}
 */
const App = () => {
  useDigicert();
  const {
    logError,
    ErrorComponent: fallbackRender
  } = useErrorBoundary()

  return (
    <Provider store={store}>
      <ErrorBoundary fallbackRender={fallbackRender} onError={logError}>
        <PersistGate persistor={persistor}>
          <Container fluid className="h-100">
            <Row className="h-100">
              <PrincipalRoute />
              <Footer />
            </Row>
          </Container>
        </PersistGate>
      </ErrorBoundary>
    </Provider>
  );
};

export default App;