import { useEffect, useState } from "react"
import { setMessage } from "../redux/actions"
import { useDispatch } from "react-redux"
import Peer from 'peerjs'
import { useParams } from "react-router-dom"
import { t } from 'i18next'

/**
 * Custom hook that will handle the peer Connection logic from the QR Modal
 * @date 4/12/2023 - 20:20:03
 * @param {boolean} isOpenQR - Determines if the QR modal is Open, hence the connection is attempted. 
 * @param {function} handleOpenIncomingVideoModal - 
 * 
 * @returns {Object} An object containing the following functions and state:
 * - id - The component's Peer id 
 **/

export const useQRPeer = (isOpenQR, handleOpenIncomingVideoModal) => {
  const [, setPeer] = useState()
  const [id, setId] = useState()
  const [isConnected, setIsConnected] = useState(false)
  const [connectionMap, setConnectionMap] = useState(new Map())
  const dispatcher = useDispatch()

  useEffect(() => {
    const initPeer = () => new Promise((resolve, reject) => {
      if(isConnected) return
      try {
        const qrPeer = new Peer()
        setPeer(qrPeer)
        /* on Open */
        qrPeer.on('open', (id) => {
          // console.log('My peer id is: ' + id)
          setId(id)
          setIsConnected(true)
          resolve(id)
        })
        /* on Error */
        qrPeer.on('error', (err) => {
          console.error(err)
          setIsConnected(false)
          setId(null)
        })
        /* on Connection */
        qrPeer.on('connection', (conn) => {
          // console.log('Incoming connection from: ' + conn.peer)
          setConnectionMap((previousMap) => previousMap.set(conn.peer, conn))
          dispatcher(setMessage(t('videoRecorder.connIncoming'), 'info'))

          /* On data */
          conn.on('data', (data) => {
            // console.log('Data received')
            handleOpenIncomingVideoModal(data)
            setTimeout(() => {
              // console.log('answering...')
              conn.send('Going back')
            }, 1)
          })
        })
      } catch (error) {
        console.error(error)
        reject()
      }
    })
    if (isOpenQR) initPeer()

  }, [isOpenQR])

  return {
    id
  }
}


/**
 * Custom hook that will handle the peer Connection logic on the redirect view
 * @date 13/12/2023 - 20:19:40
 * 
 * @returns {Object} An object containing the following functions and state:
 *   - {Peer} peer - The Peer object responsible for managing WebRTC connections.
 *   - {function}  - initPeer: Initializes the Peer object and establishes a connection.
 *   - {function}  - sendData: Sends data to the connected peer.
 **/
export const useRedirectPeer = () => {  
  const {userPeerId} = useParams()
  const [peer, setPeer] = useState()
  const [, setId] = useState()
  const [isPeerConnected, setIsPeerConnected] = useState(false)
  const [isConnected, setIsConnected] = useState(false)
  const [connectionMap, setConnectionMap] = useState(new Map())
  const [conn, setConn] = useState()
  const dispatcher = useDispatch()

  const [hasResponded, setHasResponded] = useState(false)

  const initPeer = () => new Promise((resolve, reject) => {
    if(isConnected) return
    try {
      const redirectPeer = new Peer()
      setPeer(redirectPeer)
      /* on Open */
      redirectPeer.on('open', (id) => {
        setId(id)
        setIsConnected(true)
        resolve(id)
      })
      /* on Error */
      redirectPeer.on('error', (err) => {
        console.error(err)
        console.log(JSON.parse(JSON.stringify(err)).type)
        if(JSON.parse(JSON.stringify(err)).type === 'peer-unavailable'){
          setMessage(t('videoRecorder.errorPeerUnavailable'), 'error')
        } else {
          setMessage(err.message, 'error')
        }
        setIsConnected(false)
        setId(null)
      })
    } catch (error) {
      console.error(error)
      reject()
    }
  })

  useEffect(() => {
    const connectToPeer = () => new Promise((resolve, reject) => {
      if(!peer) reject(new Error(t('videoRecorder.errorNoPeer')))
      if(connectionMap.has(userPeerId)) reject(new Error("Connection already exists"))
      try {
        const conn = peer?.connect(userPeerId, {reliable: true})
        if (!conn){
          reject(new Error(t('videoRecorder.errorConnectionEstablished')))
        } else {
          /* On Open Conn */
          conn.on('open', () => {
            // console.log('Connected to: ' + userPeerId)
            setConnectionMap((prevMap) => prevMap.set(userPeerId, conn))
            dispatcher(setMessage(t('videoRecorder.connectedToPeer'), 'success'))
            setIsPeerConnected(true)
            resolve()
          })
          /* On Error */
          conn.on('error', (err) => {
            console.error(err)
            reject(err)
          })
        }
      } catch (error) {
        console.error(error)
        reject(error)
      }
    })

    if(isConnected) connectToPeer()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected])

  const sendData = (data, callback, errorCallback) => new Promise((resolve, reject) => {
    if (!connectionMap.has(userPeerId)) {
      dispatcher(setMessage(t("videoRecorder.errorConnectedToPeer"), "error"))
      reject(new Error("Conneciton doesn't exist")) 
      setTimeout(errorCallback, 1000)
      return
    }
    try {
      const conn = connectionMap.get(userPeerId)
      if (conn) {
        setConn(conn)
        conn.send(data)
        /* HERE we control what to do on incomming Data for existing conn. */
        conn.on('data', (data) => {
          if(!hasResponded){
            callback()
            setHasResponded(true)
          }
        })
      }
    } catch (error) {
      console.error(error)
    }
  }) 

  return {
    peer,
    initPeer,
    sendData
  }
}