import { envSetting } from '.';

export const digitalSign = 'digital_sign';
export const videoFile = 'video_file';
export const avatarImage = 'avatar_image';

export const uniqueFileNames = {
  document_file: 'document_file',
  document_file_back: 'document_file_back',
  passport_file: 'passport_file',
  passport_file_back: 'passport_file_back',
  ssn_card: 'ssn_card',
  drivers_license: 'drivers_license',
  spouse_work_letter: 'spouse_work_letter',
  service_receipt: 'service_receipt'
};

export const fileCases = {
  doesNotApply: 'does_not_apply',
  mandatory: 'mandatory',
  optionalOptative: 'optional_optative',
  optionalRequired: 'optional_required'
};

export const typeImageFiles = [
  'image/png',
  'image/jpeg',
  'image/jpg',
  'application/pdf'
];

export const typeVideoFiles = [
  'video/mp4',
  'video/x-m4v',
  'video/quicktime',
  'video/3gpp'
];

export const mediaUploadAccept = 'image/png,image/jpeg,image/jpg,application/pdf';
export const mediaUploadLabel = 'png/jpeg/jpg';

export const mediaVideoAccept = 'video/mp4,video/x-m4v,video/quicktime,video/3gpp';
export const mediaVideoLabel = 'mp4/x-m4v/quicktime/3gpp';

export const mediaAvatar = 'image/png,image/jpeg,image/jpg';

export const mediaPNG = 'image/png';

export const srcImgFile = 'src_img_file';

export const labelMessageFile = 'label_file_type_message';

export const pictureFileEdit = `
  <div class="col-xs-12 col-xl-4 mb-2 mt-4">
    <label class="form-label" style="color: ${envSetting.general}; margin-bottom: 0;">##${labelMessageFile}##</label>
    <figure class="figure">
      <img src="##${srcImgFile}##" class="w-100">
    </picture>
  </div>
`;

export const messageFile = 'src_file_message';
export const dataMessageFile = 'data_file_type_message';

export const waitingFile = `
<div class="col-xs-12 mb-2 col-xl-4" data-file-type="##${dataMessageFile}##">
  <label class="form-label" style="color: ${envSetting.general}; margin-bottom: 0;">##${labelMessageFile}##</label>
  <h6 style="text-align:center; padding:40px; border-radius: 10px; border: 1px solid ${envSetting.general};">
    ##${messageFile}##
  </h6>
</div>
`;

export const waitingVideo = `
<div class="col-xs-12 mb-2" data-file-type="##${dataMessageFile}##">
  <label class="form-label" style="color: ${envSetting.general}; margin-bottom: 0;">##${labelMessageFile}##</label>
  <h6 style="text-align:center; padding:40px; border-radius: 10px; border: 1px solid ${envSetting.general};">
    ##${messageFile}##
  </h6>
</div>
`;

export const signatureFile = `
<div class="col-xs-12 mb-2 col-xl-4 col-md-6 mt-4" data-file-type="##${dataMessageFile}##">
  <label class="form-label" style="color: ${envSetting.general}; margin-bottom: 0;">##${labelMessageFile}##</label>
  <h6 style="text-align:center; padding:40px; border-radius: 10px; border: 1px solid ${envSetting.general};">
    ##${messageFile}##
  </h6>
</div>
`;

export const srcVideoFile = 'src_video_file';

export const videoFileEdit = `
  <label class="form-label" style="color: ${envSetting.general}; margin-bottom: 0;">##${labelMessageFile}##</label>
  <div class="col-xs-12 col-xl-4 mb-2 figure-video">
    <video controls src="##${srcVideoFile}##"></video>
  </div>
`;

export const fileStatus = {
  rejected: 'rejected',
  expired: 'expired'
};

export const nameFormat = {
  fileBack: 'file__back_',
  fileFront: 'file__front_',
  identityData: 'identity_data_',
  identityProtectionData: 'identity_protection_data',
  file: 'file__',
  expirationDate: '__expiration_date',
  description: '__description',
  type: '__type',
  country: '__country',
  state: '__state',
  main: '__main',
  ssn: 'ssn',
  iden: 'iden'
};

export const userFilesCode = {
  digital_sign: 'signature',
  dni_adquired_back: 'idad',
  dni_adquired: 'idad',
  dni_residence_back: 'idre',
  dni_residence: 'idre',
  document_file_back: 'iden',
  document_file: 'iden',
  document_with_photo: 'dopi',
  driver_license_back: 'dl',
  drivers_license: 'dl',
  pasa_adquired: 'paad',
  passport_file: 'paor',
  ssn_card: 'ssn',
  txid: '',
  video_file: 'video',
  videoMessage: 'video_message',
  visa_back: 'visa',
  visa: 'visa'
};

export const requiredFile = {
  country: '__country',
  type: '__type',
  main: '__main',
  description: '__description',
  expirationDate: '__expiration_date',
  state: '__state',
};

// Variable to know limit size in sum sizes files
export const fileSumSizeLimit = Math.round(parseInt(
  envSetting.maxTotalFilesSize) / Math.pow(1024, 2), 2);

export const mainDocument = [
  'ssn',
  'iden',
  'idad',
  'paor',
  'paad',
  'idre',
  'dl',
  'visa',
  'dopi',
  'txid',
  'duns'
];

export const documentType = 'document__type';