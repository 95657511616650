import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { usePhone, useSection } from '../../../hooks';
import { Button, LoaderSpinner, Select, StyledForm } from '../../shared/simple';
import { ButtonCircle } from '../../shared/simple/ButtonCircle';
import { Col, Row } from 'react-bootstrap';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from '../../shared/Input';
import { profile } from '../../../constants';

/**
 * Phone component
 * @component
 * @param {Function} setRequestEdit
 * @param {Function} setRequestTokenForce
 * @returns
 */
export const Phone = ({ setRequestEdit, setRequestTokenForce }) => {
  const { t } = useTranslation();
  const {
    errors,
    handleSubmit,
    loading,
    onSubmit,
    register,
    reset,
    setValue
  } = usePhone(setRequestTokenForce);
  const { cancelEditRequest, editRequest } = useSection(
    profile.cardPhone,
    'editModulePhone',
    reset,
    'moduleFormPhone',
    setRequestEdit,
    loading
  );

  return (
    <>
      <Col xs={12}>
        <StyledForm
          className="d-none p-0 m-0 w-100"
          onSubmit={ handleSubmit(onSubmit) }
          id={ profile.moduleFormPhone }
        >
          <Row>
            <Col className="pt-1 col-xl-6 col-xs-12">
              <Select
                dataName="country"
                id="country_id"
                isRequired={ true }
                label={ t("profile.cellPhoneCountry") }
                message={ errors.phone_number_country?.message }
                name={ register("phone_number_country").name }
                register={ register("phone_number_country") }
                setValue={ setValue }
                text="name"
              />
            </Col>
            <Col className="pt-1 col-xl-6 col-xs-12">
              <Input
                autoComplete="off"
                disabled={ loading }
                isRequiredInput={ true }
                label={ t("profile.cellPhone") }
                message={ errors.phone_number?.message }
                name={ register("phone_number").name }
                register={ register("phone_number") }
                type="text"
              />
            </Col>
          </Row>
          <Row className="justify-content-center">
            {loading
              ? (
                <Col className="pt-1" xs={12}>
                  <LoaderSpinner />
                </Col>
              ) : (
                <>
                  <Col className="pt-1 col-xs-12 col-xl-6">
                    <Button
                      className="btn mb-1"
                      disabled={ loading }
                      color="first"
                      type="submit"
                      name="btn_save_phone"
                    >
                      { t("common.submit") }
                    </Button>
                  </Col>
                  <Col className="pt-1 col-xs-12 col-xl-6">
                    <Button
                      className="btn mb-1"
                      disabled={ loading }
                      onClick={ cancelEditRequest }
                      color="fourth"
                      type="button"
                      name="btn_cancel_phone"
                    >
                      { t("common.cancel") }
                    </Button>
                  </Col>
                </>
              )
            }
          </Row>
        </StyledForm>
      </Col>
      <Col xs={12} className="float-end" id={ profile.editModulePhone }>
        <ButtonCircle
          className="float-end"
          color="fourth"
          onClick={ editRequest }
          title={ t("common.edit") }
          name="btn_edit_phone"
        >
          <FontAwesomeIcon icon={ faPen } />
        </ButtonCircle>
      </Col>
    </>
  );
};

Phone.propTypes = {
  setRequestEdit: PropTypes.func,
  setRequestTokenForce: PropTypes.func
};