import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

/**
 * Component Entry's custom hook
 * @component
 * @param {Number} id
 * @returns
 */
export const useEntry = (id, fields) => {
  /* To control button's availability */
  const [isBlockDisabled, setIsBlockDisabled] = useState(true);
  const [entryNumber, setEntryNumber] = useState('');
  /* In order for our button to be enable by default, when editing */
  const { action } = useSelector(state => state.tier);

  useEffect(() => {
    if (id !== 1) {
      setEntryNumber(`${id}`);
    }
  }, [id]);

  useEffect(() => {
    if (action === 'edit') {
      setIsBlockDisabled(false);
    }
  }, [action]);

  return {
    entryNumber,
    isBlockDisabled,
    setIsBlockDisabled,
    action
  };
};