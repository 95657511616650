import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMenu, useTextLanguage } from '../../../hooks';
import { Div, HTitle, HTier, Tier, ToggleButton } from './styled';
import { faSquare, faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { trimUpperCase } from '../../../helpers';
import { Loading } from '../Loading';
import menuIconDown from '../../../assets/images/shared/arrow-button-down.svg';
import menuIconUp from '../../../assets/images/shared/arrow-button-up.svg';

/**
 * Menu component with container, title, check and tier
 * @component
 * @returns
 */
export const Menu = () => {
  const { description } = useTextLanguage();
  const { t } = useTranslation();
  const {
    isMenuOpen,
    onClick,
    tiers,
    loading,
    heightTier,
    desktop,
    setIsMenuOpen
  } = useMenu();
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Div style={desktop ? {} : {display: 'inline-table', width: '100%'}}>
          {desktop && (
            <HTitle className="d-none d-md-block">
              { t("homepage.menu") }
            </HTitle>
          )}
          <ToggleButton
            style={{ width: '100%' }}
            onClick={ () => setIsMenuOpen(!isMenuOpen) }
          >
            <div style={{
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <HTitle style={{ textAlign: 'center' }}>
                { t("homepage.menu") }
              </HTitle>
              <img
                alt=""
                src={ isMenuOpen ? menuIconUp : menuIconDown }
                style={{ width: 25, height: 20, marginLeft: 10, marginRight: 10 }}
              />
            </div>
          </ToggleButton>
          {isMenuOpen
            ? tiers?.map(tier => (
              (tier.show) ? (
                <Tier
                  color={ tier.main_color }
                  onClick={ () => onClick(tier) }
                  key={ `tier_${tier.unique_name}` }
                  className={ (tier.disabled) && "disabled" }
                  style={{
                    display: isMenuOpen ? "grid" : "none",
                    height: heightTier
                  }}
                  name={`item_${tier.unique_name}` }
                >
                  <HTier className="p-0 m-0 align-middle">
                    {(!tier.checked) ? (
                      <FontAwesomeIcon icon={ faSquare } />
                    ) : (
                      <FontAwesomeIcon icon={ faCheckSquare }/>
                    )}
                    { trimUpperCase(tier[description]) }
                  </HTier>
                </Tier>
              ) : null
            ))
            : null}
        </Div>
      )}
    </>
  );
};