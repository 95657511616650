import { beneficiaryCategory } from '../constants';
import { useSelector } from 'react-redux';
import { fieldUniqueName } from '../helpers';
import { useFormContext } from 'react-hook-form';
import { parentCondition } from '../helpers';
import { hiddenField } from '../constants';

/**
 * Component button
 * @component
 * @param {Function} setEntries
 * @param {Number} entryNumber
 * @returns
 */
export const useRemoveEntry = (setEntries, entryNumber) => {
  const { setValue } = useFormContext();
  const fields = useSelector(
    state => state.tier.tiers.advanced.categories.fields[beneficiaryCategory]);

  const handleRemove = id => {
    /* Resetting values on RHF when a block is deleted */
    const currentFields = fields.filter(field => field.unique_name.includes(
      entryNumber) && !field.unique_name.includes('button'));
    const bothFields = {
      domElements: {},
      adminFields: {}
    };

    currentFields.forEach(currentField => {
      let field = fieldUniqueName(currentField.unique_name);
      if (field) {
        bothFields.domElements[`${field.dataset.uniqueName}__id__${field.id}`] = field;
        bothFields.adminFields[`${field.dataset.uniqueName}__id__${field.id}`] = currentField;
      }
    });

    const domFieldsNames = Object.keys(bothFields.domElements);
    domFieldsNames.forEach(domFieldName => {
      const domField = bothFields.domElements[domFieldName];
      const adminField = bothFields.adminFields[domFieldName];

      if (domFieldName.includes('code')) {
        setValue(domFieldName, hiddenField);
      } else {
        setValue(domFieldName, '');
        parentCondition(domField, adminField, setValue);
      }
    });

    /* Removing entry */
    setEntries(prevEntries => {
      const newEntries = prevEntries.slice().filter(entry => entry.id !== id);
      return newEntries;
    });
  };

  return { handleRemove };
};