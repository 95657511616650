import styled from 'styled-components';
import { Col, Image, ListGroup, Row } from 'react-bootstrap';
import { Link, Logo } from '../shared/simple';
import { theme } from '../../theme';
import { optionColor, optionColorHover } from '../../helpers';

const { sixth, first } = theme;

// Define element styles for the component
export const Div = styled.div`
  background: ${sixth};
  left: 0;
  padding: 150px 0 100px 0;
  position: relative;
  top: 0;

  @media (max-width: 767px) {
    background: ${first};
    padding-bottom: 10px;
    height: 100%;
    padding: 130px 0 100px 0;
  }
`;

export const StepsContainer = styled.div`
width: 100%;
  display: flex;
  align-items: center;
`;

export const StyledCol = styled(Col)`
  padding-left: 10%;

  @media (max-width: 767px) {
    padding: 5%;
  }
`;

export const StyledDiv = styled(Div)`
  @media (max-width: 767px) {
    background: ${sixth};
  }
`;

export const ProductsContainer = styled(Div)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: transparent;
  padding: 0;
  @media (max-width: 767px) {
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const StyledImage = styled(Image)`
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: -webkit-fill-available;
`;

export const ProductItem = styled(Image)`
  height: 130px;
  width: 165px;
  margin: 15px 10px 4px;
  cursor: pointer;
  aspect-ratio: 3/2;
  object-fit: contain;

  @media (max-width: 1200px) {
    height: 100px;
    width: 150px;
  }

  @media (max-width: 767px) {
    width: -webkit-fill-available;
    object-fit: contain;
  }
`;

export const SingleProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor:pointer;
  color: #6c757d;
  font-size:12px;

  @media (min-width: 768px) {
    font-size: 14px;
  }

  p{
    transition: .3s all;
    font-family: 'Roboto', sans-serif;
  }

  &:hover{
    p{
      transition: .3s all;
      text-shadow: .25px .25px #6c757d;
      color: hsl(208, 7%, 10%);
    }
  }

`

export const StyledLink = styled(Link)`
  font-size: inherit;
  cursor: pointer;
`;

export const StyledLogo = styled(Logo)`
  @media (max-width: 767px) {
    left: 0;
    right: 0;
    text-align: center;
    top: 20px;
    z-index: 1001;
  }
`;

export const StyledRow = styled(Row)`
  background: ${sixth};
  border-radius: 20px;
  padding: 30px 15px;
  position: relative;
`;

// Avatar
export const DivAvatar = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 200px;

  @media (max-width: 767px) {
    margin-top: 0px;
    justify-content: flex-start;
  }
`;

export const LogOutButton = styled.div`
 position: absolute;
 right: 20px;
 top: 20px;
 cursor: pointer;
`;

export const Img = styled.img`
  border-radius: 50%;
  position: relative;
  height: 200px;
  width: 200px;

  @media (max-width: 767px) {
    height: 150px;
    width: 150px;
  }
`;

export const CloseImage = styled.img`
  position: absolute;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
`;

export const InputFile = styled.input.attrs({ type: 'file' })`
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  z-index: 100;
  cursor: pointer;

  &:hover,
  &:focus {
    cursor: pointer;
  }
`;

export const InputFileExport = styled.input.attrs({ type: 'file' })`
  display: none;
`;

export const LabelButtonUploadFile = styled.label`
  color: white;
  background-color: ${props => optionColor(props.color)};
  border-color: ${props => optionColor(props.color)};
  margin: 7px 0;
  width: 100%;
  &:hover,
  &:active,
  &:focus,
  &:focus-visible {
    color: white;
    background-color: ${props => optionColorHover(props.color)};
    border-color: ${props => optionColorHover(props.color)};
  }
// `;

export const Upload = styled.div`
  align-self: flex-end;
  background-color: ${first};
  border-radius: 50%;
  border: 3px solid ${sixth};
  height: 50px;
  position: absolute;
  transform: translateX(50px);
  width: 50px;
  cursor: pointer;

  &:hover,
  &:focus {
    cursor: pointer;
  }

  @media (max-width: 767px) {
    transform: translateX(100px);
  }
`;

export const FileButton = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 300px;
  height: 200px;
  border: 1px #c4c4c4 solid;
  border-radius: 10px;
  cursor: pointer;
`;

export const FileIcon = styled.img`
  width: 100%;
  height: 100%;
  padding: 10px;
`;

export const FileIconModal = styled.img`
  width: 100%;
  height: 100%;
  padding: 40px;
`;

export const FileInputContainer = styled.div`
  width: 100%;
`;

export const InputRtc = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -10;
`

export const SectionFront = styled.section`
  height: 100%;
  width: 100%;
  backface-visibility: hidden;
  position: relative;
` 

export const SectionContainer = styled.div`
  transition: all 1s;
  position: relative;
  box-sizing: border-box;
  @media screen and (min-width: 768px){
    max-width: 50vh;
  }
  ol{
    list-style-position: inside;
    padding-inline: 1rem;
  }
`

export const BtnContainer = styled.div`
  display:flex;
  gap: 4px;
  @media screen and (min-width: 992){
    max-width: 80%;
  }
`

export const InstructionSection = styled.section`
  padding-inline: 16px;
`

export const StyledDialogTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledMobileOption = styled.div`
    font-size: 14px;
    margin-bottom: 1em;
    bottom: 0;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
`

export const SignatureClearButton = styled.button`
  background: transparent;
  border: transparent;
  position: absolute;
  left: 5px;
  bottom: 5px;
`;

export const ListItem = styled(ListGroup.Item)`
  padding: 20px !important;

  &:active,
  &.active,
  &.list-group-item.active {
    background-color: ${first} !important;
    border-color: ${first} !important;
    color: white;
    z-index: unset;
  }
`;

export const FileVideo = styled.video`
  width: 100%;
  height: 100%;
  padding: 10px;
`;
