import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form } from 'react-bootstrap';
import {
  InputFile,
  FileButton,
  FileIcon,
  FileInputContainer,
  FileVideo
} from '../../user/styled';
import { t } from 'i18next';
import { useEdit, useEditFiles, useFile, useTextLanguage } from '../../../hooks';
import { Error, Label, TooltipIcon } from '../../shared/simple';
import { useFormContext } from 'react-hook-form';
import documentIconSource from '../../../assets/images/shared/document-file-icon.svg';
import imageIconSource from '../../../assets/images/shared/camera-icon.svg';
import videoIconSource from '../../../assets/images/advance-tier/video-camera.svg';

/**
 * File Input Button component
 * @component
 * @param {Object} field
 * @param {String} accept
 * @param {String} type
 * @param {String} formatAccept
 * @param {Boolean} isVisible    - Only to be used from the Fields component (in the rejected/expired document's view)
 * @returns
 */
export const FileInputButton = ({ field, accept, type, formatAccept, isVisible = true }) => {
  const { description, languageText } = useTextLanguage();
  const { register, formState: { errors } } = useFormContext();
  const { onChange, uniqueName, fileSizeLimit } = useFile(field, type);
  const { colSize } = useEdit(field, isVisible);
  useEditFiles(field);

  return (
    <>
      {field && (
        <>
          {(colSize?.includes('col-before')) && (
            <Col xs={12} key={`col_before_${field.id}`}></Col>
          )}
          <Col
            className={ `mt-4 ${colSize}` }
            data-module={ `module-${field?.category?.field_category}` }
            data-name={ uniqueName }
            id={ `group_field_${field.id}` }
            key={ field.id }
            xs={12}
          >
            <FileInputContainer className="mb-3">
              <Label htmlFor={ field.name || field.id }>
                { field[description] }{ field.required && (
                  <strong className="text-danger">&nbsp;*</strong>) }
                { field?.[`tooltip${languageText}`] && (
                  <TooltipIcon tooltipText={ field[`tooltip${languageText}`] } />) }
              </Label>
              <FileButton>
                <InputFile
                  { ...register(uniqueName) }
                  accept={ accept }
                  data-action={ field?.action }
                  data-category={ `category-${field?.category?.field_category}` }
                  data-condition={ field?.parent_condition }
                  data-parent={ field?.parent }
                  data-type-field={ field?.data_type }
                  data-unique-name={ field.unique_name }
                  id={ field.id }
                  key={ field.id }
                  name={ uniqueName }
                  onChange={ onChange }
                />
                <FileIcon
                  src={(type === "image")
                    ? imageIconSource
                    : (type === "document") ? documentIconSource : videoIconSource}
                  data-image={ `image-${field.unique_name}` }
                />
                <FileVideo
                  data-video={ `video-${field.unique_name}` }
                  className="d-none"
                />
              </FileButton>
              <Form.Text muted>
                <strong className="text-danger">&nbsp;*&nbsp;</strong>
                { t("files.fileSizeLimit", { size: fileSizeLimit }) }
              </Form.Text><br />
              <Form.Text muted>
                { t("files.formatAccept", { accept: formatAccept }) }
              </Form.Text><br />
              {errors?.[uniqueName]?.message && (
                <Error message={ errors[uniqueName].message } />
              )}
            </FileInputContainer>
          </Col>
        </>
      )}
    </>
  );
};

FileInputButton.propTypes = {
  field: PropTypes.object,
  accept: PropTypes.string,
  type: PropTypes.string,
  formatAccept: PropTypes.string,
};