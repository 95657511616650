import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useFormContext } from 'react-hook-form';
import {
  defaultValuesToken,
  envSetting,
  paramsNext,
  profile,
  routes,
  schemaToken,
  siteMap
} from '../constants';
import {
  getValidateSms,
  tierMigratable,
  trimLowerCase,
  validateRequestType
} from '../helpers';
import {
  getPersonData,
  sendEditionToken,
  sendEmailToken,
  sendSmsToken,
  setLoader,
  setLogout,
  setMessage,
  setDelay,
  smsToken,
  validateEditionToken,
  validateEmailToken,
  validateSmsToken,
  migrateBifrost,
  updatePersonData,
  sendCheckUserTier,
  getRejectedFiles,
  setNavigate,
  personData,
  setTier
} from '../redux/actions';
import { TIER_CATEGORY, USER_VALIDATED_PHONE } from '../redux/types';
import IdleTimer from '../helpers/IdleTimer';

/**
 * Hook to get the values to CardModal
 * @param {String} codeModal
 * @param {Object} setModalOpen
 * @returns
 */
export const useTokenProfile = (codeModal, setModalOpen) => {
  const dispatch = useDispatch();
  const minutesToken = envSetting.minutesToken;
  const { t } = useTranslation();
  const { moduleProfile, userIdBifrost, lastProductRequestId } = useSelector(state => state.user);
  const { loading } = useSelector(state => state.base);
  const { register, formState: { errors }, handleSubmit, reset } = useForm({
    resolver: yupResolver(schemaToken),
    defaultValues: defaultValuesToken
  });
  const [message, setMessageToken] = useState('');

  useEffect(() => {
    if (codeModal === profile.requestEdit) {
      setMessageToken(t("profile.requestEdit"));
    }
    if (codeModal === profile.requestTokenForce) {
      if (moduleProfile === profile.cardPhone) {
        setMessageToken(t("profile.requestTokenForcePhone"));
      }
      if (moduleProfile === profile.cardEmail) {
        setMessageToken(t("profile.requestTokenForceEmail"));
      }
    }
  }, [codeModal, moduleProfile]);

  const messageExpired = () => {
    setModalOpen(false);
    dispatch(setMessage(t("common.tokenExpired"), 'error'));
  };

  const resendToken = () => {
    reset();
    if (codeModal === profile.requestEdit) {
      dispatch(setLoader(true));
      dispatch(sendEditionToken({
        'bifrost_user_id': userIdBifrost
      }))
        .then(response => (response) && document.getElementById(
          profile.resetTimer).click())
        .catch(error => console.error('resendToken in useTokenProfile: ', error));
      dispatch(setLoader(false));
    }

    if (codeModal === profile.requestTokenForce) {
      if (moduleProfile === profile.cardPhone) {
        dispatch(setLoader(true));
        const phoneNumber = document.querySelector(
          `[name="${profile.phoneNumber}"]`).value;
        const phoneNumberCountry = document.querySelector(
          `[name="${profile.phoneNumberCountry}"]`).value;
        dispatch(smsToken(phoneNumberCountry, phoneNumber, userIdBifrost))
          .then(response => (response) && document.getElementById(
            profile.resetTimer).click())
          .catch(error => console.error('resendToken in useTokenProfile: ', error));
        dispatch(setLoader(false));
      }
      if (moduleProfile === profile.cardEmail) {
        dispatch(setLoader(true));
        const email = document.querySelector(`[name="${profile.email}"]`).value;
        dispatch(sendEmailToken({
          email,
          bifrost_user_id: userIdBifrost
        }))
          .then(response => (response) && document.getElementById(
            profile.resetTimer).click())
          .catch(error => console.error('resendToken in useTokenProfile: ', error));
        dispatch(setLoader(false));
      }
    }
  };

  const handleFields = valid => {
    let moduleForm, editModule;

    if (moduleProfile === profile.cardEmail) {
      moduleForm = document.getElementById(profile.moduleFormEmail);
      editModule = document.getElementById(profile.editModuleEmail);
    }
    if (moduleProfile === profile.cardPhone) {
      moduleForm = document.getElementById(profile.moduleFormPhone);
      editModule = document.getElementById(profile.editModulePhone);
    }

    if (valid) {
      if (codeModal === profile.requestEdit) {
        editModule.classList.add('d-none');
        moduleForm.classList.remove('d-none');
      }
      if (codeModal === profile.requestTokenForce) {
        moduleForm.classList.add('d-none');
        editModule.classList.remove('d-none');
        dispatch(getPersonData(userIdBifrost, lastProductRequestId));
      }
      setModalOpen(false);
    } else {
      if (codeModal === profile.requestEdit) {
        moduleForm.classList.add('d-none');
        editModule.classList.remove('d-none');
      }
      if (codeModal === profile.requestTokenForce) {
        editModule.classList.add('d-none');
        moduleForm.classList.remove('d-none');
      }
    }
  };

  const onSubmit = async data => {
    if (codeModal === profile.requestEdit) {
      dispatch(setLoader(true));
      reset();
      dispatch(validateEditionToken({
        'token': data.token,
        'bifrost_user_id': userIdBifrost
      }))
        .then(({ error }) => handleFields(!error))
        .catch(error => handleFields(error));
      dispatch(setLoader(false));
    }
    if (codeModal === profile.requestTokenForce) {
      reset();
      dispatch(setLoader(true));
      if (moduleProfile === profile.cardPhone) {
        dispatch(validateSmsToken({
          'token': data.token,
          'bifrost_user_id': userIdBifrost
        }))
          .then(({ error }) => handleFields(!error))
          .catch(error => handleFields(error));
      }
      if (moduleProfile === profile.cardEmail) {
        dispatch(validateEmailToken({
          'token': data.token,
          'bifrost_user_id': userIdBifrost
        }))
          .then(({ error }) => handleFields(!error))
          .catch(error => handleFields(error));
      }
      dispatch(setLoader(false));
    }
  };

  return {
    errors,
    handleSubmit,
    loading,
    message,
    messageExpired,
    minutesToken,
    onSubmit,
    register,
    resendToken
  };
};

/**
 * Token to tier
 * @param {Boolean} openModal
 * @param {Object} tier
 * @param {Object} tierFieldState
 * @returns
 */
export const useTokenTier = (openModal, tier, tierFieldState) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getValues } = useFormContext();
  const { t } = useTranslation();
  const { requestLanguage, location } = useSelector(state => state.base);
  const { nextTier } = useSelector(state => state.tier);
  const { field: fields } = useSelector(state => state.staticData[
    `data_${trimLowerCase(requestLanguage)}`]);
  const { data: { id: userId } } = useSelector(state => state.personData);
  const { isValidatedPhone, lastProductRequestId, userIdBifrost } = useSelector(state => state.user);
  const [loading, setLoading] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const tokenSms = fields.filter(item =>
    trimLowerCase(item.unique_name) === 'modal__token').pop();

  useEffect(() => {
    if (openModal > 1) setModalIsOpen(true);
  }, [openModal]);

  const submitModal = async () => {
    try {
      dispatch(setLoader(true));
      const values = getValues();
      const nameToken = Array.from(Object.keys(values)).filter(
        elem => elem.includes('token')).pop();

      if (values[nameToken]) {
        setLoading(true);
        const response = await dispatch(validateSmsToken({
          'token': values[nameToken],
          'bifrost_user_id': userIdBifrost
        }));
        if (!response.error) {
          setModalIsOpen(false);
          setShowTimer(true);
          dispatch({ type: USER_VALIDATED_PHONE, payload: true });
          const formObject = validateRequestType(values, tierFieldState, userId);
          const migratable = tierMigratable(formObject.migratable, tier, false);
          if (formObject.isElement || formObject.isMedia) {
            dispatch(setLoader(true));
            formObject.elements['country_code'] = location;
            const response = await dispatch(personData(
              formObject, userIdBifrost, lastProductRequestId));
            if (response && !response.error) {
              if (nextTier?.verification_level) {
                if (tier.verification_level > nextTier.verification_level) dispatch(
                  setTier(tier));
              } else {
                dispatch(setTier(tier));
              }
              let result = await dispatch(migrateBifrost(
                userIdBifrost, migratable, userId, lastProductRequestId));
              if (result && !result.error) {
                if (await dispatch(updatePersonData(userIdBifrost, lastProductRequestId))) {
                  await dispatch(sendCheckUserTier());
                  dispatch(getRejectedFiles());
                  dispatch(setLoader(false));
                  dispatch(setDelay(false));
                  setLoading(false);
                  setShowTimer(false);
                  navigate(routes.completed(paramsNext));
                }
              } else {
                dispatch(setNavigate(false));
                dispatch(setLoader(false));
                dispatch(setDelay(false));
              }
            } else {
              if (!nextTier?.verification_level) dispatch(setTier([]));
              dispatch(setLoader(false));
              dispatch(setDelay(false));
            }
          } else {
            navigate(routes.completed(paramsNext));
          }
        }
        setLoading(false);
        dispatch(setLoader(false));
        dispatch(setDelay(false));
      } else {
        dispatch(setMessage(t("common.mustToken"), 'error'));
        dispatch(setLoader(false));
        dispatch(setDelay(false));
      }
    } catch (error) {
      console.error('submitModal in useTokenTier: ', error);
      dispatch(setMessage(t("message.errorSubmitToken"), 'error'));
      setShowTimer(false);
      setLoading(false);
      dispatch(setLoader(false));
      dispatch(setDelay(false));
      setModalIsOpen(false);
    }
  };

  const sendToken = async () => {
    try {
      dispatch(setLoader(true));
      setLoading(true);
      setShowTimer(false);
      const values = getValues();

      if (isValidatedPhone) {
        setLoading(false);
        dispatch(setLoader(false));
        setModalIsOpen(false);
        return;
      } else {
        const { phoneNumber, countryCode } = getValidateSms(values);
        if (phoneNumber && countryCode) {
          const response = await dispatch(sendSmsToken({
            'phone_number': phoneNumber,
            'country_code': countryCode,
            'bifrost_user_id': userIdBifrost
          }));
          if (response && !response.error) {
            dispatch({ type: TIER_CATEGORY, payload: [] });
            setShowTimer(true);
            setLoading(false);
            dispatch(setLoader(false));
          } else {
            setModalIsOpen(false);
            setShowTimer(false);
            setLoading(false);
            dispatch(setLoader(false));
          }
        }
      }
    } catch (error) {
      setShowTimer(false);
      setLoading(false);
      dispatch(setLoader(false));
      setModalIsOpen(false);
      dispatch(setMessage(t("message.errorSendSmsToken"), 'error'));
      console.error('sendToken in useTokenTier: ', error);
    }
  };

  return {
    loading,
    modalIsOpen,
    sendToken,
    setModalIsOpen,
    showTimer,
    submitModal,
    tokenSms
  };
};

/**
 * Hook to auto logout
 */
export const useAutoLogout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (localStorage.getItem('_expiredTime')) localStorage.removeItem('_expiredTime');
    const timer = new IdleTimer({
      timeout: envSetting.expirationTime, // expire after amount of setted seconds
      onTimeout: () => {
        dispatch(setLogout());
        navigate(siteMap.login.path2);
        dispatch(setMessage(t("common.loginToken"), 'error'));
      },
      onExpired: () => {
        // do something if expired on load
        dispatch(setLogout());
        navigate(siteMap.login.path2);
        dispatch(setMessage(t("common.loginToken"), 'error'));
      },
      onExpiredSession: () => {
        // show modal 1 minute before session expiress
        dispatch(setMessage(t("common.sessionExpired"), 'warning', true));
      }
    });

    return () => {
      timer.cleanUp();
    };
  }, []);
};