import {
  fieldAttribute,
  fieldUniqueName,
  isEmpty,
  isUndefinedNull,
  notUndefinedNull,
  sendFormatDate,
  trimLowerCase
} from './shared';
import { store } from '../redux/store/store';
import { saveFieldValue, sendData } from './base';
import {
  envSetting,
  hiddenCode,
  hiddenField,
  hiddenNumber,
  optionEdit,
  paramsEdit
} from '../constants';
import { formatValue } from 'react-currency-input-field';
import {
  addHide,
  getValueParent,
  conditionTrue,
  getCondition,
  removeHide,
  conditionFalse
} from './conditions';
import { moduleCategory } from './tier';

/**** Export functions  ****/
/**
 * Add value in field
 * @param {Object} field
 */
export const addValue = field => {
  const dataType = trimLowerCase(field.data_type);
  const storageData = (field?.storage_data_key) ? JSON.parse(
    field.storage_data_key) : null;
  let value;

  if (notUndefinedNull(storageData)) {
    if (storageData?.type !== 'default') {
      value = getValueEdit(field);

      if (value !== hiddenField && notUndefinedNull(
        value) && value !== hiddenCode && value !== hiddenNumber) {
        value = caseEdit(dataType, field, value);
      }
    }
  }

  if (isUndefinedNull(value)) {
    value = hiddenField;
  }

  return value;
};

/**
 * Get value to storage
 * @param {Object} data
 * @returns
 */
export const getValueEdit = field => {
  const data = (field?.storage_data_key) ? JSON.parse(
    field.storage_data_key) : null;
  const defaultValue = (field?.default_value) ? JSON.parse(
    field.default_value) : null;
  const dataValue = data?.value;
  let value = hiddenField;

  if (notUndefinedNull(data)) {
    if (data.type === 'person_data' && dataValue && data?.documents !== 'true' &&
      data?.files !== 'true' && !data?.conditions) {
      value = valueGeneral(data, dataValue);
    }

    if (data.type === 'text' && dataValue && !data?.conditions) {
      value = data.value;
    }

    if (data.type === 'person_data' && data?.documents === 'true' &&
      data?.files !== 'true' && !data?.conditions) {
      value = valueDocument(data, field);
    }

    if (data.type === 'person_data' && data?.documents !== 'true' &&
      data?.files === 'true' && !data?.conditions) {
      value = valueFile(data);
    }

    if (data.type === 'search' && data?.search && data?.compare && !data?.conditions) {
      value = valueSearchCompare(data);
    }

    if (data.type === 'person_data' && dataValue && data?.conditions) {
      value = valueConditions(data, dataValue);
    }

    if (data.type === 'files') {
      value = valueFilesSelect(field);
    }

    if (data.type === 'person_static') {
      value = valuePersonStatic(dataValue, data);
    }

    if (data.type === hiddenCode) {
      value = hiddenCode;
    }

    if (data.type === hiddenNumber) {
      value = hiddenNumber;
    }
  }

  if (value === hiddenField && defaultValue?.text === hiddenNumber) {
    value = hiddenNumber;
  }
  if (value === hiddenField && defaultValue?.text === hiddenCode) {
    value = hiddenCode;
  }

  return value;
};

/**
 * Get value to storage
 * @param {Object} data
 * @param {String} dataValue
 * @returns
 */
export const valueGeneral = (data, dataValue) => {
  const personData = store.getState().personData.data;
  const base = store.getState().base;
  const { actualLanguage } = base;
  const { [`data_${trimLowerCase(
    actualLanguage)}`]: staticData } = store.getState().staticData;
  let value = hiddenField;
  let index = -1;
  let newValue = '';

  if (data?.name) {
    if (notUndefinedNull(personData[data.name])) {
      if (data?.condition === 'active' && !data?.search) {
        if (Array.from(personData[data.name]).length === 0) {
          personData[data.name] = Array(personData[data.name]);
        }
        index = Array.from(personData[data.name]).findIndex(item => item.active);
      }
      if (data?.condition === 'last' && !data?.search) {
        index = personData[data.name].length - 1;
      }
      if (data?.condition === 'active' && data?.search) {
        index = Array.from(personData[data.name]).findIndex(item => {
          return data.search.every(field => {
            return trimLowerCase(item[field.name]) === trimLowerCase(field.compare);
          }) && item.active;
        });
      }
      if (!data?.condition && data?.search) {
        index = Array.from(personData[data.name]).findIndex(item => {
          return data.search.every(field => {
            return trimLowerCase(item[field.name]) === trimLowerCase(field.compare);
          }) && item.active;
        });
      }

      if (index === -1 && data.set_value) {
        if (data.set_value.type === 'static_data') {
          const valuesData = data.set_value;
          let newValue = Array.from(staticData[valuesData?.name]).filter(elem =>
            trimLowerCase(elem[valuesData?.code]) === trimLowerCase(
              valuesData?.search));
          newValue = newValue?.pop();
          newValue = newValue[valuesData.value];
          return newValue;
        }
      }

      if (notUndefinedNull(personData[data.name][index])) {
        if (data?.parent) {
          if (data?.option) {
            newValue = personData[data.name][index][data.parent][data.option][
              dataValue];
          } else {
            newValue = personData?.[data.name]?.[index]?.[data.parent]?.[dataValue];
          }
        } else {
          newValue = personData[data.name][index][dataValue];
        }
        value = (isEmpty(newValue) || isUndefinedNull(newValue)) ? value : newValue;
      }

      if (index === -1 && notUndefinedNull(personData[data.name][data?.parent])) {
        if (data?.option) {
          newValue = personData[data.name][data.parent][data.option][dataValue];
        } else {
          newValue = personData[data.name][data.parent][dataValue];
        }
        value = (isEmpty(newValue) || isUndefinedNull(newValue)) ? value : newValue;
      }
    }
  } else {
    value = personData[dataValue];
  }
  return value;
};

/**
 * parent condition to edit
 * @param {Object} element
 * @param {Object} field
 * @param {Object} methods
 */
export const parentConditionEdit = (element, field, methods) => {
  const { getValues, setValue } = methods;
  const { tierFieldState } = store.getState().tier;

  const children = Array.from(tierFieldState).filter(
    item => item.parent === field.id);
  children.forEach(child => {
    const data = (child?.storage_data_key) ? JSON.parse(
      child.storage_data_key) : null;
    const show = (child?.action) ? JSON.parse(child.action) : null;
    const condition = (child.parent_condition) ? JSON.parse(
      child.parent_condition) : '';
    const uniqueName = `${child.unique_name}__id__${child.id}`;

    if (notUndefinedNull(data)) {
      if (data?.type !== 'default' && (data?.show || show?.type)) {
        const group = fieldAttribute('id', `group_field_${child.id}`, 'div');
        let elementChild = fieldAttribute('name', uniqueName);
        if (!elementChild?.id) {
          elementChild = fieldAttribute('data-unique-name', child.unique_name);
        }
        const { action, fieldChild, name, parent, result } = getValueParent(
          elementChild, element, field, 'edit');
        const value = getValues(uniqueName);
        let fieldValue = { value: '', name: '', isChange: false };

        if (result.isCondition && show.type === 'show') {
          conditionTrue(
            fieldValue, action, child, parent, false, fieldChild, name, setValue, true);
          if (data?.show === 'false') {
            addHide(elementChild, group, true);
          } else {
            setValue(uniqueName, value)
            if (value === hiddenField) {
              setValue(uniqueName, '');
            }
          }
        } else {
          if(condition?.type !== 'code') conditionFalse(fieldValue, action, child, parent, false, fieldChild, setValue)
          if (!result.isCondition && show.type !== 'hide') {
            addHide(elementChild, group, true);
          }
          if (result.isCondition && show.type === 'hide') {
            addHide(elementChild, group, true);
          }
        }

        if (child.data_type === 'conversion') {
          if (parseInt(elementChild.textContent) === 0) {
            addHide(elementChild, group, true);
          }
        }

        if (condition?.edit) {
          const [edit] = condition.edit;
          const elementField = fieldUniqueName(edit.field);
          const valueField = getValues(elementField.name);
          /* const action = elementField?.dataset?.action ?
                          JSON.parse(elementField?.dataset?.action) : null
          const base = store.getState().base;
          const { actualLanguage } = base;
          const { [`data_${trimLowerCase(
            actualLanguage)}`]: staticData } = store.getState().staticData
          const { country: countries } = staticData           

          // This should be able to work for components Typeahead 
          if(elementField.type === 'text' && action.isTypeahead){
            // In case 'country 
            if(elementField.name.includes('_country')){
              const [option] = countries.filter(country => Number(country.country_id) === Number(valueField))
              if(notUndefinedNull(option)){
                const optionValue = option.codeValue
                let isMatch = edit?.value?.some(value => trimLowerCase(value) === trimLowerCase(optionValue))
                
                if(condition?.type === 'inequality') isMatch = !isMatch
                //debugger
                if(isMatch === false){
                  addHide(elementChild, group, true)
                }
                

              }
            }
          } */

          if (elementField.type === 'select-one') {
            const [option] = Array.from(elementField.children).filter(
              item => trimLowerCase(item.value) === trimLowerCase(valueField));
            if (notUndefinedNull(option)) {
              const valueOption = option.dataset?.[edit.type];
              if (trimLowerCase(valueOption) !== trimLowerCase(edit.value)) {
                addHide(elementChild, group, true);
              }
            }
          }
        }

        const dataParent = elementChild?.dataset?.parent;
        if (dataParent) {
          moduleCategory(dataParent);
        }
      }
    }
  });
};

/**
 * Case edit with action in fields 
 * !! Deprecated
 * @param {Object} parents
 * @param {Object} methods
 */
export const caseEditAction = (parents, methods) => {
  const { tierFieldState } = store.getState().tier;
  const { getValues, setValue } = methods;
  const emptySelectCase = [];

  Array.from(parents).forEach(item => {
    const [field] = Array.from(tierFieldState).filter(
      elem => trimLowerCase(elem.id) === trimLowerCase(item));
    const data = (field?.storage_data_key) ? JSON.parse(
      field.storage_data_key) : null;
    const action = (field?.action) ? JSON.parse(field.action) : null;
    if (field?.unique_name && data?.show !== 'false' && action?.case === paramsEdit) {
      const uniqueName = `${field.unique_name}__id__${field.id}`;
      const element = fieldAttribute('name', uniqueName);
      if (element) {
        const value = trimLowerCase(getValues(element.name));
        if (value !== hiddenField && field) {
          const children = Array.from(tierFieldState).filter(
            item => item.parent === field.id);
          children.forEach(child => {
            const data = (child?.storage_data_key) ? JSON.parse(
              child.storage_data_key) : null;
            const show = (child?.action) ? JSON.parse(child.action) : null;
            const condition = (child.parent_condition) ? JSON.parse(
              child.parent_condition) : '';
            const uniqueName = `${child.unique_name}__id__${child.id}`;

            if (notUndefinedNull(data)) {
              if (data?.type !== 'default' && (data?.show || show?.type)) {
                const group = fieldAttribute('id', `group_field_${child.id}`, 'div');
                const elementChild = fieldAttribute('name', uniqueName);
                const response = getCondition(
                  condition, element, field.data_type, 'edit', null, null, elementChild, child);
                const valueChild = trimLowerCase(getValues(uniqueName));
                    
                if (response.isCondition && data?.show !== 'false') {
                  removeHide(elementChild, group, true);
                  if (valueChild === hiddenField && !condition?.edit) {
                    setValue(uniqueName, '');
                  }
                  if (trimLowerCase(elementChild.type) === 'checkbox') {
                    setValue(uniqueName, (trimLowerCase(valueChild) === 'true'));
                  }
                } else {
                  addHide(elementChild, group, true);
                  if (child.required && (isEmpty(valueChild) || isUndefinedNull(
                    valueChild))) {
                    setValue(uniqueName, hiddenField);
                  }
                }

                if (condition?.edit) {
                  const [edit] = condition.edit;
                  const elementField = fieldUniqueName(edit.field);
                  const valueField = getValues(elementField.name);
                  const action = elementField?.dataset?.action ?
                          JSON.parse(elementField?.dataset?.action) : null
                  const base = store.getState().base;
                  const { actualLanguage } = base;
                  const { [`data_${trimLowerCase(
                    actualLanguage)}`]: staticData } = store.getState().staticData
                  const { country: countries } = staticData                  
                  /* This should be able to work for components Typeahead */
                  if(elementField.type === 'text' && action.isTypeahead){
                    // In case 'country'
                    if(elementField.name.includes('_country')){
                      const [option] = countries.filter(country => Number(country.country_id) === Number(valueField))
                      if(notUndefinedNull(option)){
                        const optionValue = option.codeValue
                        let isMatch = edit?.value?.some(value => trimLowerCase(value) === trimLowerCase(optionValue))
                        
                        if(condition?.type === 'inequality') isMatch = !isMatch
                        if(isMatch === false){
                          addHide(elementChild, group, true)
                          if (child.required && (isEmpty(valueChild) || isUndefinedNull(valueChild))) {
                            setValue(uniqueName, hiddenField)
                          }
                        }                       
                      }
                    }
                  }

                  if (elementField.type === 'select-one') {
                    const [option] = Array.from(elementField.children).filter(
                      item => trimLowerCase(item.value) === trimLowerCase(valueField));
                    if (notUndefinedNull(option)) {
                      const valueOption = option.dataset?.[edit.type];
                      if (trimLowerCase(valueOption) !== trimLowerCase(edit.value)) {
                        addHide(elementChild, group, true);
                        if (child.required && (isEmpty(valueChild) || isUndefinedNull(
                          valueChild))) {
                          setValue(uniqueName, hiddenField);
                        }
                      } else {
                        if (elementChild.type === 'select-one' && (
                          child.required && valueChild === hiddenField)) {
                          addHide(elementChild, group, true);
                          if (isEmpty(valueChild) || isUndefinedNull(valueChild)) {
                            setValue(uniqueName, hiddenField);
                          }
                        } else {
                          if (response.isCondition && data?.show !== 'false' && (
                            valueChild !== hiddenField && notUndefinedNull(
                              valueChild) && !isEmpty(valueChild)) && isEmpty(elementChild.value)) {
                            emptySelectCase.push(child);
                          }
                        }
                      }
                    }
                  }
                }

                if (child.unique_name.includes('_text')) {
                  if (isEmpty(valueChild) || valueChild === hiddenField) {
                    addHide(elementChild, group, true);
                    setValue(uniqueName, hiddenField);
                  }
                  if (valueChild !== elementChild.value && valueChild !== hiddenField) {
                    elementChild.value = valueChild;
                  }
                }

                const dataParent = elementChild?.dataset?.parent;
                if (dataParent) {
                  moduleCategory(dataParent);
                }
              }
            }
          });
        }
      }
    }
  });

  Array.from(emptySelectCase).forEach(child => {
    const uniqueName = `${child.unique_name}__id__${child.id}`;
    const elementChild = fieldAttribute('name', uniqueName);
    const valueChild = trimLowerCase(getValues(uniqueName));
    const getValuesChild = JSON.parse(child.function_get_values);
    const group = fieldAttribute('id', `group_field_${child.id}`, 'div');
    const response = sendData(getValuesChild?.type, getValuesChild, true);

    if (response) {
      const [option] = response.filter(item => trimLowerCase(
        item[getValuesChild.id]) === trimLowerCase(valueChild));
      if (option?.[getValuesChild.id]) {
        let blockOption = optionEdit.replace('##id##', option[getValuesChild.id]);
        blockOption = blockOption.replace('##value##', option[getValuesChild.id]);
        blockOption = blockOption.replace('##text##', option[getValuesChild.value]);
        elementChild.innerHTML = blockOption;
        elementChild.value = option[getValuesChild.id];
      } else {
        addHide(elementChild, group, true);
      }
    }
  });
};

/**** Local functions  ****/
/**
 * Assess cases to edit
 * @param {String} dataType
 * @param {Object} field
 * @param {String} value
 */
const caseEdit = (dataType, field, value) => {
  let response = value;

  if (dataType === 'checkbox_selection') {
    const items = [];
    const nameImages = field.unique_name.split('__').pop();
    Array.from(value).forEach(item => {
      items.push(trimLowerCase(item[nameImages]));
    });

    response = items;
  }

  if (dataType === 'image_selection') {
    const items = [];
    const nameImages = field.unique_name.split('__').pop();
    Array.from(value).forEach(item => {
      const newItem = { user: item.user };
      newItem[nameImages] = item[nameImages];
      items.push(newItem);
    });

    response = items;
  }

  if (dataType === 'radio_selection') {
    const radioSelection = fieldAttribute(
      'data-code', value, `[name="${field.unique_name}__id__${
        field.id}"]`);
    radioSelection.checked = true;
    const { allField:allRadio, newObject } = saveFieldValue(radioSelection, []);
    allRadio.push(newObject);
    response = allRadio;
  }

  if (dataType === 'date') {
    const valueToDate = new Date(value);
    let dateValue = sendFormatDate(field, 'send', valueToDate);
    const fieldMask = (notUndefinedNull(field.mask)) ? JSON.parse(
      field.mask) : null;
    const newDate = isUndefinedNull(dateValue) ? '' : (
      notUndefinedNull(fieldMask) && !fieldMask?.set_hour ?
        dateValue : new Date(`${dateValue} 00:00:00`));
    response = JSON.stringify(newDate);
  }

  if (dataType === 'money') {
    value = Number.parseFloat(value).toFixed(0);
    value = value.replaceAll(',', '').replaceAll('.', '');
    response = formatValue({
      value: value,
      decimalSeparator: '.',
      groupSeparator: ','
    });
  }

  if (dataType === 'conversion') {
    value = Number.parseFloat(value).toFixed(0);
    response = value.replaceAll(',', '').replaceAll('.', '');
  }

  return response;
};

/**
 * Get value to storage with documents
 * @param {Object} data
 * @param {Object} field
 * @returns
 */
const valueDocument = (data, field) => {
  const personData = store.getState().personData.data;
  const condition = (field?.parent_condition) ? JSON.parse(
    field.parent_condition) : null;
  let value = hiddenField;
  let index = -1;
  let newValue = '';

  if (notUndefinedNull(condition) && notUndefinedNull(personData[data.name])) {
    const { type, value:valueCondition, storage } = condition;
    const { actualLanguage } = store.getState().base;
    const staticData = (data?.static) ? store.getState().staticData[
      `data_${trimLowerCase(actualLanguage)}`][ data.static] : null;

    newValue = (notUndefinedNull(staticData)) ? Array.from(staticData).findIndex(
      item => trimLowerCase(item[type]) === trimLowerCase(
        valueCondition)) : value;
    newValue = (notUndefinedNull(staticData)) ? staticData[
      newValue][data.id] : newValue;

    if (data?.condition === 'active') {
      index = Array.from(personData[data.name]).findIndex(item => (item.active && trimLowerCase(item[storage]) === trimLowerCase(newValue)));
    }
    if (data?.condition === 'last') {
      index = Array.from(personData[data.name]).findIndex(item => trimLowerCase(item[storage]) === trimLowerCase(newValue));
    }
    if (notUndefinedNull(personData[data.name][index])) {
      value = personData[data.name][index][data.value];
    }
  }

  return value;
};

/**
 * Get value to storage with files, the values is src
 * @param {Object} data
 * @returns
 */
const valueFile = data => {
  const personData = store.getState().personData.data;
  let value = hiddenField;
  let index = -1;

  if (notUndefinedNull(personData[data.name])) {
    if (data?.condition === 'active') {
      index = Array.from(personData[data.name]).findIndex(item => (
        item.active && trimLowerCase(item[data.id]) === trimLowerCase(
          data.code)));
    }
    if (data?.condition === 'last') {
      index = Array.from(personData[data.name]).findIndex(
        item => trimLowerCase(item[data.id]) === trimLowerCase(data.code));
    }
    if (notUndefinedNull(personData[data.name][index])) {
      value = `${envSetting.wisenrollApi}${personData[data.name][index][
        data.value]}`;
    }
  }

  return value;
};

/**
 * Search and compare values to get final value
 * @param {Object} data
 * @returns
 */
const valueSearchCompare = data => {
  const personData = store.getState().personData.data;
  const { search, compare } = data;
  let valueSearch = hiddenField;
  let value = hiddenField;

  if (search.type === 'person_data') {
    valueSearch = valueGeneral(search, search.value);
  }

  if (compare.type === 'person_data' && notUndefinedNull(personData[compare.name])) {
    Array.from(personData[compare.name]).forEach(item => {
      if (compare?.condition === 'active') {
        if (item[compare.id]) {
          if (item[compare.id] === valueSearch && item.active) value = item[
            compare.value];
        } else {
          const index = Array.from(item).findIndex(
            current => current.active && current[compare.id] === valueSearch);
          value = (notUndefinedNull(item[index])) ? item[index][
            compare.value] : value;
        }
      } else {
        if (item[compare.id]) {
          if (item[compare.id] === valueSearch) value = item[compare.value];
        } else {
          const index = Array.from(item).findIndex(current => current[
            compare.id] === valueSearch);
          value = (notUndefinedNull(item[index])) ? item[index][
            compare.value] : value;
        }
      }
    });
  }

  return value;
};

/**
 * Get value to option
 * @param {Object} data
 * @param {String} dataValue
 * @returns
 */
const valueConditions = (data, dataValue) => {
  const personData = store.getState().personData.data;
  let value = hiddenField;
  let index = -1;
  let newValue = '';

  if (data?.name) {
    if (notUndefinedNull(personData[data.name])) {
      newValue = valueGeneral(data, dataValue);
      index = Array.from(data.conditions).findIndex(
        item => item.value = newValue);
    } else {
      index = Array.from(data.conditions).findIndex(
        item => trimLowerCase(item.value) === hiddenField);
    }
    if (index < 0) {
      index = Array.from(data.conditions).findIndex(
        item => trimLowerCase(item.value) === 'null');
    }
    if (notUndefinedNull(data.conditions[index])) {
      value = data.conditions[index].option;
    }
  }

  return value;
};

/**
 * Get select in checkbox to files
 * @param {Object} field
 * @returns
 */
const valueFilesSelect = field => {
  const personData = store.getState().personData.data;
  const nameSelect = field.unique_name?.split('__').pop();
  let value = [];

  if (personData.documents) {
    Array.from(personData.documents).forEach(item => {
      const document = {};
      document[nameSelect] = item.client_document_type_id;
      value.push(document);
    });
  }

  if (value.length === 0) value = hiddenField;

  return value;
};

/**
 * Get jobs company and branch info
 * @param {Object} field
 * @returns
 */
const valuePersonStatic = (dataValue, data) => {
  const personData = store.getState().personData.data;
  const base = store.getState().base;
  const { actualLanguage } = base;
  const { [`data_${trimLowerCase(
    actualLanguage)}`]:staticData } = store.getState().staticData;
  let value = hiddenField;
  let newValue = '';
  let index = -1;

  if (data?.person_name && data?.static_name) {
    if (notUndefinedNull(personData[data.person_name]) &&
      notUndefinedNull(staticData[data.static_name])) {
      if (data?.condition === 'active' && data?.static_compare) {
        if (Array.from(staticData[data.static_name]).length === 0) {
          staticData[data.static_name] = Array(staticData[data.static_name]);
        }
        index = Array.from(staticData[data.static_name]).findIndex(item =>
          item.active && item[data.static_compare] === personData[data.person_name]);
      }
      if (notUndefinedNull(staticData[data.static_name][index])) {
        if (data?.object === 'true') {
          dataValue = dataValue[0];
          newValue = staticData[data.static_name][index][dataValue.name][dataValue.value];
          if(!newValue?.length && dataValue.value === 'address'){
            /* If we were looking for 'address' but didn't find value. Fallback value here. */
            newValue = staticData?.[data.static_name]?.[index]?.[dataValue.name]?.['main_company_location']?.['street']
          }
        } else {
          newValue = staticData[data.static_name][index][dataValue];
        }
        value = (isEmpty(newValue) || isUndefinedNull(newValue)) ? value : newValue;
      }
    }
  }

  return value;
};