import React, {useRef, useState} from 'react';
import { Label } from '../../../../shared/simple';
import { StyledGroup } from '../../../../shared/Input/styled';
import { StyledSelect } from '../../../styled';
import { Col } from 'react-bootstrap';
import { sOption, hiddenField } from '../../../../../constants';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { editionStatus } from '../../../../../constants';

/**
 * Component select
 * @component
 * @returns
 */
export const Select = ({ entry, setEntries, setWisenrollUser, selectedProduct }) => {
  const requestLanguage = useSelector(state => state.base.requestLanguage);
  const [options] = useState(useSelector(
    state => state.staticData[`data_${requestLanguage.toLowerCase()}`].account_link_types));
  const selectRef = useRef('');

  const { t } = useTranslation();
  const handleChange = () => {
    if ((selectRef.current.selectedIndex === 0)) setWisenrollUser({});
    setEntries(prevEntries => {
      return prevEntries.map(prevEntry => {
        if (prevEntry.order === entry.order) {
          return {
            ...prevEntry,
            client_account_link_type_id: selectRef.current.selectedIndex,
            related_user_wisenroll_code: (selectRef.current.selectedIndex === 0) ? '' : prevEntry.related_user_wisenroll_code,
            authorized_signature: false,
            isValid: (selectRef.current.selectedIndex === 0) ? false : (prevEntry.isValid || false)
          };
        } else {
          return prevEntry;
        }
      });
    });
  };

  return (
    <>
      <Col
        className="col-md-6 col-xl-4"
        xs={12}
      >
        <StyledGroup className="mb-3">
          <Label>
            {t("block.blockType")}
          </Label>
          <StyledSelect
            ref={selectRef}
            name="client_account_link_type_id"
            onChange={ handleChange }
            disabled={selectedProduct.status === editionStatus.pending}
          >
            <option
              value={sOption}
              data-code={sOption}
            >
              { t("common.optionDefault") }
            </option>
            <option
              className="d-none"
              id={ hiddenField }
              value={ hiddenField }
            >
            </option>
            {options.map(option => {
              const isSelected = option.account_link_type_id === entry.client_account_link_type_id;
              return (
                <option
                  key={ option.code }
                  id={ option.code }
                  selected={ isSelected }
                >
                  { (option?.[`description`])
                    ? (option[`description`])
                    : option.account_link_type_id }
                </option>
              );
            })}
          </StyledSelect>
        </StyledGroup>
      </Col>
    </>
  );
};
