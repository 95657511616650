import styled from 'styled-components';
import { theme } from '../../../theme/theme';

const { sixth, first } = theme;

// Define element styles for the component
export const Div = styled.div`
  background: ${first};
  height: 100%;
  left: 0;
  position: relative;
  top: 0;
  @media (max-width: 767px) {
    padding-bottom: 18%;
  }
`;

export const HTitle = styled.h2`
  align-items: center;
  color: ${sixth};
  display: grid;
  height: 5%;
  padding: 5px;
  align-content: center;
  text-align: center;
`;

export const HTier = styled.h1`
  padding-top: 1%;
  align-items: middle;
`;

export const Tier = styled.a`
  align-items: center;
  background-color: #${props => props.color};
  color: #FFF;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: #FFF;
    transition: 200ms ease-in;
  }
`;

export const ToggleButton = styled.button`
  align-items: center;
  background-color: transparent;
  color: #FFF;
  @media (min-width: 768px) {
    display: none;
  };
  text-decoration: none;
  cursor: pointer;
  border: none;
`

export const Check = styled.input.attrs({ type: 'checkbox' })`
  border: 1px solid #eee;
  height: 2rem;
  margin: 1.5rem 1rem 1.5rem 1.5rem !important;
  outline: none;
  width: 2rem;
  padding: 1%;

  &:focus {
    border-color: ${first};
    box-shadow: none;
  }
  @media (max-width: 767px) {
    margin-bottom: 10px
  }
`;