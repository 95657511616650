import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { CurrencyMoneyInput } from '../../shared/simple';
import { Col } from 'react-bootstrap';
import { useDisabled, useEdit, useMoneyInput, useTextLanguage } from '../../../hooks';

/**
 * Component text input to money
 * @component
 * @param {Object} field
 * @returns
 */
export const MoneyInput = ({ field }) => {
  const uniqueName = `${field.unique_name}__id__${field.id}`;
  const { description, languageText } = useTextLanguage();
  const { register, formState: { errors } } = useFormContext();
  const { onChange, type } = useMoneyInput(field,  uniqueName);
  const { colSize } = useEdit(field);
  const { isDisabled } = useDisabled(field);

  return (
    <>
      {field && (
        <>
          {(colSize?.includes('col-before')) && (
            <Col xs={12} key={`col_before_${field.id}`}></Col>
          )}
          <Col
            className={ colSize }
            data-module={ `module-${field?.category?.field_category}` }
            data-name={ uniqueName }
            id={ `group_field_${field.id}` }
            key={ field.id }
            xs={12}
          >
            <CurrencyMoneyInput
              field={ field }
              isRequiredInput={ field?.required }
              label={ field?.[description] }
              message={ errors?.[uniqueName]?.message }
              name={ uniqueName }
              onChange={ onChange }
              placeholder={ field?.[`placeholder${languageText}`] }
              register={ register }
              tooltipText={ field?.[`tooltip${languageText}`] }
              type={ type }
              disabled={isDisabled}
            />
          </Col>
        </>
      )}
    </>
  );
};

MoneyInput.propTypes = {
  field: PropTypes.object.isRequired
};