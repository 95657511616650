import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { trimLowerCase } from '../../../helpers';
import { useLoading } from '../../../hooks';
import { StyledLogo } from '../../tier/styled';
import { Title } from '../simple';
import './styled.css';

/**
 * Loading component
 * @component
 * @returns
 */
export const Loading = () => {
  const { t } = useTranslation();
  const { tier, delay, language, message } = useLoading();
  const { infoProductRequest } = useSelector(state => state.user);

  return (
    <div className="screen-loading-container">
      {(message)
        ? (
          <>
            <div className="message-tier">
              <StyledLogo src={ infoProductRequest?.image_main } />
              <Title className="mt-2">
                { t("common.congratulations") }
              </Title>
              <h3 className="mb-5">
                { t("common.completed", { tier:tier?.[`description${trimLowerCase(language)}`] }) }
              </h3>
              <h3 className="col-12 mt-5 p-0">{ t("message.waitNext") }</h3>
              <h3 className="col-12 p-0">{ t("message.wait") }</h3>
            </div>
          </>
        ) : (
          <>
            <div className="loading-container">
              <div className="item"></div>
              <div className="item"></div>
              <div className="item"></div>
              <div className="item"></div>
            </div>
            { delay && (
              <h3 className="message-delay">{ t("message.loading") }</h3>
            )}
          </>
        )
      }
    </div>
  );
};