import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isUndefinedNull, notUndefinedNull, trimUpperCase } from '../helpers';
import { getAllowedCountries, setRequest } from '../redux/actions';
import {
  BASE_ACTUAL_LANGUAGE,
  BASE_ALLOWED,
  BASE_PARAMS,
  BASE_SELECTED_LANGUAGE
} from '../redux/types';
import i18n from '../translations/i18n';
import { envSetting } from '../constants';

/**
 * Hook to validate users country
 * @returns
 */
export const useAllowedCountries = () => {
  const dispatch = useDispatch();
  const {
    actualLanguage,
    allowed,
    allowedCountries,
    geolocation,
    location,
    request,
    selectedLanguages,
    loading
  } = useSelector(state => state.base);

  useEffect(() => {
    if (!request && notUndefinedNull(actualLanguage) && (isUndefinedNull(
      allowedCountries) || allowedCountries?.length === 0) && notUndefinedNull(location) ) {
      if(!location?.pathname?.includes('logout')){
        dispatch(setRequest(true));
        dispatch(getAllowedCountries());
        dispatch(setRequest(false));
      }
    }
  }, [allowedCountries, actualLanguage, request, dispatch, loading, location]);

  useEffect(() => {
    if (notUndefinedNull(allowedCountries) && notUndefinedNull(
      location) && notUndefinedNull(geolocation) && !request) {
      const result = (allowedCountries.some(country => trimUpperCase(
        country.code) === location));
      if (result !== allowed) dispatch({ type: BASE_ALLOWED, payload: result });
    }
  }, [location, allowedCountries, geolocation, request, allowed, dispatch]);

  useEffect(() => {
    const language = i18n.language.split('-').shift();
    const defaultLanguage = trimUpperCase(envSetting.defaultLanguage)
    const allowedTranslations = Object.keys(i18n.options.resources).map(language => trimUpperCase(language))
    if(allowedTranslations.includes(trimUpperCase(language))){
      if (trimUpperCase(actualLanguage) !== trimUpperCase(trimUpperCase(language))) {
        dispatch({
          type: BASE_ACTUAL_LANGUAGE,
          payload: trimUpperCase(language)
        });
      }
      if (isUndefinedNull(selectedLanguages)) {
        dispatch({ type: BASE_SELECTED_LANGUAGE, payload: [trimUpperCase(language)] });
      } else {
        if (!selectedLanguages.includes(trimUpperCase(language))) {
          selectedLanguages.push(trimUpperCase(language));
          dispatch({
            type: BASE_SELECTED_LANGUAGE,
            payload: [...selectedLanguages]
          });
        }
      }
    } else {
      dispatch({
        type: BASE_ACTUAL_LANGUAGE,
        payload: trimUpperCase(defaultLanguage)
      });
      dispatch(
        { type: BASE_SELECTED_LANGUAGE, 
          payload: [trimUpperCase(defaultLanguage)] 
        }
      );

    }
  }, [actualLanguage]);

  return { allowed };
};

/**
 * Hook to save params in route
 */
export const useRouteParams = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const { route } = useSelector(state => state.base);

  useEffect(() => {
    if (JSON.stringify(route) !== JSON.stringify(params) && notUndefinedNull(
      route) && notUndefinedNull(params)) {
      dispatch({
        type: BASE_PARAMS,
        payload: params
      });
    }
  }, [params, route, dispatch]);
};