import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, Outlet } from 'react-router-dom';
import { siteMap } from '../constants';
import { Logout } from '../components/user/Logout';
import { useRouteParams } from '../hooks';
import { useQRRedirected } from '../hooks/useAutovideoQRPage';

/**
 * Private route
 * @param {Boolean} isAuthenticated
 * @returns
 */
export const PrivateRoute = ({ isAuthenticated }) => {
  useRouteParams();
  useQRRedirected(isAuthenticated)

  return (
    <Logout>
      {(isAuthenticated)
        ? <Outlet />
        : <Navigate to={ siteMap.login.path2 } />}
    </Logout>
  );
};

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired
};