import { endpointNominatim, querysNominatim } from '../constants';
import { publicIpv4 } from 'public-ip';
import { getUserAgent } from 'universal-user-agent';
import { notUndefinedNull } from './shared';
import DeviceDetector from 'device-detector-js';
import { store } from '../redux/store/store';
import { BASE_ACTIVE_LOCATION } from '../redux/types';

/**** Export functions  ****/
/**
 * Get user geolocation
 * @returns
 */
export const getGeolocation = () => {
  return new Promise(async (resolve, rejected) => {
    navigator.permissions.query({ name: 'geolocation' }).then(result => {
      if (result.state === 'denied') rejected();
    });

    navigator.geolocation.getCurrentPosition(async position => {
      try {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        const url = `${endpointNominatim.nominatim}reverse?format=geojson&lat=${
          latitude}&lon=${longitude}`;
        const response = await fetch(url);
        const result = await response.json();
        const [{ properties }] = result.features;
        
        store.dispatch({ 
          type: BASE_ACTIVE_LOCATION, 
          payload: {
            permission:{
              state: 'granted',
              active: true
            }
          }
        })

        if (properties?.address) {
          const geolocation = properties.address;
          const data = {
            country: geolocation.country_code.toUpperCase(),
            state: geolocation.state,
            city: geolocation.city,
            latitude,
            longitude
          };
          resolve({ data });
        } else {
          rejected();
        }
      } catch (error) {
        console.error('getGeolocation ', error);
        rejected();
      }
    });
  });
};

/**
 * Verify browser geolocation permissions
 * @returns
 */
export const verifyGeolocationPermissions = () => {
  return new Promise(async (resolve, rejected) => {
    let permission;
    navigator.permissions.query({ name: 'geolocation' }).then(result => {
      try {
        const state = result.state;
        if (state === 'denied') {
          permission = {
            state,
            active: false
          };
        } else if (state === 'prompt') {
          permission = {
            state,
            active: false
          };
        } else {
          permission = {
            state,
            active: true
          };
        }
        resolve({ permission });
      } catch (error) {
        console.error('verifyGeolocationPermissions', error);
        rejected();
      }
    });
  });
};

/**
 * Search location details by values
 * @param {Object} data
 * @returns
 */
export const searchLocation = data => {
  return new Promise(async (resolve, rejected) => {
    navigator.permissions.query({ name: 'geolocation' }).then(result => {
      if (result.state === 'denied') rejected();
    });

    try {
      const { type, searchName, value } = data;
      let result;
      if (searchName?.length > 0 && querysNominatim.listQuery.includes(
        searchName[0]) && querysNominatim.listQuery.includes(
          searchName[1]) && type === "search") {
        const url = `${endpointNominatim.nominatim}?addressdetails=1&${
          searchName[0]}=${value[0]}&${searchName[1]}=${value[1]}
          &format=json&limit=1`;
        const response = await fetch(url);
        result = await response.json();
        result = result[0];
      } else if (type === "reverse") {
        const url = `${endpointNominatim.nominatim}reverse?format=jsonv2&lat=${
          value.lat}&lon=${value.lon}`;
        const response = await fetch(url);
        result = await response.json();
      }
      if (notUndefinedNull(result)) {
        const data = {
          country: result.address.country_code.toUpperCase(),
          latitude: parseFloat(result.lat),
          longitude: parseFloat(result.lon),
          address: result.display_name,
          postalCode: result.address.postcode
        };
        resolve({ data });
      } else {
        resolve();
      }
    } catch (error) {
      console.error('searchLocation ', error);
      rejected();
    }
  });
};

/**
 * Get user device information
 * @returns
 */
export const getDevice = async () => {
  try {
    const ip = await publicIpv4();
    const userAgent = getUserAgent();
    const deviceDetector = new DeviceDetector();
    const deviceInfo = deviceDetector.parse(userAgent);

    let model = '';
    if (deviceInfo.device.model && deviceInfo.device.brand) {
      model = `${deviceInfo.device.brand}, ${deviceInfo.device.model}`;
    } else if (deviceInfo.device.brand) {
      model = deviceInfo.device.brand;
    }
    const data = {
      ip_address: ip,
      device_model: model ? model : null,
      device_type: deviceInfo.device.type ? deviceInfo.device.type : null,
      browser_name: deviceInfo.client.name ? deviceInfo.client.name : null,
      browser_version: deviceInfo.client.version ? deviceInfo.client.version : null,
      os_name: deviceInfo.os.name ? deviceInfo.os.name : null,
      os_version: deviceInfo.os.version ? deviceInfo.os.version : null
    };

    return data;
  } catch (error) {
    console.error('getDevice: ', error);
  }
};
