import { sortElementInt, trimLowerCase } from './shared';
import { store } from '../redux/store/store';

/**** Export functions  ****/
/**
 * Return tiers active, regular and hide in false for menu
 * @returns
 */
export const userTier = () => {
  const { level:userLevel, appOrigin } = store.getState().user;
  const { requestLanguage } = store.getState().base;
  const {
    tier:tiers,
    application:applications
  } = store.getState().staticData[`data_${trimLowerCase(requestLanguage)}`];

  const [tierApp] = applications?.filter(application =>
    trimLowerCase(application?.unique_name) === trimLowerCase(appOrigin));
  let result = [];

  result = tiers?.filter(
    tier => tier?.active && tier?.regular && !tier?.hide);
  result = sortElementInt(result, 'verification_level');

  result?.forEach((tier, index) => {
    result[index].show = false;
    result[index].disabled = true;
    result[index].edit = false;
    if (parseInt(tier?.verification_level) <= parseInt(tierApp?.tier)) {
      result[index].show = true;
      result[index].disabled = false;
      result[index].edit = false;
      if (parseInt(tier.verification_level) < parseInt(userLevel + 1)) {
        result[index].edit = true;
      }
      if (parseInt(tier.verification_level) > parseInt(userLevel + 1)) {
        result[index].disabled = true;
      }
    }
  });

  return result;
};

/**
 * Assign value for default in checkbox
 * @param {Object} tiers
 * @returns
 */
export const defaultChecked = tiers => {
  const { level:userLevel } = store.getState().user;

  tiers?.forEach((tier, index) => {
    tiers[index].checked = (
      parseInt(userLevel) >= parseInt(tier.verification_level));
  });

  return tiers;
};