import { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { 
  setMessage, getPersonData,
  getStaticData, getUserApplications,
  getUserImages, getBase,
  sendCheckUserTier, getRejectedFiles,
  getProductRequest, setLogout,
} from '../redux/actions'
import { t } from 'i18next'
import { sendPostRequest } from '../services/baseApi'
import { handleRequest, notUndefinedNull } from '../helpers'
import { setLoader } from '../redux/actions'
import { useDispatch } from 'react-redux'
import { USER_LOGIN, USER_APP_ORIGIN, USER_INFO_PRODUCT_REQUEST } from '../redux/types'
import { logoBgAppOrigin, endpoints } from '../constants'

export const useOtpModal = ({ reset }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [otpValues, setOtpValues] = useState(null)
  const { loading } = useSelector(state => state.base)
  const dispatch = useDispatch()

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleOpen = async (receivedValues) => {
    setOtpValues(receivedValues)
    dispatch(setLoader(true));
    reset()

    try {
      const res = await sendPostRequest(receivedValues, endpoints.getOtpToken)
      if(!res.error){
        setIsOpen(true)
      } else{
        if (res.errors) setMessage(res.errors, 'error')
        dispatch(setLoader(false));
      }
  
    } catch (err) {
      console.error(err)
      if (!loading) dispatch(setLoader(false));
    }
  }

  return {
    isOpen,
    handleClose,
    handleOpen,
    otpValues
  }
}


/**
 * Custom hook for the MUIOtpInput component
 * @date 18/3/2024 - 14:19:37
 *
 * @param {{ values; handleClose }} param0
 * @param {Object}   param0.values: loginValues            - Object containing the username and password entered by the user.
 * @param {Function} param0.handleClose: handleCloseModal  - Function that simply handles closing the modal.
 * 
 * @returns {{ 
 *  otp: String; 
 *  handleChange: (newValue: string) => void; 
 *  handleComplete: (value: string) => void; 
 *  otpRef: React.MutableRefObject;
 *  onClose: ({ isLoading }: { isLoading?: boolean; }) => void; 
 * }}
 */
export const useOtpInput = ({ values: loginValues, handleClose: handleCloseModal }) => {
  const [otp, setOtp] = useState('')
  const dispatch = useDispatch()
  const otpRef = useRef(null)
  const { geolocation, deviceInfo  } = useSelector(state => state.base)
  const { applications } = useSelector(state => state.user)

  /**
   * Handles change in otp input.
   * @date 18/3/2024 - 14:11:51
   *
   * @param {String} newValue - OTP-input's value
   */
  const handleChange = (newValue) => {
    setOtp(newValue)
  }

  /**
   * Handles closing the otp modal.
   * @date 18/3/2024 - 13:55:30
   *
   * @param {{ isLoading?: boolean; }} param0  - Object with options
   * @param {boolean} [param0.isLoading=false] - Boolean that specifies whether or not the otp confirmation is loading.
   */
  const onClose = ({ isLoading = false }) => {
    handleCloseModal()
    setOtp('')
    setTimeout(() => {
      if (!isLoading) dispatch(setLoader(false));
    }, 1000);
  }

  /**
   * Function that triggers on otp input completition.
   * It will attempt to log the user in and redirect to the homepage.
   * @date 18/3/2024 - 14:14:22
   *
   * @async
   * @param {String} value - otp token value
   */
  const handleComplete = async (value) => {
    onClose({ isLoading: true })
    try {
      if(notUndefinedNull(geolocation) && notUndefinedNull(deviceInfo)){
        const values = {
          ...loginValues,
          password: value,
          ip_info: {
            ...deviceInfo,
            ...geolocation
          }
        }
        const res = await sendPostRequest(values, endpoints.login);

        dispatch(setLoader(true));
        if(!res.erros && res.success){
          const {
            token,
            info,
            local_user_info: localUserInfo,
            last_product_request_id: lastProductRequestId
          } = res.success

          const appOriginData = await dispatch(getPersonData(info.user_id, lastProductRequestId))
          if(appOriginData) {
            if (await dispatch(getStaticData())) {
              dispatch({
                type: USER_LOGIN,
                payload: {
                  userIdBifrost: info.user_id,
                  username: info.username,
                  token: token,
                  lastProductRequestId: lastProductRequestId
                }
              });
              await dispatch(getUserImages(info.user_id));
              dispatch(getBase());
              if (await dispatch(sendCheckUserTier())) {
                dispatch(getRejectedFiles());
                dispatch({ type: USER_APP_ORIGIN, payload: appOriginData });
                dispatch(getProductRequest(localUserInfo.id));
                dispatch(setLoader(false));
              } else {
                dispatch(setLogout());
                dispatch(setLoader(false));
                dispatch(setMessage(t("message.onLogin"), 'error'));
              }
              let productsAndServices = [];
              if (!applications) {
                productsAndServices = await dispatch(getUserApplications());
              } else {
                productsAndServices = applications;
              }
              let product = productsAndServices.filter(
                product => product?.application?.unique_name === appOriginData);
              if (product.length === 0) product = [{image_main: logoBgAppOrigin.default }];
              const [productRequest] = product;
              dispatch({ type: USER_INFO_PRODUCT_REQUEST, payload: productRequest });

            }
          }
        } else {
          setMessage(t("message.onLogin"), 'error')
          onClose({ isLoading: false })
          setTimeout(() => {
            dispatch(setLoader(false));
          }, 500);
        }


      } else {
        setMessage(t("message.onLogin"), 'error')
        dispatch(setLoader(false));
      }
      
    } catch (err) {
      onClose({isLoading: false})
      console.error(err)
      dispatch(setLoader(false));
    }
   
  }

  return{
    otp,
    handleChange,
    handleComplete,
    otpRef,
    onClose
  }

}

