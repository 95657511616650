import { useEffect } from 'react';
import { useTimer } from 'react-timer-hook';
import { timeMinutes } from '../helpers';

/**
 * use to Timer
 * @param {*} expiryTimestamp
 * @param {Boolean} onExpired
 * @param {String} numberOfMinutes
 * @returns
 */
export const useChronometer = (expiryTimestamp, onExpired, numberOfMinutes) => {
  const { seconds, minutes, start, restart } = useTimer({
    expiryTimestamp,
    onExpire: () => {
      if (onExpired) {
        onExpired();
      }
    }
  });

  useEffect(() => {
    start();
  }, []);

  const resetTime = () => {
    const time = timeMinutes(numberOfMinutes);
    restart(time);
  };

  return { seconds, minutes, resetTime };
};
