import * as bg0 from './statics/img/bg0.png';
import * as bg1 from './statics/img/bg1.png';
import * as bg2 from './statics/img/bg2.png';
import * as bg3 from './statics/img/bg3.png';
import * as bg4 from './statics/img/bg4.png';
import * as bg5 from './statics/img/bg5.png';
import * as bg6 from './statics/img/bg6.png';
import * as bg7 from './statics/img/bg7.png';
import * as bg8 from './statics/img/bg8.png';
import * as bg9 from './statics/img/bg9.png';
import * as puzzle0 from './statics/img/puzzle0.png';
import * as puzzle1 from './statics/img/puzzle1.png';
import * as puzzle2 from './statics/img/puzzle2.png';
import * as puzzle3 from './statics/img/puzzle3.png';
import * as puzzle4 from './statics/img/puzzle4.png';
import * as puzzle5 from './statics/img/puzzle5.png';
import * as puzzle6 from './statics/img/puzzle6.png';
import * as puzzle7 from './statics/img/puzzle7.png';
import * as puzzle8 from './statics/img/puzzle8.png';
import * as puzzle9 from './statics/img/puzzle9.png';

const bgMap = [
  { background: bg0, puzzle: puzzle0 },
  { background: bg1, puzzle: puzzle1 },
  { background: bg2, puzzle: puzzle2 },
  { background: bg3, puzzle: puzzle3 },
  { background: bg4, puzzle: puzzle4 },
  { background: bg5, puzzle: puzzle5 },
  { background: bg6, puzzle: puzzle6 },
  { background: bg7, puzzle: puzzle7 },
  { background: bg8, puzzle: puzzle8 },
  { background: bg9, puzzle: puzzle9 },
];

const getRandomStr = () => Math.floor(Math.random()*9);

const rangeValues = {
  image0: {
    desktop: { start: 0.75, end: 0.85 },
    tablet: { start: 0.65, end: 0.75 },
    mobile: { start: 0.70, end: 0.80 }
  },
  image1: {
    desktop: { start: 0.60, end: 0.70 },
    tablet: { start: 0.50, end: 0.60 },
    mobile: { start: 0.55, end: 0.65 }
  },
  image2: {
    desktop: { start: 0.70, end: 0.80 },
    tablet: { start: 0.60, end: 0.70 },
    mobile: { start: 0.65, end: 0.75 }
  },
  image3: {
    desktop: { start: 0.60, end: 0.70 },
    tablet: { start: 0.50, end: 0.60 },
    mobile: { start: 0.55, end: 0.65 }
  },
  image4: {
    desktop: { start: 0.70, end: 0.80 },
    tablet: { start: 0.60, end: 0.70 },
    mobile: { start: 0.65, end: 0.75 }
  },
  image5: {
    desktop: { start: 0.70, end: 0.80 },
    tablet: { start: 0.60, end: 0.70 },
    mobile: { start: 0.65, end: 0.75 }
  },
  image6: {
    desktop: { start: 0.65, end: 0.75 },
    tablet: { start: 0.55, end: 0.65 },
    mobile: { start: 0.60, end: 0.70 }
  },
  image7: {
    desktop: { start: 0.75, end: 0.85 },
    tablet: { start: 0.65, end: 0.75 },
    mobile: { start: 0.70, end: 0.80 }
  },
  image8: {
    desktop: { start: 0.60, end: 0.70 },
    tablet: { start: 0.50, end: 0.60 },
    mobile: { start: 0.55, end: 0.65 }
  },
  image9: {
    desktop: { start: 0.70, end: 0.80 },
    tablet: { start: 0.60, end: 0.70 },
    mobile: { start: 0.65, end: 0.75 }
  },
}

const validate = async ({ id, distance }) => {
  let percentage = parseFloat(distance);
  let matched = false;
  const selectRange = rangeValues[`image${id}`];
  const range = (window.screen.width <= 500) ? selectRange.mobile : 
                  (window.screen.width > 500 && window.screen.width <= 668 ? selectRange.tablet : selectRange.desktop);

  switch (id) {
    case 0:
      matched = percentage > range.start && percentage < range.end; // 0.80
      break;
    case 1:
      matched = percentage > range.start && percentage < range.end; // 0.65
      break;
    case 2:
      matched = percentage > range.start && percentage < range.end; // 0.75
      break;
    case 3:
      matched = percentage > range.start && percentage < range.end; // 0.65
      break;
    case 4:
      matched = percentage > range.start && percentage < range.end; // 0.75
      break;
    case 5:
      matched = percentage > range.start && percentage < range.end; // 0.75
      break;
    case 6:
      matched = percentage > range.start && percentage < range.end; // 0.70
      break;
    case 7:
      matched = percentage > range.start && percentage < range.end; // 0.80
      break;
    case 8:
      matched = percentage > range.start && percentage < range.end; // 0.65
      break;
    case 9:
      matched = percentage > range.start && percentage < range.end; // 0.75
      break;
    default:
      break;
  }

  return matched ? JSON.stringify({ code: 100 }) : JSON.stringify({ code: -100 });
};

const getPuzzle = async () => {
  const number = getRandomStr();
  const background = bgMap[number].background;
  const puzzle = bgMap[number].puzzle;
  return JSON.stringify({id: number, bgUrl: background, puzzleUrl: puzzle});
};

export { getPuzzle, validate };