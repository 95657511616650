import * as React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import { DialogContent, DialogActions, DialogTitle } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { Row, Col } from 'react-bootstrap';
import { Button } from '../../../../shared/simple';
import { useConditionsDialog } from '../../../../../hooks/useDialog';

export const ConditionsDialog = ({ handleClose, open, handleSubmit, loading }) => {
  const {
    t,
    onClose,
    onSubmit,
    condition1,
    condition2,
    setCondition1,
    setCondition2
  } = useConditionsDialog({ handleClose, handleSubmit });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle id="alert-dialog-title">
        {t("block.conditionsDialogTitle")}
      </DialogTitle>
      <DialogContent>
        <Row>
          <Col xs={12} className="d-flex mb-2">
            <Checkbox
              id="condition1"
              name="condition1"
              onChange={(e) => {
                setCondition1(e.target.checked);
              }}
            />
            <label htmlFor="condition1">
              { t("block.editCondition1") }
            </label>
          </Col>
          <Col xs={12} className="d-flex mb-2">
            <Checkbox
              id="condition2"
              name="condition2"
              onChange={(e) => {
                setCondition2(e.target.checked);
              }}
            />
            <label htmlFor="condition2">
              { t("block.editCondition2") }
            </label>
          </Col>
        </Row>
      </DialogContent>
      <DialogActions className="pb-0 px-4">
        <Button
          className="btn my-0"
          disabled={(condition1 !== true || condition2 !== true) || loading}
          onClick={onSubmit}
          color="first"
          type="button"
        >
          { t("common.accept") }
        </Button>
      </DialogActions>
      <DialogActions className="px-4">
        <Button
            className="btn my-0"
            onClick={ onClose }
            color="fourth"
            type="button"
          >
          { t("common.cancel") }
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConditionsDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};