import { checkBlock } from '../helpers';
import { store } from '../redux/store/store';
import { setLoader } from '../redux/actions';
import { useSelector } from 'react-redux';
import { trimLowerCase } from '../helpers';

/**
 * Component button
 * @component
 * @param {Object} field
 * @param {Function} setWisenrollUser
 * @param {Function} setEntries
 * @param {Number} entryNumber
 * @returns
 */
export const useCheck = ({ entryFields, setWisenrollUser, setEntries, entryNumber }) => {
  const { action } = useSelector(state => state.tier);
  const { code } = useSelector(state => state.user);
  const [field] = entryFields.filter(field => trimLowerCase(field.data_type) === 'button_check');

  const handleCheck = () => {
    store.dispatch(setLoader(true));
    checkBlock(field, setWisenrollUser, setEntries, entryNumber, code);
  };

  return { handleCheck, action };
};