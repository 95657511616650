import { Dialog, DialogTitle, DialogContent, Skeleton } from '@mui/material'
import QRCode from 'react-qr-code'
import { t } from 'i18next'

const QRModal = ({open, urlValue, id, handleCloseQR, handleOpen}) => {

	const handleBack = () => {
		handleCloseQR()
		handleOpen()
	}

  return (
		<Dialog 
			onClose={handleCloseQR} 
			open={open}
		>
			<DialogTitle sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
				{t('videoRecorder.qrTitle')}		
			</DialogTitle>
			<DialogContent sx={{paddingBlock: 0}}>
				<div style={{ height: "auto", margin: "0 auto", maxWidth: 254, width: "100%" }}>
					{ id ? (
						<QRCode
							size={256}
							style={{ height: "auto", maxWidth: "100%", width: "100%" }}
							value={urlValue}
							viewBox={`0 0 256 256`}
							/>
						) : (
							<Skeleton variant="rounded" animation="wave" width={256} height={256} />
						) 
					}
				</div>
			</DialogContent>
			<DialogTitle 
				onClick={handleBack}
				className='text-muted' 
				sx={{
							textAlign: 'center',
							fontSize: '14px',
							cursor: 'pointer',
							'&:hover':{
								textDecoration: 'underline'
							}
						}}
			>
				{t('videoRecorder.webOption')}
			</DialogTitle>
		
		</Dialog>
  )
}

export default QRModal