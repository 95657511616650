import { parentConditionMultiple } from './conditions';
import { endpointDefaultValue, saveFieldValue } from './base';
import {
  fileCases,
  hiddenField,
  nameFormat,
  requiredFile,
  videoFile
} from '../constants';
import { moduleCategory } from './tier';
import { store } from '../redux/store/store';
import {
  fieldUniqueName,
  fieldsUniqueName,
  trimLowerCase,
  notUndefinedNull,
  isUndefinedNull,
  isEmpty
} from './shared';
import { getValueEdit, valueGeneral } from './edit';
import { USER_FILES } from '../redux/types';

/**** Export functions  ****/
/**
 * Validate case to files
 * @param {Object} response    - 'function_get_values'.response (fields: ['document__search_type'])
 * @param {Object} condition   - 'parent_condition              (fields: ['document__search_type'])
 * @param {Function} setValue  - setValue function, from RHF.
 * @param {String} action      - 'default_value'.action         (fields: ['document__search_type'])
 * @param {Object} value       - Successful response of the API document-conditions
 */
export const setFileCases = (
  response,
  condition,
  setValue,
  action,
  value = []
) => {
  const { requestLanguage } = store.getState().base;
  const { [`data_${trimLowerCase(
    requestLanguage)}`]: staticData } = store.getState().staticData;
  let checkSelect = [];
  let checkSetValue = [];
  let mainItem = null;

  if (response?.type === 'static_data') {
    /* StaticData's data. In our use case, it's the 'document_types' */
    const data = staticData?.[response?.name];
    /* Groupal checkbox element */
    const documentTypes = fieldsUniqueName(response?.field);

    if (notUndefinedNull(documentTypes) && condition?.condition && notUndefinedNull(staticData)) {
      const { field: fields } = staticData;
      Array.from(condition.condition).forEach(field => {
        let fieldValue = fields.filter(elem => trimLowerCase(
          elem.unique_name) === trimLowerCase(field.name));
        fieldValue = fieldValue?.pop();
        if (notUndefinedNull(fieldValue) && fieldValue) {
          Array.from(data).forEach(item => {
            // Cases to document type and file
            if (field?.value) {
              const codeName = trimLowerCase(item[field.value]);
              let option = Array.from(documentTypes).filter(elem =>
                trimLowerCase(elem.dataset[field.value]) === codeName);
              option = option?.pop();
              if (notUndefinedNull(option)) {
                const { allField, mainId, allChecks } = searchDocumentType(
                  checkSelect,
                  fieldValue,
                  value,
                  codeName,
                  option,
                  setValue,
                  action,
                  checkSetValue);
                checkSelect = allField;
                checkSetValue = allChecks;
                mainItem = (isUndefinedNull(mainItem)) ? mainId : mainItem;
              }
            }
          });
        }
        if (notUndefinedNull(mainItem)) {
          setValue(mainItem, 'true');
        }
        if (notUndefinedNull(fieldValue?.id)) moduleCategory(fieldValue?.id);
      });
    }
  }
};

/**
 * Handle cases to files
 * @param {Object} field
 * @param {Function} setValue
 * @param {Boolean} isCondition
 */
export const handleFileCases = (field, setValue, isCondition = false) => {
  const actionValue = (notUndefinedNull(field.action)) ? JSON.parse(
    field.action) : null;

  if (isCondition) {
    const functionGetValues = (notUndefinedNull(
      field.function_get_values)) ? JSON.parse(field.function_get_values) : null;
    const condition = (notUndefinedNull(field.parent_condition)) ? JSON.parse(
      field.parent_condition) : null;
    setFileCases(functionGetValues, condition, setValue, actionValue.action);
  } else {
    const { actualLanguage } = store.getState().base;
    const { field: fieldsData } = store.getState().staticData[
      `data_${trimLowerCase(actualLanguage)}`];
    let itemField = Array.from(fieldsData).filter(child => trimLowerCase(
      child.unique_name) === trimLowerCase(actionValue?.name));
    itemField = itemField.pop();
    endpointDefaultValue(itemField, setValue);
  }
};

/**
 * remove validations and values
 * @param {Object} field
 * @param {Object} video
 * @param {Object} category
 * @param {Object} item
 * @param {Object} element
 * @param {Object} categories
 * @returns
 */
export const removeValidations = (
  field,
  video,
  category,
  item,
  element,
  categories
) => {
  // If field is esqual file
  const newField = { ...field };
  const name = newField.unique_name;
  const index = categories.findIndex(current => current.category.id === category.id);
  let isNone = true;

  if (item.field_request && index < 0) {
    if ((item.field_request.id !== element.field) && !(name.includes(
      requiredFile.country) || name.includes(requiredFile.type) || name.includes(
        requiredFile.main) || name.includes(requiredFile.state) || name.includes(
          requiredFile.expirationDate) || name.includes(requiredFile.description))) {
      if (!(item.file_name === videoFile && newField.data_type === 'message')) {
        isNone = false;
        newField.col_size = 'd-none';
        newField.default_value = JSON.stringify(
          { "type": "static", "text": hiddenField });
      }
    }
  }

  if (name.includes(nameFormat.expirationDate) || name.includes(
    nameFormat.description) || name.includes(nameFormat.file) || (name.includes(
      nameFormat.country) && newField.data_type === 'selection')) {
    if (notUndefinedNull(field.col_size) && isNone) {
      newField.col_size = field.col_size.replace('d-none', '');
    }
  }

  const storageDataKey = (notUndefinedNull(field.storage_data_key)) ? JSON.parse(
    field.storage_data_key) : null;

  // Change value to default
  if (notUndefinedNull(storageDataKey) && ((name.includes(
    nameFormat.country) && newField.data_type === 'aux') || (name.includes(
      nameFormat.type) && newField.data_type === 'text') || (name.includes(
        nameFormat.main) && newField.data_type === 'text'))) {
    const value = getValueEdit(newField);
    newField.default_value = JSON.stringify({ "type": "static", "text": `${value}` });
  }

  if (notUndefinedNull(storageDataKey) && !item.show_category) {
    let value = getValueEdit(newField);
    if (value === hiddenField) {
      value = valueGeneral(storageDataKey, storageDataKey?.value);
    }
    newField.default_value = (newField.data_type !== 'media_upload') ? JSON.stringify(
      { "type": "static", "text": `${value}` }) : JSON.stringify(
        { "type": "static", "text": hiddenField });
    newField.col_size = 'd-none';
  }

  const mask = (notUndefinedNull(newField.mask)) ? JSON.parse(newField.mask) : '';
  if (notUndefinedNull(mask) && !isEmpty(mask)) {
    mask.apply = 'start';
    newField.mask = JSON.stringify(mask);
  }

  if (item?.field_request && category.unique_name === trimLowerCase(
    nameFormat.identityProtectionData && item.show_category)) {
    if (item.field_request.id !== element.field) {
      newField.col_size = 'd-none';
      newField.default_value = JSON.stringify(
        { "type": "static", "text": hiddenField });
    }
    if (video.id === element.field) {
      newField.col_size = null;
    }
  }

  return newField;
};

/**** Local functions  ****/
/**
 * Complete name
 * @param {Array} checkSelect  - Empty array. 
 * @param {Object} field       - field object (fields: ['document__search_type'])
 * @param {Object} data        - Succesful response from the API 'document-conditions'
 * @param {String} codeName    - Code for the options. In our use case it will be the code for the current 'document_type'. PE 'paor', 'iden', 'dl' and so on.
 * @param {Object} check       - Checkbox HTML element
 * @param {Function} setValue  - setValue function, from React hoot form.
 * @param {String} action      - 'action' property, coming from an 'action' JSON, in the admin.
 * @param {Array} allChecks    - Empty array.
 * @returns
 */
const searchDocumentType = (
  checkSelect,
  field,
  data,
  codeName,
  check,
  setValue,
  action,
  allChecks
) => {
  let mainItem = fieldUniqueName(`${trimLowerCase(codeName)}__main`);
  let isMain = 'false';

  if (notUndefinedNull(mainItem?.value)) mainItem.value = hiddenField;

  if (data[codeName] !== fileCases.doesNotApply && notUndefinedNull(data[
    codeName]) && notUndefinedNull(check)) {
    if (data[codeName] === fileCases.mandatory) {
      if (action === 'show') {
        isMain = 'true';
        check.checked = true;
        check.disabled = true;
      }
    }
    if (data[codeName] === fileCases.optionalOptative || data[
      codeName] === fileCases.optionalRequired) {
      check.checked = false;
      check.disabled = false;
    }
  } else {
    check.checked = false;
    check.disabled = true;
    if (action === 'hide') {
      check.disabled = false;
    }
  }

  const { allField, newObject, index } = saveFieldValue(check, checkSelect);
  if (check.checked) {
    if (index < 0) {
      allField.push(newObject);
      allChecks.push(check.value);
    }
  } else {
    if (index >= 0) {
      allField.splice(index, 1);
      allChecks.splice(index, 1);
    }
  }

  setValue(check.name, allChecks);
  store.dispatch({ type: USER_FILES, payload: { files: allChecks } });
  parentConditionMultiple(check, field, setValue);

  const mainId = (isMain === 'true' && notUndefinedNull(
    mainItem?.name)) ? mainItem.name : null;

  return { allField, mainId, allChecks };
};