import { handleData, getValue } from './form';
import { handleFileCases } from './files';
import { moduleCategory } from './tier';
import { defaultAux, handleAttributes, putSelect } from './base';
import {
  equalField,
  fieldAttribute,
  fieldsAttribute,
  fieldsUniqueName,
  fieldUniqueName,
  isEmpty,
  isUndefinedNull,
  isIterable,
  notUndefinedNull,
  searchField,
  setMask,
  trimLowerCase
} from './shared';
import { store } from '../redux/store/store';
import {
  buttonClose,
  envSetting,
  hiddenField,
  hiddenNumber,
  paramsAdd,
  endpoints,
  paramsEdit
} from '../constants';
import { sendPostRequest } from '../services/baseApi';
import { setMessage, setLoader } from '../redux/actions';

/**** Export functions  ****/
/**
 * Calls API in order to validate a beneficiary code on the Form, in the creation proccess.
 * It uses the fields from the admin in selfNomination and the setValue from RHF.
 * @param {Object} field
 * @param {Function} setWisenrollUser
 * @param {Function} setEntries
 * @param {Number} entryNumber
 * @param {String} code
 */
export const checkBlock = async (field, setWisenrollUser, setEntries, entryNumber, code) => {
  const fields = {}; // {block__code, block__type, block__signatory}
  const functionGetValues = (notUndefinedNull(field.function_get_values))
    ? JSON.parse(field.function_get_values)
    : null;
  functionGetValues.fields.forEach(field => {
    let { name } = field.name;
    name = fieldUniqueName(field.name);
    fields[name.dataset.uniqueName] = name;
  });

  // Api call
  const codeField = Object.keys(fields).find(fieldName => fieldName.includes('code'));
  const response = await sendPostRequest({
    'wisenroll_code': fields[codeField].value,
    'owner_wisenroll_code': code
  }, endpoints.validateWisenrollCode);

  let idToValidate = entryNumber !== '' ? entryNumber : 1;
  if (response.error) {
    store.dispatch(setMessage(response.errors, 'error'));
    setWisenrollUser({
      error:true,
      user:null
    });
    setEntries(prev => {
      return prev.map(entry =>{
        if (entry.id === idToValidate) {
          return { ...entry, isValid: false };
        }
        return entry;
      });
    });
    store.dispatch(setLoader(false));
  }

  if (response.success) {
    setWisenrollUser({
      error: false,
      user:{
        firstName: response.success.first_name,
        lastName: response.success.last_name,
        docType: response.success.document_type_description,
        docNumber: response.success.document_number
      }
    });
    setEntries(prev => {
      return prev.map(entry =>{
        if (Number(entry.id) === Number(idToValidate)) {
          return { ...entry, isValid: true };
        }
        return entry;
      });
    });
    store.dispatch(setLoader(false));
  }
};

/**
 * Validate and execute conditions on fields
 * @param {String} fieldSelect
 * @param {Object} field
 * @param {Function} setValue
 * @param {String} newValue
 * @param {Boolean} isHide
 */
export const parentCondition = (
  fieldSelect,
  field,
  setValue,
  newValue=null,
  isHide=null
) => {
  const children = fieldsAttribute('data-parent', field?.id);

  children.forEach(child => {
    const { action, fieldChild, name, parent, result } = getValueParent(
      child, fieldSelect, field, 'add', newValue, isHide);
    let valueChild = '';
    let parentHide = false;
    let fieldValue = { value: '', name: '', isChange: false };
    const defaultValue = getValue(fieldChild);

    if (action?.attributes) handleAttributes(child, action.attributes);
    if (!isHide && isUndefinedNull(isHide)) {
      let response;
      if (result.isCondition) {
        response = conditionTrue(
          fieldValue, action, child, parent, parentHide, fieldChild, name, setValue);
        fieldValue = response.fieldValue;
      } else {
        response = conditionFalse(
          fieldValue, action, child, parent, parentHide, fieldChild, setValue);
        fieldValue = response.fieldValue;
        if (action?.value && action?.type !== 'static_data') {
          if (child.type !== 'select-one') {
            valueChild = (action?.type === 'show') ? ((String(
              defaultValue)) ? defaultValue : hiddenField) : (
              (String(defaultValue)) ? defaultValue : '');
            fieldValue = { value:valueChild, name: name, isChange: true };
          }
        }
        if (defaultValue === hiddenNumber) fieldValue.value = hiddenNumber;
      }

      fieldValue = (action?.type === 'value') ? handleElementValue(
        fieldSelect, fieldChild, child, action, result.isCondition) : fieldValue;
      if (notUndefinedNull(action?.valueText)) {
        if (fieldChild.data_type === 'conversion') {
          child.textContent = action.valueText;
          setValue(name, child.textContent);
        }
        if (fieldChild.data_type === 'money') {
          child.value = action.valueText;
          child.defaultValue = action.valueText;
        }
        fieldValue = { value:action.valueText, name: name, isChange: true };
      } else {
        if (fieldChild.data_type === 'conversion') {
          child.textContent = '0';
          setValue(name, '0');
        }
      }

      parentHide = response.parentHide;
    } else {
      const parentGroup = document.getElementById(`group_field_${child?.id}`);
      fieldValue = addHide(child, parentGroup);
      if (defaultValue === hiddenNumber) fieldValue.value = hiddenNumber;
    }

    if (fieldValue.isChange) setValue(fieldValue.name, fieldValue.value);
    conditionGeneral(action, fieldChild, child, fieldSelect, result, setValue);
    conditionRepeat(action, fieldChild, child, valueChild, parentHide, setValue);
  });

  const actionField = (field?.action) ? JSON.parse(field?.action) : '';
  if (notUndefinedNull(actionField?.fieldChild)) {
    const field = searchField('unique_name', actionField?.fieldChild);
    const elementField = fieldUniqueName(actionField?.fieldChild);
    if (notUndefinedNull(field?.mask) && notUndefinedNull(elementField)) {
      setMask(elementField, paramsAdd, field?.mask);
    } else {
      setMask(elementField, 'remove', field?.mask)
    }
  }
};

/**
 * Validate and execute conditions on fields that are multiple
 * @param {Object} check
 * @param {Object} field
 * @param {Function} setValue
 */
export const parentConditionMultiple = (check, field, setValue) => {
  const children = fieldsAttribute('data-parent', field?.id);

  children.forEach(child => {
    const { action, fieldChild, name, parent, result } = getValueParent(
      child, check, field);
    let parentHide = false;
    let valueChild = '';
    let fieldValue = { value: '', name: name, isChange: false };

    if (check?.checked && check?.value) {
      let response;
      if (result.isCondition) {
        response = conditionTrue(
          fieldValue, action, child, parent, parentHide, fieldChild, name, setValue);
      }
      if (response) {
        fieldValue = response.fieldValue;
        parentHide = response.parentHide;
      }
    }

    if ((!check?.checked && (check?.value && result.isCondition)) ||
      (check?.value === 'fileCases')) {
      const response = conditionFalse(
        fieldValue, action, child, parent, parentHide, fieldChild, setValue);
      fieldValue = response.fieldValue;
      parentHide = response.parentHide;
      if (action?.type === 'static_data') {
        const defaultValue = getValue(fieldChild);
        valueChild = getValueChild(action?.type, defaultValue);
        fieldValue = { value: valueChild, name: name, isChange: true };
      }
      if (action?.value && action?.type !== 'static_data') {
        if (child.type === 'select-one') {
          fieldValue = { value: '', name: name, isChange: true };
        } else {
          const defaultValue = getValue(fieldChild);
          valueChild = getValueChild(action?.type, defaultValue);
          fieldValue = { value: valueChild, name: name, isChange: true };
        }
      }
    }

    if ((check?.value && result.isCondition) || (check?.value === 'fileCases')) {
      fieldValue = (action?.type === 'value') ? handleElementValue(
        check, fieldChild, child, action, check.checked) : fieldValue;
    }

    if (fieldValue.isChange) {
      setValue(fieldValue.name, fieldValue.value);
    }

    if (check?.checked && check?.value && result.isCondition && action?.equal) {
      validateEqual(child, action.equal, setValue, true);
    }
    if (!check?.checked && check?.value && result.isCondition && action?.equal) {
      validateEqual(child, action.equal, setValue, false);
    }
  });
};

/**
 * Handle if value in field is inequality and change to new value
 * @param {Object} action
 * @param {Function} setValue
 * @param {Boolean} isSetValue
 * @returns
 */
export const checkValue = (action, setValue, isSetValue=true) => {
  let isValid = true;
  let newValue;

  Array.from(action?.fields).forEach(item => {
    let inequality = true;
    if (item.type === 'value') {
      if (item.data_type === 'text') {
        const checkField = fieldUniqueName(item.name);

        if (!isEmpty(checkField.value)) {
          if (item?.inequality) {
            inequality = (item.inequality !== checkField.value);
          }

          if (!inequality && item?.equal) {
            const valueField = fieldUniqueName(item.equal);
            newValue = (notUndefinedNull(valueField)) ? valueField.value : '';
            if (isSetValue) {
              checkField.value = newValue;
              setValue(checkField.name, checkField.value);
            }
            inequality = !inequality;
          }
        } else {
          isValid = false;
        }
      }
    }
  });

  return { isValid, newValue };
};

/**
 * Validate and execute conditions on fields that are multiple with PEP or RPEP
 * @param {Object} check
 * @param {Object} field
 * @param {Function} setValue
 */
export const checkPEPRPEP = (check, field, setValue) => {
  if (check.dataset?.pep) {
    const allCheck = fieldsUniqueName(check.dataset.uniqueName);
    if (allCheck) {
      const checkedPEP = Array.from(allCheck).filter(
        item => item.checked && item.dataset.pep === 'true');
      const checkedRPEP = Array.from(allCheck).filter(
        item => item.checked && item.dataset.pep === 'false');

      if ((!check.checked && (checkedPEP.length === 0 && checkedRPEP.length === 0)) || (
        checkedPEP.length === 0 && checkedRPEP.length !== 0)) {
        parentConditionMultiple(check, field, setValue);
      }
      if (!check.checked && check.dataset.pep === 'false' && checkedRPEP.length === 0) {
        parentConditionMultiple(check, field, setValue);
      }
      if (check.checked && check.dataset.pep === 'false') {
        parentConditionMultiple(checkedRPEP.pop(), field, setValue);
      }
      if (check.checked && check.dataset.pep === 'true') {
        parentConditionMultiple(checkedPEP.pop(), field, setValue);
      }
    }
  } else {
    parentConditionMultiple(check, field, setValue);
  }
};

/**
 * Validate conditions to know if the values ​​are equal
 * @param {Object} condition
 * @param {Object} fieldSelect
 * @param {String} typeField
 * @param {String} newValue
 * @param {Boolean} isHide
 * @param {Object} child
 * @param {Object} fieldChild
 * @returns
 */
export const getCondition = (
  condition,
  fieldSelect,
  typeField,
  actionInfo='add',
  newValue=null,
  isHide=null,
  child=null,
  fieldChild=null
) => {
  const isEquals = (value, conditionValue, isDistinct=false) => {
    let response = false;

    if (conditionValue) {
      if (typeof conditionValue === 'string' || conditionValue instanceof String) {
        response = (trimLowerCase(value) === trimLowerCase(conditionValue));
      } else {
        response = Array.from(conditionValue).some(
          item => trimLowerCase(item) === trimLowerCase(value));
      }
      if (isDistinct) {
        response = !response;
      }
    } else {
      if (isDistinct) {
        response = false;
      }
    }

    return response;
  };
  let value;
  let response = { isCondition: false };

  if (isUndefinedNull(isHide) || !isHide) {
    if (condition?.type === 'value') {
      if (typeField === 'checkbox') {
        value = (isUndefinedNull(newValue)) ? fieldSelect?.checked : newValue;
        response.isCondition = isEquals(value, condition?.value);
      } else {
        if(fieldSelect?.type !== 'checkbox'){
          value = (isUndefinedNull(newValue)) ? fieldSelect?.value : newValue;
        } else {
          value = fieldSelect?.checked ? fieldSelect?.checked : false
        }
        response.isCondition = isEquals(value, condition?.value);
      }
    }

    if (condition?.type === 'code') {
      if (typeField === 'selection') {
        value = (fieldSelect?.selectedIndex && isUndefinedNull(
          newValue)) ? fieldSelect?.options[
            fieldSelect.selectedIndex]?.dataset?.code : newValue;
        value = getValueTypeahead(
          fieldChild, value, fieldSelect, condition, condition?.type);
        response.isCondition = isEquals(value, condition?.value);
      } else {
        if (typeField === 'checkbox_selection' && actionInfo === 'edit') {
          const allCheckbox = fieldsUniqueName(fieldSelect?.dataset?.uniqueName);
          let checkSelect;
          Array.from(allCheckbox).forEach(item => {
            const [code] = Array.from(
              condition?.value).filter(elem => trimLowerCase(elem) === trimLowerCase(
                item.dataset.code));
            if (notUndefinedNull(code) && item.checked) {
              checkSelect = item;
            }
          });
          if (notUndefinedNull(checkSelect)) {
            value = (isUndefinedNull(
              newValue) && checkSelect.checked) ? checkSelect?.dataset?.code : newValue;
          } else {
            value = newValue;
          }
        } else {
          value = (isUndefinedNull(newValue)) ? fieldSelect?.dataset?.code : newValue;
        }
        response.isCondition = isEquals(value, condition?.value);
      }
    }

    if (condition?.type === 'inequality') {
      let inequality = false;
      let [inequalityValue] = condition.value;

      if (typeField === 'selection') {
        value = (fieldSelect?.selectedIndex && isUndefinedNull(
          newValue)) ? fieldSelect.options[fieldSelect.selectedIndex]?.dataset[
            inequalityValue.type] : newValue;
        value = getValueTypeahead(
          fieldChild, value, fieldSelect, condition, inequalityValue.type);
        if (notUndefinedNull(value)) inequality = isEquals(value, setConditionValue(
          inequalityValue), true);
      } else {
        value = (isUndefinedNull(newValue)) ? fieldSelect?.dataset?.[
          inequalityValue.type] : newValue;
        inequality = isEquals(value, setConditionValue(inequalityValue), true);
      }

      response.isCondition = inequality;
    }

    if (condition?.type === 'text' && notUndefinedNull(fieldChild)) {
      let conditionId = false;
      let typeDataset;

      if (condition?.id) {
        typeDataset = condition.id;
        conditionId = true;
      } else {
        typeDataset = condition.code;
      }

      value = (fieldSelect?.selectedIndex && isUndefinedNull(
        newValue)) ? fieldSelect.options[
          fieldSelect.selectedIndex]?.dataset[typeDataset] : newValue;
      if (isUndefinedNull(value)) {
        value = getValueTypeahead(
          fieldChild, value, fieldSelect, condition, typeDataset);
      }
      if (notUndefinedNull(value) && notUndefinedNull(child)) {
        const data = { value, searchSelect: true, conditionId };
        putSelect(fieldChild, child, data, null);
        response.isCondition = fieldChild.options;
      }
    }

    if (condition?.type === 'mask') {
      const field = searchField('unique_name', condition.field);
      const elementField = fieldUniqueName(condition.field);
      setMask(elementField, paramsAdd, field?.mask);
    }
  }

  return response;
};

/**
 * Remove class hide and change value
 * @param {Object} child
 * @param {Object} parent
 * @param {Boolean} defaultEdit
 * @returns
 */
export const removeHide = (child, parent, defaultEdit=false) => {
  const { isText, isCheckbox, isFile, name } = getValueType(child, parent);
  const action = (child?.dataset?.action) ? JSON.parse(child.dataset.action) : '';
  let value = '';
  let isChange = false;

  parent?.classList.remove('d-none');
  value = (isText || isCheckbox || isFile) ? '' : null;
  value = (isCheckbox) ? '' : value;
  isChange = (isText || isCheckbox || isFile) ? true : false;
  if (isFile) child.type = 'file';

  if (isCheckbox) {
    const children = fieldsAttribute('name', name);
    Array.from(children).forEach(child => {
      if (child?.checked) child.checked = value;
    });
  }

  const element = fieldAttribute('name', name);
  if (isText && element && !defaultEdit) element.value = value;
  if (element.value === hiddenField && defaultEdit) element.value = '';

  if (action?.isTypeahead && child?.parentElement?.children) {
    const response = child.parentElement.children;
    const [buttonOption] = Array.from(response).filter(
      item => item?.firstElementChild?.className?.includes(buttonClose));
    if (action.isTypeahead && value === '' && buttonOption?.firstElementChild?.type === 'button') {
      buttonOption.firstElementChild.click();
    }
  }

  return { value, name, isChange };
};

/**
 * Add class hide and change value
 * @param {Object} child
 * @param {Object} parent
 * @param {Boolean} defaultEdit
 * @returns
 */
export const addHide = (child, parent, defaultEdit=false) => {
  const { isText, isCheckbox, isFile, name } = getValueType(child, parent);
  const action = (child?.dataset?.action) ? JSON.parse(child.dataset.action) : '';
  let value = '';
  let isChange = false;

  parent?.classList.add('d-none');
  value = (isText || isFile) ? hiddenField : null;
  value = (isCheckbox) ? '' : value;
  isChange = (isText || isCheckbox || isFile) ? true : false;
  if (isFile) child.type = 'text';

  if (isCheckbox) {
    const children = fieldsAttribute('name', name);
    Array.from(children).forEach(child => {
      if (child?.checked) child.checked = value;
    });
  }

  const element = fieldAttribute('name', name);
  if (isText && element && !defaultEdit) element.value = value;

  if (action?.isTypeahead && child?.parentElement?.children) {
    const response = child.parentElement.children;
    const [buttonOption] = Array.from(response).filter(
      item => item?.firstElementChild?.className?.includes(buttonClose));
    if (action.isTypeahead && value === '' && buttonOption?.firstElementChild?.type === 'button') {
      buttonOption.firstElementChild.click();
    }
  }

  return { value, name, isChange };
};

/**
 * Get values for parentCondition and parentConditionMultiple
 * @param {Object} child
 * @param {Object} fieldSelect
 * @param {Object} field
 * @param {String} newValue
 * @param {Boolean} isHide
 * @returns
 */
export const getValueParent = (
  child,
  fieldSelect,
  field,
  actionInfo='add',
  newValue=null,
  isHide=null
) => {
  const { requestLanguage } = store.getState().base;
  const { field:fields } = store.getState().staticData[`data_${trimLowerCase(
    requestLanguage)}`];
  const parent = document.getElementById(`group_field_${child?.id}`);
  const condition = (child?.dataset?.condition) ? JSON.parse(
    child.dataset.condition) : '';
  const action = (child?.dataset?.action) ? JSON.parse(child.dataset.action) : '';
  const [fieldChild] = fields.filter(
    elem => trimLowerCase(elem.id) === trimLowerCase(child?.id));
  const result = getCondition(
    condition,
    fieldSelect,
    field.data_type,
    actionInfo,
    newValue,
    isHide,
    child,
    fieldChild);
  let name = (child?.dataset?.name) ? child.dataset.name : child?.name;
  if (isUndefinedNull(name)) {
    name = parent?.dataset?.name;
  }

  return { action, fieldChild, name, parent, result };
};

/**
 * Condition to hide or show elements when conditions if true
 * @param {Object} fieldValue
 * @param {Object} action
 * @param {Object} child
 * @param {Object} parent
 * @param {Boolean} parentHide
 * @param {Object} field
 * @param {String} name
 * @param {Object} result
 * @param {Function} setValue
 * @param {Boolean} defaultEdit
 * @returns
 */
export const conditionTrue = (
  fieldValue,
  action,
  child,
  parent,
  parentHide,
  field,
  name,
  setValue,
  defaultEdit=false,
) => {
  if (action?.type === 'show') {
    fieldValue = removeHide(child, parent, defaultEdit);
    if (field.mask) setMask(child, paramsAdd, field.mask);
  }
  if (action?.type === 'hide') {
    fieldValue = addHide(child, parent, defaultEdit);
    if (field.mask) setMask(child, 'remove', field.mask);
    parentHide = true;
  }

  const { actualLanguage } = store.getState().base;
  const { [`data_${trimLowerCase(
    actualLanguage)}`]: staticData } = store.getState().staticData;
  fieldValue = (action?.type === 'static_data') ? {
    value: handleData(field, staticData, action.type, false),
    name: name,
    isChange: true
  } : fieldValue;

  fieldValue = (action?.type === 'equal') ? { value: equalField(
    field, action), name: name, isChange: true } : fieldValue;

  fieldValue = (action?.value && (
    action?.type !== 'static_data' && action?.type !== 'equal')) ? {
      value: action?.value, name: name, isChange: true } : fieldValue;

  if (!action?.check && action?.type !== 'check' && action?.fields) {
    actionFields(action.fields, paramsAdd, field, setValue);
  }

  return { fieldValue, parentHide };
};

/**
 * Condition to hide or show elements when conditions if false
 * @param {Object} fieldValue
 * @param {Object} action
 * @param {Object} child
 * @param {Object} parent
 * @param {Boolean} parentHide
 * @param {Object} result
 * @param {Object} field
 * @param {Function} setValue
 * @param {Boolean} defaultEdit
 * @returns
 */
export const conditionFalse = (
  fieldValue,
  action,
  child,
  parent,
  parentHide,
  field,
  setValue,
  defaultEdit=false
) => {
  if (action?.type === 'show') {
    fieldValue = addHide(child, parent, defaultEdit);
    if (field.mask) setMask(child, 'remove', field.mask);
    parentHide = true;
  }
  if (action?.type === 'hide') {
    fieldValue = removeHide(child, parent, defaultEdit);
    if (field.mask) setMask(child, paramsAdd, field.mask);
  }
  fieldValue = (action?.type === 'hide') ? removeHide(
    child, parent, defaultEdit): fieldValue;

  if (!action?.check && action?.type !== 'check' && action?.fields) {
    actionFields(action.fields, 'delete', field, setValue);
  }

  return { fieldValue, parentHide };
};

/**
 * Set value depending of setted parameters
 * @param {Object} condition
 * @returns
 */
 export const setConditionValue = condition => {
  if (notUndefinedNull(condition?.variable)) {
    return Array.from(condition.variable).map(item => envSetting[item]);
  }

  return condition.value;
};

/**** Local functions  ****/
/**
 * Get value for removeHide or addHide
 * @param {Object} child
 * @param {Object} parent
 * @returns
 */
const getValueType = (child, parent) => {
  let childType = child?.type;
  let name = child?.name;
  const isFile = (child?.dataset?.typeField === 'media_upload');

  if (isUndefinedNull(childType) && parent?.dataset) {
    childType = parent.dataset?.type;
  }

  const isCheckbox = (childType === 'checkbox');
  let isText = (childType === 'text' || childType === 'select-one' ||
    childType === 'textarea' || childType === 'money' || childType === 'text' ||
    childType === 'number');

  if (child?.dataset?.name) {
    name = child.dataset.name;
    isText = true;
  }

  if (isUndefinedNull(name) && parent?.dataset) {
    name = parent.dataset?.name;
  }

  isText = (name?.includes('_conversion_')) ? true : isText;

  return { isText, isCheckbox, isFile, name };
};

/**
 * Conditionally sets the value of a field, depending on the value of its parent.
 * @param {HTMLElement} field - Target field
 * @param {String} type
 * @param {Object} elem - Object received from self-nomination's Admin
 * @param {Function} setValue
 */
const setValueConditionally = (field, type, elem, setValue, fieldChild) => {
  if (type === paramsAdd) {
    if (field?.options) {
      Array.from(field?.options).forEach(option => {
        if (option.dataset[elem.code]?.toLowerCase() === elem?.value) {
          field.value = option.value;
          setValue(field.name, option.value);
          field.dataset.codeId = option.id;
          parentCondition(fieldChild, field, setValue, option.value);
        }
      });
    } else {
      const { requestLanguage } = store.getState().base;
      const { [elem.property]:data } = store.getState().staticData[`data_${trimLowerCase(
        requestLanguage)}`];
      const [value] = data.filter(item => item?.[elem?.code]?.toLowerCase() === elem?.value);
      field.value = value.textValue;
      field.dataset.codeId = value.idValue;
      setValue(field.name, value.idValue);
      parentCondition(fieldChild, field, setValue, value?.[elem?.code]);
    }
  }
};

/**
 * Validate actions to apply length or disabled on others fields
 * @param {Object} fieldsAction
 * @param {String} type
 * @param {Object} fieldChild
 * @param {Function} setValue
 */
const actionFields = (fieldsAction, type, fieldChild, setValue) => {
  let field;

  Array.from(fieldsAction).forEach(elem => {
    field = fieldUniqueName(elem.name);
    if (elem?.type === 'length') setLength(field, elem, type);
    if (elem?.type === 'mask') setMask(field, type, fieldChild?.mask);
    if (elem?.type === 'set_value_conditionally') setValueConditionally(
      field, type, elem, setValue, fieldChild);
    if (elem?.attributes) {
      Array.from(elem.attributes).forEach(item => {
        if (item.name === 'disabled') {
          setDisabled(field, elem, type);
        }
      });
    }
  });
};

/**
 * Change length to field
 * @param {Object} field
 * @param {Object} action
 * @param {String} type
 */
const setLength= (field, action, type) => {
  if (field) {
    const element = fieldAttribute('name', field?.name, 'span');
    if (element) element.remove();

    if (type === paramsAdd) {
      field.dataset.validate = 'length';
      field.dataset.length = action?.value;
    } else {
      delete field.dataset.validate;
      delete field.dataset.length;
    }
  }
};

/**
 * Get value to value child
 * @param {String} type
 * @param {String} defaultValue
 * @returns
 */
const getValueChild = (type, defaultValue) => {
  const valueChild = (type === 'show') ? ((String(
    defaultValue)) ? defaultValue : hiddenField) : ((String(
    defaultValue)) ? defaultValue : '');

  return valueChild;
};

/**
 * Handle if value change
 * @param {Object} parent
 * @param {Object} field
 * @param {Object} child
 * @param {Object} action
 * @param {Boolean} child
 * @returns
 */
const handleElementValue = (parent, field, child, action, isCondition) => {
  let isChange = false;
  let value = '';
  let name = '';

  if (action?.field) {
    // Select to field for obtain value
    const uniqueName = fieldUniqueName(action.field.name);
    if (uniqueName) {
      if (action.field.data_type === 'text' && action.field.type === 'equal') {
        if (isCondition) {
          if (action.field?.search) {
            const { actualLanguage } = store.getState().base;
            const { [`data_${trimLowerCase(
              actualLanguage)}`]: data } = store.getState().staticData;
            value = handleData(
              field, data, action.field.search.type, false, uniqueName.value);
          } else {
            value = uniqueName.value;
          }
          isChange = !isChange;
          name = child.name;
        } else {
          const defaultValue = (notUndefinedNull(
            field.default_value)) ? JSON.parse(field.default_value) : null;
          value = defaultValue?.text;
          isChange = !isChange;
          name = child.name;
        }
      }
    }
  } else {
    if (field?.parent_condition) {
      const condition = JSON.parse(field.parent_condition);
      if (condition?.typeahead) {
        if (condition.typeahead && parent.length >= 0) {
          const [typeaheadParent] = parent;
          value = typeaheadParent?.[condition.code];
          value = (isEmpty(value) || isUndefinedNull(value)) ? hiddenField : value;
          if (child.type === 'text') {
            child.value = value;
            isChange = !isChange;
            name = child.name;
          }
        }
      }
    }
  }

  return { value, name, isChange };
};

/**
 * Evaluate conditions generals to field
 * @param {Object} action
 * @param {Object} field
 * @param {Object} child
 * @param {Object} fieldSelect
 * @param {Object} result
 * @param {Function} setValue
 */
const conditionGeneral = (
  action,
  field,
  child,
  fieldSelect,
  result,
  setValue
) => {
  if (action?.type === 'file') {
    if (!result.isCondition && action?.default) {
      actionDefault(action.default, setValue);
    } else {
      handleFileCases(field, setValue, result.isCondition);
    }
  }
  if (action?.type === 'check' && action?.fields) {
    const { isValid } = checkValue(action, setValue);
    if (isValid && field?.parent_condition) {
      handleFileCases({ action: field.parent_condition }, setValue);
    }
  }
  if (action?.check) {
    const childAction = (action.check?.name) ? fieldAttribute(
      'name', action.check.name) : child;
    if (notUndefinedNull(childAction) && childAction) {
      const dataType = (action.check?.data_type) ?
        action.check.data_type : field.data_type;
      const response = getCondition(action.check, childAction, dataType);
      if (response && action?.fields) {
        actionFields(action.fields, (
          response.isCondition && result.isCondition) ? paramsAdd : 'delete', field, setValue);
      }
    }
  }
  // If action for select
  if (action?.filter === 'code' && result.isCondition) {
    const code = fieldSelect.children[fieldSelect.selectedIndex].dataset?.code;
    Array.from(child.children).forEach(item => {
      if (item.dataset?.codeParent) {
        item.classList.add('d-none');
        if (trimLowerCase(item.dataset.codeParent) === trimLowerCase(code)) {
          item.classList.remove('d-none');
        }
      }
    });
  }

  // If select is "Select Option" with value ''
  if (fieldSelect?.type === 'select-one') {
    if (fieldSelect.selectedIndex >= 0) {
      if (action?.type === 'show' && isEmpty(fieldSelect.children[
        fieldSelect.selectedIndex].value)) {
        const groupHide = document.getElementById(`group_field_${field?.id}`);
        groupHide.classList.add('d-none');
      }
    }
  }
};

/**
 * Handle if field contain children to repeat function
 * @param {Object} action
 * @param {Object} field
 * @param {Object} child
 * @param {Object} valueChild
 * @param {Boolean} parentHide
 * @param {Function} setValue
 */
const conditionRepeat = (
  action,
  field,
  child,
  valueChild,
  parentHide,
  setValue
) => {
  if (action?.children) {
    const children = fieldsAttribute('data-parent', field?.id);
    if (children.length > 0 && notUndefinedNull(child?.id)) {
      parentCondition(child, field, setValue, valueChild, parentHide);
      moduleCategory(child?.id);
    }
  }
};

/**
 * Execute default value in field
 * @param {Object} defaultField
 * @param {Function} setValue
 */
const actionDefault = (defaultField, setValue) => {
  const { requestLanguage } = store.getState().base;
  const { field:fields } = store.getState().staticData[`data_${trimLowerCase(
    requestLanguage)}`];

  Array.from(defaultField.fields).forEach(item => {
    let field = fields.filter(
      elem => trimLowerCase(elem.unique_name) === trimLowerCase(item.name));
    field = field?.pop();

    if (notUndefinedNull(field) && field?.id) {
      const value = getValue(field);
      setValue(`${field.unique_name}__id__${field.id}`, value);
      const uniqueName = fieldUniqueName(field.unique_name);
      uniqueName.value = value;

      if (item.type === 'aux') {
        defaultAux(field, setValue);
      }
    }
  });
};

/**
 * Apply or remove disabled attributes on field
 * @param {Object} field
 * @param {Object} action
 * @param {String} type
 */
const setDisabled = (field, action, type) => {
  if (field && field?.type !== 'aux') {
    if (field.type === 'checkbox') {
      let field = (action.type === 'code') ? fieldUniqueName(
        action.name, `[data-code=${action?.value}]`) : fieldUniqueName(
        action.name, `[value=${action?.value}]`);

      if (type === paramsAdd) {
        field.checked = true;
      } else {
        field.removeAttribute('disabled');
        field.checked = false;
      }
    } else {
      if (type === paramsAdd) {
        field.disabled = true;
      } else {
        field.removeAttribute('disabled');
      }
    }
  }
};

/**
 * Get value to field type typehead
 * @param {Object} fieldChild
 * @param {String} value
 * @param {Object} fieldSelect
 * @param {Object} condition
 * @param {String} type
 * @returns
 */
const getValueTypeahead = (fieldChild, value, fieldSelect, condition, type) => {
  const actionfieldChild = (fieldChild?.action) ? JSON.parse(
    fieldChild.action) : '';
  const { action } = store.getState().tier;

  if (actionfieldChild?.isTypeahead) {
    if (actionfieldChild.isTypeahead) {
      const getValues = JSON.parse(fieldChild?.function_get_values);
      if(isIterable(fieldSelect)){
        const [code] = fieldSelect;
        value = (code?.[getValues?.code]) ? code[getValues?.code] : value;
      }
    }
  }
  if (condition?.typeahead) {
    if (condition.typeahead && fieldSelect.length > 0) {
      if(isIterable(fieldSelect)){
        const [code] = fieldSelect;
        value = (code?.[type]) ? code[type] : value;
      }
    } else {
      if (condition.typeahead && (action === paramsEdit || action === paramsAdd) && !isEmpty(
        fieldSelect.value)) {
        const dataset = fieldSelect.dataset;
        value = (dataset?.[type]) ? dataset[type] : value;
      }
    }
  }

  return value;
};

/**
 * Put the same value of another field
 * @param {Object} child
 * @param {String} name
 * @param {Function} setValue
 */
const validateEqual = (child, name, setValue, condition) => {
  if (child && name) {
    const element = fieldUniqueName(name);
    const action = (element.dataset?.action) ? JSON.parse(element.dataset.action) : '';

    if (action?.isTypeahead && condition) {
      const codeId = element.dataset?.codeId;
      setValue(child.name, codeId);
      child.value = element.value;
    }

    if (!condition) {
      setValue(child.name, hiddenField);
    }
  }
};