import { useSelector } from "react-redux"
import { trimLowerCase } from "../helpers"

/**
 * Helper custom hook that will help us get the translated videos out of the redux
 * @date 26/1/2024 - 16:07:01
 * 
 * @returns {Object} - Object containing the following:
 * - {String} videoUrl - String for the url for the instructions video
 * - {String} title    - Title required for the iframe tag
 * */
export const useVideos = () => {
  // Getting the correct url and title for the video intstruction according to the current language
  const { requestLanguage } = useSelector(state => state.base)
  const {
    field_helper: fieldHelper
  } = useSelector(state => state.staticData[`data_${trimLowerCase(requestLanguage)}`])

  const instructionVideoObject = fieldHelper.filter(field => field[`title_${trimLowerCase(requestLanguage)}`].toLowerCase() === 'html')[0]
  const videoContent = JSON.parse(instructionVideoObject[`content_${trimLowerCase(requestLanguage)}`])
  // Getting url
  const videoUrl = videoContent.html.split("'").filter(elem => elem.startsWith('http'))[0]

  const titles = {
    title_es: 'Cómo grabar el video selfie perfecto para verificación de identidad',
    title_en: 'How to record the perfect video for identity verification'
  }
  const title = titles[`title_${trimLowerCase(requestLanguage)}`]

  return {
    videoUrl,
    title
  }
}