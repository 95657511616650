import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { envSetting } from '../../../constants';
import { Error, Label, TooltipIcon } from '../../shared/simple';
import { StyledGroup } from '../../shared/Input/styled';
import { StyledImage } from '../styled';
import { useImageSelect, useTextLanguage } from '../../../hooks';

/**
 * Component image select
 * @component
 * @param {Object} field
 * @returns
 */
export const ImageSelect = ({ field }) => {
  const uniqueName = `${field.unique_name}__id__${field.id}`;
  const { description, languageText } = useTextLanguage();
  const { register, formState: { errors } } = useFormContext();
  const { images, onClick } = useImageSelect(field);

  return (
    <>
      {field && (
        <>
          {(images && field.col_size?.includes('col-before')) && (
            <Col xs={12} key={`col_before_${field.id}`}></Col>
          )}
          {images && (
            <Col
              className={ field.col_size }
              data-module={ `module-${field?.category?.field_category}` }
              data-name={ uniqueName }
              id={ `group_field_${field.id}` }
              key={ field.id }
              xs={12}
            >
              <StyledGroup className="mb-3">
                <Label
                  className="d-block"
                  htmlFor={ uniqueName }
                  xs={12}
                >
                  { field[description] }{ (field[`tooltip${
                    languageText}`]) && (<TooltipIcon tooltipText={ field[
                    `tooltip${languageText}`] } />)}
                </Label>
                {images?.map(image => {
                  if (image.active) {
                    return (
                      <OverlayTrigger
                        key={ `over_${image.idValue}` }
                        overlay={
                          <Tooltip>
                            { (image?.[`textValue${languageText}`]) ? (image[
                              `textValue${languageText}`]) : image.textValue }
                          </Tooltip>}
                        placement="top"
                      >
                        <StyledImage
                          { ...register(uniqueName) }
                          className={ image?.classStatus }
                          data-category={
                            `category-${field?.category?.field_category}` }
                          data-object={ JSON.stringify(image) }
                          data-unique-name={ field.unique_name }
                          id={ image.idValue }
                          key={ image.idValue }
                          name={ uniqueName }
                          onClick={ field.editable_after_migrate && onClick }
                          data-test={ `image_${image.id}` }
                          src={ `${envSetting.wisenrollApi}${image.image_download_link}` }
                          style={ image?.style }
                        />
                      </OverlayTrigger>
                    );
                  }
                  return null;
                })}
                {errors?.[uniqueName]?.message && (
                  <Error message={ errors[uniqueName].message } />
                )}
              </StyledGroup>
            </Col>
          )}
        </>
      )}
    </>
  );
};

ImageSelect.propTypes = {
  field: PropTypes.object.isRequired,
};