import React from 'react';
import { useTranslation } from 'react-i18next';
import { Back, Button, Title } from '../components/shared/simple';
import { Help } from '../components/shared/Help';
import { Row, Col } from 'react-bootstrap';
import { useRequestList } from '../hooks';
import { TableRequest } from '../components/products';

/**
 * Page listing requests
 * @returns {JSX}
 */
const ProductRequestsPage = () => {
  const { t } = useTranslation();
  const { routeProducts } = useRequestList();

  return (
    <>
      <Col className="p-0" xl={3} md={4} xs={12}>
        <Help backButton={false} isProduct/>
      </Col>
      <Col className="p-4" xl={9} md={8} xs={12}>
        <Row>
          <Col className="p-0 text-end" xs={12}>
            <Back />
          </Col>
          <Col className="p-4" xs={12}>
            <Title style={{marginBottom: "30px"}}>
              { t("productRequest.request") }
            </Title>
            <div style={{display: "flex", justifyContent: "flex-end"}}>
              <Col className="mb-4 text-end" xs={12} sm={3}>
                <Button
                  className="btn"
                  color="first"
                  onClick={routeProducts}
                  name="btn_product"
                >
                { t("productRequest.add") }
                </Button>
              </Col>
            </div>
            <TableRequest />
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default ProductRequestsPage;