import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTokenProfile } from '../../../hooks';
import { Button, LoaderSpinner, StyledForm } from '../../shared/simple';
import { CloseImage, StyledRow } from '../styled';
import { Col } from 'react-bootstrap';
import { customStyles } from '../../../constants';
import { Input } from '../../shared/Input';
import { Timer } from '../../tier/Timer';
import closeIcon from '../../../assets/images/token-modal/close.png';
import Modal from 'react-modal';

/**
 * CardModal component
 * @component
 * @param {String} codeModal
 * @param {Boolean} modalOpen
 * @param {Function} setModalOpen
 * @returns
 */
export const CardModal = ({ codeModal, modalOpen, setModalOpen }) => {
  const { t } = useTranslation();
  const {
    errors,
    handleSubmit,
    loading,
    message,
    messageExpired,
    minutesToken,
    onSubmit,
    register,
    resendToken
  } = useTokenProfile(codeModal, setModalOpen);

  return (
    <Modal
      ariaHideApp={ false }
      isOpen={ modalOpen }
      onAfterOpen={() => { }}
      onRequestClose={() => setModalOpen(false)}
      onAfterClose={() => { }}
      style={ customStyles }
      id={ codeModal }
    >
      <CloseImage
        alt=""
        onClick={() => setModalOpen(false)}
        src={ closeIcon }
      />
      <Col xs={12} className="pt-1">
        <p>{ message }</p>
      </Col>
      <Col xs={12}>
        <StyledRow className="justify-content-md-center p-0">
          <StyledForm
            className="col-xs-12 p-0"
            onSubmit={ handleSubmit(onSubmit) }
          >
            <Input
              autoComplete="off"
              disabled={ loading }
              isRequiredInput={ true }
              label={ t("profile.token") }
              message={ errors.token?.message }
              name={ register("token").name }
              register={ register("token") }
              type="text"
            />
            <Col xs={12} className="pt-1">
              <Timer
                onExpired={ messageExpired }
                numberOfMinutes={ minutesToken }
              />
            </Col>
            <Col xs={12} className="pt-1">
              {loading
                ? (
                  <LoaderSpinner />
                ) : (
                  <>
                    <Button
                      className="btn mb-0"
                      disabled={ loading }
                      color="first"
                      type="submit"
                      name="btn_card_token"
                    >
                      { t("common.buttonToken") }
                    </Button>
                    <Button
                      className="btn mb-0"
                      disabled={ loading }
                      onClick={ resendToken }
                      color="fourth"
                      type="button"
                      name="btn_resend"
                    >
                      { t("common.buttonResendToken") }
                    </Button>
                  </>
                )
              }
            </Col>
          </StyledForm>
        </StyledRow>
      </Col>
    </Modal>
  );
};

CardModal.propTypes = {
  codeModal: PropTypes.string,
  modalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func
};