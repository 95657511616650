import i18n from '../../translations/i18n';
import { endpoints } from '../../constants';
import { handleRequest, searchStaticData, trimUpperCase } from '../../helpers';
import { sendPostRequest } from '../../services/baseApi';
import { setMessage, setLoader } from '.';

/**
 * Call to send sms token
 * @param {Object} data
 * @returns
 */
export const sendSmsToken = data => async dispatch => {
  try {
    const response = await sendPostRequest(data, endpoints.sendSmsToken);
    dispatch(handleRequest(response));
    return response;
  } catch (error) {
    console.error('sendSmsToken: ', error);
    dispatch(setMessage(i18n.t("message.smsToken"), 'error'));
    dispatch(setLoader(false));
    return;
  }
};

/**
 * Call to validate sms token
 * @param {Object} data
 * @returns
 */
export const validateSmsToken = data => async dispatch => {
  try {
    const response = await sendPostRequest(data, endpoints.validateSmsToken);
    dispatch(handleRequest(response));
    return response;
  } catch (error) {
    console.error('validateSmsToken: ', error);
    dispatch(setMessage(i18n.t("message.validateToken"), 'error'));
    dispatch(setLoader(false));
    return;
  }
};

/**
 * Call to edition token
 * @param {Object} data
 * @returns
 */
export const sendEditionToken = data => async dispatch => {
  try {
    const response = await sendPostRequest(data, endpoints.sendEditionToken);
    dispatch(handleRequest(response));
    return response;
  } catch (error) {
    console.error('sendEditionToken: ', error);
    dispatch(setMessage(i18n.t("message.sendEditionToken"), 'error'));
    dispatch(setLoader(false));
    return;
  }
};

/**
 * Call to validate edition token
 * @param {Object} data
 * @returns
 */
export const validateEditionToken = data => async dispatch => {
  try {
    const response = await sendPostRequest(data, endpoints.validateEditionToken);
    dispatch(handleRequest(response));
    return response;
  } catch (error) {
    console.error('validateEditionToken: ', error);
    dispatch(setMessage(i18n.t("message.validateEditionToken"), 'error'));
    dispatch(setLoader(false));
    return;
  }
};

/**
 * Call sendSmsToken
 * @param {String} phoneNumberCountry
 * @param {String} phoneNumber
 * @param {String} userIdBifrost
 * @returns
 */
export const smsToken = (
  phoneNumberCountry,
  phoneNumber,
  userIdBifrost
) => async dispatch => {
  try {
    const country = await searchStaticData('country', 'country_id', phoneNumberCountry);
    const response = await dispatch(sendSmsToken({
      'phone_number': phoneNumber.replaceAll('-', ''),
      'country_code': trimUpperCase(country.code),
      'bifrost_user_id': userIdBifrost,
      'force': true
    }));
    dispatch(handleRequest(response));
    return response;
  } catch (error) {
    console.error('smsToken: ', error);
    dispatch(setMessage(i18n.t("message.smsToken"), 'error'));
    dispatch(setLoader(false));
    return;
  }
};

/**
 * Send email token
 * @param {Object} data
 * @returns
 */
export const sendEmailToken = data => async dispatch => {
  try {
    const response = await sendPostRequest(data, endpoints.sendEmailToken);
    dispatch(handleRequest(response));
    return response;
  } catch (error) {
    console.error('sendEmailToken: ', error);
    dispatch(setMessage(i18n.t("message.sendEmailToken"), 'error'));
    dispatch(setLoader(false));
    return;
  }
};

/**
 * Validate token to save email
 * @param {Object} data
 * @returns
 */
export const validateEmailToken = data => async dispatch => {
  try {
    const response = await sendPostRequest(data, endpoints.validateEmailToken);
    dispatch(handleRequest(response));
    return response;
  } catch (error) {
    console.error('validateEmailToken: ', error);
    dispatch(setMessage(i18n.t("message.validateEmailToken"), 'error'));
    dispatch(setLoader(false));
    return;
  }
};