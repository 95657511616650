import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { optionColor, optionColorHover } from '../../../helpers';

// Define element styles for the component
const StyledButton = styled.button`
  color: white;
  background-color: ${props => optionColor(props.color)};
  border-color: ${props => optionColor(props.color)};
  margin: 7px 0;
  width: 100%;
  &:hover,
  &:active,
  &:focus,
  &:focus-visible {
    color: white;
    background-color: ${props => optionColorHover(props.color)};
    border-color: ${props => optionColorHover(props.color)};
  }
`;

/**
 * Button component with distinct colors
 * @component
 * @param {Element} children
 * @returns
 */
export const Button = ({ children, ...props }) => {
  return <StyledButton { ...props }>{ children }</StyledButton>;
};

Button.propTypes = {
  children: PropTypes.any
};