import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  useCompletedForm,
  useFilesFields,
  useFormFiles,
  useInitFormTier,
  usePersonFiles,
  useTextLanguage
} from '../../hooks';
import { Back, Button, StyledFormTier, SubTitle, Title } from '../shared/simple';
import { StyledRow } from '../user/styled';
import { Fields } from './';
import { Loading } from '../shared/Loading';
import { fileStatus } from '../../constants';
import { FormProvider } from 'react-hook-form';

/**
 * Component to files
 * @component
 * @returns
 */
export const Files = () => {
  const { t } = useTranslation();
  const { codeTier } = useParams();
  const { files } = usePersonFiles(codeTier);
  const { description } = useTextLanguage();
  const { tierFieldsFile, fileFields } = useFilesFields(files);
  const { loading, schemaTier } = useInitFormTier(fileFields);
  const { methods, onSubmit } = useFormFiles(fileFields, schemaTier);
  useCompletedForm(methods);

  return (
    <Row>
      <Col className="p-0 text-end" xs={12}>
        <Back />
      </Col>
      <Col className="p-4 pt-0 pb-3" xs={12}>
        <Title>
          {t("documents.identity")}&nbsp;-&nbsp;
          {(codeTier === fileStatus.expired) ? t("documents.expired") : t("documents.rejected")}
        </Title>
      </Col>
      <Col className="p-4 pt-0" xs={12}>
        <StyledRow className="p-0">
          <FormProvider {...methods}>
            <StyledFormTier
              className="col-xs-12 p-3"
              onSubmit={methods.handleSubmit(onSubmit)}
            >
              {tierFieldsFile.map(item => {
                return (
                  <div
                    className="col-12 row"
                    style={{ display: (!item?.category?.show_category) && "none" }}
                    id={`module_${item?.category?.id}`}
                    key={`fragment_${item?.category?.unique_name}_${item?.category?.id}`}
                  >
                    {item?.category?.active && (
                      <SubTitle
                        className="mb-2 mt-3"
                        key={`subtitle_${item?.category?.unique_name}_${item?.category?.id}`}
                      >
                        {item?.category?.[description]}
                      </SubTitle>
                    )}
                    <Fields
                      files={files}
                      fields={item?.fields}
                      key={`category_${item?.category?.unique_name}_${item?.category?.id}`}
                    />
                  </div>
                );
              })}
              <Row className="justify-content-center m-0">
                <Col className="p-3 mt-5 mb-5" xs={12} md={6} xl={4}>
                  {loading
                    ? (
                      <Loading />
                    ) : (
                      <Button
                        className="btn mb-0"
                        type="submit"
                        color="first"
                        name="btn_file"
                      >
                        {t("common.submit")}
                      </Button>
                    )
                  }
                </Col>
              </Row>
            </StyledFormTier>
          </FormProvider>
        </StyledRow>
      </Col>
    </Row>
  );
};