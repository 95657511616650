import styled from 'styled-components';
import { Form } from 'react-bootstrap';
import { theme } from '../../../../theme';

const { first } = theme;

// Define element styles for the component
export const StyledControl = styled(Form.Control)`
  border-radius: .5rem;
  border: 1px solid #C4C4C4;
  outline: none;
  &:focus {
    border-color: ${first};
    box-shadow: none;
  }
`;

export const StyledGroup = styled(Form.Group)`
  background: transparent;
  margin-bottom: 20px;
`;