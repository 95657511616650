import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { schemaAssistans, defaultValuesAssistants } from '../constants';
import {
  isEmpty,
  isUndefinedNull,
  notUndefinedNull,
  trimLowerCase
} from '../helpers';
import {
  getAvailableAssistants,
  notifyAssistant,
  personData,
  setLoader,
  setMessage,
  verifyAssisantCode
} from '../redux/actions';
import { BASE_SELECTED_ASSISTANT } from '../redux/types';
import { useTextLanguage } from './useShared';

/**
 * Hook to List Assistants
 * @param {Function} setModalIsOpen
 * @returns
 */
export const useListAssistants = setModalIsOpen => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { description } = useTextLanguage();
  const { data: personDataObject } = useSelector(state => state.personData);
  const { allAssistants, loading, location, requestLanguage } = useSelector(
    state => state.base);
  const { appOrigin, lastProductRequestId, userIdBifrost } = useSelector(state => state.user);
  const { application } = useSelector(state => state.staticData[
    `data_${trimLowerCase(requestLanguage)}`]);
  const [assistantsList, setAssistantsList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedAssistant, setSelectedAssistant] = useState({});
  const [stepModal, setStepModal] = useState(1);
  const [assistantsSelect, setAssistantsSelect] = useState([]);
  const [seeAll, setSeeAll] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
    setError,
    setFocus
  } = useForm({
    resolver: yupResolver(schemaAssistans),
    defaultValues: defaultValuesAssistants
  });

  useEffect(() => {
    if (assistantsList?.length === 0 && !isUndefinedNull(allAssistants)) {
      setAssistantsList(allAssistants);
      const newListAssistants = [];
      allAssistants?.forEach(assistant => {
        const firstName = assistant.first_name[0].toUpperCase() + assistant.first_name.slice(1).toLowerCase();
        const lastName = assistant.last_name[0].toUpperCase() + assistant.last_name.slice(1).toLowerCase();
        newListAssistants.push({
          id: assistant.id,
          value: `${firstName} ${lastName}  -
          ${assistant.proximity_km}km / ${assistant.proximity_mi}mi`
        });
      });
      setAssistantsSelect(newListAssistants);
    }
  }, [allAssistants]);

  const setValues = value => {
    setValue(value);
    setSelectedOption(value);
    const message = (!isEmpty(value))
      ? ''
      : t("common.fieldRequired");
    setError('select_assistant', { message: message });
  };

  const changeStep = () => {
    if (stepModal === 1) {
      if (notUndefinedNull(selectedOption) && !isEmpty(selectedOption)) {
        setSelectedAssistant(assistantsList.find(
          assistant => assistant.id === parseInt(selectedOption)));
        setStepModal(stepModal + 1);
      } else {
        setError('select_assistant', { message: t("common.fieldRequired") });
        setSelectedAssistant(null);
        return;
      }
    } else if (stepModal === 3) {
      const product = application?.find(app => app.unique_name === appOrigin);
      const data = {
        assistant_code: selectedAssistant.assistant_code,
        distance: selectedAssistant.proximity_mi,
        product: product?.[`${description}`],
        first_name: personDataObject.first_name,
        last_name: personDataObject.last_name,
        phone_number: personDataObject.celular_phone,
        email: personDataObject.personal_email
      };

      Object.keys(data).forEach(key => !notUndefinedNull(
        data[key]) && delete data[key]);

      dispatch(notifyAssistant(data))
        .then(response => {
          if (response.success) {
            dispatch(setMessage(response.success, 'success'));
          } else {
            dispatch(setMessage(t("assistants.errorNotifyAssistant"), 'error'));
          }
        })
        .catch(error => {
          console.error('changeStep in useListAssistants: ', error);
        });
      setStepModal(stepModal + 1);
    } else {
      setStepModal(stepModal + 1);
    }
  };

  const afterCloseModal = () => {
    setStepModal(1);
    setSelectedAssistant(null);
    setSelectedOption(null);
    reset();
  };

  const onSubmit = values => {
    const { assistant_code: assistantRequestCode } = values;
    dispatch(setLoader(true));
    dispatch(verifyAssisantCode({
      assistant_code: selectedAssistant.assistant_code,
      assistant_request_code: assistantRequestCode
    }))
      .then(async response => {
        if (response.success) {
          const { client_assistant_person_id, assistant_code } = response.success;
          const data = {
            elements: {
              client_assistant_person_id,
              assistant_code,
              country_code: location
            }
          };
          dispatch({ type: BASE_SELECTED_ASSISTANT, payload: selectedAssistant });
          await dispatch(personData(data, userIdBifrost, lastProductRequestId));
          dispatch(setMessage(t("assistants.assistantVerified"), 'success'));
          dispatch(setLoader(false));
          setModalIsOpen(false);
          reset();
        } else {
          dispatch(setMessage(t("assistants.errorVerifyAssistant"), 'error'));
          dispatch(setLoader(false));
        }
      })
      .catch(error => {
        dispatch(setLoader(false));
        console.error('onSubmit in useListAssistants: ', error);
      });
  };

  const seeAllAssistants = async () => {
    dispatch(setLoader(true));
    const data = {
      country_code: location,
      user_id: personDataObject.id,
      by_distance: false
    };
    dispatch(getAvailableAssistants(data))
      .then(response => {
        if (response) {
          const newListAssistants = [];
          response?.forEach(assistant => {
            const firstName = assistant.first_name[0].toUpperCase() + assistant.first_name.slice(1).toLowerCase();
            const lastName = assistant.last_name[0].toUpperCase() + assistant.last_name.slice(1).toLowerCase();
            newListAssistants.push({
              id: assistant.id,
              value: `${firstName} ${lastName}  -
              ${assistant.proximity_km}km / ${assistant.proximity_mi}mi`
            });
          });
          setAssistantsSelect(newListAssistants);
          setAssistantsList(response);
          setSeeAll(true);
          setFocus('select_assistant');
          dispatch(setLoader(false));
        } else {
          dispatch(setMessage(t("assistants.errorGetAssistants"), 'error'));
          dispatch(setLoader(false));
        }
      })
      .catch(error => {
        dispatch(setLoader(false));
        console.error('seeAllAssistants in useListAssistants: ', error);
      });
  };

  return {
    register,
    errors,
    assistantsSelect,
    stepModal,
    changeStep,
    selectedAssistant,
    loading,
    setValues,
    handleSubmit,
    onSubmit,
    afterCloseModal,
    seeAll,
    seeAllAssistants
  };
};