import React from 'react';
import { Ads } from '../components/shared/Ads';
import { Col } from 'react-bootstrap';
import { RecoverPasswordEmail } from '../components/user/RecoverPasswordEmail';

/**
 * Login page
 * @returns {JSX}
 */
const RecoverPasswordPage = () => {
  return (
    <>
      <Col className="p-0" xs={12} md={6} xl={7}>
        <RecoverPasswordEmail />
      </Col>
      <Col className="p-0 d-none d-md-block" md={6} xl={5}>
        <Ads />
      </Col>
    </>
  );
};

export default RecoverPasswordPage;