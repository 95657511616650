import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  actionTypeClick,
  fieldUniqueName,
  moduleCategory,
  notUndefinedNull,
  parentCondition
} from '../helpers';
import { useTextLanguage } from './useShared';
import { useParams } from 'react-router-dom';
import { fileStatus, nameFormat, paramsEdit } from '../constants';
import { useSelector } from 'react-redux';

export const useAutocompleteTypeahead = field => {
  const uniqueName = `${field.unique_name}__id__${field.id}`;
  const { languageText } = useTextLanguage();
  const { setValue, getValues } = useFormContext();
  const { t } = useTranslation();
  const { codeTier } = useParams();
  const { action } = useSelector(state => state.tier);
  const staticData = useSelector(state => state?.staticData[`data${languageText}`])
  let countries
  if (notUndefinedNull(staticData)) countries = staticData?.country

  useEffect(() => {
    if (codeTier === fileStatus.rejected || codeTier === fileStatus.expired){
      if(getValues(uniqueName)){
        const inputTypeahead = fieldUniqueName(field.unique_name);
        if(uniqueName.includes(nameFormat.country)){
          const option = countries.filter(country => country.country_id === getValues(uniqueName))
          const [value] = option
          const newValue = (value?.idValue) ? value.idValue : ''
          inputTypeahead.dataset.codeId = newValue;
          parentCondition(option, field, setValue, newValue);
        }
      }
    }
  }, [action, field, uniqueName]); 

  const onChange = option => {
    const [value] = option;
    const inputTypeahead = fieldUniqueName(field.unique_name);
    const newValue = (value?.idValue) ? value.idValue : '';
    const action = (field?.action) ? JSON.parse(field.action) : '';
    const textValue = (newValue === '') ? t("common.optionDefault") :  value.textValue;

    setValue(uniqueName, newValue);
    inputTypeahead.dataset.codeId = newValue;

    parentCondition(option, field, setValue, newValue);
    if (notUndefinedNull(field?.id)) moduleCategory(field.id);

    // Case click in action
    if (action !== '' && action?.type === 'click') actionTypeClick(
      action.click, newValue, 'typeahead', setValue, textValue);
  };

  const onLabelKey = option => {
    let label = option.textValue;
    if (option?.[`textValue${languageText}`]) {
      label = option[`textValue${languageText}`];
    }
    return label ? label.trim() : '';
  };

  return { onChange, onLabelKey, t };
};