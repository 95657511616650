import React from 'react';
import { Errors } from '../components/errors/Errors';

/**
 * 400 error page
 * @returns {JSX}
 */
export const ErrorsPage = () => {
  return (
    <Errors />
  );
};

export default ErrorsPage;