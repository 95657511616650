import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRequestList } from '../../hooks';
import { statusRequest } from '../../constants';
import Ellipsis from '../../assets/images/requests/ellipsis.svg';
import Dropdown from 'react-bootstrap/Dropdown';

/**
 * Component with dropdown
 * @component
 * @returns
 */
export const DropdownActions = ({ data }) => {
  const { t } = useTranslation();
  const { onUpdateRequest, status, onEditRequest } = useRequestList();

  return (
    <Dropdown>
      <Dropdown.Toggle variant="transparent">
          <img src={Ellipsis} alt="img" style={{height: "25px", width: "15px", }}/>
      </Dropdown.Toggle>
      {data.status.unique_description === statusRequest.processing && (
        <Dropdown.Menu>
          {!status && (
            <Dropdown.Item onClick={() => onEditRequest(data)}>
              {t("productRequest.edit")}
            </Dropdown.Item>
          )}
          <Dropdown.Item
            onClick={() => onUpdateRequest(data.id, statusRequest.canceled)}
          >
            {t("productRequest.cancel")}
          </Dropdown.Item>
        </Dropdown.Menu>
      )}
      {data.status.unique_description === statusRequest.canceled && (
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => onUpdateRequest(data.id, statusRequest.processing)}
          >
            {t("productRequest.resume")}
          </Dropdown.Item>
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
};