import React from 'react';
import PropTypes from 'prop-types';
import { StyledControl, StyledGroup } from '../../shared/Input/styled';
import { Error, Label, TooltipIcon } from '../../shared/simple';
import {
  useAutocompleteGeolocation,
  useTextLanguage,
  useTextInput,
  useDisabled,
  useEdit
} from '../../../hooks';
import { Col } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { trimLowerCase } from '../../../helpers';

/**
 * Autocomplete Input component
 * @component
 * @param {Object} field description
 */
export const AutocompleteGeolocation = ({ field }) => {
  const uniqueName = `${field.unique_name}__id__${field.id}`;
  const { description, languageText } = useTextLanguage();
  const {
    register,
    formState: { errors },
    setValue
  } = useFormContext();
  const { type } = useTextInput(field, uniqueName);
  const { isDisabled } = useDisabled(field);
  const {
    ref,
    apiKey,
    setValueOnNotSelected
  } = useAutocompleteGeolocation(setValue, uniqueName);
  const { colSize } = useEdit(field);

  return (
    <>
      {field && (
        <>
          {(colSize?.includes('col-before')) && (
            <Col xs={12} key={ `col_before_${field.id}` }></Col>
          )}
          <Col
            className={ colSize }
            data-module={ `module-${field.category?.field_category}` }
            data-name={ uniqueName }
            id={ `group_field_${field.id}` }
            key={ field.id }
            xs={12}
          >
            <StyledGroup className="mb-3">
              <Label htmlFor={ register(uniqueName).name || trimLowerCase(field.id) }>
                { field[description] }{ field.required && (
                  <strong className="text-danger">&nbsp;*</strong>)}
                {field[`tooltip${languageText}`] && (
                  <TooltipIcon tooltipText={ field[`tooltip${languageText}`] } />
                )}
              </Label>
              <StyledControl
                { ...register(uniqueName) }
                { ... (apiKey ? { ref: ref } : { setValue: setValue })}
                as=""
                autoComplete="off"
                className="w-100"
                data-action={ field?.action }
                data-category={ (field?.category) && `category-${field.category.field_category}` }
                data-condition={ field?.parent_condition }
                data-parent={ field?.parent }
                data-unique-name={ field?.unique_name }
                disabled={ isDisabled }
                id={ trimLowerCase(field.id) }
                key={ trimLowerCase(field.id) }
                maxLength={ (field?.max_length) && field.max_length?.toString() }
                placeholder={ field[`placeholder${languageText}`] }
                type={ type }
                onChange={ setValueOnNotSelected }
              />
              {errors?.[uniqueName]?.message && (
                <Error message={ errors[uniqueName].message } />
              )}
            </StyledGroup>
          </Col>
        </>
      )}
    </>
  );
};

AutocompleteGeolocation.propTypes = {
  field: PropTypes.object.isRequired
};