import React from 'react';
import { Col } from 'react-bootstrap';
import { ProductDescription } from '../components/products';

/**
 * Description page of the selected product
 * @returns {JSX}
 */
const ProductDescriptionPage = () => {
  return (
    <Col className="p-0" xs={12}>
      <ProductDescription />
    </Col>
  );
};

export default ProductDescriptionPage;