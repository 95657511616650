import React from 'react';
import { useTranslation } from 'react-i18next';
import { Div, StyledCol, Link } from './styled';
import { envSetting, languagueEs } from '../../../constants';
import { Language } from './Language';
import { Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { trimLowerCase, trimUpperCase } from '../../../helpers';

const fullYear = new Date().getFullYear();

/**
 * Footer component and languages switching
 * @component
 * @returns
 */
export const Footer = () => {
  const { t } = useTranslation();
  const { actualLanguage, requestLanguage } = useSelector(state => state.base);

  return (
    <Div>
      <Row className="m-0">
        <StyledCol md={4} xs={3} displaycol={'flex'}>
          <Link
            href={(actualLanguage) ? (
              (trimUpperCase(actualLanguage) === languagueEs) ? envSetting.termsConditionsEs : envSetting.termsConditionsEn
            ) : (
              envSetting.termsConditionsEn
            )}
            color="third"
            target="_blank"
          >
            {t("footer.termsConditions")}
          </Link>
        </StyledCol>
        <StyledCol
          className="justify-content-center"
          displaycol={'flex'}
          md={4}
          xs={6}
        >
          <Link
            href={ envSetting.companyPage }
            target="_blank"
          >
            {t("footer.company", { company: envSetting.companyName, year: fullYear })}
          </Link>
        </StyledCol>
        <StyledCol
          className="justify-content-end"
          displaycol={'flex'}
          md={4} xs={3}
        >
          <Language />
        </StyledCol>
      </Row>
      <StyledCol
        className="justify-content-center"
        displaycol={'flex'}
        xs={12}
      >
        <div
          id="DigiCertClickID_f8wBj4Ja"
          lang={ trimLowerCase(requestLanguage) }
        ></div>
      </StyledCol>
    </Div>
  );
};