import i18n from '../../translations/i18n';
import { appsOrigin, endpoints, statusRequestOrder } from '../../constants';
import { handleRequest, deepMerge, isUndefinedNull, notUndefinedNull } from '../../helpers';
import { sendGetEndpoint, sendPostRequest } from '../../services/baseApi';
import { setLogout, setMessage, setTier, setLoader } from '.';
import {
  PERSON_DATA_SET_DATA,
  USER_CODE_APP,
  USER_VALIDATED_PHONE,
  USER_VERIFICATION,
  PERSON_DATA_DATA_SENT
} from '../types';
import { store } from '../store/store';

/**** Export functions  ****/
/**
 * Call to endpoint to get person-data
 * @param {String} userIdBifrost
 * @param {String} lastProductRequestId
 * @returns
 */
export const getPersonData = (userIdBifrost, lastProductRequestId) => async dispatch => {
  try {
    const response = await sendGetEndpoint(endpoints.personData(
      userIdBifrost, lastProductRequestId));
    dispatch(handleRequest(response));
    if (!response.error) {
      delete response.error;
      delete response.status;
      const result = response.success;
      const { nextTier } = store.getState().tier;
      const { appOrigin } = store.getState().user;
      const { appOriginData, level, code } = getAppData(result, appOrigin);

      if (result?.product_request?.id) {
        const regularTier = result?.last_regular_tier;
        dispatch({ type: USER_VERIFICATION, payload: level });
        dispatch({ type: USER_CODE_APP, payload: code });
        dispatch({ type: USER_VALIDATED_PHONE, payload: result.validate_phone });
        dispatch(setPersonData(result));
        dispatch(setTier(regularTier));
        if (nextTier?.verification_level) {
          if (regularTier?.verification_level > nextTier.verification_level) dispatch(
            setTier(regularTier));
        } else {
          dispatch(setTier(regularTier));
        }
        return appOriginData;
      } else {
        dispatch(setLogout());
        dispatch(setMessage(i18n.t("common.errorPerson"), 'error'));
        return false;
      }
    } else {
      dispatch(setLogout());
      dispatch(setMessage(i18n.t("common.errorPerson"), 'error'));
      return false;
    }
  } catch (error) {
    console.error('getPersonData: ', error);
    dispatch(setLogout());
    dispatch(setMessage(i18n.t("common.errorPerson"), 'error'));
    return false;
  }
};

/**
 * Call to endpoint to save tier and person data
 * @param {Object} data
 * @param {String} userIdBifrost
 * @returns
 */
 export const personData = (data, userIdBifrost, lastProductRequestId) => async dispatch => {
  try {
    const response = await sendPostRequest(data.elements, endpoints.personData(
      userIdBifrost, lastProductRequestId));
    dispatch(handleRequest(response));
    return response;
  } catch (error) {
    console.error('personData: ', error);
    dispatch(setLoader(false));
    dispatch(setMessage(i18n.t("message.errorPersonData"), 'error'));
    return;
  }
};

/**
 * Call to endpoint to update person data
 * @param {String} userIdBifrost
 * @param {String} lastProductRequestId
 * @returns
 */
export const updatePersonData = (userIdBifrost, lastProductRequestId) => async dispatch => {
  try {
    const response = await sendGetEndpoint(endpoints.personData(userIdBifrost, lastProductRequestId));
    dispatch(handleRequest(response));
    if (!response.error) {
      delete response.error;
      delete response.status;
      const result = response.success;
      if (result) {
        const { appOrigin } = store.getState().user;
        const { level } = getAppData(result, appOrigin);
        dispatch({ type: USER_VERIFICATION, payload: level });
        dispatch(setPersonData(result));
        return true;
      }
      return false;
    } else {
      dispatch(setLogout());
      dispatch(setMessage(i18n.t("message.errorUpdatePerson"), 'error'));
      return false;
    }
  } catch (error) {
    console.error('updatePersonData: ', error);
    dispatch(setLogout());
    dispatch(setMessage(i18n.t("message.errorUpdatePerson"), 'error'));
    return false;
  }
};

/**
 * Get data to app
 * @param {Object} result
 * @returns
 */
export const getAppData = (result, appOrigin) => {
  const { level: levelUser } = store.getState().user;
  let appOriginData, code;
  let level = levelUser;

  if (result.product_request?.id) {
    const productRequest = result.product_request;
    level = (result?.last_regular_tier) ? result.last_regular_tier?.verification_level : 0;
    appOriginData = productRequest.application_origin?.unique_name;
    code = productRequest.user?.code;
  } else {
    if (result?.last_regular_tier) {
      level = result?.last_regular_tier?.verification_level;
    }
    if (appOrigin?.toLowerCase() === appsOrigin.default.toLowerCase() || isUndefinedNull(
      appOrigin)) {
      Array.from(statusRequestOrder).forEach(item => {
        if (isUndefinedNull(appOriginData) && isUndefinedNull(code)) {
          const [productRequest] = Array.from(result.product_request).filter(
            elem => elem.status.unique_description.toLowerCase() === item.toLowerCase());
          if (productRequest?.application_origin) {
            appOriginData = productRequest.application_origin?.unique_name;
            code = productRequest.user?.code;
            level = (level > productRequest.application_origin.tier) ? productRequest.application_origin.tier : level;
          }
        }
      });
    } else {
      const [productRequest] = Array.from(result.product_request).filter(
        elem => elem.application_origin.unique_name.toLowerCase() === appOrigin.toLowerCase());
      if (productRequest?.application_origin) {
        level = (level > productRequest.application_origin.tier) ? productRequest.application_origin.tier : level;
      }
    }
  }

  level = (notUndefinedNull(level)) ? level : 0;

  return { appOriginData, level, code };
};


/**
 * Receives the current array of elements to be sent in POST personData (elements) and save it to the Redux.
 *
 * @param {Object} elements - Elements to be sent as payload in POST personData.
 * @returns {Object}
 */
export const validatePersonData = (elements) => {
  const { data_sent } = store.getState().personData

  /* Merging the data sent in previous steps with the current */
  const allSentElements = deepMerge(elements, data_sent)
  store.dispatch({
    type: PERSON_DATA_DATA_SENT,
    payload: elements
  })

  return allSentElements

}

/**** Local functions  ****/
/**
 * Return action and payload
 * @param {Object} data
 * @returns
 */
const setPersonData = data => ({
  type: PERSON_DATA_SET_DATA,
  payload: {
    data
  }
});