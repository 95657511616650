import React from 'react'
import { StyledContainerQR } from '../styled'
import { InstructionSection, BtnContainer, StyledDialogTitle } from '../../user/styled'
import { t } from 'i18next'
import { Button } from '../../shared/simple'
import { DialogContent, DialogActions, DialogTitle, Dialog } from '@mui/material'
import { browserNames, operativeSystemNames, recorderStatus } from '../../../constants'
import { useRedirectPeer, useDetect, useRecord, useAutovideoQRPage, useVideos } from '../../../hooks'

const AutovideoQRPage = () => {
  const { initPeer, peer, sendData } = useRedirectPeer()
  const { userPermission, videoMessage } = useRecord()
  const { browserInfo, OS } = useDetect()
  const { videoUrl, title } = useVideos()

  /* Extra Styling */
	const btnExtraStyles = {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '8px',
		color: 'white',
		fontWeight: '500'
	}

  const { 
    isOpen, handleClose, handleOpen,
    isWarningOpen, handleWarningOpen, handleWarningClose,
    status, currentVideoSize,
    fileSizeLimit, buttons
  } = useAutovideoQRPage(sendData, browserInfo)

  const handleOpenRecorder = async () => {
    if (!peer) await initPeer()

    handleOpen()
  }

  return (
    <StyledContainerQR>
      <h1>{t('videoRecorder.callToAction')}</h1>

    	<iframe data-video='video-instructions' height={'250px'} src={videoUrl} title={title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      <InstructionSection>
        {t("videoRecorder.instructions")}
        <ol>
          <li>
            {t("videoRecorder.first")}
          </li>
          <li>
            {t("videoRecorder.second")}
          </li>
          <li>
            {t("videoRecorder.third")}<br></br>
          </li>
        </ol>
        <div 
          style={{
            padding: '0.3rem 0.6rem',
            marginTop: '6px'
          }}
          className='alert alert-info'
          role='alert'
        >
          {videoMessage.message}
        </div>
			</InstructionSection>

      { ((browserInfo?.name === browserNames.crios || browserInfo?.name === browserNames.unknown) && OS === operativeSystemNames.iOS ) ? (
        /* Show Warning Message */
        <BtnContainer>
          <Button key='1' color='first' type='button' className='btn' style={btnExtraStyles} onClick={handleWarningOpen}>
            {t('videoRecorder.start')} 
          </Button>
        </BtnContainer>
      ) : (
        /* Show Recorder Modal */
        <BtnContainer>
          <Button key='2' color='first' type='button' className='btn' style={btnExtraStyles} onClick={handleOpenRecorder}>
            {t('videoRecorder.start')} 
          </Button>
        </BtnContainer>
      )}

      {/* Warning modal, for not supported browsers */}
      <Dialog 
        onClose={handleWarningClose} 
        open={isWarningOpen}
        sx={{
          '& .MuiDialog-container': {
            alignItems: 'start'
          }
        }}
      >
        <DialogTitle sx={{ paddingInline: '40px' }}>
          Oops
        </DialogTitle>
        <DialogContent dividers sx={{ padding: '0px'}}>
          <div className='alert alert-warning m-0' role='alert'>
            {t('common.browserNotAllowed')}
          </div>
        </DialogContent>
      </Dialog>

      {/* Modal where the video is recorded */}
      <Dialog 
        onClose={handleClose} 
        open={isOpen}
        sx={{
          '& .MuiPaper-root': {
            margin: '0px', 
            borderRadius: '0px'
          },
          '& .MuiDialog-container': {
            alignItems: 'start'
          }
        }}
      > 
        {(userPermission !== 'denied') ? 
        ( <>
            <DialogTitle sx={{ position: 'relative', padding: '8px' }}>
                <StyledDialogTitle className='text-muted'>
                  <span>
                    {t('videoRecorder.callToAction')}
                  </span>
                </StyledDialogTitle>
            </DialogTitle>
            <DialogContent dividers sx={{ padding: '0px'}}>
              <section style={{position: 'relative'}}>
                {/* Video tag for the recording */}
                <div className='relative' >
                  <video className='' playsInline data-video='video-recording' style={{ maxHeight: '42vh', width: '100%'}} autoPlay muted
                  /> 
                </div>
                {(status !== recorderStatus.inactive && status !== recorderStatus.stopped) ?
                  (<div className='badge rounded-pill bg-dark position-absolute user-select-none'
                        style={{
                          bottom: '8px',
                          left: '2px'
                        }}
                      >
                        {(parseInt(currentVideoSize)/ Math.pow(1024, 2)).toFixed(1)}/{fileSizeLimit}MB
                      </div>
                  ) 
                : null}

                {/* Video tag for the preview */}
                <video className='d-none' data-video='video-preview'
                  style={{ maxHeight: '42vh', width: '100%'}}
                  playsInline
                >
                  <source id='video-preview-src-1' type='video/mp4' />
                  <source id='video-preview-src-2' type='video/webm' />
                  <source id='video-preview-src-3' type='video/ogg' />

                </video>  

              </section>
              
              <InstructionSection>
                {t("videoRecorder.instructions")}
                <ol>
                  <li>
                  {t("videoRecorder.first")}
                  </li>
                  <li>
                  {t("videoRecorder.second")}
                  </li>
                  <li>
                    {t("videoRecorder.third")}<br></br>
                  </li>
                </ol>
                <div 
                  style={{
                    padding: '0.3rem 0.6rem',
                    marginTop: '6px'
                  }}
                  className='alert alert-info'
                  role='alert'
                >
                  {videoMessage.message}
                </div>
    
              </InstructionSection>
              <DialogActions sx={{ display: 'flex', justifyContent: 'center', paddingInline: '40px', paddingBlock: '0px' }}>
                {buttons}
              </DialogActions>
            </DialogContent>
          </>
        ) : null}

        {/* Camera permission denied previously? ↓ */}
        {(userPermission === 'denied') ?
          (<>
            <DialogTitle sx={{ paddingInline: '40px' }}>
              Oops
            </DialogTitle>
            <DialogContent dividers sx={{ padding: '0px'}}>
              <div className='alert alert-warning m-1' role='alert' dangerouslySetInnerHTML={{__html: t('videoRecorder.permissionsDeniedPreviously')}}>
                
              </div>
            </DialogContent>
          </>) : null
        }
      </Dialog>

    </StyledContainerQR>
  )
}

export default AutovideoQRPage