import React from 'react';
import PropTypes from 'prop-types';
import { IoIosAdd } from 'react-icons/io';
import styled from 'styled-components';

const Btn = styled.button`
  width:30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding:0;
  border-radius: 100%;
  position: absolute;
  right:20px;
  top:-15px;
`;

/**
 * Component button
 * @component
 * @param {Function} handleAdd - Function that will take care of handling the adding of elements to the list.
 * @returns {JSX.Element}
 */
export const Add = ({ handleAdd }) => {
  return (
    <Btn className="btn btn-success" type="button" onClick={handleAdd}>
      <IoIosAdd className="fs-2" />
    </Btn>
  );
};

Add.propTypes = {
  handleAdd: PropTypes.func.isRequired
};
