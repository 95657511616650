import i18n from '../../translations/i18n';
import { handleRequest } from '../../helpers';
import { migrateSubmit } from '../../services/tierApi';
import { setLoader, setMessage, setDelay, getProductRequest } from '.';

/**** Export functions  ****/
/**
 * Send migrat or update birfrost tier
 * @param {String} userIdBifrost
 * @param {Object} migratable
 * @param {String} userId
 * @param {String} productRequestId
 * @returns
 */
export const migrateBifrost = (
  userIdBifrost,
  migratable,
  userId,
  productRequestId
) => async dispatch => {
  try {
    let response = { error: false };

    if (migratable.edit) {
      response = await migrateSubmit(
        migratable.tier, userIdBifrost, migratable.migrate, productRequestId);
      dispatch(handleRequest(response, false));
      if (response) {
        dispatch(getProductRequest(userId));
      }
    }
    return response;
  } catch (error) {
    console.error('migrateBifrost: ', error);
    dispatch(setLoader(false));
    dispatch(setDelay(false));
    dispatch(setMessage(i18n.t("message.errorMigrate"), 'error'));
    return;
  }
};