import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import { BtnContainer } from '../../user/styled'
import { Button } from '../../shared/simple'
import { IoCheckmark, IoClose } from 'react-icons/io5'
import { t } from 'i18next'
import { fieldAttribute } from '../../../helpers'
import { setMessage } from '../../../redux/actions'
import { recorderStatus } from '../../../constants'

const IncomingVideoModal = (props) => {
  const { onCloseIncomingVideoModal, onCloseQR, open, payload, field, setValue, setStatus } = props
  const uniqueName = `${field.unique_name || field.file_name}__id__${field.id}`

  const handleClose = () => {
    onCloseIncomingVideoModal()
  };

  const btnExtraStyles = {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '8px',
		color: 'white',
		fontWeight: '500'
	}

  const handleContinue = () => {
    const placeholderImage = fieldAttribute('data-image', `image-${uniqueName}`)
		const videoPreview = fieldAttribute('data-video', `video-${field.unique_name}-preview`)
    const input = fieldAttribute('data-unique-name', `${field.unique_name || field.file_name}`)

    const file = new File([payload?.data], 'autovideo', { type: 'video/mp4' })
    const transferer = new DataTransfer()
    transferer.items.add(file)
    setValue(uniqueName, file)
    input.files = transferer.files
    videoPreview.classList.remove('d-none')
    videoPreview.src = URL.createObjectURL(file)
    placeholderImage.classList.add('d-none')
    setMessage(t('videoRecorder.uploadSuccess'), 'success')
    setStatus(recorderStatus.receivedFromQR)
    onCloseIncomingVideoModal()
    onCloseQR()
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{t('videoRecorder.recordedVideoTitle')}</DialogTitle>
      <DialogContent>
        <section style={{position: 'relative'}}>
          {/* Video tag for the preview */}
          <video data-video='video-preview' id='preview-qr'
            controls
            style={{
              width: '100%',
            }}
          >
            <source src='' id='preview-qr-src-1' type='video/mp4' />
            <source src='' id='preview-qr-src-2' type='video/webm' />
            <source src='' id='preview-qr-src-3' type='video/ogg' />

          </video> 
        </section>

        <DialogActions sx={{ display: 'flex', justifyContent: 'center', paddingInline: '40px', paddingBlock: '0px' }}>
          <BtnContainer>
            <Button key='6' onClick={handleContinue} color='first' type='button' className='btn' style={btnExtraStyles}>
              {t('videoRecorder.accept')} 
              <IoCheckmark />
            </Button>
            <Button key='7' onClick={handleClose} color='fourth' type='button' className='btn' style={btnExtraStyles}>
              {t('videoRecorder.retry')}
              <IoClose />
            </Button>
          </BtnContainer>
        </DialogActions>
      </DialogContent>

    </Dialog>
  );
}


export default IncomingVideoModal