import * as yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  avatarImage,
  envSetting,
  fileStatus,
  nameFormat,
  siteMap,
  typeImageFiles,
  typeVideoFiles,
  userFilesCode,
  videoFile
} from '../constants';
import {
  personData,
  saveUserImage,
  setLoader,
  setMessage,
  updatePersonData
} from '../redux/actions';
import { useNavigate } from 'react-router-dom';
import {
  fieldAttribute,
  isUndefinedNull,
  notUndefinedNull,
  removeValidations,
  sortElementInt,
  trimLowerCase,
  validateRequestType
} from '../helpers';
import { t } from 'i18next';
import { useForm, useFormContext } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TIER_VALUES_EDIT } from '../redux/types';
import { useParams } from 'react-router-dom';
import documentIconSource from '../assets/images/shared/pdf-file-icon.svg';

/**
 * Change values to fields
 * @param {Object} field
 * @param {String} type
 * @returns
 */
export const useFile = (field, type) => {
  const { codeTier } = useParams();
  const { setValue } = useFormContext();
  const dispatch = useDispatch();
  const uniqueName = `${field.unique_name || field.file_name}__id__${field.id}`;

  const fileSizeLimit = Math.round(parseInt(
    type === 'document' || type === 'image' ? envSetting.fileSize : (
      type === 'video' && envSetting.videoSize)) / Math.pow(1024, 2), 2);

  let newImgFileType = typeImageFiles;
  if(codeTier === 'advanced'){ // Modifying the accepted file types, based on its tier.
    newImgFileType = newImgFileType.filter(imgType => !imgType.includes('pdf'));
  }

  const onChange = ({ target: { files: comingFiles } }) => {
    if (comingFiles) {
      const [file] = comingFiles;
      if (file?.size) {
        const size = file.size;
        const typeFile = file.type;
        let error = false;

        if ((size > envSetting.fileSize) && (
          type === 'image' || type === 'document')) {
          dispatch(setMessage(
            t("files.errors.maxFileSize", { size: fileSizeLimit }), 'error'));
          error = true;
        } else if ((type === 'image' || type === 'document') && (
          newImgFileType.findIndex(item => item === typeFile) < 0)) {
          dispatch(setMessage(t("files.errors.typeFileNotAllowed"), 'error'));
          error = true;
        } else if (type === 'video' && size > envSetting.videoSize) {
          dispatch(setMessage(
            t("files.errors.maxVideoSize", { size: fileSizeLimit }), 'error'));
          error = true;
        } else if (type === 'video' && (typeVideoFiles.findIndex(
          item => item === typeFile) < 0)) {
          dispatch(setMessage(t("files.errors.typeFileNotAllowed"), 'error'));
          error = true;
        }

        if (!error) {
          const [currentFile] = Array.prototype.slice.call(comingFiles);
          const imageChange = fieldAttribute(
            'data-image', `image-${field.unique_name}`);
          const videoChange = fieldAttribute(
            'data-video', `video-${field.unique_name}`);
          field.file_size = currentFile?.size;
          field.input_file = true;
          if (imageChange) imageChange.classList.remove('d-none');
          if (videoChange) videoChange.classList.add('d-none');
          if (type === 'video') {
            //* Uncomment next 2 lines if need to use FileInputButton component 
            // const videoChange = fieldAttribute(
            //   'data-video', `video-${field.unique_name}`);
            let videoChange = fieldAttribute(
              'data-video', `video-${field.unique_name}-preview`);
            videoChange?.classList.remove('d-none');
            if (videoChange){
              videoChange.src = URL.createObjectURL(file);
            } else {
              videoChange = fieldAttribute('data-video', `video-${field.unique_name}`);
              videoChange.src = URL.createObjectURL(file);
            }
            videoChange?.classList.remove('d-none')
            imageChange?.classList.add('d-none');
          }
          if (type === 'image') {
            if (file.type === 'application/pdf') {
              imageChange.src = documentIconSource;
            } else {
              imageChange.src = URL.createObjectURL(file);
            }
            const transferer = new DataTransfer()
            const input = fieldAttribute('data-unique-name', `${field.unique_name || field.file_name}`)
            transferer.items.add(currentFile)
            setValue(uniqueName || field.file_name, currentFile)
            input.files = transferer.files
          }
        } else {
          setValue(uniqueName || field.file_name, '');
        }
      }
    }
  };

  return { onChange, uniqueName, fileSizeLimit };
};

/**
 * Get fields to files
 * @param {Object} data
 * @returns
 */
export const useFilesFields = data => {
  const [tierFieldsFile, setTierFieldsFile] = useState([]);
  const [fileFields, setFileFields] = useState([]); // seem to hold all of the 'fields' related to the received files ('data')
  const { requestLanguage } = useSelector(state => state.base);
  const {
    field: fields,
    tier_field: tierField,
    field_category: fieldCategory,
    document_type: documentTypes
  } = useSelector(state => state.staticData[`data_${trimLowerCase(requestLanguage)}`]);

  const getFields = useCallback(() => {
    const identityData = sortElementInt(fieldCategory.filter(
      item => item.unique_name.includes(nameFormat.identityData)), 'id');
    const tierFields = sortElementInt(tierField, 'order');
    let categories = [];
    let fieldAll = [];

    data.forEach(item => {
      let category = [];
      let documentType = [];
      let videoMessage;

      if (notUndefinedNull(item.client_document_type_id)) {
        const index = documentTypes.findIndex(
          element => item.client_document_type_id === element.document_type_id);
        if (index >= 0) {
          category = identityData.filter(
            element => element.unique_name === trimLowerCase(
              `${nameFormat.identityData}${documentTypes[index].code}`)).pop();
          documentType = documentTypes[index];
        }
      } else {
        category = fieldCategory.filter(
          element => element.unique_name === trimLowerCase(
            nameFormat.identityProtectionData)).pop();
        if (item.file_name.includes(videoFile)) {
          videoMessage = fields.filter(element => element.unique_name.includes(
            userFilesCode.videoMessage)).pop();
        }
      }

      const index = categories.findIndex(
        current => current.category.id === category.id);
      if (category?.id) {
        if (category?.show_category !== true) category[
          'show_category'] = item.show_category;
        const tierCategoryFile = sortElementInt(tierFields.filter(
          element => element.field_category === category?.id), 'order');
        tierCategoryFile.forEach(element => {
          const iField = fields.findIndex(
            current => element.field === current.id);
          if (iField >= 0) {
            const field = removeValidations(
              fields[iField], videoMessage, category, item, element, categories);
            const iAll = fieldAll.findIndex(current => current.id === field.id);
            if (iAll < 0) {
              fieldAll.push(field);
              element['data'] = field;
            } else {
              if (category?.show_category !== true) {
                fieldAll[iAll] = field;
                const iCategory = categories[index]?.fields.findIndex(
                  current => current.field === field.id);
                if (iCategory >= 0) {
                  categories[index].fields[iCategory].data = field;
                }
              }
            }
            element.data['category'] = { 'field_category': category.id };
          }
        });
        const [tierCategoryFileOrder] = tierCategoryFile;
        if (index < 0) {
          categories.push({
            category,
            documentType,
            fields: tierCategoryFile,
            order: tierCategoryFileOrder.order,
          });
        }
      }
    });

    setTierFieldsFile(sortElementInt(categories, 'order'));
    setFileFields(fieldAll);
  }, [data, documentTypes, fieldCategory, fields, tierField]);

  useEffect(() => {
    getFields();
  }, [getFields]);

  return { tierFieldsFile, fileFields };
};

/**
 * Get expired/rejected fields
 * @param {String} codeTier
 * @returns
 */
export const usePersonFiles = codeTier => {
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const { data: {
    documents,
    user_files: userFiles
  } } = useSelector(state => state.personData);
  const { requestLanguage, expiredFiles, rejectedFiles } = useSelector(
    state => state.base);
  const { document_type: documentTypes, field: fields } = useSelector(
    state => state.staticData[`data_${trimLowerCase(requestLanguage)}`]);

  if (codeTier === fileStatus.expired) {
    if (!expiredFiles) navigate(siteMap.homepage.path);
  }

  if (codeTier === fileStatus.rejected) {
    if (!rejectedFiles) navigate(siteMap.homepage.path);
  }

  const getFields = useCallback(() => {
    if (codeTier === fileStatus.expired) {
      const fileExpired = [];
      documents.forEach(item => {
        fileExpired.push(item);
        if (item.expiration_date_notified_period === fileStatus.expired) {
          fileExpired[fileExpired.length - 1]['show_category'] = true;
        } else {
          fileExpired[fileExpired.length - 1]['show_category'] = false;
        }
      });
      setFiles(fileExpired);
    }

    if (codeTier === fileStatus.rejected) {
      const fileRejected = [];
      userFiles.forEach(item => {
        if (avatarImage !== item.file_name) {
          fileRejected.push(item);
          const fieldRequest = fields.filter(element => JSON.parse(
            element?.request_type)?.name === item.file_name)?.pop();
          if (notUndefinedNull(fieldRequest.request_type)) {
            const requestType = JSON.parse(fieldRequest.request_type);
            if (userFilesCode[requestType.name]) {
              const fileRejected = documentTypes.filter(element => trimLowerCase(
                element.code) === trimLowerCase(userFilesCode[
                  requestType.name]))?.pop();
              item['client_document_type_id'] = fileRejected?.document_type_id;
              item['field_request'] = fieldRequest;
            }
          }
          if (item.user_file_status && trimLowerCase(
            item.user_file_status.unique_description) === fileStatus.rejected) {
            fileRejected[fileRejected.length - 1]['show_category'] = true;
          } else {
            fileRejected[fileRejected.length - 1]['show_category'] = false;
          }
        }
      });
      setFiles(fileRejected);
    }
  }, [codeTier, documents, userFiles, fields, documentTypes]);

  useEffect(() => {
    getFields();
  }, [getFields]);

  return { files };
};

/**
 * Hook to form files
 * @param {Object} fileFields
 * @param {Object} schemaTier
 * @returns
 */
export const useFormFiles = (fileFields, schemaTier) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { firstProductRequestId, lastProductRequestId, userIdBifrost } = useSelector(
    state => state.user);
  const { defaultValuesTier } = useSelector(state => state.tier);
  const { data: { id: userId } } = useSelector(state => state.personData);
  const { location } = useSelector(state => state.base);
  const methods = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(yup.object(schemaTier))
  });
  const [tierFieldsEdit, setTierFieldEdit] = useState(null);

  // Effect runs when user state is updated
  useEffect(() => {
    // Reset form
    methods.reset(defaultValuesTier);
  }, [defaultValuesTier, methods]);

  useEffect(() => {
    if (isUndefinedNull(tierFieldsEdit) && notUndefinedNull(defaultValuesTier)) {
      dispatch({ type: TIER_VALUES_EDIT, payload: defaultValuesTier });
      setTierFieldEdit(defaultValuesTier);
    }
  }, [dispatch, defaultValuesTier, tierFieldsEdit]);

  const onSubmit = async values => {
    try {
      dispatch(setLoader(true));
      const formObject = validateRequestType(values, fileFields, userId);
      if (formObject.isElement || formObject.isMedia) {
        formObject.isMedia = false;
        formObject.elements['country_code'] = location;
        const response = await dispatch(personData(
          formObject, userIdBifrost, firstProductRequestId));
        if (response && !response.error) {
          if (await dispatch(saveUserImage(userIdBifrost, formObject.formMedia, false, true))) {
            if (await dispatch(updatePersonData(userIdBifrost, lastProductRequestId))) {
              dispatch(setMessage(t("common.filesSubmmited"), 'success'));
              navigate(siteMap.homepage.path);
              dispatch(setLoader(false));
            }
          } else {
            dispatch(setLoader(false));
          }
        } else {
          dispatch(setLoader(false));
        }
      } else {
        dispatch(setMessage(t("common.filesSubmmitedError"), 'error'));
        dispatch(setLoader(false));
      }
    } catch (error) {
      console.error('onSubmit in useFormFiles: ', error);
      dispatch(setMessage(t("common.filesSubmmitedError"), 'error'));
      dispatch(setLoader(false));
    }
  };

  return { methods, onSubmit };
};