import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from './Link';
import { siteMap } from '../../../constants';
import styled from 'styled-components';

// Define element styles for the component
const LinkText = styled(Link)`
  font-size: 18px !important;
  cursor: pointer;
`;

/**
 * Back button component for back homepage
 * @component
 * @returns
 */
export const Back = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleGoBack = async() => {
    if (localStorage.getItem("idRequest")){
      localStorage.removeItem("idRequest");
    }
    navigate(siteMap.homepage.path);
  };

  return (
    <LinkText color="first" onClick={ handleGoBack } name="link_back">
      {`< ${ t("common.backHomepage") }`}
    </LinkText>
  );
};