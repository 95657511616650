import React from 'react';
import { useChangePassword } from '../../../hooks';
import { useTranslation } from 'react-i18next';
import { Button, LoaderSpinner, StyledForm } from '../../shared/simple';
import { Col, Form as FormBootstrap } from 'react-bootstrap';
import { Input } from '../../shared/Input';
import { characters } from '../../../constants';

/**
 * Change Password Component
 * @component
 * @returns
 */
export const Password = () => {
  const { t } = useTranslation();
  const {
    errors,
    handleSubmit,
    loading,
    onSubmit,
    register
  } = useChangePassword();

  return (
    <StyledForm
      className="p-0 m-0 w-100"
      onSubmit={ handleSubmit(onSubmit) }
    >
      <Col className="col-xs-12">
        <p>
          { t("profile.passwordEdit") }
        </p>
      </Col>
      <Col className="col-xs-12">
        <p>
          { t("password.currentHelp", { min: 8 }) }
        </p>
      </Col>
      <Col className="col-xs-12 col-lg-6">
        <Input
          autoComplete="off"
          disabled={ loading }
          isRequiredInput={ true }
          label={ t("password.current") }
          message={ errors.current_password?.message }
          name={ register("current_password").name }
          register={ register("current_password") }
          type="password"
        />
      </Col>
      <Col className="col-xs-12 col-lg-6">
        <Input
          autoComplete="off"
          disabled={ loading }
          isRequiredInput={ true }
          label={ t("password.new") }
          message={ errors.new_password?.message }
          name={ register("new_password").name }
          register={ register("new_password") }
          type="password"
        />
      </Col>
      <Col className="col-xs-12 col-lg-6">
        <Input
          autoComplete="off"
          disabled={ loading }
          isRequiredInput={ true }
          label={ t("password.repeat") }
          message={ errors.confirm_password?.message }
          name={ register("confirm_password").name }
          register={ register("confirm_password") }
          type="password"
        />
      </Col>
      <Col className="col-xs-12 mb-3 mt-3">
        <FormBootstrap.Text muted>
          <strong className="text-danger">&nbsp;*&nbsp;</strong>
          { t("getStarted.passwordRequired", { min: 8, characters }) }
        </FormBootstrap.Text>
      </Col>
      <Col className="pt-1" xs={12} lg={6}>
        {loading
          ? (
            <LoaderSpinner />
          ) : (
            <Button
              className="btn mb-0"
              disabled={ loading }
              color="first"
              type="submit"
              name="btn_save_password"
            >
              { t("common.submit") }
            </Button>
          )
        }
      </Col>
    </StyledForm>
  );
};