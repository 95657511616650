import i18n from '../translations/i18n';
import {
  fieldsAllowedCountries,
  hiddenField,
  mediaPNG,
  paramsAdd,
  paramsEdit
} from '../constants';
import { moduleCategory } from './tier';
import { searchStaticData } from './staticData';
import {
  fieldAttribute,
  fieldsUniqueName,
  fieldUniqueName,
  isEmpty,
  isUndefinedNull,
  notUndefinedNull,
  sortElement,
  trimLowerCase,
  trimUpperCase
} from './shared';
import { store } from '../redux/store/store';
import { USER_APP_ORIGIN } from '../redux/types';
import { checkValue } from './conditions';
import { sendGetEndpointData, setLoader, setMessage } from '../redux/actions';
import { setFileCases } from './files';
import { languagueEs } from '../constants';

/**** Export functions  ****/
/**
 * Validate app origin
 * @param {Object} lastAppOrigin
 * @param {String} param
 * @param {Boolean} isAuthenticated
 * @returns
 */
export const validateAppOrigin = (lastAppOrigin, param, isAuthenticated) => {
  const { appParam } = getAppOrigin(param);

  if (notUndefinedNull(isAuthenticated)) {
    if ((lastAppOrigin !== appParam || !notUndefinedNull(
      lastAppOrigin)) && !isAuthenticated) {
      const appOrigin = appParam;
      store.dispatch({ type: USER_APP_ORIGIN, payload: appOrigin });
    }
  }

};

/**
 * Save field
 * @param {Object} field
 * @param {Object} fieldSelect
 * @returns
 */
export const saveFieldValue = (field, fieldSelect) => {
  const { data: personData } = store.getState().personData;
  const allField = [...fieldSelect];
  const fieldObject = JSON.parse(field.dataset?.object);
  const name = field.name.split('__id__').shift().split('__').pop();
  const index = Array.from(allField).findIndex(
    elem => elem[name]?.toString() === fieldObject?.idValue.toString());
  const newObject = {
    user: personData.id
  };
  newObject[name] = fieldObject?.idValue;

  return { allField, newObject, index };
};

/**
 * Create File
 * @param {Object} dataURI
 * @param {String} fileName
 * @returns
 */
export const dataURItoBlob = (dataURI, fileName) => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // New Code
  const blob = new Blob([ab], { type: mimeString });
  const file = new File([blob], fileName, { type: mediaPNG });

  return file;
};

/**
 * Get if field show or not
 * @param {String} field
 * @returns
 */
export const getShowField = field => {
  const { appOrigin } = store.getState().user;
  const condition = (field?.parent_condition) ? JSON.parse(
    field.parent_condition) : field?.parent_condition;
  let response = true;

  if (notUndefinedNull(condition?.app)) {
      response = Array.from(condition.app).some(item => item === appOrigin);
  }
  if (notUndefinedNull(condition?.app_except)) {
    response = !Array.from(condition?.app_except).some(item => item === appOrigin)
  }

  return response;
};

/**
 * Validate where to get the data from
 * @param {String} typeData
 * @param {Object} getValues
 * @param {Boolean} isStart
 * @param {Function} setValue
 * @returns
 */
export const sendData = (
  typeData,
  getValues,
  isStart,
  setValue = null
) => {
  const { data: personData } = store.getState().personData;
  const base = store.getState().base;
  const { actualLanguage } = base;
  const { [`data_${trimLowerCase(
    actualLanguage)}`]: staticData } = store.getState().staticData;
  let response;

  if (typeData === 'static_data') {
    response = (isStart) ? validateData(getValues, staticData) : searchData(
      getValues, staticData.field, setValue);
  } else if (typeData === 'person_data') {
    response = validateData(getValues, personData);
  } else if (typeData === 'base') {
    response = (isStart) ? validateData(getValues, base) : searchData(
      getValues, staticData.field, setValue);
  } else {
    response = validateData(getValues, []);
  }

  return response;
};

/**
 * Change to options
 * @param {Object} element
 * @param {Object} field
 * @param {Object} data
 * @param {Function} setValue
 */
export const putSelect = (element, field, data, setValue = null) => {
  const { actualLanguage } = store.getState().base;
  const { [`data_${trimLowerCase(
    actualLanguage)}`]: staticData } = store.getState().staticData;
  const getValues = JSON.parse(element?.function_get_values);
  const response = validateData(getValues, staticData);
  let newValues;

  if (response && data) {
    Array.from(field.children).forEach((item, idx) => {
      if (idx !== 0) field.removeChild(item);
    });

    if (data?.searchSelect) {
      newValues = Array.from(response).filter(
        item => trimLowerCase(item?.codeParent) === trimLowerCase(data.value));
    } else {
      newValues = Array.from(response).filter(
        item => trimLowerCase(item[getValues?.compare]) === trimLowerCase(
          data[getValues?.search]));
    }

    if(data?.company_branches?.length){
      newValues = [...data?.company_branches]
    }

    Array.from(newValues).forEach(item => {
      const option = document.createElement('option');
      option.id = item[getValues?.id];
      option.value = item[getValues?.id];
      option.text = item[getValues?.value];
      option.setAttribute('data-type', 'new');
      if (getValues?.code) option.setAttribute('data-code', getValues?.code);
      if (data?.searchSelect) option.setAttribute(
        'data-parent', trimUpperCase(data.value));
      field.appendChild(option);
    });
    if (notUndefinedNull(setValue)) setValue(field.name, '');
    element.options = true;
  }
  if (newValues?.length === 0 || response?.length === 0 || !data) {
    element.options = false;
  }
};

/**
 * Evaluate default value in field type aux
 * @param {Object} field
 * @param {Function} setValue
 */
export const defaultAux = (field, setValue, uniqueName = null) => {
  const { data: personData } = store.getState().personData;
  const defaultValue = (isUndefinedNull(field.default_value)) ? '' : JSON.parse(
    field.default_value);
  if (defaultValue?.type === 'set' && defaultValue?.fields) {
    defaultValueFieldsAux(defaultValue.fields, personData.id, setValue);
  }
  if (defaultValue?.type === 'endpoint') endpointDefaultValue(field, setValue);
  if (defaultValue?.type === 'static') setValue(uniqueName, defaultValue.text);
  if (defaultValue?.type === 'base') {
    if (defaultValue?.text) setValue(uniqueName, defaultValue.text.toUpperCase());
  }

};

/**
 * Add or remove attributes in field
 * @param {Object} field
 * @param {Object} attributes
 */
export const handleAttributes = (field, attributes) => {
  Array.from(attributes).forEach(attribute => {
    if (attribute?.name === 'checked') {
      field.checked = true;
    }
    if (attribute?.name === 'disabled') {
      field.disabled = true;
    }
    if (attribute?.name === 'not_disabled') {
      field.disabled = false;
    }
    if (attribute?.name === 'show') {
      field.value = '';
      const divGroup = fieldAttribute(
        'data-name', field.name, `div#group_field_${field.id}`);
      if (notUndefinedNull(divGroup)) {
        divGroup.classList.remove('d-none');
        if (notUndefinedNull(field?.id)) moduleCategory(field.id);
      }
    }
  });
};

/**
 * Get default value to endpoint
 * @param {Object} field
 * @param {Function} setValue
 */
export const endpointDefaultValue = async (field, setValue) => {
  const { action } = store.getState().tier;
  const defaultValue = (notUndefinedNull(field.default_value)) ? JSON.parse(
    field.default_value) : null;
  const actionValue = (notUndefinedNull(field.action)) ? JSON.parse(
    field.action) : null;
  const functionGetValues = (notUndefinedNull(
    field.function_get_values)) ? JSON.parse(field.function_get_values) : null;
  const condition = (notUndefinedNull(field.parent_condition)) ? JSON.parse(
    field.parent_condition) : null;
  let isSendEndpointData = true;

  if (notUndefinedNull(defaultValue) && functionGetValues.type === 'endpoint' && (
    action === paramsAdd || (action === paramsEdit && field.editable_after_migrate))) {
    let params = {};
    if (defaultValue.params === 'key') {
      Array.from(defaultValue?.fields).forEach(item => {
        const uniqueName = fieldUniqueName(item.name);
        if (uniqueName) {
          let value = uniqueName.value;
          if (value === hiddenField) {
            const { isValid, newValue } = checkValue(
              condition, setValue, false);
            value = (isValid) ? newValue : value;
          }
          isSendEndpointData = (value === hiddenField) ? false :
            isSendEndpointData;
          params[actionValue?.keys[item.name]] = value;
        }
      });
      functionGetValues.compare = params;
    }

    if (isSendEndpointData) {
      store.dispatch(setLoader(true));
      const response = await sendGetEndpointData(functionGetValues);
      store.dispatch(setLoader(false));
      validateResponse(response, field, setValue, defaultValue?.action);
    } else {
      store.dispatch(setMessage(i18n.t("files.getFilesData"), 'error'));
      // Case document
      if (condition?.type === 'file') {
        const [nameField] = condition.condition;
        const allFields = fieldsUniqueName(nameField.name);
        let nameFieldValue, id;
        Array.from(allFields).forEach(item => {
          if (item.type === 'checkbox') {
            item.checked = false;
            item.disabled = false;
            nameFieldValue = item.name;
            id = item.dataset.fieldId;
          }
        });
        setValue(nameFieldValue, []);
        moduleCategory(id, true);
      }
    }
  }
};

/**
 * Validate the selected country of residence
 * @param {Object} elements
 * @param {Object} allowedCountries
 * @param {Object} tier
 * @returns
 */
export const validateCountry = (elements, allowedCountries, tier) => {
  let isValid = true;

  if (fieldsAllowedCountries?.[tier]) {
    Array.from(fieldsAllowedCountries[tier]).forEach(item => {
      if (elements?.[item] && isValid) {
        isValid = allowedCountries?.some(elem => trimUpperCase(
          elem.country_id) === trimUpperCase(elements[item]));
      }
    });
  }

  return isValid;
};

/**
 * Validate if an assistant is needed to complete a form
 * @param {String} location
 * @param {Array} assistantRules
 * @returns
 */
export const validateAssistantRule = (location, assistantRules) => {
  let needAssistant = false;

  Array.from(assistantRules).forEach(rule => {
    if (trimLowerCase(rule.country_code) === trimLowerCase(
      location) && rule.active) {
      needAssistant = true;
    } else if (rule.active) {
      needAssistant = true;
    }
  });

  return needAssistant;
};


/**
 * Search list objects that satisfy denpendancy input condition
 * @param {Object} functionGetValues
 * @param {Object} formValues
 * @param {Array}  listObject
 * @param {Object} fieldAction
 * @returns
 */
export const searchDependentValue = async (functionGetValues, formValues, listObject, fieldAction) => {
  const [dependsValues] = functionGetValues?.depends || [];
  let result;
  let dependsField;
  if (functionGetValues.type === 'static_data' && typeof (
    dependsValues) === 'object') {
    dependsField = Array.from(Object.keys(formValues)).find(
      item => item.includes(dependsValues.field));
    if (notUndefinedNull(dependsField) && !isEmpty(formValues[
      dependsField]) && notUndefinedNull(formValues[dependsField])) {
      const searchedObject = await searchStaticData(
        dependsValues.name, dependsValues.search, formValues[dependsField]);
      if(listObject?.length){
        result = listObject.filter(item => item[
          dependsValues.search_value] === searchedObject?.[dependsValues.compare]);
      } else{
        let optionsProp = `${fieldAction.dependent_value}`
        result = searchedObject[`${optionsProp}`].map(result => {
          return {
            ...result,
            description: result.name || '',
            textValue: result.name || '',
            active: true,
            exclude: false,
            idValue: result.branch_id || null
          }
        })
      }
    }
  }

  return { result, dependsField };
};

/**
 * IMPORTANT description missing
 * @param {*} action
 * @param {*} value
 * @param {*} type
 * @param {*} setValue
 * @param {*} textValue
 */
export const actionTypeClick = (action, value, type, setValue, textValue) => {
  if (type === 'typeahead') {
    Array.from(action).forEach(item => {
      if (item.type === 'equal') {
        const inputTypeahead = fieldUniqueName(item.field);
        inputTypeahead.dataset.codeId = value;
        inputTypeahead.value = textValue;
        setValue(inputTypeahead.name, value);
      }
    });
  }
};

/**
 * IMPORTANT description missing
 * @param {*} currentLanguage
 * @param {*} date
 * @returns
 */
export const formatDate = (currentLanguage, date) => {
  const newDate = new Date(date);
  const day = currentLanguage === languagueEs ? ((newDate.getDate() < 10) ? `0${
    newDate.getDate()}` : newDate.getDate()) : (((newDate.getMonth() + 1) < 10) ? `0${
    newDate.getMonth() + 1}` : newDate.getMonth() + 1);
  const month = currentLanguage === languagueEs ? (((newDate.getMonth() + 1) < 10) ? `0${
    newDate.getMonth() + 1}` : newDate.getMonth() + 1) : ((newDate.getDate() < 10) ? `0${
      newDate.getDate()}` : newDate.getDate());
  return (`${day}/${month}/${newDate.getFullYear()}`);
};

/**** Local functions  ****/
/**
 * Get app origin
 * @param {String} param
 * @returns
 */
const getAppOrigin = param => {
  const appParam = param;

  return { appParam };
};

/**
 * Get value to static_data/static/person_data
 * @param {Object} valuesData
 * @param {Object} data
 * @returns
 */
const validateData = (valuesData, data) => {
  const { appOrigin } = store.getState().user;
  let response = [];

  if (valuesData?.type === 'static') {
    const responseSplit = valuesData?.values.split(';');
    responseSplit.forEach(elem => {
      const value = elem.split(':').pop();
      const index = elem.split(':').shift();
      response.push(
        { idValue: index, textValue: value, codeValue: index, active: true });
    });
    response = sortElement(response, 'textValue');
  } else {
    const result = data[valuesData?.name];
    if (!result?.length && notUndefinedNull(result)) {
      response = result;
    } else {
      if (result) {
        const { actualLanguage } = store.getState().base;
        result?.forEach((elem, index) => {
          let isSave = true;
          if (valuesData?.exclude) {
            const excludes = Array.from(valuesData.exclude).map(current => {
              if (valuesData?.parent) {
                let parentExclude = [...valuesData.parent];
                parentExclude = parentExclude.pop();
                if (valuesData?.app) {
                  if (trimLowerCase(valuesData.app).includes(trimLowerCase(
                    appOrigin)) && trimLowerCase(current?.value) === trimLowerCase(
                      elem[parentExclude.name][parentExclude.code])) {
                    return elem;
                  }
                } else {
                  if (trimLowerCase(current?.value) === trimLowerCase(elem[
                    parentExclude.name][parentExclude.code])) {
                    return elem;
                  }
                }
              } else {
                if (String(elem[current?.type]) !== hiddenField) {
                  if (valuesData?.app) {
                    if (trimLowerCase(valuesData.app).includes(trimLowerCase(
                      appOrigin)) && trimLowerCase(current?.value) === trimLowerCase(
                        elem[current?.type])) {
                      return elem;
                    }
                  } else {
                    if (trimLowerCase(current?.value) === trimLowerCase(elem[
                      current?.type])) {
                      return elem;
                    }
                  }
                }
              }
              return null;
            }).filter(current => notUndefinedNull(current));
            isSave = (excludes?.pop()) ? false : true;
          }
          if (isSave) {
            if (isUndefinedNull(elem?.active)) {
              result[index].active = true;
            }

            result[index].idValue = elem[valuesData?.id];
            result[index].textValue = elem[valuesData?.value];
            if (elem[`${valuesData?.value}_${trimLowerCase(actualLanguage)}`]) {
              result[index][`textValue_${trimLowerCase(actualLanguage)}`] = elem[
                `${valuesData.value}_${trimLowerCase(actualLanguage)}`];
            }

            result[index].exclude = !isSave;
            if (elem[valuesData?.code]) {
              result[index].codeValue = elem[valuesData.code].trim();
            }
            if (elem[valuesData?.change]) {
              result[index].codeId = elem[valuesData.change];
            }
            if (valuesData?.parent) {
              let parent = [...valuesData.parent];
              parent = parent.pop();
              result[index].codeParent = (elem[parent?.name]) ? elem[
                parent.name][parent.code] : elem[parent.code];
              if (parent.type === 'id') {
                result[index].codeId = result[index].codeParent;
              }
            }
            response.push(result[index]);
          }
        });
        response = sortElement(response, 'textValue');
      }
    }
  }

  return response;
};

/**
 * Search data
 * @param {Object} valuesData
 * @param {Object} data
 * @param {Function} setValue
 * @returns
 */
const searchData = async (valuesData, data, setValue) => {
  const putValues = valuesData?.put;
  let response;
  let result;

  response = await searchStaticData(
    valuesData?.name, valuesData?.search, valuesData?.compare);

  if (putValues) {
    result = [];
    const elements = putValues.split(',');
    Array.from(elements).forEach(elem => {
      const uniqueName = elem.split(':').shift();
      const value = elem.split(':').pop();
      const field = fieldUniqueName(uniqueName);
      let element = Array.from(data).filter(
        item => trimLowerCase(item.unique_name) === trimLowerCase(uniqueName));
      element = element?.pop();

      if (element?.id && field) {
        field.value = (response) ? response[value] : '';
        if (value !== 'function_get_values') {
          result.push({
            'uniqueName': `${uniqueName}__id__${element?.id}`,
            'value': (response) ? ((notUndefinedNull(
              response[value])) ? response[value] : null) : null
          });
        } else {
          putSelect(element, field, response, setValue);
        }
      }
    });
  }

  return result;
};

/**
 * Set default values
 * @param {Object} fields
 * @param {String} userId
 * @param {Function} setValue
 */
const defaultValueFieldsAux = (fields, userId, setValue) => {
  const { actualLanguage } = store.getState().base;
  const { field: fieldsData } = store.getState().staticData[
    `data_${trimLowerCase(actualLanguage)}`];
  Array.from(fields).forEach(item => {
    let itemField = Array.from(fieldsData).filter(child => (
      trimLowerCase(child.unique_name) === trimLowerCase(item.name)));
    let allField;
    itemField = itemField.pop();

    if (item.type === 'checkbox') {
      allField = [];
      const name = itemField.unique_name.split('__').pop();
      const elements = fieldsUniqueName(item.name);
      if (elements.length > 0) {
        Array.from(item.values).forEach((elem, index) => {
          elem.value = trimLowerCase(elem.value);
          if (elem.code === 'code') {
            let check = Array.from(elements).filter(child => (
              trimLowerCase(child.dataset?.code) === trimLowerCase(elem.value)));
            check = check?.pop();
            if (moduleCategory(check)) handleAttributes(check, item.attributes);
          }
          allField[index] = { user: userId };
          allField[index][name] = elem.value;
        });
      }
    }

    if (item.type === 'text') {
      let element = fieldUniqueName(item.name);
      if (notUndefinedNull(element)) {
        allField = '';
        handleAttributes(element, item.attributes);
      }
    }

    setValue(`${item.name}__id__${itemField.id}`, allField);
  });
};

/**
 * Validate response to endpoint
 * @param {Object} data        - Response of the API (document-condition) - 
 * @param {Object} field       - Field object, coming from self nomination (fields: ['document__search_type'])
 * @param {Function} setValue  - setValue function from RHF
 * @param {String} action      - 'default_value'.action                    (fields: ['document__search_type'])
 */
const validateResponse = (data, field, setValue, action) => {
  const actionValue = (notUndefinedNull(field.action)) ? JSON.parse(
    field.action) : null;
  const functionGetValues = (notUndefinedNull(
    field.function_get_values)) ? JSON.parse(field.function_get_values) : null;
  const condition = (field?.parent_condition) ? JSON.parse(
    field.parent_condition) : null;

  if (data[functionGetValues?.success]) {
    const result = (data[functionGetValues.success]);
    if (actionValue?.constants === 'fileCase' && condition.type === 'file') {
      setFileCases(
        functionGetValues.response, condition, setValue, action, result);
    }
  }
  if (data[functionGetValues.error]) {
    console.error('validateResponse: ', data[functionGetValues.error]);
  }
};