import React, { useContext } from 'react';
import { FormProvider } from 'react-hook-form';
import { useCompletedForm, useDescription, useFormTier, useTextLanguage } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { Button, Label, ModalQuestion, SubTitle } from '../shared/simple';
import { Category } from './Category';
import { LinkText } from './styled';
import { ModuleContext } from '../../context';
import { Row, Col } from 'react-bootstrap';
import { StyledRow, StepsContainer, StyledLink } from '../user/styled';
import { StyledFormTier } from '../shared/simple';
import { useFormSend, useInitFormTier } from '../../hooks/useFormTier';
import { Token } from './Token';
import { trimLowerCase } from '../../helpers';
import { baseField, contactSMS, paramsAdd, paramsEdit } from '../../constants';
import { Loading } from '../shared/Loading';
import { useSelector } from 'react-redux';

/**
 * Component form for tiers
 * @returns
 * @component
 */
export const Form = () => {
  const { t } = useTranslation();
  const [categoriesTier, tier, tierFieldState] = useContext(ModuleContext);
  const { data } = useSelector(state => state.personData);
  const { description } = useTextLanguage();
  const { action, loading, schemaTier } = useInitFormTier(tierFieldState);
  const { hideCategories, methods, onRouteProfile, steps } = useFormTier(
    tier, tierFieldState, categoriesTier, schemaTier);
  const {
    activeGroup,
    highstStep,
    lastTier,
    modalIsOpen,
    modalMessage,
    onChangeGroup,
    onChangeStep,
    onContinueStep,
    onFinishRequest,
    onSave,
    onSubmit,
    openModal,
    sendTier,
    setModalIsOpen
  } = useFormSend(hideCategories, methods, tier, tierFieldState);
  const {
    flagRequest
  } = useDescription();
  useCompletedForm(methods);

  return (
    <StyledRow className="p-0">
      <FormProvider { ...methods }>
        <StyledFormTier
          className="col-xs-12 p-3 pt-0"
          onSubmit={ methods.handleSubmit(onSubmit) }
          novalidate
        >
          {activeGroup > 1 && (
            <div style={{ display:'flex', justifyContent:'end' }}>
              <LinkText
                color="third"
                onClick={ onChangeGroup }
              >
                {`< ${ t("common.back") }`}
              </LinkText>
            </div>
          )}
          {steps && steps.length > 1 && (
            <StepsContainer>
              {steps.map((step, index) =>
                <div key={ index } style={{
                  height: 5,
                  width: `${100 / steps.length}%`,
                  margin: 5,
                  borderTop: `4px solid ${
                    step <= activeGroup ? "#006d72" : "#767676"}`
                }} />
              )}
            </StepsContainer>
          )}
          {hideCategories?.map(category => {
            return (
              (category.unique_name === baseField.modalValidateSms) && (
                <Token
                  category={ category }
                  key={`token_${category.unique_name}_${category.id}`}
                  openModal={ openModal }
                  tier={ tier }
                  tierFieldState={ tierFieldState }
                />
              )
            );
          })}
          {hideCategories?.filter(item => trimLowerCase(
            item.unique_name) !== baseField.modalValidateSms).map((
              category, index, row) => {
            return (
              <div
                className={`col-12 row ${category.action}`}
                style={ (category.step !== activeGroup) ? { display: "none" } : {} }
                id={`module_${category.id}`}
                key={`fragment_${category.unique_name}_${category.id}`}
              >
                { category?.active && (
                  <SubTitle
                    className="mb-2 mt-3"
                    key={`subtitle_${category.unique_name}_${category.id}`}
                  >
                    { category[description] }
                  </SubTitle>
                )}
                {(category.unique_name === contactSMS && action === paramsEdit) && (
                  <Label>
                    { t("profile.sms") }&nbsp;
                    <StyledLink
                      className="p-0"
                      onClick={ onRouteProfile }
                      color="first"
                      xs={12}
                    >
                      { t("profile.name") }
                    </StyledLink>
                  </Label>
                )}
                <Category
                  category={ category }
                  key={`category_${category.unique_name}_${category.id}`}
                />
              </div>
            );
          })}
          <Row className="justify-content-center m-0">
            <Col className="p-3 mt-5 mb-5" xs={12} md={6} xl={4}>
              {loading
                ? (
                  <Loading />
                ) : ((highstStep === activeGroup && sendTier) ? (
                  <>
                    {(action === paramsAdd) && (
                      <Button
                        className="btn mb-0"
                        type="button"
                        color="first"
                        onClick={ onSave }
                        name="btn_save"
                      >
                        { t("common.submit") }
                      </Button>
                    )}
                    {(!flagRequest && action === paramsEdit && tier.verification_level !== lastTier.verification_level) && (
                      <Button
                        className="btn mb-0"
                        type="button"
                        color="first"
                        onClick={ onSave }
                        name="btn_next"
                      >
                        { t("common.nextTier") }
                      </Button>
                    )}
                    {!flagRequest && (action === paramsEdit) && data.last_migrated_tier && lastTier.checked && (
                      <Button
                        className="btn mb-0"
                        type="button"
                        color="fourth"
                        onClick={ onFinishRequest }
                        name="btn_finish"
                      >
                        { t("common.finishRequest") }
                      </Button>
                    )}
                  </>
                ) : (
                  <Button
                    className="btn mb-0"
                    type="button"
                    color="fourth"
                    onClick={ onContinueStep }
                    name="btn_continue"
                  >
                    { t("common.continue") }
                  </Button>
                ))
              }
            </Col>
            <ModalQuestion
              modalIsOpen={ modalIsOpen }
              setModal={ setModalIsOpen }
              onChangeStep={ onChangeStep }
              message={ modalMessage }
            />
          </Row>
        </StyledFormTier>
      </FormProvider>
    </StyledRow>
  );
};