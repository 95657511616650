import React from 'react';
import styled from 'styled-components';
import { Image } from 'react-bootstrap';

// Define element styles for the component
const StyledLogo = styled(Image)`
  height: 100px;
  left: 30px;
  margin: auto;
  position: absolute;
  top: 20px;
  max-width: 350px;
  object-fit: contain;
  aspect-ratio: 4;

  @media (max-width: 767px) {
    height: 100px;
    top: 10px;
    width: 250px;
  }
`;

/**
 * Logo component
 * @component
 * @returns
 */
export const Logo = ({ ...props }) => {
  return <StyledLogo { ...props } />;
};
