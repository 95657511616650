import React from 'react';
import { logoBgAppOrigin } from '../../constants';
import { RouterLink, Logo } from '../shared/simple';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { routes, appsOrigin } from '../../constants';

// Define element styles for the component
const LinkStyled = styled(RouterLink)`
  height: 100px;
  max-width: 350px;
  margin: 30px !important;
`;

/**
 * LinkLogo component
 * @component
 * @returns
 */
export const LinkLogo = () => {
  const { infoProductRequest, selectedProduct } = useSelector(state => state.user);
  const image_main = infoProductRequest?.image_main || logoBgAppOrigin.default;
  const image_main_auth = selectedProduct?.image_main || logoBgAppOrigin.default;
  return (
    <RouterLink
      className="p-0 col-12 link-logo"
      to={routes.login(appsOrigin.default)}
    >
      <Logo
        className="d-none d-md-block"
        src={ infoProductRequest ? image_main : image_main_auth }
        name={`img_${infoProductRequest ? infoProductRequest?.application?.unique_name : !selectedProduct?.application ? "wisenroll" : selectedProduct.application?.unique_name}`}
      />
    </RouterLink>
  );
};