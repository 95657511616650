import {
  persistStore,
  persistReducer
} from 'redux-persist'
import localforage from 'localforage'
import { configureStore, combineReducers } from '@reduxjs/toolkit'

import {
  baseReducer,
  messageReducer,
  personDataReducer,
  staticDataReducer,
  tierReducer,
  userReducer
} from '../reducers';

const rootReducer = combineReducers({
  base: baseReducer,
  message: messageReducer,
  personData: personDataReducer,
  staticData: staticDataReducer,
  tier: tierReducer,
  user: userReducer
})

const persistConfig = {
  key: 'root',
  storage: localforage
};

const persistedReducer = persistReducer(persistConfig, rootReducer)

// Automatically adds the thunk middleware and the Redux DevTools extension
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false
  }),
  devTools: {
    autoPause: true,
    shouldRecordChanges: false
  }
});

const persistor = persistStore(store);

export { persistor, store };