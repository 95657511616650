/**
 * Types to tier
 */
export const TIER_ACTION = '[TIER] ACTION';
export const TIER_CATEGORIES = '[TIER] CATEGORIES';
export const TIER_CATEGORY = '[TIER] CATEGORY';
export const TIER_CLEAR = '[TIER] CLEAR';
export const TIER_CLEAR_CATEGORY = '[TIER] CLEAR CATEGORY';
export const TIER_LAST_TIER = '[TIER] LAST TIER';
export const TIER_PARAMS = '[TIER] PARAMS';
export const TIER_PARENTS = '[TIER] PARENTS';
export const TIER_SET_CURRENT_TIER = '[TIER] SET CURRENT TIER';
export const TIER_SET_SCHEMA = '[TIER] SET SCHEMA';
export const TIER_SET_TIERS = '[TIER] SET TIERS';
export const TIER_STEPS = '[TIER] STEPS';
export const TIER_VALUES_EDIT = '[TIER] EDITABLE FIELDS';