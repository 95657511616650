import React from 'react';
import PropTypes from 'prop-types';
import { StyledGroup, StyledControl } from './styled';
import { trimLowerCase } from '../../../helpers';
import { Error, Label, TooltipIcon } from '../simple';
import { useInput } from '../../../hooks';
import imgEyeClosed from '../../../assets/images/profile/eye-closed.png';
import imgEyeOpen from '../../../assets/images/profile/eye-open.png';

/**
 * Input component
 * @component
 * @param {String} autoComplete
 * @param {String} className
 * @param {Boolean} disabled
 * @param {Object} field
 * @param {String} id
 * @param {Boolean} isRequiredInput
 * @param {String} label
 * @param {String} message
 * @param {String} name
 * @param {String} placeholder
 * @param {Object} register
 * @param {Function} setValue
 * @param {String} tooltipText
 * @param {String} type
 * @returns
 */
export const Input = ({
  autoComplete,
  className,
  disabled,
  field,
  id,
  isRequiredInput,
  label,
  message,
  name,
  placeholder,
  register,
  setValue,
  tooltipText,
  type
}) => {
  const { fieldAction, onClick, onKeyDown, onKeyUp } = useInput(
    field, type, setValue);

  return (
    <StyledGroup className="mb-3">
      <Label htmlFor={ name || id }>
        { label }{isRequiredInput && (
          <strong className="text-danger">&nbsp;*</strong>)}
        {tooltipText && (<TooltipIcon tooltipText={ tooltipText } />)}
      </Label>
      <StyledControl
        { ...register }
        as={(trimLowerCase(type) === 'textarea') ? 'textarea' : ''}
        autoComplete={ autoComplete }
        className={ className }
        data-action={ field?.action }
        data-category={ (field?.category) && `category-${field.category.field_category}` }
        data-condition={ field?.parent_condition }
        data-parent={ field?.parent }
        data-unique-name={ field?.unique_name }
        disabled={ disabled }
        id={ id }
        key={ id }
        maxLength={ (field?.max_length) && field.max_length?.toString() }
        onKeyDown={ (event) => (type === 'number') ? onKeyDown(event) : null }
        onKeyUp={ (event) => (fieldAction?.validate) ? onKeyUp(event) : null }
        placeholder={ placeholder }
        type={ type }
      />
      {type === 'password' && (
        <span
          className="field-icon-toggle"
          onClick={ event => onClick(event, name) }
        >
          <img
            alt="Eye Open"
            className="toggle-password img-eye-open"
            data-password={ name }
            src={ imgEyeOpen }
          />
          <img
            alt="Eye Closed"
            className="toggle-password d-none img-eye-closed"
            data-password={ name }
            src={ imgEyeClosed }
          />
        </span>
      )}
      {message && (
        <Error message={ message } />
      )}
    </StyledGroup>
  );
};

Input.propTypes = {
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  field: PropTypes.object,
  id: PropTypes.string,
  isRequiredInput: PropTypes.bool,
  label: PropTypes.string.isRequired,
  message: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  setValue: PropTypes.func,
  register: PropTypes.object.isRequired,
  tooltipText: PropTypes.string,
  type: PropTypes.string.isRequired
};

Input.defaultProps = {
  field: {},
  setValue: function() {}
};