import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card , Col, Row } from 'react-bootstrap';
import { Email } from './Email';
import { Phone } from './Phone';
import { profile } from '../../../constants';

/**
 * Link component
 * @component
 * @param {String} codeCard
 * @param {String} color
 * @param {Array} data
 * @param {Function} setRequestEdit
 * @param {Function} setRequestTokenForce
 * @param {String} title
 * @returns
 */
export const Section = ({
  codeCard,
  color,
  data,
  setRequestEdit,
  setRequestTokenForce,
  title
}) => {
  const { t } = useTranslation();
  const { code } = useSelector(state => state.user);

  return (
    <Card className="mt-3 mb-2" style={{ borderColor: color }}>
      <Card.Body>
        <Card.Title className="mb-2" style={{ color: color }}>
          { title }
        </Card.Title>
        <Row>
          {(codeCard === profile.cardGeneral) && (
            <Col className="mb-1" xs={12} lg={6}>
              <small className="text-muted" xs={12}>
                <strong>{ t("homepage.code") }</strong>
              </small>&nbsp;<strong>{ code }</strong>
            </Col>
          )}
          {data.map(item =>
            <Col className="mb-1" lg={ item?.lg } xs={12} key={ item.key }>
              <small className="text-muted" xs={12}>
                <strong>{ item.name }</strong>
              </small>
              <p className="mb-0" xs={12} id={ item.key }>
                { item.text }
              </p>
            </Col>
          )}
          {(codeCard === profile.cardEmail) && (
            <Email
              color={ color }
              setRequestEdit={ setRequestEdit }
              setRequestTokenForce={ setRequestTokenForce }
            />
          )}
          {(codeCard === profile.cardPhone) && (
            <Phone
              color={ color }
              setRequestEdit={ setRequestEdit }
              setRequestTokenForce={ setRequestTokenForce }
            />
          )}
        </Row>
      </Card.Body>
    </Card>
  );
};

Section.propTypes = {
  codeCard: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  setRequestEdit: PropTypes.func,
  setRequestTokenForce: PropTypes.func,
  title: PropTypes.string.isRequired
};