import styled from 'styled-components';
import { theme } from '../../theme';

const { sixth, first } = theme;

// Define element styles for the component
export const Div = styled.div`
  background: ${sixth};
  margin-bottom: 50px;
  margin-top: 2rem;
`;

export const Block = styled.div`
  background: ${sixth};
  border-radius: 10px;
  border: 5px solid ${first};
  height: 100%;
  min-height: 0vh;
  padding: 10px 15px;
`;

export const Title = styled.h3`
  color: ${first};
`;

export const ImgList = styled.img`
  cursor: pointer;
  width: 100%;
  height: 100px;
  object-fit: contain;
`;

export const LabelList = styled.label`

  @media (min-width: 640px){
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
  }

`

export const Img = styled.img`
  height: 125px;
`;

export const DivImage = styled.div`
  cursor: pointer;
  padding: 10px;
  width: -webkit-fill-available;
  position: relative;
  width: 200px;
  text-align: center;
`;