import React, { useState, useEffect } from 'react';
import { useInput } from '../../../../hooks';
import { StyledGroup, StyledControl } from './styled';
import { Error, Label, TooltipIcon } from '../../simple';
import { useSelector } from 'react-redux';
import { envSetting } from '../../../../constants';
import PhoneInput from 'react-phone-number-input';
import PropTypes from 'prop-types';
import 'react-phone-number-input/style.css';
import { notUndefinedNull } from '../../../../helpers';

/**
 * Phonenumber Input component
 * @component
 * @param {String} autoComplete
 * @param {String} className
 * @param {Boolean} disabled
 * @param {Object} field
 * @param {String} id
 * @param {Boolean} isRequiredInput
 * @param {String} label
 * @param {String} message
 * @param {String} name
 * @param {String} placeholder
 * @param {Object} register
 * @param {Function} setValue
 * @param {String} tooltipText
 * @param {String} type
 * @returns
 */
export const PhoneNumberInput = ({
  autoComplete,
  className,
  disabled,
  field,
  id,
  isRequiredInput,
  label,
  message,
  name,
  placeholder,
  register,
  setValue,
  tooltipText,
  type,
  setCountryValue
}) => {
  const { fieldAction, onKeyDown, onKeyUp } = useInput(
    field, type, setValue);

  /* Redux */
  const base = useSelector(state => state.base);
  const countries = base?.allowedCountries?.map(country => country.code);
  const labels = base?.allowedCountries?.reduce((result, country) => {
    result[country.code] = country.name;
    return result;
  }, {});

  /* PhoneNumber */
  const [phoneValue, setPhoneValue] = useState('');

  const handleGetCountryChange = () => {
    if (notUndefinedNull(setCountryValue)) {
      const selectedCountry = document.getElementsByName(`${name}Country`)[0].value;
      const countryIdValue = base?.allowedCountries?.filter(country => country.code === selectedCountry)[0]?.country_id;
      setCountryValue(countryIdValue);
    }
  }

  useEffect(() => {
    if (notUndefinedNull(setCountryValue)) {
      handleGetCountryChange();
    }
  }, [])
  

  return (
    <StyledGroup className="mb-3">
      <Label htmlFor={ name || id }>
        { label }{isRequiredInput && (
          <strong className="text-danger">&nbsp;*</strong>)}
        {tooltipText && (<TooltipIcon tooltipText={ tooltipText } />)}
      </Label>
      <PhoneInput
        { ...register }
        labels={labels}
        value={phoneValue}
        onChange={setPhoneValue}
        onCountryChange={handleGetCountryChange}
        addInternationalOption={false}
        countries={countries}
        defaultCountry={envSetting.baseCountry}
        inputComponent={StyledControl}
        limitMaxLength={true}
        international={true}
        countryCallingCodeEditable={false}
        autoComplete={ autoComplete }
        className={ className }
        data-action={ field?.action }
        data-category={ (field?.category) && `category-${field.category.field_category}` }
        data-condition={ field?.parent_condition }
        data-parent={ field?.parent }
        data-unique-name={ field?.unique_name }
        disabled={ disabled }
        onKeyDown={ (event) => (type === 'number') ? onKeyDown(event) : null }
        onKeyUp={ (event) => (fieldAction?.validate) ? onKeyUp(event) : null }
        id={ id }
        key={ id }
        placeholder={ placeholder }
        type={ type }
      />

      {message && (
        <Error message={ message } />
      )}
    </StyledGroup>
  );
};

PhoneNumberInput.propTypes = {
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  field: PropTypes.object,
  id: PropTypes.string,
  isRequiredInput: PropTypes.bool,
  label: PropTypes.string.isRequired,
  message: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  setValue: PropTypes.func,
  register: PropTypes.object.isRequired,
  tooltipText: PropTypes.string,
  type: PropTypes.string.isRequired
};

PhoneNumberInput.defaultProps = {
  field: {},
  setValue: function() {}
};