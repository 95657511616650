import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { StyledGroup } from '../Input/styled';
import { Form } from 'react-bootstrap';
import { theme } from '../../../theme';
import { Error, Link } from '.';

const { first } = theme;

// Define element styles for the component
const StyledCheck = styled(Form.Check.Input)`
  border-radius: .5rem;
  outline: none;
  &:focus {
    border-color: ${first};
    box-shadow: none;
  }
`;

/**
 * Check component with input type checkbox
 * @component
 * @param {String} color
 * @param {Boolean} disabled
 * @param {String} href
 * @param {String} message
 * @param {Object} params
 * @param {Object} register
 * @param {String} translation
 * @param {String} type
 * @returns
 */
export const CheckLink = ({
  color,
  disabled,
  href,
  message,
  params,
  register,
  translation,
  type
}) => {
  const { t } = useTranslation();

  return (
    <StyledGroup className="mb-3">
      <Form.Check>
        <StyledCheck
          { ...register }
          disabled={ disabled }
          type={ type }
        />
        <Form.Check.Label>
          <Link
            href={ href }
            color={ color }
            target="_blank"
            xs={12}
          >
            { t(translation, params) }
          </Link>
        </Form.Check.Label>
      </Form.Check>
      {message && (
        <Error message={ message } />
      )}
    </StyledGroup>
  );
};

CheckLink.propTypes = {
  color: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  href: PropTypes.string.isRequired,
  message: PropTypes.string,
  params: PropTypes.object.isRequired,
  register: PropTypes.object.isRequired,
  translation: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};