import AlertModal from '../modal/Modal'
import CloseIcon from '../../../assets/images/slide-captcha-modal/close-btn.svg';
import { MuiOtpInput } from 'mui-one-time-password-input'
import { t } from 'i18next' 
import { useOtpInput } from '../../../hooks';
import { Timer } from '../../tier/Timer';

const OtpInputModal = ({
  isOpen,
  handleClose,
  title,
  values
}) => {
  const { otp, handleChange, handleComplete, otpRef, onClose } = useOtpInput({values, handleClose})

  return (
    <div>
      <AlertModal 
        isOpen={isOpen}
        overlayClassName="SlideModal__Overlay"
        modalClassName="SlideModal__Container"
      >
        <div className="Slide__Modal__Container">

          {title && <div className="SlideModal__Header">
                <h1>{t("otp_modal.title")}</h1>
          </div>}


          <MuiOtpInput 
            ref={otpRef} value={otp} 
            onChange={handleChange} onComplete={handleComplete} 
            length={6} 
            style={{marginBottom: '6px'}}
          />

          <Timer
            key={'otp_timer_key'}
            onExpired={() => null}
          />

          <div style={{ width: "100%", display: "flex", justifyContent: "end", marginTop: 10 }}>
            <img
              src={CloseIcon}
              alt=""
              className="SlideModal__Close-btn"
              onClick={onClose}
            />
          </div>
        </div>
      </ AlertModal>
    </div>
  )
}

export default OtpInputModal