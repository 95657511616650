import { endpoints } from '../constants';
import { setLoader } from '../redux/actions';
import { store } from '../redux/store/store';
import { sendGetEndpoint } from '../services/baseApi';
import { trimLowerCase } from './shared';

/**** Export functions  ****/
/**
 * Search to staticData
 * @param {String} name
 * @param {String} itemValue
 * @param {String} value
 * @returns
 */
export const searchStaticData = async (name, itemValue, value) => {
  const { applications } = store.getState().user;
  const { actualLanguage } = store.getState().base;
  const { [`data_${trimLowerCase(
    actualLanguage)}`]:staticData } = store.getState().staticData;
  let response;
  if (name === 'application') {
    response = Array.from(applications).filter(item => trimLowerCase(item.application[
      itemValue]) === trimLowerCase(value));
    response = response?.pop();
  } else {
    const dataName = staticData[name];
    response = Array.from(dataName).filter(item => trimLowerCase(item[
      itemValue]) === trimLowerCase(value));
    response = response?.pop();
    if (name === 'company' && !response) {
      store.dispatch(setLoader(true));
      try{
        const responseData = await sendGetEndpoint(endpoints.companyDataByCode(value));
        if (!responseData.error) {
          response = responseData.success;
        }
      } catch(e) {
        console.error(e)
      }
      store.dispatch(setLoader(false));
    }
  }

  return response;
};