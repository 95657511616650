import React from 'react';
import { useSelector } from 'react-redux';
import { logoAdsAppOrigin } from '../../../constants';
import { Div, StyledImage, StyledLogo } from './styled';
import ImageAds from '../../../assets/images/login/wisenroll.png';

/**
 * Ads component with structure, image and logo
 * @component
 * @returns
 */
export const Ads = () => {
  const { selectedProduct } = useSelector(state => state.user);

  return (
    <Div>
      {ImageAds && (
        <StyledImage src={ ImageAds } />
      )}
      <StyledLogo
        src={ (selectedProduct) ? selectedProduct?.image_ads || logoAdsAppOrigin.default : logoAdsAppOrigin.default }
      />
    </Div>
  );
};