import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMessage } from '../redux/actions';
import { sendPostRequest } from '../services/baseApi';
import { useNavigate } from 'react-router-dom';
import { endpoints, siteMap } from '../constants';
import { useTranslation } from 'react-i18next';

export const useDialog = ({ entries, selectedProduct }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bifrostUserId = useSelector(state => state.personData.data.bifrost_user_id);
  const [open, setOpen] = useState(false);
  const [openToken, setOpenToken] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCloseToken = () => {
    setOpenToken(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const requestEditionToken = async () => {
    setLoading(true);
    const result = await sendPostRequest({
      'bifrost_user_id': bifrostUserId,
      'token_type': 'beneficiary_edition'
    }, endpoints.requestEditionToken(bifrostUserId));
    setLoading(false);
    return result;
  };

  const handleSubmitTokenRequest = async () => {
    let response = await requestEditionToken();
    if (!response) {
      console.error('Error on requestEditionToken');
    } else {
      dispatch(setMessage(t("block.editionTokenSent"), 'success'));
    }
    setOpenToken(true);
    return response;
  };

  const requestTokenValidation = async (token) => {
    setLoading(true);
    const result = await sendPostRequest({
      'token': token,
      'bifrost_user_id': bifrostUserId,
      'token_type': 'beneficiary_edition'
    }, endpoints.requestEditionTokenValidation);
    setLoading(false);
    return result;
  };

  const postBeneficieries = async (data) => {
    setTimeout(() => {
      setMessage(t("block.updatingBeneficiaries"), 'info');
    }, 750);
    setLoading(true);
    const result = await sendPostRequest(data, endpoints.postNewBeneficiaries);
    return result;
  };

  const handleTokenValidation = async (token) => {
    let response = await requestTokenValidation(token);

    if (!response) {
      console.error('Error on requestTokenValidation');
    } else if (response.error) {
      dispatch(setMessage(t("block.errorTokenValidation"), 'error'));
    } else {
      dispatch(setMessage(t("block.successTokenValidation"), 'success'));
      let activeEntries = entries.filter(entry => entry.active).map(({
        active, client_related_account_id, order, user, isValid, ...entry}) => {
        return {
          ...entry,
          "client_product": selectedProduct.product_id,
          "bifrost_user_id": bifrostUserId
        };
      });

      const data = activeEntries.length
      ? activeEntries
      : {
        "client_product": selectedProduct.product_id,
        "bifrost_user_id": bifrostUserId,
        "deactivate_all": true
      };

      const result = await postBeneficieries(data);
      if (result.error) {
        setMessage(t("block.errorPostBeneficiaries"), 'error');
        setLoading(false);
      } else {
        //happy path
        setMessage(t("block.successPostBeneficiaries"), 'success');
        setTimeout(() => {
          navigate(siteMap.homepage.path);
        }, 2500);
      }
    }
  };

  return {
    open,
    handleClose,
    setOpen,
    handleSubmitTokenRequest,
    openToken,
    handleCloseToken,
    handleTokenValidation,
    loading
  };
};

export const useConditionsDialog = ({ handleClose, handleSubmit }) => {
  const { t } = useTranslation();
  const [condition1, setCondition1] = useState(false);
  const [condition2, setCondition2] = useState(false);

  const onClose = () => {
    setCondition1(false);
    setCondition2(false);
    handleClose();
  };

  const onSubmit = async() => {
    const response = await handleSubmit();
    if (response.error === false) {
      onClose();
    }
  };

  return { t, onClose, onSubmit, condition1, condition2, setCondition1, setCondition2 };
};

/**
 *
 * @param {*} param0
 * @returns
 */
export const useTokenDialog = ({ handleClose }) => {
  const { t } = useTranslation();
  const [token, setToken] = useState('');

  const onClose = () => {
    setToken('');
    handleClose();
  };

  return { token, setToken, onClose, t };
};