import { useState, useEffect } from 'react'
import { fieldAttribute } from '../helpers'
import { RecordRTCPromisesHandler, getSeekableBlob } from 'recordrtc'
import { recorderStatus } from '../constants'
import { setMessage } from '../redux/actions'
import { t } from 'i18next'
import { envSetting, QRPath } from '../constants'
import { BtnContainer } from '../components/user/styled'
import { Button } from '../components/shared/simple'
import { IoFilm, IoSave, IoPause, IoPlay, IoCheckmark, IoReload, IoClose } from 'react-icons/io5'
import { stopBothVideoAndAudio } from '../helpers'
import { useDispatch, useSelector } from 'react-redux'
import { setQRRoute } from '../redux/actions'
import { useLocation } from 'react-router-dom'
import { browserNames } from '../constants'

/**
 * Description placeholder
 * @date 13/12/2023 - 22:38:35
 * @param {Function} sendData  - Function thayt will handle the logic for sending the data
 * @param {String} browserInfo - Object holding the current browser's name and mimeType.
 * 
 * @returns {Object} An object containing the following functions and state:
 * - {Boolean}  isOpen           - is modal open
 * - {Function} handleOpen       - handles opening modal logic
 * - {Function} handleClose      - handles closing modal logic
 * - {String}   status           - current recording status  ('inactive' |'recording' | 'paused' | 'stopped' | 'displaying')
 * - {Number}   currentVideoSize - current video size, in bytes
 * - {Number}   fileSizeLimit    - File size limit
 * - {JSX}      buttons          - JSX for displaying the buttons, according to the value of 'status'
 *
 **/
export const useAutovideoQRPage = (sendData, browserInfo) => {
	const [isOpen, setIsOpen] = useState(false)
	const [stream, setStream] = useState(null)
	const [recorder, setRecorder] = useState(null)
	const [status, setStatus] = useState('inactive')
  const [savedBlob, setSavedBlob] = useState(null)
  let buttons
	const [currentVideoSize, setCurrentVideoSize] = useState(0)
  const fileSizeLimit = Math.round(parseInt(envSetting.videoSize) / Math.pow(1024, 2))
  /* Determining if video arrived to peer */
  const [loading, setLoading] = useState(false)
  const [mimetype, setMimetype] = useState('video/webm;codecs=vp9')
  const [isWarningOpen, setIsWarningOpen] = useState(false)
  const handleWarningOpen = () => { setIsWarningOpen(true) }
  const handleWarningClose = () => { setIsWarningOpen(false) }

  useEffect(() => {
    if(browserInfo?.name === browserNames.safari) setMimetype('video/webm;codecs=vp8')
  }, [browserInfo?.name])

  /* Functions */
  const handleClose = async () => {
    if(status !== recorderStatus.stopped) setStatus(recorderStatus.inactive) // Reset status, if not 'stopped'
    if (recorder && (status !== recorderStatus.stopped)){ // Avoid destroying recorder if status === 'stopped'
      try {
        await recorder?.destroy() 
      } catch (error) {
        console.error(error)
      }
    }      
    stopBothVideoAndAudio(stream)
    setCurrentVideoSize(0)
    setIsOpen(false)
    setLoading(false)
  }

  const handleOpen = async () => {
		try {
			const stream = await navigator.mediaDevices.getUserMedia({
				video: {
					width: 640
				},
				audio: true
			})
			setStream(stream)
			const recorder = new RecordRTCPromisesHandler(stream, { 
				type: 'video',
				timeSlice: 1000,
        mimeType: mimetype
      })
			setRecorder(recorder)
			setIsOpen(true)
		} catch (error) {
			console.error('Error encountered: ' + error)
			if(error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError'){
				setMessage(t('videoRecorder.errorMessagePermissionDenied'), 'error')
			}
			if(error.name === 'NotFoundError' || error.name === 'DevicesNotFoundError'){
				setMessage(t('videoRecorder.errorMessageNotConnected'), 'error')
			}
			if(error.name === 'NotReadableError' || error.name === 'TrackStartError'){
				setMessage(t('videoRecorder.errorMessageNotReadableError'), 'error')
			}
		}	

	}

  const startRecording = async () => {
    /* IIFE in order to track file size in real time */
		(async function looper (){
			if(!recorder || await recorder.getState() === recorderStatus.stopped){
				return
			}
			const internal = await recorder.getInternalRecorder()
			if(internal && internal.getArrayOfBlobs){
				const blob = new Blob(internal.getArrayOfBlobs(), {
					type: 'video/mp4'
				})
				setCurrentVideoSize(blob.size)

				if(blob.size >(envSetting.videoSize)){
					stopRecording(recorder)
					return
				}

			}
			setTimeout(looper, 500)
		})()
    recorder.startRecording()
    setStatus(await recorder.getState())
  }

  const pauseRecording = async () => {
    const video = fieldAttribute('data-video', 'video-recording')
    video.pause()
    recorder.pauseRecording()
    setStatus(await recorder.getState())
  }

  const resumeRecording = async () => {
    const video = fieldAttribute('data-video', 'video-recording')
    video.play()
    recorder.resumeRecording()
    setStatus(await recorder.getState())
  }

  const stopRecording = async () => {
    const video = fieldAttribute('data-video', 'video-recording')
    const preview = fieldAttribute('data-video', 'video-preview')
    const previewSrc1 = document.getElementById('video-preview-src-1')
    const previewSrc2 = document.getElementById('video-preview-src-2')
    const previewSrc3 = document.getElementById('video-preview-src-3')
    try {
      video.pause()
      await recorder.stopRecording()
      setStatus(await recorder.getState())  
      video.classList.add('d-none')
      const blob = await recorder.getBlob()
      setCurrentVideoSize(blob.size)

      preview.classList.remove('d-none')
      preview.pause()
      preview.controls = true

    	// Let's not use 'getSeekableBlob' in Safari, since there seems to be an ongoing issue with it.
			// https://github.com/muaz-khan/RecordRTC/issues/719
      if(browserInfo?.name === browserNames.safari){
        setSavedBlob(blob)
        const file = new Blob([blob], {type: 'video/mp4' })
        preview.src = URL.createObjectURL(file)
        previewSrc1.src = URL.createObjectURL(file)
        previewSrc2.src = URL.createObjectURL(file)
        previewSrc3.src = URL.createObjectURL(file)
      }else{
        getSeekableBlob(blob, (seekableBlob) => {
          setSavedBlob(seekableBlob)
          const seekableFile = new File([seekableBlob], {type: 'video/mp4'})
          preview.src = URL.createObjectURL(seekableFile)
          previewSrc1.src = URL.createObjectURL(seekableFile)
          previewSrc2.src = URL.createObjectURL(seekableFile)
          previewSrc3.src = URL.createObjectURL(seekableFile)
        })
      }
      preview.load()
      stopBothVideoAndAudio(stream)
    } catch (error) {
      setMessage(error, 'error')
    }

  }

  const retry = async () => {
    const video = fieldAttribute('data-video', 'video-recording')
    const preview = fieldAttribute('data-video', 'video-preview')
    preview.src = null
    preview.classList.add('d-none')
    video.classList.remove('d-none')
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
				video: {
					width: 640
				},
				audio: true
			})
			setStream(stream)
      video.srcObject = stream
      video.play()
      const recorder = new RecordRTCPromisesHandler(stream, { 
				type: 'video',
				timeSlice: 1000,
        mimeType: mimetype
			})
			setRecorder(recorder)
    } catch (error) {
      console.error(error)
    }
    setStatus(recorderStatus.displaying)
    setCurrentVideoSize(0)
    setSavedBlob(null)
  }

  const sendVideo = () => {
    setLoading(true)
    const data = {
      type:'video',
      data: savedBlob,
      browser: browserInfo?.name
		}
		sendData(data, () => {
      setLoading(false)
      setMessage(t('videoRecorder.videoReceived'), 'info')
      handleClose()
    },
    () => {
      setLoading(false)
      handleClose()
    }
    )
	}

  const cancelRecording = () => {
    const video = fieldAttribute('data-video', 'video-recording')
    video.pause()
		setCurrentVideoSize(0)
    
    if(window.confirm(t('videoRecorder.confirmCancel'))) {
      setCurrentVideoSize(0)
      handleClose()
    }else{
      video.play()
    }

  }

  /* Effects */
  useEffect(() => {
    setTimeout(() => {
      const video = fieldAttribute('data-video', 'video-recording')
      if(isOpen) {
        video.srcObject = stream
        setStatus(recorderStatus.displaying)
      }
    }, 100)
  }, [isOpen])

  /* Changing 'QRRoute.active' back to false, if needed. */
  const { QRRoute } = useSelector(state => state.base) 
  const dispatch = useDispatch()
  useEffect(() => {
    if(QRRoute?.active){
      dispatch(setQRRoute({
        active: false
      }))
    }
  }, [QRRoute?.active, dispatch])

  /* Styling */
	const btnExtraStyles = {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '8px',
		color: 'white',
		fontWeight: '500'
	}

  /* Create buttons Markup according to current 'status' */
  switch (status) {
    case 'displaying':
      buttons = (
        <BtnContainer>
          <Button key='0' color='first' type='button' className='btn' onClick={startRecording} style={btnExtraStyles}>
            {t('videoRecorder.start')}
            <IoFilm />
					</Button>
        </BtnContainer>
      )
      break;
    case 'recording':
      buttons = (
        <BtnContainer>
          <Button key='1' color='first' type='button' className='btn' onClick={stopRecording} style={btnExtraStyles}>
            {t('videoRecorder.save')} 
            <IoSave />
          </Button>
          <Button key='2' color='third' type='button' className='btn' onClick={pauseRecording} style={btnExtraStyles}>
						<IoPause />
					</Button>
					<Button key='3' color='second' type='button' className='btn' onClick={cancelRecording}  style={btnExtraStyles}>
						<IoClose />
					</Button>
        </BtnContainer>
      )
      break;
    case 'paused':
      buttons = (
        <BtnContainer>
          <Button key='4' color='first' type='button' className='btn' onClick={stopRecording} style={btnExtraStyles}>
            {t('videoRecorder.save')} 
            <IoSave />
          </Button>
          <Button key='5' color='third' type='button' className='btn' onClick={resumeRecording} style={btnExtraStyles}>
						<IoPlay />
					</Button>
					<Button key='6' color='second' type='button' className='btn' onClick={cancelRecording}  style={btnExtraStyles}>
						<IoClose />
					</Button>
        </BtnContainer>
        )
        break;
    case 'stopped':
      buttons = (
        <BtnContainer>
          <Button key='7' color='first' type='button' className='btn' onClick={sendVideo} style={btnExtraStyles}>
            {t('videoRecorder.send')} 
            <IoCheckmark />
          </Button>
          <Button key='8' color='fourth' type='button' className='btn' onClick={retry} style={btnExtraStyles}>
            <IoReload />
          </Button>
					<Button key='9' color='second' type='button' className='btn' onClick={cancelRecording}  style={btnExtraStyles}>
						<IoClose />
					</Button>
        </BtnContainer>
      )
      
      if(loading){
        buttons = (
          <BtnContainer>
            <Button key='10' color='first' type='button' className='btn' onClick={sendVideo} style={btnExtraStyles}>
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              {t('videoRecorder.loading')} 
            </Button>
          </BtnContainer>
          )
      }else{}
        break;

    default:
      break;
  }

	return {
		isOpen,
		handleOpen,
		handleClose,
		status,
    currentVideoSize,
    fileSizeLimit,
    buttons,
    mimetype,
    handleWarningOpen,
    handleWarningClose,
    isWarningOpen
	}
}

/**
 * Helper custom hook that will help us redirect to the AutovideoQRPage after login, given we have been redirected here from the QR code.
 * @param {Boolean} isAuthenticated - Is the user authenticated?
 * @date 18/12/2023 - 21:08:33
 **/
export const useQRRedirected = (isAuthenticated) => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const mainPath = pathname.split('/')[1]
  const peerId = pathname.split('/')[2]

  useEffect(() => {
    if(mainPath.toLowerCase() === QRPath.toLowerCase() && !isAuthenticated) {
      /* If the path equals our QRPath, and is not auth, set this redux prop that will help redirect after loggin in */
      dispatch(setQRRoute({
        active: true,
        peerId
      }))
    }
  }, [dispatch, mainPath, isAuthenticated, peerId])
}