import styled from 'styled-components';
import { Image, Form } from 'react-bootstrap';
import { theme } from '../../theme';
import { Link } from '../shared/simple';

const { sixth, first } = theme;

// Define element styles for the component
export const StyledImage = styled(Image)`
  height: 100px;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 100px;
  border: 1px solid #eee;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #eee;
`;

export const LinkText = styled(Link)`
  font-size: 18px;
  cursor: pointer;
`;

export const StyledCheck = styled(Form.Check)`
  border-radius: .5rem;
  outline: none;
  &:focus {
    border-color: ${sixth};
    box-shadow: none;
  }
`;

export const StyledSelect = styled(Form.Select)`
  &:focus {
    border-color: ${first};
    box-shadow: none;
  }
`;

export const StyledLogo = styled(Image)`
  height: 100px;
  margin-top: 15%;
  width: auto;

  @media (max-width: 767px) {
    margin-top: 50px;
  }
`;

export const StyledContainerQR = styled.div`
  position:relative;
  display:flex;
  flex-direction: column;
  padding-inline: 0 !important;
  align-items: center;
  & > h1{
    font-family: 'Roboto', sans-serif;
    padding-block: .75rem;
    margin: 0;
  }

  & > section{
    margin-top:20px
  }
`