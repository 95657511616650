import React from 'react';
import { useAppOriginParam } from '../hooks';
import { Ads } from '../components/shared/Ads';
import { Col } from 'react-bootstrap';
import { GetStartedEmail } from '../components/user/GetStartedEmail';

/**
 * Get started page
 * @returns {JSX}
 */
const GetStartedEmailPage = () => {
  useAppOriginParam();

  return (
    <>
      <Col className="p-0" xs={12} md={6} xl={7}>
        <GetStartedEmail />
      </Col>
      <Col className="p-0 d-none d-md-block" md={6} xl={5}>
        <Ads />
      </Col>
    </>
  );
};

export default GetStartedEmailPage;