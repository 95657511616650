import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { optionColor } from '../../../helpers';

// Define element styles for the component
const StyledButton = styled(Button)`
  color: ${props => optionColor(props.color)};
  padding: 0 .25rem!important;
  &:hover {
    color: ${props => optionColor(props.color)};
  }
`;

/**
 * TooltipIcon component with icon for help
 * @component
 * @param {String} tooltipText
 * @returns
 */
export const TooltipIcon = ({ tooltipText, color }) => {
  return (
    <OverlayTrigger
      overlay={<Tooltip>{ tooltipText }</Tooltip>}
      placement="top"
    >
      <StyledButton size="sm" variant="link" color={ color }>
        <FontAwesomeIcon icon={ faInfoCircle } />
      </StyledButton>
    </OverlayTrigger>
  );
};

TooltipIcon.propTypes = {
  tooltipText: PropTypes.string.isRequired,
};

TooltipIcon.defaultProps = {
  color: 'first'
};