import React from 'react';
import PropTypes from 'prop-types';
import {
  AutocompleteGeolocation,
  AuxInput,
  DateInput,
  FileInputButton,
  LabelConversion,
  Maps,
  MoneyInput,
  SignatureInput,
  TextInput
} from './input';
import { Button, LoaderSpinner } from '../shared/simple';
import {
  AutocompleteTypeahead,
  CheckboxSelect,
  ImageSelect,
  Select,
  RadioSelect,
  CheckSelect
} from './select';
import { Timer } from './Timer';
import {
  mediaUploadAccept,
  mediaUploadLabel,
  mediaVideoAccept,
  mediaVideoLabel,
  uniqueFileNames
} from '../../constants';
import { VideoMessage } from './VideoMessage';
import { getShowField, trimLowerCase } from '../../helpers';
import { useCategory, useTextLanguage } from '../../hooks';
import { Block } from './block';
import VideoInputRTC from './autovideoRTC/VideoInputRTC';

const getNumberOfMinutes = valueField => {
  if (valueField) {
    const value = JSON.parse(valueField);
    return value.text;
  }

  return valueField;
};

/**
 * Component with fields for tier
 * @component
 * @param {Object} category
 * @param {Boolean} loading
 * @param {Function} onClickToken
 * @param {Boolean} showTimer
 * @param {Function} submitModal
 * @returns
 */
export const Category = ({
  category,
  loading,
  onClickToken,
  showTimer,
  submitModal
}) => {
  const { categoryFields } = useCategory(category);
  const { description } = useTextLanguage();

  return (
    <>
      {categoryFields?.map(field => {
        if (field.active && getShowField(field)) {
          if ((trimLowerCase(field.data_type) === 'text' && !JSON.parse(field?.action)?.isBlock) ||
            trimLowerCase(field.data_type) === 'email' ||
            trimLowerCase(field.data_type) === 'number' ||
            trimLowerCase(field.data_type) === 'url' ||
            trimLowerCase(field.data_type) === 'textarea') {
            return (
              <TextInput
                field={ field }
                key={ field.id }
              />
            );
          }
          if (trimLowerCase(field.data_type) === 'autocomplete_geolocation') {
            return (
              <AutocompleteGeolocation
                field={ field }
                key={ field.id }
              />
            );
          }
          if (trimLowerCase(field.data_type) === 'image_selection') {
            return (
              <ImageSelect
                field={ field }
                key={ field.id }
              />
            );
          }
          if (trimLowerCase(field.data_type) === 'checkbox_selection' ||
            trimLowerCase(field.data_type) === 'media_selection') {
            return (
              <CheckboxSelect
                field={ field }
                key={ field.id }
              />
            );
          }
          if (trimLowerCase(field.data_type) === 'money') {
            return (
              <MoneyInput
                field={ field }
                key={ field.id }
              />
            );
          }
          if (trimLowerCase(field.data_type) === 'checkbox' && !JSON.parse(field?.action)?.isBlock) {
            return (
              <CheckSelect
                field={ field }
                key={ field.id }
              />
            );
          }
          if (trimLowerCase(field.data_type) === 'date') {
            return (
              <DateInput
                field={ field }
                key={ field.id }
              />
            );
          }
          if (trimLowerCase(field.data_type) === 'selection' && !JSON.parse(field?.action)?.isBlock) {
            if (field?.action && JSON.parse(field?.action).isTypeahead) {
              return (
                <AutocompleteTypeahead
                  field={ field }
                  key={ field.id }
                />
              );
            }
            return (
              <Select
                field={ field }
                key={ field.id }
              />
            );
          }
          if (trimLowerCase(field.data_type) === 'block'){
            return (
              <Block
                field={ field }
                key={ field.id }
              />
            );
          }
          if (trimLowerCase(field.data_type) === 'radio_selection') {
            return (
              <RadioSelect
                field={ field }
                key={ field.id }
              />
            );
          }
          if (trimLowerCase(field.data_type) === 'modal_label') {
            return (
              <p key={ field.id }>{ field[description] }</p>
            );
          }
          if (trimLowerCase(field.data_type) === 'timer' && showTimer) {
            return (
              <Timer
                key={ field.id }
                onExpired={() => null}
                numberOfMinutes={ getNumberOfMinutes(field.default_value) }
              />
            );
          }
          if (field.unique_name === 'modal__validate_token') {
            return (loading ? (
              <LoaderSpinner key="loader_spinner" />
            ) : (
              <Button
                key={ field.id }
                disabled={ loading }
                className="btn mb-0"
                color="first"
                type="button"
                onClick={ submitModal }
                name="btn_submit_modal"
              >
                { field[description] }
              </Button>
            ));
          }
          if (field.unique_name === 'modal__resend') {
            return (
              (!loading) && (
                <Button
                  key={ field.id }
                  className="btn mb-0"
                  color="fourth"
                  type="button"
                  onClick={ onClickToken }
                  name="btn_token"
                >
                  { field[description] }
                </Button>
              )
            );
          }
          if (trimLowerCase(field.data_type) === 'message') {
            return (
              <VideoMessage
                field={ field }
                key={ field.id }
              />
            );
          }
          if (trimLowerCase(field.data_type) === 'media_video') {
            return (
              <VideoInputRTC
                key={field.id}
                field={field}
                accept={ mediaVideoAccept }
                formatAccept={mediaVideoLabel}
                type='video'
              />
            )
          }
          if (trimLowerCase(field.data_type) === 'media_upload') {
            return (
              <FileInputButton
                accept={ mediaUploadAccept }
                formatAccept={ mediaUploadLabel }
                field={ field }
                key={ field.id }
                type={(field.unique_name === uniqueFileNames[field.file_name])
                  ? "document"
                  : "image"}
              />
            );
          }
          if (trimLowerCase(field.data_type) === 'media_signature') {
            return (
              <SignatureInput
                field={ field }
                key={ field.id }
              />
            );
          }
          if (trimLowerCase(field.data_type) === 'conversion') {
            return (
              <LabelConversion
                field={ field }
                key={ field.id }
              />
            );
          }
          if (trimLowerCase(field.data_type) === 'aux') {
            return (
              <AuxInput
                field={ field }
                key={ field.id }
              />
            );
          }
          if (trimLowerCase(field.data_type) === 'map') {
            return (
              <Maps
              field={ field }
              key={ field.id }
              />
            );
          }
          if (trimLowerCase(field.data_type) === 'typeahead') {
            return (
              <AutocompleteTypeahead
                field={ field }
                key={ field.id }
              />
            );
          }
        }
        return null;
      })}
    </>
  );
};

Category.propTypes = {
  category: PropTypes.object,
  loading: PropTypes.bool,
  onClickToken: PropTypes.func,
  showTimer: PropTypes.bool,
  submitModal: PropTypes.func
};