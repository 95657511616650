import {useState, useEffect} from 'react'
import { setMessage } from '../redux/actions'
import { useDispatch } from 'react-redux'
import {t} from 'i18next'

export const useIncomingVideoModal = (browserInfo) => {
  const [openIncomingVideoModal, setOpenIncomingVideoModal] = useState(false)
  const handleCloseIncomingVideoModal = () => setOpenIncomingVideoModal(false)
  const [payload, setPayload] = useState(null)

  const dispatcher = useDispatch()
  const handleOpenIncomingVideoModal = (data) => { 
    setOpenIncomingVideoModal(true)
    setPayload(data)
    dispatcher(setMessage(t('videoRecorder.videoReceived'), 'info'))
  }

  useEffect(() => {

    const setVideoPreview = async () => {
      const video = document.getElementById('preview-qr')
      const src = document.getElementById('preview-qr-src-1')
      const src2 = document.getElementById('preview-qr-src-2')
      const src3 = document.getElementById('preview-qr-src-3')

      if(openIncomingVideoModal && video) {
        const file = new File([payload.data], { type: 'video/mp4' })
        const newObjectUrl = URL.createObjectURL(file) 
        const oldObjectUrl = video.currentSrc

        if(oldObjectUrl && oldObjectUrl.startsWith('blob:')){
          video.src = ''
          URL.revokeObjectURL(oldObjectUrl)
        }

        src.src = newObjectUrl
        src2.src = newObjectUrl
        src3.src = newObjectUrl
        video.load()
      }
    }

    setTimeout(() => {
      setVideoPreview()
    }, 100)
  }, [openIncomingVideoModal, payload?.data])

  return {
    openIncomingVideoModal,
    handleOpenIncomingVideoModal,
    handleCloseIncomingVideoModal,
    payload,
    setPayload
  }
}



