import React from 'react';
import { useBlock } from '../../../hooks/useBlock';
import { Entry } from './Entry';
import PropTypes from 'prop-types';

/**
 * Component Block
 * @component
 * @param {Object} field
 * @returns {JSX.Element}
 */
export const Block = ({ field }) => {
  const {
    fields,
    maxLength,
    handleAdd,
    setEntries,
    entries,
    canContinue,
    setCanContinue
  } = useBlock(field);

  return (
    <div className="row">
      {entries.map((entry) => {
        return(
          <Entry
            fields={fields}
            setEntries={setEntries}
            entry={entry}
            key={entry.id}
            maxLength={maxLength}
            entries={entries}
            canContinue={canContinue}
            setCanContinue={setCanContinue}
            handleAdd={handleAdd}
          />
        );
      })}
    </div>
  );
};

Block.propTypes = {
  field: PropTypes.object.isRequired
};