import { MESSAGE_SET } from '../types';
import { toast } from 'react-toastify';
import { notUndefinedNull } from '../../helpers';

/**** Export functions  ****/
/**
 * Show message
 * @param {String} message
 * @param {String} variant
 * @returns
 */

export const setMessage = (description, variant, session=false) => {
  const optionToast = {
    position: 'top-center',
    autoClose: session ? 60000 : 7000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored'
  };

  if (notUndefinedNull(description)) {
    if (variant === 'success') {
      toast.success(description, optionToast);
    } else if (variant === 'error') {
      toast.error(description, optionToast);
    } else if (variant === 'warning') {
      toast.warn(description, optionToast);
    } else if (variant === 'info') {
      toast.info(description, optionToast);
    } else {
      toast(description, optionToast);
    }

    return ({
      type: MESSAGE_SET,
      payload: { variant, description }
    });
  }

  return ({
    type: MESSAGE_SET,
    payload: {
      variant,
      description: ''
    }
  });
};