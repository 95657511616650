import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import {
  useAutocompleteTypeahead,
  useSelect,
  useTextLanguage,
  useEdit,
  useDisabled
} from '../../../hooks';
import { Col } from 'react-bootstrap';
import { Error, Label, TooltipIcon } from '../../shared/simple';
import { StyledGroup } from '../../shared/Input/styled';
/* RBT */
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';

/**
 * Component Typeahead
 * @component
 * @param {Object} field
 * @returns
 */
export const AutocompleteTypeahead = ({ field }) => {
  const uniqueName = `${field.unique_name}__id__${field.id}`;
  const { description, languageText } = useTextLanguage();
  const { register, formState: { errors }, getValues } = useFormContext();
  const { options } = useSelect(field, uniqueName);
  const { colSize } = useEdit(field);
  const {
    onChange,
    onLabelKey,
    t
  } = useAutocompleteTypeahead(field);

  /* Since we want to be able to set some values conditionally for RBT,
  our component will have to be 'controlled' (hold an internal state) */
  const [selected, setSelected] = useState([]);
  const { isDisabled } = useDisabled(field);

  /* In case of existing values (when editing) */
  const existingOption = options.find(
    opt => Number(opt.idValue) === Number(getValues(uniqueName)));

  useEffect(() => {
    if (getValues(uniqueName) && existingOption) {
      setSelected([{...existingOption}]);
    }
  }, [existingOption, options, uniqueName, getValues, field]);

  const handleChange = option => {
    onChange(option);
    setSelected(option);
  };

  return (
    <>
      {field && (
        <>
          {(colSize?.includes('col-before')) && (
            <Col xs={12} key={`col_before_${field.id}`}></Col>
          )}
          <Col
            className={ colSize }
            data-module={ `module-${field?.category?.field_category}` }
            data-name={ uniqueName }
            id={ `group_field_${field.id}` }
            key={ field.id }
            xs={12}
          >
            <StyledGroup className="mb-3">
              <Label htmlFor={ uniqueName }>
                { field[description] }{(field.required) && (
                  <strong className="text-danger">&nbsp;*</strong>)}
                {(field?.[`tooltip${languageText}`]) && (
                  <TooltipIcon tooltipText={ field[`tooltip${languageText}`] } />)}
              </Label>
              <Typeahead
                clearButton
                { ...register(uniqueName) }
                id={ field.id }
                labelKey={ onLabelKey }
                onChange={ handleChange }
                options={ options }
                selected={selected}
                disabled={isDisabled}
                placeholder={ t("common.optionDefault") }
                renderMenuItemChildren={(option) => (
                  <option
                    data-code-parent={ option?.codeParent }
                    data-code={ option?.codeValue }
                    data-code-id={ option?.idValue }
                    data-id={ option?.codeId }
                    id={ option.idValue }
                    key={ option.idValue }
                    value={ option.idValue }
                    style={{display: "contents"}}
                  >
                    { (option?.[`textValue${languageText}`])
                      ? (option[`textValue${languageText}`])
                      : option.textValue }
                  </option>
                )}

                renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
                  <input
                    { ...inputProps }
                    data-code={ selected[0]?.codeValue }
                    data-id={ selected[0]?.idValue }
                    className="form-control"
                    data-action={ field.action }
                    data-category={ `category-${field?.category?.field_category }`}
                    data-condition={ field.parent_condition }
                    data-parent={ field.parent }
                    data-unique-name={ field.unique_name }
                    id={ field.id }
                    name={ uniqueName }
                    ref={(input) => {
                      inputRef(input);
                      referenceElementRef(input);
                    }}
                  />
                )}
              />
              {errors?.[uniqueName]?.message && (
                <Error message={ errors[uniqueName].message } />
              )}
            </StyledGroup>
          </Col>
        </>
      )}
    </>
  );
};

AutocompleteTypeahead.propTypes = {
  field: PropTypes.object.isRequired
};